import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Alert, Button, CloseIcon, RightIcon, Switch, Typography } from '@uy3/web-components';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask } from 'utils/constants';
import { cnpjMask, cpfMask, cpfMaskAndSpace } from 'utils/constants';
import { useApiRequest } from 'contexts/apiRequestContext';
import { typeAccountsNaturalPerson } from 'contexts/bankAccount/bankAccountType';
import { useBankList } from 'contexts/bankList';
import { useFormContext } from 'contexts/formContext';
import { pixKeyTypeValueOpt } from 'services/accounts/bankAccount/bankAccount.types';
import { activeTheme } from 'services/theme';

type CreateBeneficiaryFormProps = {
    onClose: () => void;
};

const theme = activeTheme();

const CreateBeneficiaryForm = ({ onClose }: CreateBeneficiaryFormProps) => {
    const { bankListAutocomplete } = useBankList({ compe: false });
    const { submitError } = useApiRequest();
    const { watch, validationErrors, setValue, submitting } = useFormContext();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, '').length > 11;

    return (
        <Stack direction="column" spacing={3}>
            <Grid item xs={6}>
                <SelectFormField
                    name="pixKeyTypeValue"
                    label="Selecione o tipo da chave pix"
                    defaultValue={false}
                    options={pixKeyTypeValueOpt}
                />
            </Grid>

            {watch('pixKeyTypeValue') === 'Phone' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        name="keyPix"
                        label={`Telefone`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: phoneNumberMask },
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'Automatic' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        name="keyPix"
                        label={`Chave aleatória`}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'NaturalRegistrationNumber' ? (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        name="keyPix"
                        label={`Chave Pix CPF`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: cpfMask },
                        }}
                        fullWidth
                    />
                </Grid>
            ) : (
                <></>
            )}
            {watch('pixKeyTypeValue') === 'LegalRegistrationNumber' && (
                <Grid item xs={6}>
                    <TextFormField
                        style={{ width: '100%' }}
                        name="keyPix"
                        variant="outlined"
                        label="CNPJ"
                        placeholder="00.000.000/0000-00"
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: cnpjMask },
                        }}
                    />
                </Grid>
            )}
            {watch('pixKeyTypeValue') === 'Email' && (
                <Grid item xs={6}>
                    <TextFormField
                        name="keyPix"
                        label={`Chave Pix Email`}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}
            {watch('pixKeyTypeValue') === 'AgencyAndAccount' && (
                <>
                    <TextFormField name="name" label="Nome" variant="outlined" fullWidth />
                    <Grid item xs={6}>
                        <TextFormField
                            name="registrationNumber"
                            label="CPF/CNPJ"
                            variant="outlined"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteField
                            name="bank"
                            label="Selecione um banco"
                            displayName="bankDisplay"
                            {...bankListAutocomplete}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            label="Agência (sem o digito)"
                            name="agency"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            label="Conta"
                            name="accountNumber"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField
                            name="accountType"
                            label="Tipo de conta"
                            options={typeAccountsNaturalPerson}
                        />
                    </Grid>

                    <Stack direction="row" alignItems="center">
                        <Switch
                            id="simple-switch-label"
                            onChange={(_: any, checked: boolean) => {
                                setValue('saveBeneficiary', checked);
                            }}
                            checked={watch('saveBeneficiary')}
                            name='saveBeneficiary'
                        />
                        <Typography variant="sm" color="neutral.medium" lineHeight="19px" ml={2}>
                            Salvar novas informações do contato para transações futuras
                        </Typography>
                    </Stack>
                </>
            )}
            {validationErrors && (
                <Box>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar: Corrija os erros do formulário e envie novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <ApiErrorAlert error={submitError} />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 17, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-next"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    disabled={submitting}
                    variant="contained"
                    size="medium"
                    type="submit"
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};

export default CreateBeneficiaryForm;
