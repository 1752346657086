import { Grid, Stack } from '@mui/material'
import { AddIcon, Button, Typography } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import { activeTheme } from 'services/theme'

type WarrantyHeaderTabProps = {
    handleAdd: () => void
}

const theme = activeTheme();
export const WarrantyHeaderTab = ({ handleAdd }: WarrantyHeaderTabProps) => {
    const colorBlack = theme.palette.common.black;
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Garantias
                </Typography>
            </Grid>
            <Grid>
                <Button
                    name='btn-add-involved-list'
                    variant="text"
                    onClick={handleAdd}
                    sx={{ color: colorBlack }}
                    startIcon={<AddIcon htmlColor={colorBlack} sx={iconSx} />}
                >
                    Adicionar garantia
                </Button>
            </Grid>
        </Stack>
    )
}

