import React, { useState } from 'react';
import ManagePixKeyList from './ManagePixKeyList';
import {
    useBankAccountDeleteKeyPixMutation,
    useBankAccountPixKeyData,
} from 'contexts/bankAccount/bankAccountPixKey';
import { useParams } from 'react-router';
import { Stack } from '@mui/material';
import { Button, Popup, UpdateIcon } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { ApiResponseError, ToastType, showErrorToast, toastState } from 'contexts/apiRequestContext';
import { ManagePixKeyFormContainer } from '../ManagePixKeyForm/ManagePixKeyFormContainer';
import { Error } from 'components/Errors/Error';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { ManagementPixKeyType } from 'services/accounts/bankAccount/bankAccount.types';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

export const ManagePixKeyListContainer = () => {
    const { bankAccountId } = useParams();
    const [rowsPerPage, setRowsPerPage] = React.useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const [page, setPage] = React.useState(0);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [rowData, setRowData] = useState<ManagementPixKeyType | null>(null);

    const { data: pixKeyData, refetch, isFetching, status, error } = useBankAccountPixKeyData(bankAccountId!);

    const onSuccess = () => {
        refetch();
        setRowData(null);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useBankAccountDeleteKeyPixMutation(bankAccountId!, onSuccess, onError);
    const deletePixKey = (keyPix: string) => mutate(keyPix);

    if (status === 'error') return <Error error={error} />;

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => setPage(page);

    if (pixKeyData?.type === 'error') return <Error error={pixKeyData} />

    return (
        <ErrorBoundary fallback='gerenciamento de chave Pix'>
            <Toast toast={toast} setToast={setToast} />
            <ManagePixKeyFormContainer refetch={refetch} />
            <RefreshProgress refreshing={isFetching} />

            <Stack direction='row' justifyContent='right'>
                {pixKeyData && pixKeyData?.length > 0 && <Button
                    sx={{ color: 'black', mr: 2 }}
                    variant="text"
                    size="medium"
                    onClick={() => refetch()}
                    startIcon={
                        <UpdateIcon
                            width={20}
                            height={20}
                            htmlColor={isFetching ? 'grey' : 'black'}
                        />
                    }
                    color="primary"
                >
                    Atualizar
                </Button>}
            </Stack>
            <ManagePixKeyList
                queryData={pixKeyData ?? []}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                setRowData={(values) => setRowData(values)}
            />
            <Popup
                open={rowData !== null}
                onClose={() => setRowData(null)}
                title="Excluir"
                text={`Tem certeza que deseja excluir a chave Pix 
                    ${formatBankAccountManagePixKey(rowData?.typeDisplay ?? "", rowData?.pixKey ?? "") ?? ''}
                `}
                children={
                    <Stack direction="row" spacing={2} mt={2} justifyContent="right">
                        <Button variant="outlined" onClick={() => setRowData(null)}>
                            Não, cancelar
                        </Button>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => deletePixKey(rowData?.pixKey!)}
                        >
                            Sim, confirmar
                        </Button>
                    </Stack>
                }
            />
        </ErrorBoundary>
    );
};
