import { creditAxiosApi } from "services/axiosApi/axiosApi";
export type TenantFiltersProps = {
    page: number;
    size: number;
    searchString?: string | undefined;
    tenant?: string;
};

let url = `/Tenant`;
export async function getTenantList(filters: TenantFiltersProps, token: string) {
    const { searchString, ...rest } = filters;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            searchString: searchString ? searchString : undefined,
            ...rest,
        },
    };

    return await creditAxiosApi.get(url, config);
}
