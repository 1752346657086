import { Grid, Stack } from '@mui/material'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { Alert, Button } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { useGetRejectionReason } from 'contexts/creditNote/creditContext';

type CancelFormFormProps = {
    hasWarrantyStatus: boolean;
    setPopupProps: (open: boolean | undefined) => void;
}

export const CancelForm = ({ hasWarrantyStatus, setPopupProps }: CancelFormFormProps) => {
    const { watch } = useFormContext();
    const { listReasons } = useGetRejectionReason();

    return (
        <Stack>
            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} mt={1}>
                    <SelectFormField
                        label='Motivo da Reprovação'
                        name='rejectionReasonCode'
                        required
                        multiple
                        options={listReasons}
                    />
                </Grid>

                <><Grid item xs={12} mt={2}>
                    <SelectFormField
                        name="insertBlock"
                        label="Realizar Bloqueio?"
                        fullWidth
                        options={[
                            { label: 'Não', value: null },
                            { label: 'Tomador', value: "Person" },
                            { label: 'Operação', value: "Operation" }
                        ]}
                    />
                </Grid>
                    {watch("insertBlock") !== null &&
                        <Grid item xs={12} mt={2}>
                            <Grid item>
                                <DatePickerFormField
                                    label="Data de Bloqueio"
                                    name="blockUntil"
                                    fullWidth
                                    disabled={false}
                                    disablePast
                                />
                            </Grid>
                        </Grid>}

                    <Grid item xs={12} mt={3}>
                        <TextFormField
                            name="message"
                            label="Digite o Motivo"
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>

                </>
            </Grid>

            {hasWarrantyStatus &&
                <Grid m='20px 0 10px 0'>
                    <Alert severity='info' text='Não é possível cancelar a operação na etapa de garantia. Por favor, entre em contato com a área do financeiro.' />
                </Grid>
            }

            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setPopupProps(undefined)}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={hasWarrantyStatus}
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    )
}
