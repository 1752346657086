import { Grid, Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import { useGetRejectionReason } from 'contexts/creditNote/creditContext'
import { complianceChecklist, reasonDisapprovalSigaturiesList } from 'contexts/creditNote/creditNoteOptions'
import { useFormContext } from 'contexts/formContext'
import { PersonReponseParams, useGetNaturalOrLegalPerson } from 'contexts/personContext'
import { ComplianceChecklistProps } from 'services/creditNote/complianceChecklist/complianceChecklist'

type RejectFormProps = {
  onClose: () => void;
  statusCreditNote: string;
  personInformation: PersonReponseParams;
}

const RejectForm = ({ onClose, statusCreditNote, personInformation }: RejectFormProps) => {
  const { watch } = useFormContext();
  const { data: naturalOrLegalPerson } = useGetNaturalOrLegalPerson(personInformation);
  const { listReasons } = useGetRejectionReason();

  const showMessage = watch('rejectionReasonCode')?.includes("OUTROS_MOTIVOS");

  return (
    <Stack direction='column' spacing={2} mt={3}>
      {["ComplianceApproval", "Liquidation", "ManualLiquidation"].includes(statusCreditNote) && (
        <>
          <SelectFormField
            label='Motivo da Reprovação'
            name='rejectionReasonCode'
            required
            multiple
            options={listReasons}
          />

          <Grid item xs={12} mt={2}>
            <SelectFormField
              name="insertBlock"
              label="Realizar Bloqueio?"
              options={[
                { label: 'Não', value: null },
                { label: 'Tomador', value: 'Person' },
                { label: 'Operação', value: 'Operation' },
              ]}
            />
          </Grid>

          {watch('insertBlock') !== null && (
            <Grid item xs={12} mt={2}>
              <DatePickerFormField
                label="Validade do Bloqueio"
                name="blockUntil"
                fullWidth
                required
                disabled={false}
                disablePast
              />
            </Grid>
          )}

          {watch('insertBlock') === 'Person' &&
            <Grid item xs={12} mt={2}>
              <SelectFormField
                label='Incluir na Blacklist'
                name='complianceChecklist'
                required
                multiple
                options={complianceChecklist(naturalOrLegalPerson as ComplianceChecklistProps)}
              />
            </Grid>}

          {showMessage && (
            <Grid item xs={12} mt={3}>
              <TextFormField
                name="message"
                label="Digite o Motivo"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>)
          }
        </>
      )}

      {statusCreditNote === 'SignaturesValidation' &&
        <>
          <Grid item xs={12} mt={2}>
            <SelectFormField
              label='Motivo da Reprovação'
              name='reasonDisapproval'
              required
              multiple
              options={reasonDisapprovalSigaturiesList}
            />
          </Grid>
          {watch('reasonDisapproval')?.includes('OUTROS_MOTIVOS') &&
            <Grid item xs={12} mt={2}>
              <TextFormField
                name="message"
                label="Digite o Motivo"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                disabled={false}
                required
              />
            </Grid>
          }
        </>
      }

      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          size="medium"
          sx={{ mt: 3, mr: 2 }}
        >
          Não, cancelar
        </Button>
        <Button
          name='btn-confirm-compliance'
          variant="contained"
          color="primary"
          type="submit"
          size="medium"
          sx={{ mt: 3 }}
        >
          Sim, confirmar
        </Button>
      </Grid>
    </Stack>
  )
}

export default RejectForm
