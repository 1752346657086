import { Grid, Stack, SvgIconProps } from '@mui/material'
import { Button, CloseIcon, CompleteIcon, PersonIcon, RegistrationIcon } from '@uy3/web-components'
import DrawerAddPerson from 'components/DrawerAddPerson/DrawerAddPerson'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { SelectFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import { iconSx } from 'contexts/apiRequestContext'
import { useAppConfig } from 'contexts/appConfig'
import { useFormContext } from 'contexts/formContext'
import { useRelatedBankAccountData } from 'contexts/relatedBankAccountContext'
import { isEmpty } from 'lodash'
import { getFieldOptions } from 'helpers/methods/formFieldConfiguration'
import { handlePersonLink } from 'pages/CreditNote/CreditNoteForm/Tabs/InformationTab'
import React, { FunctionComponent, useState } from 'react'
import { activeTheme } from 'services/theme'
import { IDesconto } from 'services/creditNote/types/creditNoteReadModel'

type OpenCreditNoteFormProps = {
    productAutocomplete: AutocompleteOptionList;
    fundAutocomplete: AutocompleteOptionList;
    personAutocomplete: AutocompleteOptionList;
    onClose: () => void;
    descontoData: IDesconto
}

const theme = activeTheme();

export const OpenCreditNoteForm: React.FC<OpenCreditNoteFormProps> = ({
    fundAutocomplete,
    personAutocomplete,
    productAutocomplete,
    onClose, 
    descontoData
}) => {
    const { appConfig } = useAppConfig();
    const [actionAddGeneric, setActionAddGeneric] = useState<string | undefined>(undefined);
    const { watch, readOnly } = useFormContext()
    const [personId, personDiscriminator] = watch(['personId', 'personDiscriminator']);
    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const fieldConfigOptions = (fieldName: string) => getFieldOptions(fieldName, formFieldsConfig);
    const onCloseGeneric = () => setActionAddGeneric(undefined);

    const { data: personBankList } = useRelatedBankAccountData(descontoData?.personId ??  personId, descontoData?.personDiscriminator ?? personDiscriminator);

    const fieldBankAccountIdOptions = fieldConfigOptions('bankAccountId');
    const bankAccountIdOptions = fieldBankAccountIdOptions || (personBankList?.relatedBankAccountData ?? []);

    const enableAddPerson = actionAddGeneric === 'addPerson';
    const enableAddBankAccount = actionAddGeneric === 'addBankAccount';

    return (
        <Stack spacing={2}>
            <Grid>
                <AutocompleteField
                    name="personId"
                    label="Tomador"
                    displayName="personDisplay"
                    required
                    endIconType="link"
                    link={handlePersonLink({
                        personId: personId,
                        personDiscriminator: watch('personDiscriminator'),
                    })}
                    showEndIcon={personId}
                    {...personAutocomplete!}
                />
                <ActionFlexEnd
                    action={() => setActionAddGeneric('addPerson')}
                    Icon={PersonIcon}
                    title='Adicionar tomador'
                />
            </Grid>
            <Grid>
                <SelectFormField
                    name="bankAccountId"
                    label="Conta de liquidação"
                    variant="outlined"
                    options={bankAccountIdOptions}
                    fullWidth
                    required
                />
                {!isEmpty(watch('personId')) &&
                    <ActionFlexEnd
                        title='Adicionar conta'
                        Icon={RegistrationIcon}
                        action={() => setActionAddGeneric('addBankAccount')}
                    />}
            </Grid>

            <Grid>
                <AutocompleteField
                    name="productId"
                    label="Produto"
                    displayName="productIdDisplay"
                    endIconType="link"
                    link={`/cadastro/produtos-credito/${watch('productId')}`}
                    showEndIcon={watch('productId')}
                    required
                    {...productAutocomplete}
                />
            </Grid>
            <Grid>
                <AutocompleteField
                    name="fundId"
                    label="Fundo Cessionário"
                    displayName="fundIdDisplay"
                    link={`/cadastro/fundos/${watch('fundId')}`}
                    showEndIcon={watch('fundId')}
                    endIconType="link"
                    required
                    {...fundAutocomplete!}
                />
            </Grid>
            <DrawerAddPerson
                open={enableAddPerson || enableAddBankAccount}
                isAddPerson={enableAddPerson}
                isAddBankAccount={enableAddBankAccount}
                onClose={onCloseGeneric}
                toggleDrawer
            />

            <Stack direction='row' spacing={2} alignItems='center' justifyContent='end'>
                <Button
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    variant='outlined'
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    variant='contained'
                    type='submit'
                    disabled={readOnly}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack >
    )
}


const ActionFlexEnd = ({ action, Icon, title }: {
    action: () => void,
    title: string,
    Icon: FunctionComponent<SvgIconProps>
}) => {
    return (
        <Stack direction='row' justifyContent='flex-end' mt={1} mb={-1}>
            <Button
                name="btn-add-person"
                startIcon={
                    <Icon htmlColor={theme.palette.primary.main} sx={iconSx} />
                }
                variant="text"
                onClick={action}
            >
                {title}
            </Button>
        </Stack>

    )
}