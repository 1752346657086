import {
    ApiResponseError,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from '../../apiRequestContext';
import { useIdentity } from '../../identityContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { BankSlipDebtCollectionCancelType, BankSlipFull, BankSlipParamsProps, IBankSlip, LiquidationPostType, PayloadTUpdateOtherInformationsType, UpdateAmountBankSlipType } from 'services/walletManagement/bankSlip/bankSlip.types';
import { bankSlipDebtCollectionCancel, bankSlipUpdateAmount, bankSlipUpdateOtherInformation, cancelAbatmentAsync, downBankSlipByBarCode, downloadBankSlipByBarCode, getBankSlipListAsync, getBankSlipListByBarCodeAsync, insertAbatmentBankSlipByBarCode, postBankSlipAgainRegisterAsync, protestBankSlipByBarCode, settlementSTRByBarCode, transferBankSlipByBarCode, updateDueDateBankSlipByBarCode } from 'services/walletManagement/bankSlip';
import { useState } from 'react';
import { useFilterValues } from 'contexts/filterValuesContext';
import { isEmpty } from 'lodash';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { cancelBillingBId, sendEmailBillingById, updateCategoryBillingByBillingId } from 'services/walletManagement/billing';
import { useTenant } from 'contexts/tenantContext';

export function useBankSlipList(filters: BankSlipParamsProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const [billingList, setBillingList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'Billing' : filterValues.recordType;
    const listDataFilters = recordType === 'Billing' && { ...filterValues.filters };
    const filtersComplete = { ...listDataFilters, ...filters, tenent: currentTenantId };

    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContextBilling = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['bankslip-get-all-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getBankSlipListAsync(filtersComplete, token);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as GetListApiResponseSuccess<BankSlipFull>;
            const options = dataSuccess.data.map((item) => ({
                label: `${item.agreementCode} (${item.assignor})`,
                value: item.id,
            }));
            setBillingList(options);

            return data as BankSlipFull[];
        },
    });

    const billingAutoCompleteProps = {
        listOptions: billingList,
        loading: queryContextBilling.isLoading || queryContextBilling.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ searchString }, 'Billing')
    };

    return {
        ...queryContextBilling,
        billingAutoCompleteProps,
    };
};


export function useBankSlipAgainRegisterMutation(
    onSuccess: (data: BankSlipFull | string) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (barCode: string) => {
            startRequest();
            try {
                const { data, status, statusText } = await postBankSlipAgainRegisterAsync(barCode, token!);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data as BankSlipFull;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useBankSlipCancelAbatmentMutation(
    onSuccess: (data: BankSlipFull | string) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (barCode: string) => {
            startRequest();
            try {
                const { data, status, statusText } = await cancelAbatmentAsync(barCode, token!);
              
                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data as BankSlipFull;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useBankSlipByBarCodeList(bankSlipId: string) {
    const { token } = useIdentity();

    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const bankSlipQuery = useQuery({
        enabled: !!token && bankSlipId !== undefined && (!!bankSlipId && !isEmpty(bankSlipId)),
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['bank-slip-by-id', bankSlipId],
        queryFn: async (): Promise<IBankSlip> => {
            startRequest();
            const { data, status, statusText } = await getBankSlipListByBarCodeAsync(bankSlipId!, token);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            return data as IBankSlip;
        },
    });

    return bankSlipQuery
};


export function useUpdateDueDateMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (novoVencimento: string) => {
            startRequest();
            try {
                const { data, status, statusText } = await updateDueDateBankSlipByBarCode(barCode, novoVencimento, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { updateDueDateMutateAsync: mutateAsync, isLoading, error };
}

export function useDownloadBankSlipByBarCodeMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (walltCode?: string) => {
            startRequest();
            return await downloadBankSlipByBarCode(barCode, walltCode, token)
                .then((response: any) => {
                    endRequest(true);
                    const file = new Blob([response?.data], { type: 'application/pdf' });
                    const contentDisposition = response?.headers.get('Content-Disposition');
                    const filename = contentDisposition.slice(contentDisposition.indexOf("''")).replace("''", "");
                    const path = document.createElement('a');
                    path.href = URL.createObjectURL(file);
                    path.download = filename;
                    path.click();
                    onSuccess && onSuccess(response)
                }).catch((error: ApiResponseError) => {
                    onError(error)
                    endRequest(false);
                });
        }
    });

    return { downloadMutateAsync: mutateAsync, isLoading, error };
}

export function useSettlementSTRBankSlipMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (payload: LiquidationPostType) => {
            startRequest();
            try {
                const { data, status, statusText } = await settlementSTRByBarCode(barCode, payload, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { settlementSTRBankSlipMutateAsync: mutateAsync, isLoading, error };
}


export function useProtestBankSlipMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async () => {
            startRequest();
            try {
                const { data, status, statusText } = await protestBankSlipByBarCode(barCode, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { protestBankSlipMutateAsync: mutateAsync, isLoading, error };
}

export function useDownBankSlipMutation(
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async ({ keepBillingOpen, barCode }: { keepBillingOpen: boolean, barCode: string }) => {
            startRequest();
            try {
                const { data, status, statusText } = await downBankSlipByBarCode(barCode, keepBillingOpen, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { downBankSlipMutateAsync: mutateAsync, isLoading, error };
}

export function useUpdateCategoryBillingMutation(
    billingId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (tags: string[]) => {
            startRequest();
            try {
                const { data, status, statusText } = await updateCategoryBillingByBillingId(billingId, tags, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { updateCategorysSlipMutateAsync: mutateAsync, isLoading, error };
}

export function useCancelBillingMutation(
    billingId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (isPaid: boolean) => {
            startRequest();
            try {
                const { data, status, statusText } = await cancelBillingBId(billingId, isPaid, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { cancelBillingMutateAsync: mutateAsync, isLoading, error };
}

export function useSendEmailBillingByIdMutation(
    billingId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async () => {
            startRequest();
            try {
                const { data, status, statusText } = await sendEmailBillingById(billingId, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { sendEmailBillingMutateAsync: mutateAsync, isLoading, error };
}

export function useInsertAbatmentBankSlipMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (value: string) => {
            startRequest();
            try {
                const { data, status, statusText } = await insertAbatmentBankSlipByBarCode(barCode, value, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { insertAbatmentSlipMutateAsync: mutateAsync, isLoading, error };
}

export function useBankSlipDebtCollectionCancelMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (payload: BankSlipDebtCollectionCancelType) => {
            startRequest();
            try {
                const { data, status, statusText } = await bankSlipDebtCollectionCancel(barCode, payload, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { bankSlipDebtCollectionCancelMutate: mutateAsync, isLoading, error };
}

export function useTransferBankSlipMutation(
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async ({ barCode, toWalletCode }: {toWalletCode: string, barCode: string}) => {
            startRequest();
            try {
                const { data, status, statusText } = await transferBankSlipByBarCode(barCode, toWalletCode, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            endRequest(false);
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { transferSlipMutateAsync: mutateAsync, isLoading, error };
}

export function useUpdateOtherInformationBankSlipMutation(
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async ({ barCode, payload }: PayloadTUpdateOtherInformationsType) => {
            startRequest();
            try {
                const { data, status, statusText } = await bankSlipUpdateOtherInformation(barCode, payload, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { updateOtherInformationAsync: mutateAsync, isLoading, error };
}

export function useUpdateAmountBankSlipMutation(
    barCode: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (payload: UpdateAmountBankSlipType) => {
            startRequest();
            try {
                const { data, status, statusText } = await bankSlipUpdateAmount(barCode, payload, token);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error };
}
