import { FormProvider } from "contexts/formContext";
import { defaultValuesApproveForm, validationSchemaApproveForm } from "../CreditLimitSchema";
import { useCreditApprovalMutation } from "contexts/creditStatusRequest";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { AproverCreditStatusRequestPost } from "services/creditStatusRequest/creditStatusRequest.types";
import ApproveCreditRequestForm from "./ApproveCreditRequestForm";

type ApproveCreditRequestFormContainerProps = {
    closeModal: () => void;
    refetch: () => void;
    rowData: any;
};

function ApproveCreditRequestFormContainer({ refetch, closeModal, rowData }: ApproveCreditRequestFormContainerProps) {
    const { setSubmitError } = useApiRequest();
    const schema = validationSchemaApproveForm();

    const onSuccess = () => { refetch(); closeModal(); };
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutateAsync } = useCreditApprovalMutation(onSuccess, onError);
    const handleSubmit = async (values: AproverCreditStatusRequestPost) => {
        mutateAsync({ ...values, creditStatusRequestId: rowData.id });
    };

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValuesApproveForm}
            onSubmit={handleSubmit}>
            <ApproveCreditRequestForm closeModal={closeModal} />
        </FormProvider>
    );

};

export default ApproveCreditRequestFormContainer;