import { Stack } from '@mui/material'
import { AddIcon, Button, Typography } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'
import { activeTheme } from 'services/theme'

type SignatoriesHeaderProps = {
    handleClick: () => void;
}
const theme = activeTheme();
export const SignatoriesHeader = ({ handleClick }: SignatoriesHeaderProps) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Signatários
            </Typography>

            <Button
                variant='text'
                startIcon={<AddIcon sx={iconSx}/>}
                onClick={handleClick}
                sx={{color: theme.palette.common.black}}
            >
                Adicionar signatário
            </Button>
        </Stack>
    )
}
