import { GridColDef } from '@mui/x-data-grid'
import { DataTable } from '@uy3/web-components'
import { maskCurrencyInCents } from 'helpers/formats/Currency'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { IAdicional } from 'services/remessasVortx/remessasVortx.type'

type AdditionalsListProps = {
    queryData: IAdicional[]
}

export const AdditionalsList = ({ queryData }: AdditionalsListProps) => {
    const columns: GridColDef[] = [
        {
            field: 'cpf',
            headerName: 'CPF',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => formatDocumentNumber(value)

        },
        {
            field: 'protocolo',
            headerName: 'Protocolo',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1
        },
        {
            field: 'dataPrevistaRepasse',
            headerName: 'Data prevista',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => {
                let date = String(value);
                let year = date.slice(0,4) 
                let month = date.slice(4).slice(2) 
                let day = date.slice(6) 
                return new Date(`${year}-${month}-${day}T00:00:00`).toLocaleDateString('pt-br')
            }
        },
        {
            field: 'dataRepasse',
            headerName: 'Data repasse',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => new Date(value).toLocaleDateString('pt-br')
        },
        {
            field: 'valorGarantia',
            headerName: 'Valor garantia',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => `${maskCurrencyInCents(value)}`
        }
    ];

    return (
        <DataTable
            headerBarWrapper={{
                header: 'Adicionais'
            }}
            columns={columns}
            rows={queryData ?? []}
            page={0}
            rowCount={queryData?.length ?? 0}
            rowsPerPage={5}
            setPage={() => { }}
            setRowsPerPage={() => { }}
            getRowId={(x) => x?.cpf + x?.protocolo}
        />
    )
}
