import { string, object } from "yup"

export const validationSchemaSelectWarrantyForm = () => {
    return object().shape({
        warrantySelected: string().nullable()
    })
}

export const defaultvaluesSelectWarrantyForm = {
    warrantySelected: null 
}