import React, { FunctionComponent } from 'react';
import {
    RadioGroup,
    Radio as MuiRadio,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
    SvgIconProps,
    Grid,
} from '@mui/material';
import { Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { ControlOptionRadio } from 'components/Forms/Radio/Radio';
import { TooltipComponent, TooltipComponentProps } from 'components/Tooltip/Tooltop';

export interface IOption {
    value: string | number;
    label: string;
    Icon?: FunctionComponent<SvgIconProps>;
    description?: string;
    disabled?: boolean;
    tooltipComponent?: TooltipComponentProps;
}

export type HandleChangeCardsSelectedProps = {
    value: string | string[] | number;
    displayName?: string;
    checked?: boolean;
};

interface CardsSelectProps {
    options: IOption[];
    value: string;
    onChange: (props: HandleChangeCardsSelectedProps) => void;
    selectedList?: string[] | number[];
    isCheckbox?: boolean;
}

const theme = activeTheme();
const CardsSelect: React.FC<CardsSelectProps> = ({
    options,
    value,
    onChange,
    selectedList,
    isCheckbox = false,
}) => {
    const handleChange = (event: any, checked: boolean, label: string) => {
        let value = event.target.value;
        onChange({ value, checked, displayName: label });
    };

    const renderLabel = (option: IOption) => {
        const { disabled, Icon, label, description, tooltipComponent } = option;
        return (
            <Stack direction="row" alignItems="center">
                {Icon && (
                    <Typography ml={3} variant="h5">
                        <Icon htmlColor={disabled ? '#9EA7AB' : theme.palette.common.black} />
                    </Typography>
                )}
                <Grid>
                    <Typography ml={Icon ? 1 : 3} variant="h5">
                        {label}
                    </Typography>
                    <Typography
                        ml={Icon ? 1 : 3}
                        display="block"
                        variant="subtitle1"
                        sx={{ width: !!tooltipComponent ? '95%' : 'auto' }}
                    >
                        {description ?? ''}
                    </Typography>
                </Grid>
            </Stack>
        );
    };

    const renderFormControlLabel = (option: IOption, isActive: boolean) => {
        const { disabled, label } = option;
        const valueOpt = option.value;

        const isChecked =
            selectedList?.some((item) => item === valueOpt) || value?.includes(String(valueOpt));

        return (
            <FormControlLabel
                sx={{
                    border: `1.5px solid ${isActive ? theme.palette.primary.main : '#A0A0A0'}`,
                    borderRadius: 2,
                    padding: '20px 10px',
                    marginBottom: 3,
                    position: 'relative',
                    cursor: 'pointer',
                    '.MuiFormControlLabel-label': {
                        fontSize: '1.5rem',
                        ml: -2,
                    },
                }}
                control={
                    <Checkbox
                        size="medium"
                        checked={isChecked}
                        sx={{
                            '&.Mui-checked': { color: theme.palette.primary.main },
                            '& .MuiSvgIcon-root': { fontSize: '2.5rem' },
                            position: 'absolute',
                            right: 2,
                        }}
                    />
                }
                label={renderLabel(option)}
                disabled={disabled}
                value={option.value}
                onChange={(event: React.SyntheticEvent<Element, Event>, checked: boolean) =>
                    handleChange(event, checked, label)
                }
            />
        );
    };

    const renderRadioGroup = (option: IOption, isActive: boolean) => (
        <React.Fragment key={option.value}>
            <RadioGroup value={value ?? null} sx={{ ml: 1, width: '100%' }}>
                <ControlOptionRadio
                    disabled={option.disabled}
                    key={option.value}
                    sx={{
                        border: `1.5px solid ${isActive ? theme.palette.primary.main : '#A0A0A0'}`,
                        borderRadius: 2,
                        padding: '20px 10px',
                        marginBottom: 3,
                        position: 'relative',
                        '.MuiFormControlLabel-label': {
                            fontSize: '1.5rem',
                            ml: -2,
                        },
                    }}
                    control={
                        <MuiRadio
                            size={'medium'}
                            sx={{
                                '&.Mui-checked': {
                                    color: theme.palette.primary.main,
                                },
                                position: 'absolute',
                                right: 2,
                            }}
                        />
                    }
                    label={renderLabel(option)}
                    value={option.value}
                    onChange={(event: React.SyntheticEvent<Element, Event>, checked: boolean) =>
                        handleChange(event, checked, option.label)
                    }
                />
            </RadioGroup>
        </React.Fragment>
    );

    return (
        <Stack>
            <FormGroup sx={{ ml: 1, width: '100%' }}>
                {options.map((option) => {
                    const valueOpt = String(option.value);
                    const isActive =
                        selectedList?.some((item) => item === valueOpt) ||
                        value?.includes(String(valueOpt));

                    const Render = isCheckbox ? renderFormControlLabel(option, isActive) : renderRadioGroup(option, isActive);

                    return (
                        <React.Fragment>
                            {!!option.tooltipComponent ? (
                                <TooltipComponent {...option.tooltipComponent}>
                                    <Grid>{Render}</Grid>
                                </TooltipComponent>
                            ) : (
                                Render
                            )}
                        </React.Fragment>
                    );
                })}
            </FormGroup>
        </Stack>
    );
};

export default CardsSelect;
