import { Grid, Stack } from '@mui/material';
import { Button, CardData, CloseIcon, Typography } from '@uy3/web-components';
import { useAppConfig } from 'contexts/appConfig';
import { formatWitTenant } from 'helpers/formats/StringFormats';
import moment from 'moment';
import { activeTheme } from 'services/theme';

type BankAccountItemDetailsProps = {
    informationData: any;
    setOpenDrawer: (open: boolean) => void;
    title: string;
};

const formatDate = (date: string | Date) => {
    return new Date(date).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
    });
};

const theme = activeTheme();
export const BankAccountItemDetails = ({
    informationData,
    setOpenDrawer,
    title = 'Informações',
}: BankAccountItemDetailsProps) => {
    const { appConfig } = useAppConfig();
    const isRootTenancy = appConfig.TENANT_TYPE === 0;
    const errorMessage = informationData?.errorMessage ?? '';
    const createdBy = informationData?.createdBy;
    const updatedBy = informationData?.updatedBy;

    const cardData = [
        {
            id: '1',
            title: 'Criado por',
            value: `${formatWitTenant(createdBy, isRootTenancy) ?? 'N/D'}`,
        },
        {
            id: '2',
            title: 'Data da criação',
            value: `${formatDate(informationData?.createdAt)}`,
        }
    ]

    if (!!updatedBy) cardData.push(
        {
            id: '3',
            title: 'Atualizado por',
            value: `${formatWitTenant(updatedBy, isRootTenancy) ?? 'N/D'}`,
        },
        {
            id: '4',
            title: 'Data da atualização',
            value: `${formatDate(informationData?.updatedAt)}`,
        })

    return (
        <Stack spacing={2}>
            <Typography variant="lg" fontWeight={700}>{title}</Typography>
            <CardData
                listItem={cardData}
            />
            {informationData?.approvals?.length > 0 && (
                <>
                    <Typography variant='lg' fontWeight="700">Fluxo de aprovação</Typography>
                    {informationData.approvals.length > 0 &&
                        informationData.approvals.map((item: any) => {
                            return (
                                <Grid>
                                    <CardData
                                        listItem={[
                                            {
                                                id: 'resp',
                                                title: 'Resposta',
                                                value: `${item?.actionDisplay === 'Aprovar'
                                                    ? 'Aprovado'
                                                    : 'Reprovado'
                                                    }`,
                                            },
                                            {
                                                id: 'operator',
                                                title: 'Operador',
                                                value: ` ${formatWitTenant(item?.user, isRootTenancy)}`,
                                            },
                                            {
                                                id: 'data-resp',
                                                title: 'Data da resposta',
                                                value: `${moment(item?.createdAt).format(
                                                    'DD/MM/YYYY'
                                                )}`,
                                            },
                                        ]}
                                    />
                                </Grid>
                            );
                        })}
                </>
            )}
            {errorMessage?.length > 0 && (
                <>
                    <Typography variant="h5">Erros de processamento</Typography>
                    <CardData
                        listItem={[
                            {
                                id: '1',
                                value: <Typography variant="sm">{errorMessage}</Typography>,
                            },
                        ]}
                    />
                </>
            )}
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={{ height: 18, width: 19 }} />}
                    variant="outlined"
                    size="medium"
                    onClick={() => setOpenDrawer(false)}
                >
                    Fechar
                </Button>
            </Stack>
        </Stack>
    );
};
