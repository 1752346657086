import { useMutation, useQuery } from "@tanstack/react-query";
import { editOrCreateCreditProduct, getCreditProductById, getModalityListAsync, getProductsList, getSubModalityListAsync } from "services/creditProduct";
import { useIdentity } from "./identityContext";
import { ApiResponseError, GetListApiResponse, GetListApiResponseSuccess, IRefetchOnMountModeGetQuery, useApiRequest } from "./apiRequestContext";
import { AxiosError, isAxiosError } from "axios";
import { Modality, ModalityListProps, ProductFiltersProps, CreditProductReadModel, SubModalityProps } from 'services/creditProduct/types/creditProductReadModel';
import { useTenant } from "./tenantContext";
import { useState } from "react";
import { useAppConfig } from "./appConfig";
import { CreditProductFormSchema } from "pages/Product/CreditProduct/CreditProductForm/CreditProductSchema";
import { useFilterValues } from "./filterValuesContext";
import { useUserPermissionData } from "./userContext";
import { isEmpty } from 'lodash';
import { getCosifAccountTransaction } from "services/creditNote";

export function useCreditProductData(productId?: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditProduct', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!productId && productId !== "novo" && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["product-credit", productId],
        queryFn: async (): Promise<CreditProductReadModel> => {
            startRequest();
            const { data, status, statusText } = await getCreditProductById(productId!, token, currentTenantId);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            endRequest(true);
            return data as CreditProductReadModel;
        }
    });

    return {
        creditProductStatus: status,
        creditProductData: data,
        creditProductErr: error,
        creditProductFetch: isFetching,
        refetch,
        creditProductLoading: isLoading,
    };
}

type ProductOptionList = {
    label: string;
    value: string;
    amortizationType: string;
    maximumInterestRate: number;
    minimumInterestRate: number;
    maximumPrincipalAmount: number;
    minimumPrincipalAmount: number;
    maximumAutomaticLiquidationValueInCents: number;
    allowFinanceTaxExemption: boolean;
    isConsumerCreditNote: boolean;
    maximumAmortizationPeriods: number;
    useCollateral: boolean;
    defaultCollateralDescription: string;
    warrantyRegistrationOffice: string;
};

export function useCreditProdutList(filters: ProductFiltersProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { appConfig } = useAppConfig();
    const { token } = useIdentity();
    const [creditProductList, setCreditProduct] = useState<ProductOptionList[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditProduct', 'Read');

    const recordType = isEmpty(filterValues.recordType) ? 'CreditProduct' : filterValues.recordType;
    const listDataFilters = recordType === 'CreditProduct' && { ...filterValues.filters };
    const isAutocomplete: boolean = refetchOnMount === 'always';
    const filtersParams = isAutocomplete ? { 
        ...filters, 
        size: 10, 
        tenant: currentTenantId, 
        isConsiderPostingDeadline: true
    } as ProductFiltersProps : filters; // it's a specific case
    const filtersComplete = { ...filtersParams, ...listDataFilters, tenant: currentTenantId };
    const isRootTenancy = appConfig.TENANT_TYPE === 0;

    const queryContextCreditProduct = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ["products-list", filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<CreditProductReadModel>> => {
            startRequest();
            const resp = await getProductsList(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            const dataSuccess = data as GetListApiResponseSuccess<CreditProductReadModel>;
            const options = dataSuccess.data.map((item) =>
            ({
                label: isRootTenancy ? `${item.name || "Não informado"} (${item.tenantDisplay})` : item.name,
                value: item.id,
                amortizationType: item.amortizationType,
                instrumentType: item?.instrumentType,
                maximumInterestRate: item.maximumInterestRate,
                minimumInterestRate: item.minimumInterestRate,
                maximumPrincipalAmount: item.maximumPrincipalAmount,
                minimumPrincipalAmount: item.minimumPrincipalAmount,
                maximumAutomaticLiquidationValueInCents: item.maximumAutomaticLiquidationValueInCents,
                allowFinanceTaxExemption: item.allowFinanceTaxExemption,
                isConsumerCreditNote: item.isConsumerCreditNote,
                maximumAmortizationPeriods: item.maximumAmortizationPeriods,
                useCollateral: item.useCollateral,
                defaultCollateralDescription: item.defaultCollateralDescription,
                warrantyRegistrationOffice: item.warrantyRegistrationOffice,
            }));
            setCreditProduct(options);

            return dataSuccess
        }
    });

    const creditProductAutoCompleteProps = {
        listOptions: creditProductList,
        loading: !hasReadPermission ? false : queryContextCreditProduct.isLoading || queryContextCreditProduct.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ searchString }, 'CreditProduct')
    };

    return {
        ...queryContextCreditProduct,
        creditProductAutoCompleteProps
    };
};

export function useCreditProductMutation(productId: string, onSuccess?: (data: CreditProductReadModel) => void, onError?: (error: any) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: CreditProductFormSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateCreditProduct(values, productId, token, currentTenantId);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            endRequest(true);
            return data;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutateAsync, isLoading, error };
};

export function useGetModalityData(filters: ModalityListProps) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const [getModalityList, setGetModalityList] = useState<{ label: string; value: string }[]>([])
    const [search, setSearch] = useState<string | undefined>(undefined);
    const filtersComplete = { ...filters, descriptionModality: search };

    const queryContextModality = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: ["modality-list", filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<Modality>> => {
            startRequest();
            const resp = await getModalityListAsync(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            const dataSuccess = data as GetListApiResponseSuccess<Modality>;
            const options = dataSuccess.data.map((item) => ({
                label: `(${item.code || 'Não informado'}) - ${item.description}`,
                value: item.id,
                code: item.code
            }));
            setGetModalityList(options);

            return dataSuccess
        }
    });

    const modalityAutoCompleteProps = {
        listOptions: getModalityList,
        loading: queryContextModality.isLoading || queryContextModality.isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString)
    };

    return {
        ...queryContextModality,
        modalityAutoCompleteProps
    };
};

export function useGetSubModalityData(filters: SubModalityProps) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const [getSubModalityList, setGetSubModalityList] = useState<{ label: string; value: string }[]>([])
    const [search, setSearch] = useState<string | undefined>(undefined);
    const filtersComplete = { ...filters, descriptionSubModality: search };

    const queryContextSubModality = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: ["submodality-list", filtersComplete],
        queryFn: async () => {
            if (!filtersComplete?.codeModalite) return;
            startRequest();
            const resp = await getSubModalityListAsync(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            const dataSuccess = data as GetListApiResponseSuccess<Modality>;
            const options = dataSuccess.data.map((item) => ({
                label: `(${item.code || 'Não informado'}) - ${item.description}`,
                value: item.id,
            }));
            setGetSubModalityList(options);

            return dataSuccess
        }
    });

    const subModalityAutoCompleteProps = {
        listOptions: getSubModalityList,
        loading: queryContextSubModality.isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString)
    };

    return {
        ...queryContextSubModality,
        subModalityAutoCompleteProps
    };
};

export function useCosifData() {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const  { data, isLoading, error, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["cosif-data"],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getCosifAccountTransaction(token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            endRequest(true);
            return data ?? []
        }
    });

    return { cosifData: data, isLoading, error, refetch };
}