import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useIdentity } from 'contexts/identityContext';
import { useState } from 'react';
import { useApiRequest } from './apiRequestContext';
import { creditAxiosApi } from "services/axiosApi/axiosApi";
export type EnumRequestProps = {
    enumName:
        | 'RelatedBank'
        | 'PaymentPurpose'
        | 'FileType'
        | 'UF'
        | 'SignatureType'
        | 'SignatureValidation'
        | 'TypeOfRelationship'
        | 'LegalNature';
    showValueOnLabel?: boolean;
    size?: number;
};

export type EnumItem = {
    label: string;
    value: string;
};

const apiPath = (name: string) => `/Enum/${name}`;

export function useEnumContext({
    enumName,
    showValueOnLabel = false,
    size = 10,
}: EnumRequestProps) {
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    let params: Object = { size };
    if (search && search.length >= 3) {
        params = { ...params, search };
    }

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['bank-account', 'beneficiaries', enumName, params],
        queryFn: async (): Promise<EnumItem[]> => {
            startRequest();
            const { data, status, statusText } = await creditAxiosApi.get(`${apiPath(enumName)}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params,
            });
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataList = data as Array<{ valueAsInt: number; value: string; name: string }>;
            return dataList.map((i) => {
                return {
                    value: i.value,
                    label: showValueOnLabel ? `${i.valueAsInt} | ${i.name}` : i.name,
                };
            });
        },
    });

    const autoCompleteProps = {
        listOptions: queryContext.data ?? [],
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) => {
            setSearch(searchString);
        },
    };

    return { ...queryContext, autoCompleteProps };
}
