import { Grid, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CompleteIcon, DataTable } from '@uy3/web-components';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import RiskScoreContainer from 'components/RiskScore/RiskScore/RiskScoreContainer';
import { useAppConfig } from 'contexts/appConfig';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { CreditNoteFilterContainer } from 'components/DataTableFilters/CreditNoteFilter/CreditNoteFilterContainer';
import DatasetsStatusColumnContainer from 'components/RiskScore/DatasetsStatusColumn/DastasetsStatusColumnContainer';
import { useUserPermissionData } from 'contexts/userContext';

type DeletedCreditListProps = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setOpenPopup: (rowData: any) => void;
};

export const DeletedCreditList = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setOpenPopup,
}: DeletedCreditListProps) => {
    const { hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const { submitError } = useApiRequest();

    const useComplianceAnalysis = appConfig.USE_COMPLIANCE_ANALYSIS_DATASET;
    const useCreditAnalysis = appConfig.USE_CREDIT_ANALYSIS_DATASET;
    const isRootTenancy = appConfig.TENANT_TYPE === 0;

    const columnsDeletedList = [
        {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            hideSortIcons: true,
            width: 141,
            align: 'left',
            editable: false,
            renderCell: (cellValues: any) => cellValues.value,
        },
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            minWidth: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => `#${cellValues.value}`,
        },
        {
            field: 'personDisplay',
            headerName: 'Tomador',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            align: 'left',
        },
        {
            field: 'amortization.principalAmountInCents',
            headerName: 'Valor principal',
            hideSortIcons: true,
            minWidth: 130,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                formatCurrencyInCents(cellValues?.row?.amortization?.principalAmountInCents),
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => {
                // Using "pago" temporarily
                const statusDisplay = cellValues.row?.statusDisplay;
                return statusDisplay === 'Liquidação' ? "Pago" : statusDisplay;
            }

        },
        {
            field: 'amortization.apr',
            headerName: 'Taxa',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatPercentage(cellValues?.row?.amortization?.apr),
        },
        {
            field: 'amortization.startDate',
            headerName: 'Data de início',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                new Date(cellValues.row?.amortization?.startDate).toLocaleDateString('pt-BR'),
        },
        useComplianceAnalysis
            ? {
                field: 'statusMotorAnalysis',
                headerName: 'Compliance',
                hideSortIcons: true,
                minWidth: 113,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <DatasetsStatusColumnContainer
                            recordType={'CreditNote'}
                            id={cellValues?.id}
                            datasetName="complianceanalysis"
                        />
                    );
                },
            }
            : undefined,
        useCreditAnalysis
            ? {
                field: 'statusMotorCredit',
                headerName: 'Análise de crédito',
                hideSortIcons: true,
                minWidth: 110,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <DatasetsStatusColumnContainer
                            recordType={'CreditNote'}
                            id={cellValues?.id}
                            datasetName="creditanalysis"
                        />
                    );
                },
            }
            : undefined,
        isRootTenancy
            ? {
                field: 'riskScore',
                headerName: 'Score de risco',
                hideSortIcons: true,
                minWidth: 120,
                editable: false,
                renderCell: (cellValues: any) => {
                    var isLegalperson = cellValues.row?.personDiscriminator === 'LegalPerson';
                    return (
                        <RiskScoreContainer
                            discriminator={cellValues.row?.personDiscriminator}
                            id={isLegalperson ? undefined : cellValues.row?.personId}
                        />
                    );
                },
            }
            : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            headerAlign: 'center',
            align: 'center',
            flex: 0.8,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {hasPermission('CreditNote', 'Update') ?
                            <IconButton title={`Restaurar operação - ${cellValues?.row?.creditNoteNo ?? ""}`} onClick={() => setOpenPopup(cellValues)}>
                                <CompleteIcon sx={iconSx} />
                            </IconButton> : <>-</>}
                    </>
                );
            },
        },
    ].filter(Boolean);
    const columns = columnsDeletedList as GridColDef[];

    return (
        <>
            <ApiErrorAlert error={submitError} />
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: (
                        <Grid mb={-0.5}>
                            <CreditNoteFilterContainer />
                        </Grid>
                    ),
                }}
                columns={columns}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};
