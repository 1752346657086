import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import { validationSchemaUserForm, defaultValuesUserForm } from './UserFormSchema';
import { UserForm } from './UserForm';
import { UserCreateSuccessResponse, useEnableOrDisableMutation, useUserData, useUserMutation, useUserPermissionData } from 'contexts/userContext';
import { Box } from '@mui/material';
import { Alert, Modal } from '@uy3/web-components';
import { ApiResponseError, ToastType, showErrorToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { resetUserPassword } from 'services/user';
import React, { useState } from 'react';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { FieldValues } from 'react-hook-form';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import { defaultValuesPasswordForm, validationSchemaResetPasswordForm } from './ResetPasswordForm/ResetPasswordFormSchema';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import Toast from 'components/Toast/Toast';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { useQueryClient } from '@tanstack/react-query';
import { Error } from 'components/Errors/Error';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';

export const UserFormContainer = () => {
    const { userId } = useParams();
    const [stepAction, setStepAction] = useState<string | undefined>(undefined);
    const formMode: FormMode = userId !== 'novo' ? 'update' : 'create';
    const { hasPermission } = useUserPermissionData(); 
    const { data, error, refetch, isFetching, isLoading } = useUserData(userId!);
    const { submitError, setSubmitError } = useApiRequest();
    const { setFormFieldsErrors } = useFormFieldsError();
    const queryClient = useQueryClient();
    const { currentTenantId } = useTenant();
    const navigate = useNavigate();
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);

    const hasPermissionCreateOrUpdate = hasPermission('User', formMode === 'create' ? 'Create' : 'Update')

    const handleSuccessMessage = async (type: string) => {
        const optionsMessage: { [type: string]: string } = {
            "create": "Operador salvo com sucesso!",
            "enableUser": "Operador habilitado com sucesso!",
            "disableUser": "Operador desabilitado com sucesso!",
        }
        setToast({
            title: optionsMessage[type],
            severity: 'success',
            open: true,
        });

        if (type !== 'create') {
            await queryClient.refetchQueries(['get-user-data', userId]);
            onCloseStep();
        }
    }

    const onSuccess = (data: UserCreateSuccessResponse) => {
        const id = formMode === 'create' ? data.username : userId;
        navigate(`/seguranca/usuarios/${id}`);
        if (userId !== 'novo') refetch();
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        handleSuccessMessage('create');
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage, fieldErrors } = mapErrorResponse(error);
        setSubmitError(error);
        setFormFieldsErrors(fieldErrors ?? []);
        setToast({
            title: 'Ops, ocorreu um erro!',
            description: errorMessage,
            severity: 'error',
            open: true,
        });
    };

    const { mutateAsync } = useUserMutation(userId!, onSuccess, onError);
    const { mutate } = useEnableOrDisableMutation(userId!, () => handleSuccessMessage(stepAction?.toString()!), onError);
    const onSubmit = async (values: FieldValues) => {
        mutateAsync(values);
    };

    const onCloseStep = () => setStepAction(undefined);

    if (data?.type === 'error') return <Error error={error} />
    const resetPassword = async (values: any) => {
        let payload = {
            id: userId!,
            token: token!,
            tenant: currentTenantId!,
            password: values.password
        }
        await resetUserPassword(payload)
            .then(() => {
                setToast({
                    title: 'Ótimo! Senha redefinida com sucesso!',
                    severity: 'success',
                    open: true,
                });
                onCloseStep()
            }).catch((response) => {
                const { errorMessage } = mapErrorResponse(response)
                setSubmitError(response)
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);
            });
    };

    const isEditing = formMode === 'update';
    const schemaUserForm = validationSchemaUserForm(isEditing);
    const defaultValues = data ?? defaultValuesUserForm;
    const schemaResetPassword = validationSchemaResetPasswordForm();
    const defaultValuesResetPassword = defaultValuesPasswordForm;

    return (
        <GenericFormSkeleton isLoading={isLoading && userId !== 'novo'}>
            <React.Fragment>
                <Toast toast={toast} setToast={setToast} />
                <>
                    {error && userId !== 'novo' && (
                        <Box mb={1}>
                            <Alert
                                severity="error"
                                text="Erro ao carregar dados do usuário."
                                variant="filled"
                            />
                        </Box>
                    )}
                </>
                <ApiErrorAlert error={submitError} />
                <FormProvider
                    validationSchema={schemaUserForm}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    readOnly={!hasPermissionCreateOrUpdate}
                >
                    <UserForm
                        hasPermissionCreateOrUpdate={hasPermissionCreateOrUpdate}
                        setStepAction={(step) => setStepAction(step)}
                        userId={userId}
                        isFetching={isFetching}
                        refetch={refetch}
                        setToast={setToast}
                        isDisabled={data?.enabled! === false}
                    />
                </FormProvider>

                <Modal
                    title='Resetar senha'
                    open={stepAction === 'restPassword'}
                    onClose={onCloseStep}
                    size='large'
                    children={
                        <FormProvider
                            defaultValues={defaultValuesResetPassword}
                            validationSchema={schemaResetPassword}
                            onSubmit={resetPassword}
                        >
                            <ResetPasswordForm />
                        </FormProvider>
                    }
                />

                <Modal
                    title='Habilitar usuário'
                    description='Tem certeza que desja habilitar esse usuário?'
                    open={stepAction === 'enableUser'}
                    onClose={onCloseStep}
                    size='small'
                    children={
                        <GenericActionHandler
                            handleSubmit={() => mutate('EnableUser')}
                            isModal
                            onClose={onCloseStep}
                        />
                    }
                />

                <Modal
                    title='Desabilitar usuário'
                    description='Tem certeza que desa desabilitar esse usuário?'
                    open={stepAction === 'disableUser'}
                    onClose={onCloseStep}
                    size='small'
                    children={
                        <GenericActionHandler
                            handleSubmit={() => mutate('DisableUser')}
                            onClose={onCloseStep}
                            isModal
                        />
                    }
                />
            </React.Fragment>

        </GenericFormSkeleton>
    );
};
