import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { InstallmentListHeader } from './InstallmentListHeader'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton'
import { RefreshProgress } from 'components/RefreshProgress'
import { Drawer, Modal } from '@uy3/web-components'
import { FilterProvider } from 'contexts/filterContext'
import { ApiResponseError, handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext'
import Toast from 'components/Toast/Toast'
import { InstallmentList } from './InstallmentList'
import { InstallmentCreateFormContainer } from './Drawers/InstallmentCreateForm/InstallmentCreateFormContainer'
import { useCancelInstallmentMutation, useGetInstallmentList, useRegisterInstallmentMutation, useTransferInstallmentMutation, useUpdateInstallmentMutate } from 'contexts/wallet/Installment/installmentContext'
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types'
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler'
import { FieldValues } from 'react-hook-form'
import { TransferFormContainer } from './Drawers/TransferForm/TransferFormContainer'
import { RegisterFormContainer } from './Drawers/RegisterForm/RegisterFormContainer'
import { InstallmentEditFormContainer } from './Drawers/InstallmentEditForm/InstallmentEditFormContainer'
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types'
import { installmentStatusOptions } from 'components/DataTableFilters/InstallmentFilters/InstallmentFilters'
import { useFilterValues } from 'contexts/filterValuesContext'

export const InstallmentListContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const { filterValues: { filters } } = useFilterValues();
    const hasFilter = Object.values(filters).length > 0;

    const [handleAction, setHandleAction] = useState<{ action: string | undefined, row?: IInstallmentFull | undefined } | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [page, setPage] = useState(0);

    const currentActionSelected = handleAction?.action;
    const rowDataSelected = handleAction?.row;

    const onClose = () => setHandleAction(undefined);

    const onSuccessCustom = (message: string, description?: string) => {
        showSuccessToast(message, description, setToast);
        onClose();
        refetch();
    }

    const onError = (error: ApiResponseError) => handleOnError(error, setToast)

    const { data: queryData, refetch, isFetching, isLoading } = useGetInstallmentList({ page, size: rowsPerPage });

    const { mutateAsync: transferMutateAsync, isLoading: isLoadingTransfer } = useTransferInstallmentMutation(
        {
            id: rowDataSelected?.id,
            onSuccess: () => onSuccessCustom("Transferência realizada com sucesso"),
            onError
        });

    const { mutateAsync: cancelMutateAsync, isLoading: isLoadingCancel } = useCancelInstallmentMutation(() => onSuccessCustom("Sucesso ao cancelar parcela"), onError);
    const { mutateAsync: registerMutateAsync, isLoading: isLoadingRegister } = useRegisterInstallmentMutation(rowDataSelected?.id!, () => onSuccessCustom("Parcela registrada com sucesso"), onError);
    const { mutateAsync: updateMutateAsync, isLoading: isLoadingUpdate } = useUpdateInstallmentMutate(rowDataSelected?.id!, () => onSuccessCustom("Sucesso ao atualizar parcelas"), onError);

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onChangePage = (newPage: number) => setPage(newPage);

    const handleRegisterInstallment = async (values: FieldValues) => {
        await registerMutateAsync(
            {
                enableRegisterOverDue: values?.enableRegisterOverDue
            });
    }
    return (
        <Stack spacing={2}>
            <Toast toast={toast} setToast={setToast} />

            <InstallmentListHeader {...{
                refetch,
                setHandleAction
            }} />
            <RefreshProgress {...{ refreshing: !isLoading && isFetching }} />
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    walletsCode: { label: 'Código convênio', type: 'text', multiple: true },
                    payerName: { label: 'Nome do pagador', type: 'text' },
                    payerRegistrationNumber: { label: 'CPF/CNPJ do pagador', type: 'text' },
                    statusesValue: {
                        label: 'Status',
                        type: 'select',
                        multiple: true,
                        options: installmentStatusOptions
                    },
                    inicialCreatedDate: {
                        label: 'Data de criação',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalCreatedDate'
                    },
                    firstInstructionDebtCollection: {
                        label: "Instrução de protesto",
                        type: 'select',
                        options: firstInstructionDebtCollectionOptions,
                        multiple: false
                    }
                }}
            >
                <ListSkeleton {...{
                    isLoading: (isLoading && !hasFilter)
                }}>
                    <Stack marginRight={20}>
                        <InstallmentList
                            {...{
                                page,
                                queryData,
                                rowsPerPage,
                                setPage: onChangePage,
                                setRowsPerPage: onChangeRowsPerPage,
                                isLoading: false,
                                handleAction: setHandleAction
                            }}
                        />
                    </Stack>
                </ListSkeleton>
            </FilterProvider>


            <Drawer {...{
                anchor: "right",
                open: ["add-installment"].includes(currentActionSelected!),
                title: "Novo parcelamento",
                description: "Preencha as informações abaixo antes de criar o parcelamento.",
                onClose,
                children: <InstallmentCreateFormContainer {...{ setToast, onClose, refetch }} />
            }} />

            <Drawer {...{
                anchor: "right",
                open: ["edit"].includes(currentActionSelected!),
                title: "Editar parcelamento",
                description: "Preencha as informações abaixo antes de editar.",
                onClose,
                children: <InstallmentEditFormContainer {...{
                    installmentData: rowDataSelected!,
                    isLoading: isLoadingUpdate,
                    onClose,
                    updateMutateAsync
                }} />
            }} />

            <Drawer {...{
                anchor: "right",
                open: ["transfer"].includes(currentActionSelected!),
                title: `Transferir parcela #${handleAction?.row?.walletCode ?? ""}`,
                description: "Realize a transferência informando o código de carteira abaixo.",
                onClose,
                children: <TransferFormContainer {...{
                    isLoading: isLoadingTransfer,
                    onClose,
                    onSubmit: (values) => transferMutateAsync(values),
                    rowData: rowDataSelected!
                }} />
            }} />

            <Modal {...{
                open: ["cancel"].includes(currentActionSelected!),
                title: `Cancelar parcela #${handleAction?.row?.walletCode ?? ""}`,
                description: "Tem certeza que deseja cancelar essa parcela?",
                onClose,
                children: (
                    <GenericActionHandler
                        handleSubmit={() => cancelMutateAsync(rowDataSelected?.id)}
                        onClose={onClose}
                        isLoading={isLoadingCancel}
                        isModal
                    />
                )
            }} />

            <Modal {...{
                open: ["register"].includes(currentActionSelected!),
                title: `Regisrar parcelamento`,
                description: "Tem certeza que deseja cancelar essa parcela?",
                onClose,
                children: (
                    <RegisterFormContainer {...{
                        installmentData: rowDataSelected!,
                        isLoading: isLoadingRegister,
                        onClose,
                        onSubmit: handleRegisterInstallment
                    }} />
                )
            }} />

        </Stack>
    )
}
