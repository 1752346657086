import { Box, Grid, IconButton, Paper, Stack } from '@mui/material';
import { Button, Typography, EditIcon, DeleteIcon, SearchIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { handleRedirectByLine } from 'helpers/methods/GenericMethods';
import { Link } from 'react-router-dom';
import { activeTheme } from 'services/theme';

type InvolvedBankAccountProductProps = {
    openDrawer: (indexNumber?: number | undefined) => void;
    onDeleteRelatedPerson: (index: number) => void;
    enableActionAddInvolved: boolean;
    name: string;
};

const styleGridInvolved = {
    display: 'block',
    boxShadow: 'none',
    border: '1px solid #E0E3EA',
    borderLeft: '2px solid orangeRed',
    margin: '0 10px',
    borderRadius: '4px',
    width: '280px',
    height: '600px',
    padding: '1rem',
};

const theme = activeTheme();
export const InvolvedBankAccountProductList = ({
    name,
    openDrawer,
    onDeleteRelatedPerson,
    enableActionAddInvolved,
}: InvolvedBankAccountProductProps) => {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];

    const colorMain = theme.palette.primary.main;
    return (
        <>
            <Paper
                component={Grid}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                sx={{ p: 3, mr: 2, mb: 2, boxShadow: 'none', border: '1px solid #E0E3EA' }}
            >
                {queryData?.length >= 1 ? (
                    queryData.map((item: any, indexNumber: number) => {
                        return (
                            <Grid style={styleGridInvolved} position="relative">
                                <Typography
                                    fontSize={'16px'}
                                    color="neutral.medium"
                                    fontWeight={600}
                                    lineHeight={2}
                                >
                                    Pessoa
                                </Typography>
                                <Typography
                                    fontSize={'18px'}
                                    color="neutral.dark"
                                    fontWeight={600}
                                    lineHeight={2}
                                >
                                    {item?.personIdDisplay}
                                </Typography>
                                <Typography
                                    fontSize={'16px'}
                                    color="neutral.medium"
                                    fontWeight={600}
                                    lineHeight={2}
                                >
                                    Tipo de relação
                                </Typography>
                                <Typography
                                    fontSize={'18px'}
                                    color="neutral.dark"
                                    fontWeight={600}
                                    lineHeight={2}
                                >
                                    {item?.typeOfRelationshipDisplay}
                                </Typography>
                                {item?.tenantName && (
                                    <>
                                        <Typography
                                            fontSize={'16px'}
                                            color="neutral.medium"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            Correspondente
                                        </Typography>
                                        <Typography
                                            fontSize={'18px'}
                                            color="neutral.dark"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            {item?.tenantNameDisplay}
                                        </Typography>
                                    </>
                                )}
                                {item?.userIdDisplay && (
                                    <>
                                        <Typography
                                            fontSize={'16px'}
                                            color="neutral.medium"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            Operador
                                        </Typography>
                                        <Typography
                                            fontSize={'18px'}
                                            color="neutral.dark"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            {item?.userIdDisplay}
                                        </Typography>
                                    </>
                                )}

                                {item?.levelDisplay && (
                                    <>
                                        <Typography
                                            fontSize={'16px'}
                                            color="neutral.medium"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            Nível de permissão
                                        </Typography>
                                        <Typography
                                            fontSize={'18px'}
                                            color="neutral.dark"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            {item?.levelDisplay}
                                        </Typography>
                                    </>
                                )}

                                {item?.relatedToIdDisplay && (
                                    <>
                                        <Typography
                                            fontSize={'16px'}
                                            color="neutral.medium"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            Relacionado a
                                        </Typography>
                                        <Typography
                                            fontSize={'18px'}
                                            color="neutral.dark"
                                            fontWeight={600}
                                            lineHeight={2}
                                        >
                                            {item?.relatedToIdDisplay}
                                        </Typography>
                                    </>
                                )}

                                {enableActionAddInvolved && (
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            mt: 3,
                                            position: 'absolute',
                                            bottom: 15,
                                            width: '90%',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => openDrawer(indexNumber)}
                                        >
                                            <EditIcon htmlColor={colorMain} /> &nbsp; Editar
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => onDeleteRelatedPerson(indexNumber)}
                                        >
                                            <DeleteIcon htmlColor={colorMain} /> &nbsp; Excluir
                                        </Button>
                                    </Stack>
                                )}

                                <Grid sx={{ position: 'absolute', top: 10, right: 5 }}>
                                    <Link to={handleRedirectByLine(item)}>
                                        <IconButton>
                                            <SearchIcon htmlColor={colorMain} sx={iconSx} />
                                        </IconButton>
                                    </Link>
                                </Grid>
                            </Grid>
                        );
                    })
                ) : (
                    <Box flex={1} textAlign="center" mt={6} mb={6}>
                        <Typography variant="h4" fontWeight="bold" color="neutral.medium">
                            Nenhum produto adicionado.
                        </Typography>
                    </Box>
                )}
            </Paper>
        </>
    );
};
