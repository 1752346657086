/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { ToastType, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import { useEnumContext } from 'contexts/enumContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import { useParams } from 'react-router';
import { useCreditData } from 'contexts/creditNote/creditContext';
import { useIdentity } from 'contexts/identityContext';
import { getLegalPersonById } from 'services/accounts/legalPerson';
import { getNaturalPersonById } from 'services/accounts/naturalPerson';
import { uniqBy } from 'lodash';
import { useUserPermissionData } from 'contexts/userContext';
import Toast from 'components/Toast/Toast';
import { isEmpty } from 'lodash';
import { UploadWithRelatedHeader } from './UploadWithRelatedHeader';
import { GridValidRowModel } from '@mui/x-data-grid';
import UploadWithRelatedList from './UploadWithRelatedList';
import DrawerWrapper from '../DrawerWrapper/DrawerWrapper';
import { checkActionsPerms } from 'services/permissions';
import { useAppConfig } from 'contexts/appConfig';

export const UploadWithRelatedContainer = () => {
    const {
        status,
        data: typeOfDocument,
        error,
    } = useEnumContext({ size: 100, enumName: 'FileType' });
    const { appConfig } = useAppConfig();
    const isRootTenancy = appConfig?.TENANT_TYPE === 0;
    const [selectedUploadIndex, setSelectedUploadIndex] = useState<number | undefined>();
    const [statusSignature, setStatusSignature] = useState('');
    const [toast, setToast] = useState<ToastType>(toastState);
    const [selectedAction, setSelectedAction] = useState<string | undefined>();
    const [rowData, setRowData] = useState<GridValidRowModel | undefined>();
    const { token } = useIdentity();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch } = useFormContext();
    const { id } = useParams<{ id: string | undefined }>();
    const { creditData } = useCreditData(id ?? 'nova');
    const [naturalPersonData, setNaturalPersonData] = useState<any>();
    const [legalPersonData, setLegalPersonData] = useState<any>();
    const involvedPersons = creditData?.relatedPersonCreditNote.filter((value) => {
        return value.typeOfRelationship !== 'Witness';
    });

    const statusCreditNote = watch('status');
    const discriminator = watch('personDiscriminator');
    const personId = watch('personId');
    const { hasPermission, data: permissionsData } = useUserPermissionData();

    const personType = discriminator === 'LegalPerson' ? 'LegalPerson' : 'NaturalPerson';
    const hasReadPermission = hasPermission(personType, 'Read');
    const hasPermissionCreateOrUpdate = hasPermission('CreditNote', id === 'nova' ? 'Create' : 'Update')

    const hasActionPermission = (action: string): boolean => {
        if (checkActionsPerms(action, permissionsData, 'CreditNote')) {
            return true;
        }
        return false;
    };

    const getNaturalPersonDocuments = useCallback(
        (id: string) => {
            if (isEmpty(id)) return;
            if (hasReadPermission) {
                getNaturalPersonById(id!, token!).then(({ data }: any) => {
                    const person = {
                        id: data?.id,
                        uploads: data?.uploads,
                        name: data?.name,
                    };
                    setNaturalPersonData((prev: any) => [...(prev ?? []), person]);
                });
            }
        },
        [hasReadPermission]
    );

    const getLegalPersonDocuments = useCallback(
        (id: string) => {
            if (isEmpty(id)) return;
            if (hasReadPermission) {
                getLegalPersonById(id!, token!).then(({ data }: any) => {
                    const person = {
                        id: data?.id,
                        name: data?.name,
                        uploads: data?.uploads,
                    };
                    setLegalPersonData((prev: any) => [...(prev ?? []), person]);
                });
            }
        },
        [hasReadPermission]
    );

    useEffect(() => {
        if (discriminator === 'LegalPerson') {
            getLegalPersonDocuments(personId);
        } else {
            getNaturalPersonDocuments(personId);
        }

        uniqBy(involvedPersons, (i) => i.personId)?.map((person) => {
            if (person.discriminator === 'NaturalPerson') {
                getNaturalPersonDocuments(person.personId);
            } else if (person.discriminator === 'LegalPerson') {
                getLegalPersonDocuments(person.personId);
            }
        });
    }, [discriminator, personId]);

    useEffect(() => {
        const { uploads } = creditData || {};

        if (uploads) setValue('uploads', uploads);
    }, [creditData?.uploads]);

    const uploads = watch('uploads') ?? watch('relatedDocs') ?? [];

    if (status === 'loading') return <RefreshProgress refreshing={true} />;
    if (status === 'error') return <Error error={error} />;

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    function onDelete(index: number) {
        let newUploads = [...uploads];
        newUploads?.splice(index, 1);
        if (watch('relatedDocs')) setValue('relatedDocs', newUploads);
        else setValue('uploads', newUploads);
        const title = 'Sucesso ao remover o documento.';
        const desc = undefined;
        showSuccessToast(title, desc, setToast);
    }

    function getUniqueValues(array: any[]): any[] {
        const UniqueIdOnlyList: any[] = [];
        const uniqueArray = [];
        for (let i = 0; i < array.length; i++) {
            const id = array[i].id;
            if (!UniqueIdOnlyList.includes(id)) {
                uniqueArray.push(array[i]);
                UniqueIdOnlyList.push(id);
            }
        }
        return uniqueArray;
    }

    const saveCreditNoteIsEnabled = ['', 'Draft', 'Error', 'Disapproved', 'Revision'].includes(statusCreditNote!) && hasPermissionCreateOrUpdate;

    const updateDocsIsEnabled = () => {
        const hasPermissionUploadDocs = hasActionPermission('UploadDocs'); 
        if (isRootTenancy && hasPermissionUploadDocs) {
            return true;
        } else if (
            !isRootTenancy &&
            hasPermissionUploadDocs &&
            ['InstrumentApproval', 'Signatures', 'Draft'].includes(statusCreditNote!)
        ) {
            return true;
        }
        return false
    }

    const enableActionAddedDocument: boolean = saveCreditNoteIsEnabled || updateDocsIsEnabled();

    return (
        <>
            <DrawerWrapper
                {...{
                    data: creditData,
                    targetId: id!,
                    openDrawer,
                    setOpenDrawer,
                    selectedAction,
                    selectedUploadIndex,
                    setSelectedUploadIndex,
                    rowData,
                    typeOfDocument: typeOfDocument ?? [],
                    setRowData,
                    setSelectedAction,
                    setStatusSignature,
                    setToast,
                    targetType: 'CreditNoteOp'
                }}
            />
            <Toast toast={toast} setToast={setToast} />
            <UploadWithRelatedHeader
                hasPermissionCreateOrUpdate={hasPermissionCreateOrUpdate}
                addNewDocuemnt={() => setOpenDrawer(true)}
                disabled={enableActionAddedDocument === false}
            />
            <UploadWithRelatedList
                enableAction={hasPermissionCreateOrUpdate}
                titleHeader="Documentos da operação"
                uploads={uploads ?? []}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                setOpenDrawer={(type, row) => {
                    setSelectedAction(type);
                    setRowData(row!);
                }}
                page={page}
                setSelectedUploadIndex={(rowNumber: number | undefined) => {
                    setSelectedUploadIndex(rowNumber ?? uploads?.length);
                    setRowData(undefined);
                }}
                onDelete={onDelete}
                typeOfDocument={typeOfDocument}
                statusSignature={statusSignature}
            />

            {getUniqueValues(legalPersonData ?? [])
                ?.filter((person: any) => person?.id)
                ?.map((option: any) => (
                    <UploadWithRelatedList
                        enableAction={hasPermissionCreateOrUpdate}
                        titleHeader={`Documentos - ${option?.name}`}
                        uploads={option?.uploads ?? []}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        setOpenDrawer={() => { }}
                        page={page}
                        setSelectedUploadIndex={(rowNumber: number | undefined) =>
                            setSelectedUploadIndex(rowNumber ?? option?.uploads?.length)
                        }
                        onDelete={onDelete}
                        typeOfDocument={typeOfDocument}
                    />
                ))}
            {getUniqueValues(naturalPersonData ?? [])
                ?.filter((person: any) => person?.id)
                ?.map((option: any) => (
                    <UploadWithRelatedList
                        enableAction={hasPermissionCreateOrUpdate}
                        titleHeader={`Documentos - ${option?.name}`}
                        uploads={option?.uploads ?? []}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        setOpenDrawer={() => { }}
                        page={page}
                        setSelectedUploadIndex={(rowNumber: number | undefined) =>
                            setSelectedUploadIndex(rowNumber ?? option?.uploads?.length)
                        }
                        onDelete={onDelete}
                        typeOfDocument={typeOfDocument}
                    />
                ))}
        </>
    );
};
