import { Grid, Box, Stack } from '@mui/material';
import {
    Typography,
    SaveIcon,
    Alert,
    OperatorIcon,
    UpdateIcon,
    TabsProps,
    DeleteIcon,
    ShareIcon
} from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';
import { activeTheme } from 'services/theme';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType, iconSx, showSuccessToast } from 'contexts/apiRequestContext';
import { Actions } from 'components/Actions/Actions';
import { useUserPermissionData } from 'contexts/userContext';
import { useAppConfig } from 'contexts/appConfig';
import { Dispatch, SetStateAction } from 'react';
import { hasCustomPermission, isActionAllowed, isRootTenancy } from 'helpers/methods/GenericMethods';
import { useWatch } from 'react-hook-form';
import moment from 'moment';

type FundType = {
    fundId: string;
    mode: FormMode;
    refetch: () => void;
    setToast: Dispatch<SetStateAction<ToastType>>;
    setGenericAction: (action: string) => void;
};

const theme = activeTheme();
const FundForm = ({ fundId, mode, setGenericAction, refetch, setToast }: FundType) => {
    const { validationErrors, control, isDirty, readOnly, watch } = useFormContext();
    const { appConfig } = useAppConfig()
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BatchAssignment', 'Read');
    const fundName = useWatch({ name: 'name', control });

    const newFund = mode === 'create';

    const tabs = [
        { label: 'Informações', href: fundId },
        { label: 'Envolvidos', href: 'envolvidos' },
        { label: 'Documentos', href: 'documentos' },
        ...(!newFund && hasReadPermission ? [{ label: 'Cessões', href: 'cessoes' }] : [])
    ] as TabsProps[];


    const refreshWithToast = () => {
        refetch();
        const title = 'Fundo atualizado com sucesso!';
        const desc = 'As informações do fundo foram atualizadas com sucesso.';
        showSuccessToast(title, desc, setToast);
    };

    const resource = 'Fund';
    const typePermission = newFund ? 'Create' : 'Update';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, rootTenancy);
    const createdBy = watch('createdBy.userIdDisplay');
    const createdAtFormated = `Criado em ${moment(watch('createdAt')).format('DD/MM/YYYY')} ${createdBy ? ` por ${createdBy}` : ''}`;

    return (
        <>
            {validationErrors && (
                <Box mt={2} mb={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box>
                <NotSavedChangesAlert isDirty={isDirty} />
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
                    <Stack>
                        <Typography
                            variant="h4"
                            color="neutral.dark"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="32px"
                            lineHeight="38.4px"
                        >
                            {newFund ? 'Novo cadastro' : `${fundName}`}
                        </Typography>
                        {!newFund &&
                            <Typography color="neutral.medium" variant="xs">
                                {createdAtFormated}
                            </Typography>}
                    </Stack>
                    <Actions
                        numberOfButtons={4}
                        buttonsActionsList={[
                            {
                                enable: enableAction,
                                label: "Salvar",
                                type: 'submit',
                                icon: <SaveIcon htmlColor={theme.palette.common.black} />
                            },
                            {
                                enable: !newFund,
                                label: "Atualizar",
                                action: refreshWithToast,
                                icon: <UpdateIcon
                                    htmlColor={theme.palette.common.black}
                                />
                            },
                            {
                                enable: !newFund && enableAction,
                                label: "Clonar registro",
                                action: () => setGenericAction('clone'),
                                icon: <ShareIcon htmlColor="black" sx={iconSx} />
                            },
                            {
                                enable: !newFund && enableAction,
                                label: "Atribuir",
                                action: () => setGenericAction('assign'),
                                icon: <OperatorIcon
                                    htmlColor={theme.palette.common.black}
                                />
                            },
                            {
                                enable: !newFund && hasPermission('Fund', 'Delete') && rootTenancy,
                                label: "Excluir",
                                action: () => setGenericAction('delete'),
                                icon: <DeleteIcon htmlColor={theme.palette.common.black} />
                            }
                        ]}
                    />
                </Stack>
                <OperatorViewInfo resource='fund' />
                <Grid sx={{ mr: 3 }}>
                    <TabBar
                        tabs={tabs}
                    />
                </Grid>
            </Box>
        </>
    );
};

export default FundForm;