import { Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { FileLinesHeader } from './FileLinesHeader'
import { useDownloadTransactionReceiptFileConfirmationAsyncMutation, useGetByIdFileLines } from 'contexts/wallet/files/fileInstruction/fileInstructionContext';
import { useParams } from 'react-router';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { FileLinesList } from './FileLinesList';
import { Drawer } from '@uy3/web-components';
import { ShowDetailsFiles } from 'components/FileLines/ShowDetailsFiles/ShowDetailsFiles';
import { Error } from 'components/Errors/Error';
import { LinesFileTypeGeneric } from 'services/walletManagement/bankSlip/bankSlip.types';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { RefreshProgress } from 'components/RefreshProgress';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useDownloadBankSlipByBarCodeMutation } from 'contexts/wallet/bankSlip/bankSlip';

type FileLinesContainerProps = {
    recordType: "FileInstruction" | "FileConfirmation"
}

export const FileLinesContainer = ({ recordType }: FileLinesContainerProps) => {
    const { id } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [actionStep, setActionStep] = useState<{ step: string, data?: LinesFileTypeGeneric | null } | undefined>(undefined);
    const { data: queryData, isLoading, refetch, error, isFetching } = useGetByIdFileLines(id!, recordType!, { lineTypes: "RetornoTransacao"});
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const onClose = () => setActionStep(undefined);
    const isFileConfirmation = recordType === 'FileConfirmation';

    const onSuccess = (type: string) => {
        const optionsMessage: { [type: string]: string } = {
            "ReceiptFile": "Sucesso ao baixar o recibo",
            "BankSlip": "Sucesso ao baixar boleto",
        };
        return showSuccessToast(optionsMessage[type], "", setToast);
    }

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        return showErrorToast("Ops, ocorreu um erro", errorMessage, setToast);
    };

    const currentData = actionStep?.data;

    const { mutateAsync: transactionReceiptMutateAsync } = useDownloadTransactionReceiptFileConfirmationAsyncMutation(() => onSuccess("ReceiptFile"), onError)
    const { downloadMutateAsync } = useDownloadBankSlipByBarCodeMutation(currentData?.detail?.barCode! ?? currentData?.detail?.bankSlipId!, () => onSuccess('BankSlip'), onError);

    const [page, setPage] = useState(0);

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const handleActionDownload = () => {
        
        if (isFileConfirmation) {
            return transactionReceiptMutateAsync(currentData?.id as string);
        }

        return downloadMutateAsync(currentData?.walletCode);
    }

    const onChangePage = (newPage: number) => setPage(newPage);

    if (queryData?.type === "error")
        return <Error error={error} />

    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />

            <Grid sx={{ mb: "25px" }}>
                <FileLinesHeader {...{
                    refetch,
                    fileName: isFileConfirmation ? "retorno" : "remessa"
                }} />
            </Grid>

            {isFetching &&
                <Grid mb={2}>
                    <RefreshProgress refreshing={!isLoading && isFetching} />
                </Grid>}

            <ListSkeleton isLoading={isLoading}>
                <FileLinesList
                    action={(step, data) => setActionStep({ step, data })}
                    setHandleAction={() => { }}
                    page={page}
                    recordType={recordType}
                    setPage={onChangePage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    queryData={queryData!}
                    downloadTransactionReceiptMutate={transactionReceiptMutateAsync}
                />
            </ListSkeleton>

            <Drawer
                anchor='right'
                title='Ver detalhes'
                description='Confira as informações'
                open={actionStep?.step === 'show-details'}
                onClose={onClose}
            >
                <ShowDetailsFiles {...{
                    handleActionDownload,
                    dataQuery: actionStep?.data!,
                    recordType: recordType,
                    setToast,
                    onClose
                }} />
            </Drawer>
        </Stack>
    )
}
