import React from 'react';
import { CheckboxWithTooltip } from '../CheckboxWithTooltip';

export const BankAccountRequestActions = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Realizar abertura de conta bancária',
                    name: 'actions.OpenBankAccount',
                }}
                helpMessage="Permite o usuário realizar a abertura de uma conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar Compliance',
                    name: 'actions.ComplianceApproval',
                }}
                helpMessage="Permite aprovar o compliance de uma solicitação de conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar Instrumento',
                    name: 'actions.InstrumentApproval',
                }}
                helpMessage="Permite aprovar um instrumento de uma solicitação de conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Validação de assinaturas',
                    name: 'actions.SignatureValidation',
                }}
                helpMessage="Permite validar registros que estão em validação de assinaturas."
            />
        </React.Fragment>
    );
};
