import { creditAxiosApi } from "services/axiosApi/axiosApi";
import {
    BatchAssignmentFilter,
    PayloadBatchAssignmentApprovalProps,
    PayloadBatchAssignmentProps,
    PayloadCnab444BatchAssignmentProps,
    FGTSBatchAssignmentType,
    BathCreditNoteProps
} from "./BatchAssignment.types";
import { UploadGet } from "contexts/apiRequestContext";

const url = `/BatchAssignment`;

export async function getBatchAssignment(params: BatchAssignmentFilter, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };
    return await creditAxiosApi.get(url, config);
}

export async function getCreditNotesBatchAssignment(id: string, params: BathCreditNoteProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };
    return await creditAxiosApi.get(`${url}/${id}/creditNotes`, config);
}

export async function getPaymentScheduleBatchAssignment(id: string, params: { page: number, size: number }, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    }
    return await creditAxiosApi.get(`${url}/${id}/paymentScheduleItems`, config);
}

export async function getBatchAssignmentById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.get(`${url}/${id}/querySimple`, config);
}

export async function approveBatchAssignmentById(id: string, payload: PayloadBatchAssignmentApprovalProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.put(`${url}/${id}/approval`, payload, config);
}

export async function cancelBatchAssignmentById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.put(`${url}/${id}/cancel`, {}, config);
}

export async function deleteBatchAssignmentById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.delete(`${url}/${id}`, config);
}

export async function postBatchAssignmentById(payload: PayloadBatchAssignmentProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(url, payload, config);
}

export async function generateCnab444BatchAssignmentById(id: string, payload: PayloadCnab444BatchAssignmentProps | null, token: string) {
    const urlGenerate = `${url}/${id}/cnab444`;

    let isAssignmentCalculation: boolean = payload?.isAssignmentCalculation === false;

    const config = {
        headers:
            isAssignmentCalculation ? {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            } : {
                Authorization: `Bearer ${token}`,
            }
    };

    return await creditAxiosApi.post(urlGenerate, isAssignmentCalculation ? {} : payload, config);
}

export async function postCalculateById(id: string, token: string, values: any) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/${id}/calculate`, values, config);
}

export async function FGTSBatchAssignment(values: FGTSBatchAssignmentType, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`/Vortx/NovaRemessa`, values, config);
}

export function liquidationFileBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/liquidationFile`, {}, config);
}

export function remittanceVortxBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/remessa_vortx`, {}, config);
}

export function generateZipBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/generateZip`, {}, config);
}

export function saveDocumentBatchAssignment(id: string, uploads: UploadGet[], token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.put(`${url}/${id}/upload`, { uploads }, config);
}

export function hemeraDepositBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/Hemera/Deposit`, {}, config);
}

export function hemeraGenerateFileBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/Hemera/GenerateFile`, {}, config);
}

export function hemeraSendFileBatchAssignment(id: string, list: string[], token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/Hemera/SendFile`, list, config);
}

export function cercRegisterBatchAssignment(batchId: string, token?: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    return creditAxiosApi.post(`${url}/${batchId}/Cerc/Register`, {}, config);
}

export function cercPaymentLiquidation(paymentScheduleIds: string[], token?: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/Cerc/PaymentLiquidation`, paymentScheduleIds, config);
}

export function installmentBatchAssignment(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return creditAxiosApi.post(`${url}/${id}/Installment`, {}, config);
}