import React, { useState } from 'react';
import { useCreditNotesBatchAssignment } from 'contexts/batchAssignment/batchAssignmentContext';
import { useParams } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { Popup } from '@uy3/web-components';
import ApproveLiquidationContainer from 'pages/CreditNote/CreditNoteForm/Modals/ApproveLiquidation/ApproveLiquidationContainer';
import { FieldValues } from 'react-hook-form';
import { approveCreditNoteById } from 'services/creditNote';
import { useIdentity } from 'contexts/identityContext';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import OperationBatchHeader from './OperationBatchHeader';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import OperationBatchListWithFilters from './OperationBatchListWithFilters';

export function OperationBatchAssigmentContainer() {
    const { id } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { token } = useIdentity();
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const { setSubmitError } = useApiRequest();
    const [selectedStatusOfTable, setSelectedStatusOfTable] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const {
        data: batchAssignmentData,
        error,
        status,
        isFetching,
        refetch,
        isLoading,
    } = useCreditNotesBatchAssignment(id!, { page, size: rowsPerPage });
    const { personAutoCompleteProps, data: personsData } = usePersonsList(
        { page: 0, size: 10 },
        'always'
    );

    const onClose = () => setOpenPopup(false);

    const handleSelectionModelChange = (selectionModel: string[] | any) => {
        setSelectedStatusOfTable(selectionModel);
    };

    const selectedCreditNote = batchAssignmentData?.data?.filter((operation: CreditNoteReadModel) =>
        selectedStatusOfTable.includes(operation.id)
    );

    const handleLiquidationApprove = (values: FieldValues) => {
        selectedStatusOfTable?.map(async (item) => {
            await approveCreditNoteById(item, token!, 'Liquidation', values)
                .then(() => {
                    refetch();
                    onClose();
                    const title = 'Liquidação aprovada com sucesso!';
                    const description = undefined;
                    showSuccessToast(title, description, setToast);

                    setSelectedStatusOfTable([]);
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                    setSelectedStatusOfTable([]);
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                })
                .catch((error: ApiResponseError) => {
                    setSubmitError(error);
                    const { errorMessage } = mapErrorResponse(error);
                    const title = 'Ops, ocorreu um erro!';
                    const description = errorMessage;
                    showErrorToast(title, description, setToast);
                });
        });
    };

    const showLiquidationApprove = (status: string): boolean => {
        const hasStatus = selectedCreditNote?.every(
            (item: CreditNoteReadModel) => item.status === status
        );
        return hasStatus && selectedStatusOfTable.length > 0;
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    if (status === 'error') return <Error error={error} />;

    let dataPerson = personsData as any;
    const personAutocompleteOptions: any = {
        ...personAutoCompleteProps,
        listOptions:
            dataPerson?.data?.map((person: PersonFull) => {
                return { label: person?.name, value: person?.id };
            }) || [],
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <OperationBatchHeader
                showLiquidationApprove={showLiquidationApprove}
                onLiquidationApprove={() => setOpenPopup(true)}
                refetch={refetch}
                isFetching={isFetching}
            />
            <OperationBatchListWithFilters
                {...{
                    handleSelectionModelChange,
                    isLoading,
                    batchAssignmentData,
                    onChangeRowsPerPage,
                    page,
                    personAutocompleteOptions,
                    rowsPerPage,
                    rowsSelected: selectedStatusOfTable,
                    setPage,
                }}
            />
            <Popup
                open={openPopup}
                onClose={onClose}
                title="Aprovar liquidação"
                text="Tem certeza que deseja aprovar esses registros?"
                children={
                    <ApproveLiquidationContainer
                        handleApproveLiquidation={handleLiquidationApprove}
                        creditData={batchAssignmentData}
                        onClose={onClose}
                        formType="batchAssignment"
                    />
                }
            />
        </>
    );
}
