import {
    SaveIcon,
    ApprovalIcon,
    DeleteIcon,
    UpdateIcon,
    RejectIcon,
    ApproveIcon,
    SettingsIcon,
    DoneIcon,
    PersonIcon,
    InvoiceReceivableIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { useFormContext } from 'contexts/formContext';
import { useAppConfig } from 'contexts/appConfig';
import React from 'react';
import { checkActionsPerms } from 'services/permissions';
import { AttributeTypeChecklist } from 'services/creditNote/complianceChecklist/complianceChecklist';
import { useUserPermissionData } from 'contexts/userContext';
import { useParams } from 'react-router';
import { hasCustomPermission, isRootTenancy } from 'helpers/methods/GenericMethods';

interface IButtonsOfActionsProps {
    isNew: boolean;
    submitApproval: () => void;
    onDelete: () => void;
    deleteCheckList: () => void;
    onChangeStatus: () => void;
    onApprove: () => void;
    refetch: () => void;
    onDisapprove: () => void;
    onUploadDocs: (uploads: []) => void;
    onSendApprovalRevision: () => void;
    openBankAccountRequest: () => void;
    onAssignBankAccountRequest: () => void;
    complianceChecklist: AttributeTypeChecklist[];
    onApproveInstrument: () => void;
    onRejectInstrument: () => void;
    onApproveSignature: () => void;
    onDisapproveSignature: () => void;
};

const ButtonsOfActions: React.FC<IButtonsOfActionsProps> = ({
    isNew,
    submitApproval,
    deleteCheckList,
    onDelete,
    onChangeStatus,
    onUploadDocs,
    onApprove,
    onApproveInstrument,
    onRejectInstrument,
    refetch,
    onDisapprove,
    onSendApprovalRevision,
    openBankAccountRequest,
    onAssignBankAccountRequest,
    complianceChecklist, 
    onApproveSignature, 
    onDisapproveSignature
}) => {
    const { bankAccountId } = useParams();
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const { getValues, watch } = useFormContext();
    const status = getValues('status');
    const isSendCreditApproval = !!appConfig.SEND_CREDIT_APPROVAL;
    const isApproveAndReject = !!appConfig.APPROVE_REJECT_CREDIT;
    const uploads = watch('uploads') ?? [];
    const isNewRequest = bankAccountId === 'nova';

    const isApprovalStts = ['Approval'].includes(status);

    const resource = 'BankAccountRequest';
    const typePermission = isNewRequest ? 'Create' : 'Update';
    const hasPermToCreateOrEdit = hasCustomPermission(resource, typePermission, hasPermission);
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);

    const hasActionPermission = (action: string, resource?: string): boolean => {
        if (checkActionsPerms(action, permissionsData, resource ?? 'BankAccountRequest')) {
            return true;
        }
        return false;
    };

    const showDeletecomplianceChecklist = () => {
        if (complianceChecklist?.length > 0 && isApprovalStts && isApproveAndReject) return true;
        return false;
    };

    const showApproveInstrumentAndOnReject = (status: string): boolean => {
        if (
            status &&
            ['InstrumentApproval'].includes(status) &&
            hasActionPermission('InstrumentApproval')
        )
            return true;
        return false;
    };


    const hiddenSubmit = ['Timeout', 'Approval', 'Processing', 'Closed', 'InstrumentApproval', "Active"];
    const showBtnSubmit = (status: string): boolean => {
        if (
            hasPermToCreateOrEdit &&
            !hiddenSubmit.includes(status!)
        )
            return true;
        return false;
    };

    const showApproveOrRejectAction = () => {
        if (
            status === 'SignaturesValidation' &&
            rootTenancy &&
            hasActionPermission('SignatureValidation')
        )
            return true;
        return false;
    }

    return (
        <Actions
            numberOfButtons={4}
            buttonsActionsList={[
                {
                    label: 'Aprovar instrumento',
                    enable: showApproveInstrumentAndOnReject(status),
                    action: onApproveInstrument,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar instrumento',
                    enable: showApproveInstrumentAndOnReject(status),
                    action: onRejectInstrument,
                    icon: <RejectIcon />,
                },
                {
                    label: 'Aprovar',
                    enable: isApprovalStts && isApproveAndReject,
                    action: onApprove,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar',
                    enable: isApprovalStts && isApproveAndReject,
                    action: onDisapprove,
                    icon: <RejectIcon />,
                },
                {
                    label: 'Aprovar assinatura',
                    enable: showApproveOrRejectAction(),
                    action: onApproveSignature,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar assinatura',
                    enable: showApproveOrRejectAction(),
                    action: onDisapproveSignature,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Enviar para aprovação',
                    enable: [
                        'Draft',
                        'Timeout',
                        'Cancel',
                        'CorbanPreAnalysis',
                        'Revision',
                    ].includes(status),
                    action: submitApproval,
                    icon: <ApprovalIcon />,
                },
                {
                    label: 'Salvar',
                    enable: showBtnSubmit(status),
                    icon: <SaveIcon />,
                    type: 'submit',
                },
                {
                    label: 'Atualizar',
                    enable: !isNew,
                    action: refetch,
                    icon: <UpdateIcon />,
                },
                {
                    label: 'Definir status',
                    enable: !isNew && rootTenancy,
                    action: onChangeStatus,
                    icon: <SettingsIcon />,
                },
                {
                    label: 'Remover restrições',
                    action: deleteCheckList,
                    enable: showDeletecomplianceChecklist() && !isNew,
                    icon: <DeleteIcon />,
                },
                {
                    label: 'Atribuir',
                    enable: !isNew && hasPermToCreateOrEdit,
                    action: onAssignBankAccountRequest,
                    icon: <PersonIcon />,
                },
                {
                    label: 'Abrir conta',
                    enable:
                        !isNew &&
                        status !== 'Active' &&
                        hasActionPermission('OpenBankAccount') &&
                        rootTenancy,
                    action: openBankAccountRequest,
                    icon: <ApprovalIcon />,
                },
                {
                    label: 'Salvar documentos',
                    enable: false,
                    action: () => onUploadDocs(uploads),
                    icon: <InvoiceReceivableIcon />,
                },
                {
                    label: 'Concluir Revisão',
                    enable:
                        !isNew &&
                        status !== 'Active' &&
                        hasActionPermission('OpenBankAccount') &&
                        rootTenancy,
                    action: onSendApprovalRevision,
                    icon: <DoneIcon />,
                },
                {
                    label: 'Excluir',
                    enable:
                        ['Draft', 'Revision', 'CorbanPreAnalysis'].includes(status) &&
                        isSendCreditApproval &&
                        hasPermission('BankAccountRequest', 'Delete'),
                    action: onDelete,
                    icon: <DeleteIcon />,
                },
            ]}
        />
    );
};
export default ButtonsOfActions;
