import { Alert, DataTable } from '@uy3/web-components';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import DatasetWrapper from 'components/Datasets/DatasetWrapper';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { sum } from 'lodash';
import { HandleTitle } from './TypographyAndValues';

type FgtsProps = {
    id?: string | undefined;
    disableRequestDataSet?: boolean;
};

export function Fgts({ id, disableRequestDataSet}: FgtsProps) {
    const recordType = 'NaturalPerson';
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const dataId = id ?? personId;
    const { dataset, status, refetch } = useDatasetData(recordType, dataId!, 'Fgts');
    const { setSubmitError } = useApiRequest();

    const onSuccess = () => refetch();
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutate, isLoading } = useDatasetMutation(recordType, dataId!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'dataRepasse',
            headerName: 'Data Repasse',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false
        },
        {
            field: 'valor',
            headerName: 'Valor',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues.value ? formatCurrencyInCents(cellValues.value * 100) : 'N/D'
        }
    ];

    const data = dataset?.find((dataset) => dataset?.dataSet === 'fgts') as any;
    const result = data?.result ?? [];

    let error = result?.codigo && `${result?.codigo} - ${result?.descricao}`;
    let error2 = result?.erro && `${result?.erro} - ${result?.mensagem}`;

    if (result?.erro === "429") {
        error2 = result?.erro && `${result?.erro} - O serviço de consulta ao FGTS está indisponível no momento. Tente novamente em alguns segundos.`;
    };

    return (
        <>

            {result?.existeSaldoDesconsiderado ?
                <Alert severity="warning" text='Existe saldo desconsiderado. Isso pode fazer com que o saldo disponível fique bloqueado para novos adiantamentos.' />
                : ""}

            {error && <Alert severity="error" text={error} />}
            {error2 && <Alert severity="error" text={error2} />}

            {!error && !error2 && <>
                <div>
                    <HandleTitle title={'Data do Saldo'} value={result?.dataSaldo ?? 'N/D'} key="dataSaldo" />
                    <HandleTitle
                        title={'Existe Saldo Desconsiderado'}
                        value={result?.existeSaldoDesconsiderado ? "Sim" : "Não"}
                        key="existeSaldoDesconsiderado" />
                    <HandleTitle
                        title={'Quantidade de Pedidos'}
                        value={result?.qtdPedidos ?? 0}
                        key="qtdPedidos" />
                    <HandleTitle
                        title={'Soma das Parcelas Disponíveis'}
                        value={result?.periodos
                            ? formatCurrencyInCents(
                                sum(
                                    result?.periodos
                                        .map((items: any) => items.valor)
                                        .splice(0, 10)
                                        .map((y: any) => y * 100)
                                )
                            )
                            : 'N/D'}
                        key="periodos" />

                </div>
                <DataTable
                    getRowId={(row) => Math.random().toString()}
                    columns={columns}
                    rows={result?.periodos ?? []}
                    page={page}
                    rowCount={result?.periodos?.length ?? 5}
                    rowsPerPage={rowsPerPage}
                    setPage={onChangePage}
                    setRowsPerPage={onChangeRowsPerPage}
                    paginationMode="client" />
            </>}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('Fgts')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};