import { Stack, useMediaQuery } from '@mui/material';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { Button, SaveIcon, Typography, CloseIcon } from '@uy3/web-components';
import { useTenant } from 'contexts/tenantContext';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { activeTheme } from 'services/theme';

type AssignFormProps = {
    groupList: AutocompleteOptionList;
    onClose: () => void; 
    listOperator: AutocompleteOptionList;
    setSelectedTenant: React.Dispatch<React.SetStateAction<{
        label: string | undefined;
        value: string | number | undefined;
    } | undefined>>};

const theme = activeTheme();
export const AssignForm = ({ groupList, onClose, listOperator, setSelectedTenant }: AssignFormProps) => {
    const { tenantAutoCompleteProps } = useTenant();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack spacing={4}>
            <Typography variant='h6'>Selecione um correspondente, operador ou grupo</Typography>

            <AutocompleteField
                label='Correspondente'
                name='tenant'
                onChange={(_, option) => setSelectedTenant({ label: option?.label, value: option?.value as string })}
                displayName='tenantDisplay'
                {...tenantAutoCompleteProps}
            />

            <AutocompleteField
                label='Operadores'
                name='userId'
                displayName='userIdDisplay'
                {...listOperator}
            />

            <AutocompleteField
                label='Grupos'
                name='groupName'
                displayName='groupName'
                {...groupList}
            />
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}>
                    Fechar
                </Button>
                <Button
                    type='submit'
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    size="medium">
                    Atribuir
                </Button>
            </Stack>
        </Stack>
    )
};