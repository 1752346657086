import { useBankAccountPaymentReceiptMutation, usePaymentList } from 'contexts/bankAccount/bankAccountContext';
import { FilterProvider } from 'contexts/filterContext';
import { PaymentTab } from './PaymentList';
import { useState } from 'react';
import { Error } from 'components/Errors/Error';
import PaymentHeader from './PaymentHeaderList';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { RefreshProgress } from 'components/RefreshProgress';
import Toast from 'components/Toast/Toast';

interface PaymentListContainerProps {
    setPaymentId: (paymentId: string) => void;
    setStepAction: (action: string) => void;
    setTransferInfo: (cellValues: any) => void;
    bankAccountId: string | undefined;
    statusBankAccountOptions: any;
}

export default function PaymentListContainer({
    setPaymentId,
    setStepAction,
    setTransferInfo,
    bankAccountId,
    statusBankAccountOptions
}: PaymentListContainerProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [page, setPage] = useState(0);
    const { submitting } = useApiRequest();
    const { isLoading: loadingPaymentreceipt, mutateTransferAndPayment } = useBankAccountPaymentReceiptMutation(bankAccountId!);
    const filtersParams = { page, size: rowsPerPage };
    const { paymentListData, paymentListStatus, paymentListError, isFetching, paymentRefetch } = usePaymentList(bankAccountId!, filtersParams);

    const handlePaymentReceipt = (values: any) => mutateTransferAndPayment(values);

    if (paymentListStatus === 'error') return <Error error={paymentListError} />;

    const onChangePage = (page: number) => setPage(page);

    const onAction = (paymentId: string, action: string) => {
        setStepAction(action);
        setPaymentId(paymentId);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };
    const isPending = paymentListStatus === 'loading';
    const refreshProgress = isFetching || isPending || loadingPaymentreceipt;

    return (
        <div>
            <Toast toast={toast} setToast={setToast} />
            <PaymentHeader isFetching={isFetching || submitting} refetch={paymentRefetch} bankAccountId={bankAccountId} />
            {!isPending && <RefreshProgress refreshing={refreshProgress}/>}
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    f_paymentDate: { label: 'Data do pagamento', type: 'date' },
                    f_amount: { label: 'Valor do pagamento', type: 'text' },
                    f_status: {
                        label: 'Status',
                        type: 'select',
                        multiple: false,
                        options: statusBankAccountOptions,
                    },
                }}
            >
                <ListSkeleton isLoading={isPending }>
                    <PaymentTab
                        onApprove={(paymentId) => onAction(paymentId, 'approve')}
                        onReject={(paymentId) => onAction(paymentId, 'reject')}
                        onCancel={(paymentId) => onAction(paymentId, 'cancel')}
                        handlePaymentReceipt={handlePaymentReceipt}
                        setTransferInfo={setTransferInfo}
                        queryData={paymentListData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                        setToast={setToast}
                    />
                </ListSkeleton>
            </FilterProvider>
        </div>
    );
}
