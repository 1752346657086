/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar } from 'react-date-range';
import pt from 'date-fns/locale/pt';
import 'react-date-range/dist/styles.css'; // Importa o estilo padrão
import 'react-date-range/dist/theme/default.css'; // Importa o tema padrão
import { activeTheme } from 'services/theme';
import { MainBox } from './DatePickerFormField.styled';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CalendarIcon, SearchIcon, TextField, Typography } from '@uy3/web-components';
import { IconButton, InputAdornment, Stack, Popover } from '@mui/material';
import moment from 'moment';
import { useFormContext } from 'contexts/formContext';
import { resolve } from 'services/arrayGetters';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { parse, isValid } from 'date-fns';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { isFieldDisable, isFieldRequired } from 'helpers/methods/formFieldConfiguration';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { dateMask } from 'utils/constants';

type DatePickerFormFieldProps = {
    label: string;
    name: string;
    disabled?: boolean;
    required?: boolean;
    fullWidth?: boolean;
    disablePast?: boolean;
    disableFuture?: boolean;
    showEndAdornment?: boolean;
    componentFilter?: ReactNode;
    shrink?: boolean;
};

const theme = activeTheme();
export function DatePickerFormField({
    label,
    name,
    fullWidth,
    required = false,
    disabled = false,
    disablePast,
    disableFuture,
    showEndAdornment = false,
    componentFilter,
    shrink = false
}: DatePickerFormFieldProps) {
    const [keyboard, setKeyboard] = useState<string>();
    const { validationErrors, watch, setValue, readOnly } = useFormContext();
    const { formFieldsErrors } = useFormFieldsError();
    const { appConfig } = useAppConfig();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const componentRef = useRef(null);
    const error = validationErrors && resolve(name, validationErrors)?.message;
    const onClose = () => setAnchorEl(false);
    const currentValue = watch(name);
    const [dateSelected, setDateSelected] = useState<Date | undefined>(new Date(currentValue))
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    useEffect(() => {
        const currentDate = moment(currentValue)
        const currentDateFormatted = currentDate.format('DD/MM/YYYY');
        if (currentDate.isValid() && currentDateFormatted !== keyboard) {
            setKeyboard(currentDateFormatted);
        }
    }, [currentValue]);

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key)
    });

    const hasValue = () => {
        if (currentValue !== null) {
            return keyboard !== undefined || currentValue?.toString()?.length > 0 ? true : false;
        }
        return false;
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        !readOnly && setAnchorEl(event.currentTarget);
        setDateSelected(undefined)
    };

    const onChange = (event: Date) => {
        if (setValue) {
            const value = toIsoStringWithTimezone(event);
            setValue(name, value, { shouldDirty: true });
            setDateSelected(event)
            onClose();
            setKeyboard(moment(event).format('DD/MM/YYYY'));
        }
    };

    function hasEmptyString(...strings: string[]): boolean {
        return strings.some((str) => {
            const cleanedStr = str.replace(/\D/g, ''); // Remove non-numeric characters
            return cleanedStr === '' || cleanedStr.length !== str.length; // Check if it is empty or has invalid size
        });
    }

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value;
        const date = value?.split('/');
        const day = date[0];
        const month = date[1];
        const year = date[2];
        const valueLength = value.replace(/\D/g, '').length === 8;

        if (value === '' || hasEmptyString(day, month, year)) setValue(name, null);
        if (valueLength) {
            const dateString = `${year}-${month}-${day}`;
            const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());

            if (isValid(parsedDate)) {
                const value = toIsoStringWithTimezone(parsedDate);
                setValue(name, value, { shouldDirty: true });
            }
        }
        setKeyboard(value);
    };

    const isRequired = !!required || isFieldRequired(name);
    const labelWithRequired = isRequired ? `${label} *` : label;
    const skipShrink = shrink || hasValue() || Boolean(keyboard && keyboard?.length > 0);
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);

    const dateFromCalender = hasValue() && dateSelected === undefined ? new Date(currentValue) : dateSelected;
    const colorSelectedDay = dateSelected !== undefined ? 'transparent': theme.palette.info.main; 

    return (
        <div ref={componentRef}>
            <Stack direction="column" alignItems="center" position="relative">
                <TextField
                    variant="outlined"
                    fullWidth={fullWidth}
                    disabled={isFieldDisabledConfig || readOnly || disabled || !!anchorEl}
                    label={labelWithRequired}
                    onChange={handleChange}
                    value={currentValue !== undefined ? keyboard ?? moment(currentValue).format('DD/MM/YYYY') : undefined}
                    error={error || hasResponseError}
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: dateMask },
                        endAdornment: (
                            <>
                                <IconButton
                                    disabled={isFieldDisabledConfig || readOnly || disabled}
                                    sx={{ padding: '15px 0' }}
                                    onClick={handleClick}
                                >
                                    <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                        <CalendarIcon
                                            htmlColor={
                                                disabled || readOnly || isFieldDisabledConfig
                                                    ? theme.palette.grey[500]
                                                    : theme.palette.primary.main
                                            }
                                        />
                                    </InputAdornment>
                                </IconButton>
                                {showEndAdornment && (
                                    <InputAdornment position="end" sx={{ marginX: -0.9 }}>
                                        <IconButton type="submit">
                                            <SearchIcon
                                                sx={{ width: 25, height: 25 }}
                                                htmlColor="#666666"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                                {componentFilter && <>{componentFilter}</>}
                            </>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: skipShrink,
                    }}
                />
            </Stack>
            {!!error && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{responseError?.helpMessage}</>
                </Typography>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MainBox>
                    <Calendar
                        date={dateFromCalender}
                        minDate={disablePast ? new Date() : undefined}
                        maxDate={disableFuture ? new Date() : undefined}
                        onChange={onChange}
                        preventSnapRefocus={false}
                        locale={pt}
                        color={colorSelectedDay}
                        rangeColors={[theme.palette.primary.main, 'blue', 'green']}
                    />
                </MainBox>
            </Popover>
        </div>
    );
}
