import { useMutation, useQuery } from '@tanstack/react-query';
import { useTenant } from "contexts/tenantContext";
import { useIdentity } from "contexts/identityContext";
import { ApiResponseError, GetListApiResponse, GetListApiResponseSuccess, GetViewApiResponse, GetViewApiResponseSuccess, useApiRequest } from "contexts/apiRequestContext";
import { AxiosError, isAxiosError } from "axios";
import { deleteBankAccountOperatorByOperatorId, getAccountOperators, postBankAccountOperator } from "services/accounts/bankAccount/bankAccount";
import { useFilterValues } from "contexts/filterValuesContext";
import { useUserPermissionData } from "contexts/userContext";
import { isEmpty } from 'lodash';
import { BankAccountRequestCreateOrUpdate } from "services/accounts/bankAccountRequest/bankAccountRequest.types";
import { DeleteOperatorByOperatorIdType, postBankAccountRequestOperatorProps } from "services/accounts/bankAccount/bankAccount.types";
import { approveOrRejectSignatureById, editOrCreateBankAccRequest, getBankAccountRequestById, getBankAccountRequestList } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { BankAccountRequestFormSchema } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/BankAccountRequestSchema';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';

export function useBankAccountRequestData(bankAccountId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BankAccountRequest', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && bankAccountId !== "nova" && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["bankAccountRequest-data", bankAccountId],
        queryFn: async (): Promise<GetViewApiResponse<any>> => {
            startRequest();
            if (bankAccountId === "nova") return;
            const { data, status, statusText } = await getBankAccountRequestById(bankAccountId, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponseSuccess<any>
        }
    });

    return {
        bankAccountRequestStatus: status,
        bankAccountRequestData: data as GetViewApiResponseSuccess<any>,
        bankAccountRequestErr: error,
        isLoading,
        isFetching,
        refetch
    };

};

export function useBankAccoutRequestList(filters: any) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const recordType = isEmpty(filterValues.recordType) ? 'BankAccountRequest' : filterValues.recordType;
    const listDataFilters = recordType === 'BankAccountRequest' && { ...filterValues.filters };
    const filtersComplete = { ...filters, ...listDataFilters, tenant: currentTenantId };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BankAccountRequest', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["bankAccountRequest-list", filtersComplete],
        queryFn: async (): Promise<any> => {
            startRequest();
            const resp = await getBankAccountRequestList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data;
        }
    });

    return { bankAccRequestStatus: status, bankAccRequestData: data, bankAccRequestErr: error, bankAccRequestIsFetch: isFetching, refetch, isLoading };

};

export function useAccountOperatorsList(bankAccountId: string, filters: { page: number, size: number }) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && bankAccountId !== "nova",
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["account-operators-list", filters],
        queryFn: async (): Promise<GetListApiResponse<any>> => {
            startRequest();
            const resp = await getAccountOperators(bankAccountId, filters, token!)
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetListApiResponseSuccess<any>
        }
    });

    return {
        accOperatorsStatus: status,
        accOperatorsData: data,
        accOperatorsError: error,
        accOperatorsIsFetch: isFetching,
        refetch
    };

};

export function useBankAccountMutation(id: string, onSuccess?: (data: any) => void, onError?: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (values: BankAccountRequestFormSchema): Promise<GetViewApiResponse<BankAccountRequestCreateOrUpdate>> => {
            startRequest();
            const resp = await editOrCreateBankAccRequest(values, id, token!, currentTenantId);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            return data as GetViewApiResponse<BankAccountRequestCreateOrUpdate>
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);

        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }

    });

    return { mutateAsync, isLoading };
};

export function useDeleteBankAccountOperatorMutation(onSuccess?: (data: any) => void, onError?: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: DeleteOperatorByOperatorIdType): Promise<GetViewApiResponse<BankAccountRequestCreateOrUpdate>> => {
            startRequest();
            const resp = await deleteBankAccountOperatorByOperatorId(values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            return data as GetViewApiResponse<BankAccountRequestCreateOrUpdate>
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);

        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }

    });

    return { mutate, isLoading };
};

export function useCreateOrEditOperatorMutation(accountNumber: string, onSuccess?: (data: any) => void, onError?: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: postBankAccountRequestOperatorProps): Promise<GetViewApiResponse<BankAccountRequestCreateOrUpdate>> => {
            startRequest();
            const resp = await postBankAccountOperator(accountNumber, values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            return data as GetViewApiResponse<BankAccountRequestCreateOrUpdate>
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);

        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }

    });

    return { mutate, isLoading };
};




export function useApproveOrRejectBankAccountRequestMutation(
    bankAccountRequestId: string, 
    isRepprove: boolean, 
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (body: object) => {
            startRequest();
            const { data } = await approveOrRejectSignatureById(bankAccountRequestId, body, isRepprove, token!);
            endRequest(true);
            return data as unknown;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        }
    });
}

