import moment from 'moment';
import { string, object } from 'yup';

export function validationCreditNoteFilterSchema() {
    const int64MaxValue = 9223372036854775807;

    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        tenant: string().when("optionFilter", {
            is: "tenant",
            then: string().required('Tenant: Precisa ser preenchido.').typeError('Correspondente: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        creditNoteNo: string().when("optionFilter", {
            is: "creditNoteNo",
            then: string().typeError('Número da operação precisa ser preenchido.').required('Número da operação: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        ownLiquidation: string().when("optionFilter", {
            is: "ownLiquidation",
            then: string().typeError('Liquidação própria precisa ser preenchido.').required('Liquidação própria: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        ownCompliance: string().when("optionFilter", {
            is: "ownCompliance",
            then: string().typeError('Aprovação compliance própria precisa ser preenchido.').required('Aprovação compliance própria: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        relatedPersons: string().when("optionFilter", {
            is: "relatedPersons",
            then: string().typeError('Envolvido precisa ser preenchido.').required('Envolvido: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        productCategoryDisplay: string().when("optionFilter", {
            is: "productCategoryDisplay",
            then: string().typeError('Categoria do produto inválido.').required('Categoria do produto: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        status: string().when("optionFilter", {
            is: "status",
            then: string().typeError('Status precisa ser preenchido.').required('Status: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        personId: string().when("optionFilter", {
            is: "personId",
            then: string().typeError('Tomador precisa ser preenchido.').required('Tomador: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxValue: string().when("optionFilter", {
            is: "maxValue",
            then: string().test('is-less-than-max', 'Valor máximo inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor máximo precisa ser um número.').required('Valor máximo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minValue: string().when("optionFilter", {
            is: "minValue",
            then: string().test('is-less-than-min', 'Valor mínimo inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor mínimo precisa ser um número.').required('Valor mínimo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string()
                .typeError('Data de início precisa ser preenchido.').required('Data de início: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().typeError('Data final precisa ser preenchido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialPaymentDate: string().when("optionFilter", {
            is: "initialPaymentDate",
            then: string()
                .typeError('Data inicial do pagamento precisa ser preenchido.').required('Data inicial do pagamento: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalPaymentDate: string().when("optionFilter", {
            is: "finalPaymentDate",
            then: string().typeError('Data final do pagamento precisa ser preenchido.').required('Data final do pagamento: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        filterByOwnerGroup: string().when("optionFilter", {
            is: "filterByOwnerGroup",
            then: string().typeError('Grupo de proprietário precisa ser preenchido.').required('Grupo de proprietário: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesCreditNoteFilter = {
    maxValue: 0,
    minValue: 0,
    personId: null,
    productCategoryDisplay: null,
    filterByOwnerGroup: null,
    searchString: null,
    optionFilter: 'searchString',
    tenant: null,
    creditNoteNo: null,
    relatedPersons: null,
    status: null,
    ownLiquidation: false,
    ownCompliance: false,
    initialDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
    initialPaymentDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalPaymentDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate()
};