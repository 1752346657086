import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiResponseError, GetListApiResponse, useApiRequest, GetListApiResponseSuccess, GetViewApiResponse, IRefetchOnMountModeGetQuery } from "./apiRequestContext";
import { useIdentity } from "./identityContext";
import { LegalPersonFormSchema } from "pages/LegalPerson/LegalPersonForm/LegalPersonSchema";
import { editOrCreateLegalPerson, getLegalPersonById, getLegalPersonsList } from "services/accounts/legalPerson";
import { AxiosError, isAxiosError } from "axios";
import { LegalPersonFiltersProps, LegalPersonReadModel } from "services/accounts/legalPerson/types/legalPersonReadModel";
import { useTenant } from "./tenantContext";
import { useState } from "react";
import { useAppConfig } from "./appConfig";
import { useFilterValues } from "./filterValuesContext";
import { useUserPermissionData } from "./userContext";
import { isEmpty } from 'lodash';
import { formatDocumentNumber } from "helpers/formats/DocumentNumber";

export function useLegalPersonData(personId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('LegalPerson', 'Read');

    const isNew = personId === "novo";
    const { data, error, status, refetch, isFetching, isLoading } = useQuery({
        enabled: !!token && !isNew && !isEmpty(personId) && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['legalPerson-data', personId],
        queryFn: async (): Promise<any> => {
            startRequest();
            if(isNew) return;
            const resp = await getLegalPersonById(personId, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponse<LegalPersonReadModel>
        }
    });

    return { legalPersonData: data, legalPersonError: error, legalPersonStatus: status, refetch, isFetching, isLoading };

};

export function useLegalPersonMutation(personId: string, onSuccess?: (data: any) => void, onError?: (error: any) => void) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: LegalPersonFormSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateLegalPerson(values, personId, token, currentTenantId);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};

export function useLegalPersonListData(filters: LegalPersonFiltersProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { appConfig } = useAppConfig();
    const isRootTenancy = appConfig.TENANT_TYPE === 0;
    const [legalPersonList, setLegalPersonList] = useState<{ label: string, value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'LegalPerson' : filterValues.recordType;
    const listDataFilters = recordType === 'LegalPerson' && { ...filterValues.filters };
    const filtersComplete = { ...listDataFilters, ...filters, tenant: currentTenantId };

    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('LegalPerson', 'Read');

    const queryContext = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ["legalPerson-list", filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<LegalPersonReadModel>> => {
            startRequest();
            const resp = await getLegalPersonsList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            var list = data as GetListApiResponseSuccess<LegalPersonReadModel>
            setLegalPersonList(list.data.map((n: LegalPersonReadModel) => {
                return {
                    label: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay}) - (${formatDocumentNumber(n.registrationNumber)})` : n.name,
                    value: n.id
                };
            }));

            return data as GetListApiResponse<LegalPersonReadModel>
        }

    });

    const legalPersonAutoCompleteProps = {
        listOptions: legalPersonList,
        loading: !hasReadPermission ? false : queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ searchString }, 'LegalPerson')
    };

    return {
        ...queryContext,
        legalPersonAutoCompleteProps
    };
};
