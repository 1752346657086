import { removeDuplicateData } from 'helpers/formats/ArrayFormats';
import { ErrorDetails, ApiResponseError } from './apiRequestContext';
import { HelperErrorType } from './formFieldsErrors';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { isEmpty } from 'lodash';

type ResponseMessage = {
    code: string;
    errorMessage: string;
    correlationId: string;
    message?: string;
    formErrors?: string[];
    fieldErrors?: HelperErrorType[];
    warningToastError?: string;
};

type ErrorBillingDataIntegrationType = { details: string, message: string };

type FormErrorsType = {
    message: string | undefined;
    fieldErrors: HelperErrorType[] | undefined;
    warningToastError?: string;
};

const invalidCodes = ['ERR_NETWORK'];

const listRegex = [
    '',
    'Uploads',
    'Owner.Address',
    'BankAccounts',
    'amortization.amortizationType',
    'RelatedPerson',
    'RelatedPersonFund',
    'RelatedPersonCreditNote',
    'commissionRange',
    'CommercialAddress',
    'MaximumAbsAmortizationPeriods',
    'MaximumAbsInterestPeriods'
];

const genericMessage = 'Houve um erro inesperado: Por favor tente novamente mais tarde.';

function isMessageInvalid(message: string): boolean {
    const isEmpty = message === undefined || message === null || message === '';
    const hasObject = message?.includes('object Object');
    return isEmpty || hasObject;
}

const detailsFull = (resultErrorDetails: any) => {
    const details = resultErrorDetails?.details as any;
    if (details?.details?.length > 0) {
        return details?.details;
    }
    return details;
}

function mapMessageDetails(formErrors: any, messageResult: string, code: string) {
    const onlyMesage: string[] = formErrors?.map((item: any) => {
        let message: string = item?.message ?? item?.reason ?? item?.details ?? item?.errorMessage;
        if (message?.includes('-')) {
            let index = message?.indexOf('-');
            message = message.slice(index)?.replace("-", "")?.trim();
        }
        return message;
    })

    const listOnlyIsRequired = onlyMesage.filter((message: string) => message?.includes("É obrigatório informar"));
    const messagesRequired = listOnlyIsRequired?.map(item => item?.slice(22)).join();

    const joinStringError = formErrors?.slice(0, 3)?.map((item: any) => item?.message)?.join(',\n');
    const genericArrayMessage = `${joinStringError} ${formErrors?.length > 3 ? "[...]" : ""}`
    const containsMessageRequired = messagesRequired?.length > 0;

    const optionsMessage = containsMessageRequired ? messagesRequired : genericArrayMessage ?? genericMessage;
    const discriminatorMessage =
        code === "INVALID_FORM" && containsMessageRequired ? "É obrigatório informar: " : `${messageResult}: `;

    return `${discriminatorMessage} ${optionsMessage}`;
}

function mapDetails(details: any[], code: string): string {
    const detailsList = detailsFull(details) ?? details;
    if (detailsList?.length === 0) return genericMessage;
    const detailsMapped = detailsList?.map((item: any) => item?.errorMessage || item?.message)?.join(', ');
    const detailsMessage = mapMessageDetails(details, genericMessage, code);
    return detailsMessage ?? detailsMapped;
}


function checkForCustomErrorMessages(formErrors: Array<{ key: string }>): boolean {
    if (formErrors?.length === 0) return false;
    return formErrors.every(({ key }) => {
        const indexInvalidKey = key.indexOf('[');
        const regex = key.slice(0, indexInvalidKey !== -1 ? indexInvalidKey : key.length);
        const possible = regex.slice(0, regex.indexOf('.'));
        if (possible === 'CommercialAddress') return true;
        return isMessageInvalid(regex) || listRegex?.includes(regex);
    });
}


function mapFormErrors({ formErrors, ...rest }: ErrorDetails, defaultMessage: string, code?: string): FormErrorsType {
    const toObjectError = rest as any

    if (Object?.values(toObjectError)?.length > 0) {
        const messageErr = toObjectError[0]?.errorMessage ?? genericMessage
        return {
            fieldErrors: undefined,
            message: messageErr
        };
    }

    if (!formErrors || formErrors.length === 0) {
        return {
            fieldErrors: undefined,
            message: undefined
        };
    }

    let isCustomMessage = checkForCustomErrorMessages(formErrors);

    const findKeyEmpty = formErrors.find((item) => item.key.length === 0);

    if (Object.keys(findKeyEmpty ?? {}).length > 0) {
        const message = findKeyEmpty?.message ?? defaultMessage;
        return {
            fieldErrors: undefined,
            message
        }
    }

    const messages = removeDuplicateData(formErrors, 'message').map(({ message }) => message);
    const fieldErrors = removeDuplicateData(formErrors, 'message').map(({ key, message }) => ({
        key: key?.toLowerCase(),
        helpMessage: message
    }));

    const errorMessage = `${defaultMessage}: ${messages.join(', ')}`;
    const message = isCustomMessage ? errorMessage : errorMessage ?? defaultMessage;

    // This is a specific case where the error message is displayed in a toast instead of the form.
    const warningToastError = code === 'INVALID_BUSINESS_RULE' && formErrors.length === 0 ? errorMessage : errorMessage ?? defaultMessage;

    return {
        message,
        fieldErrors: isCustomMessage ? undefined : fieldErrors,
        warningToastError
    };
}

// (SRP)
// The function now has a single responsibility: to map an `ApiResponseError` to a `ResponseMessage`.
export function mapErrorResponse(responseError: ApiResponseError): ResponseMessage {
    if (responseError?.code === undefined || invalidCodes?.includes(responseError?.code)) {
        return {
            correlationId: '',
            errorMessage: responseError?.message ?? genericMessage,
            code: '',
        };
    }
    // (DIP)
    // The function now depends on abstractions (interfaces) instead of concrete implementations. This makes the function more flexible and easier to test.
    const errorHandler = getErrorHandler(responseError);
    return errorHandler.mapError();
}

// (ISP)
// The `ErrorHandler` interface has been split into smaller, more specific interfaces, such as `FormErrorHandler` and `GenericErrorHandler`. To makes the code more modular and reusable.
interface ErrorHandler {
    mapError: () => ResponseMessage;
}

const invalid_form = "INVALID_FORM"
// (LSP)
// The `FormErrorHandler` and `GenericErrorHandler` classes both implement the `ErrorHandler` interface, so they can be substituted for each other without breaking the code.
function getErrorHandler(responseError: ApiResponseError): ErrorHandler {
    const codeByResponse = responseError?.code;
    const codeByDataResponse = responseError?.response?.data?.code;

    if (codeByResponse === invalid_form || codeByDataResponse === invalid_form) return new FormErrorHandler(responseError);
    return new GenericErrorHandler(responseError);
}

function formatErrorBillingDataIntegration({ details, message }: ErrorBillingDataIntegrationType): string {
    if (isMessageInvalid(details)) return message;
    return `${message}: ${details}`;
}

function invalidBusinessRuleMessage(details: any, defaultMessage: string | undefined): string {
    const formErrors = details?.formErrors ?? []; 
    
    if (!isEmpty(formErrors)) {
        const joinMessage = formErrors?.map((item: any) => item?.message)
        return joinMessage?.join('. ');
    }
    
    if (details?.length > 0 && typeof details === 'object') {
        const hasPropertieMembersName = details?.every((x: any) => x?.memberNames?.length > 0); 
        const getMessageWithJoin = details?.map((x: any) => x?.errorMessage)?.join(', '); 
        return hasPropertieMembersName && getMessageWithJoin?.length > 0 ? getMessageWithJoin : genericMessage;
    }

    return defaultMessage ?? genericMessage;

}

function getMessageLimit(details: any) {
    const isBusinessHourMessage = details?.isBusinessHour ? "em horário comercial" : "fora do horário comercial"
    return `Sua transação ${details?.transactionTypeDisplay} no valor de ${formatCurrencyInCents(details.transferValue)}
    para ${details.personTypeDisplay} ${isBusinessHourMessage} não pôde ser processada.
    Limite atual: ${formatCurrencyInCents(details.currentLimit)}. Solicite aumento do seu limite na aba 'Meus Limites'`;
}
class GenericErrorHandler implements ErrorHandler {
    constructor(private responseError: ApiResponseError) { }

    mapError(): ResponseMessage {
        const resultError = this.responseError?.response?.data ?? this.responseError as any;
        if (resultError?.details === null) {
            return {
                correlationId: resultError.correlationId,
                errorMessage: resultError.message,
                code: this.responseError!?.code,
            };
        }

        const formatDetail = (details: any, key: string, label: string, isCurrency: boolean = false) => {
            if (!details[key]) return "";

            const currencyValue = details[key];
            return isCurrency ? `${label}: ${formatCurrencyInCents(currencyValue)}` : `${label}: ${currencyValue}`;
        };

        const mapAmortizationErrors = (details: any) => {
            if (!details) return "";

            /**
            * Definições de mapeamento para formatar detalhes específicos do objeto `details`.
            * Cada objeto define uma chave, uma etiqueta descritiva e se o valor associado deve ser formatado como moeda.
            */
            const mappings = [
                { key: "principalAmountInCents", label: "Valor do contrato", isCurrency: true },
                { key: "valueNumberBankNotes", label: "Valor da nota", isCurrency: true },
                { key: "productMaximumPrincipalAmount", label: "Valor máximo do produto", isCurrency: true },
                { key: "productMinimumPrincipalAmount", label: "Valor mínimo do produto", isCurrency: true },
                { key: "liquidValue", label: "Valor líquido", isCurrency: true },
                { key: "comissions", label: "Emissões", isCurrency: true },
                { key: "financeTax", label: "IOF", isCurrency: true },
                { key: "firstDueDate", label: "Data da primeira parcela" },
                { key: "startDate", label: "Data de início" },
                { key: "expectedFinanceTax", label: "IOF esperado", isCurrency: true },
                { key: "receivedFinanceTax", label: "IOF enviado", isCurrency: true },
                { key: "lastPrincipalAmountInCents", label: "Saldo devedor final", isCurrency: true },
            ];

            return mappings
                .map(({ key, label, isCurrency }) => formatDetail(details, key, label, isCurrency))
                .filter(msg => msg !== "")
                .join(", ");
        };

        const mapformErrors = (formErrors: any) => {
            if (formErrors === undefined || formErrors === null || formErrors?.length === 0) return resultError?.message;
            return mapMessageDetails(formErrors, resultError?.message, resultError?.code);
        }

        const errors: { [code: string]: { message: string } } = {
            INVALID_MFA: {
                message: `${resultError?.details?.reason ?? resultError?.message}`,
            },
            BILLING_DATA_INTEGRATION_ERROR: {
                message: `${formatErrorBillingDataIntegration({
                    details: resultError?.details?.details,
                    message: resultError?.message
                })}`
            },
            FGTS_DATA_INTEGRATION_ERROR: {
                message: `${resultError?.message}${isMessageInvalid(resultError!.details?.reason)
                    ? ''
                    : `: ${resultError?.details?.reason ?? genericMessage}`
                    }`,
            },
            RESOURCE_ID_NOT_FOUND: {
                message: `${resultError?.message} ${!isMessageInvalid(resultError!.details?.errorMessages)
                    ? `: ${resultError?.details?.errorMessages}`
                    : ''
                    }`,
            },
            UNEXPECTED_EXCEPTION: {
                message: `${resultError?.message}`
            },
            INVALID_AMORTIZATION_QUERY: {
                message: `${resultError?.message} ${mapAmortizationErrors(resultError?.details)}`
            },
            INVALID_FORM: {
                message: resultError?.details?.length > 0 ?
                    `${mapDetails(resultError?.details as any, resultError?.code)}` :
                    mapformErrors(resultError?.details?.formErrors) ?? resultError?.message,
            },
            INSUFICIENT_TRANSACTION_LIMIT: {
                message: !!resultError?.details ? getMessageLimit(resultError?.details) : resultError?.message
            },
            RECORD_NOT_EXISTS_OR_MISSING_PERMISSION: {
                message: `${resultError?.message} ${resultError?.details?.resource ? `(${resultError?.details?.resource})` : ""}`
            },
            INVALID_ACTION_FOR_RECORD: {
                message: `${resultError?.message ?? genericMessage} ${resultError?.details?.details ?? ""}`
            },
            INVALID_BUSINESS_RULE: {
                message: invalidBusinessRuleMessage(resultError?.details, resultError?.message)
            }
        };

        const hasCurrentCode = Object.keys(errors)?.includes(resultError!.code);

        const errorOnlyString =
            typeof resultError?.details === 'string' ? `${resultError?.message}: ${resultError?.details}` : resultError?.message;
        const errorMessage = hasCurrentCode ? errors[resultError!.code]?.message : errorOnlyString;

        //ToDo: Precisa melhorar a lógica de validação para casos de erros em FGTS 'CPF inválido etc...'
        const reasonErr = resultError?.details?.code === "FGTS_DATA_INTEGRATION_ERROR" ? resultError?.details?.reason : errors[resultError!.code]?.message ?? resultError?.message;

        return {
            code: this.responseError?.response?.data.code ?? this.responseError.code,
            errorMessage: errorMessage ?? genericMessage,
            correlationId: resultError?.correlationId ?? "",
            message: resultError?.message ?? genericMessage,
            warningToastError: reasonErr
        };
    }
}

class FormErrorHandler implements ErrorHandler {
    constructor(private responseError: ApiResponseError) { }

    mapError(): ResponseMessage {
        const details = this.responseError?.details ?? this.responseError?.response?.data?.details;
        const errorsByBadRequest = this.responseError?.response?.data.details;
        const messageResult = this.responseError?.response?.data.message ?? this.responseError?.message ?? genericMessage;
        const code = this.responseError?.code;
        const codeByDataResponse = this.responseError?.response?.data?.code;
        const invalidForm = code === 'INVALID_FORM' ? code : codeByDataResponse

        if (errorsByBadRequest !== undefined && code === 'ERR_BAD_REQUEST' && !invalidForm) {
            let message: string = "";
            const errorsToList = errorsByBadRequest as any;
            if (typeof errorsByBadRequest === 'object' && errorsToList?.length > 0) {
                const errorsMessage = mapDetails(errorsToList, code);
                message = errorsMessage;
            } else {
                message = messageResult ?? genericMessage;
            }
            const possibleMessage = message ?? messageResult;
            return {
                code: code,
                correlationId: this.responseError?.correlationId!,
                errorMessage: possibleMessage ?? genericMessage,
            };
        }

        const { fieldErrors, warningToastError } = mapFormErrors(details!, messageResult!, invalidForm);
        return {
            code,
            correlationId: this.responseError?.correlationId!,
            errorMessage: messageResult ?? genericMessage,
            fieldErrors,
            warningToastError
        };
    }
}
