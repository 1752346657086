import styled from '@emotion/styled';
import { Grid, Stack } from '@mui/material';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

interface CustomGridProps {
    borderColor?: string;
}

export const CustomGrid = styled(Grid)<CustomGridProps>`
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    border-color: ${props => props.borderColor};

    &:hover {
        border-color: ${theme.palette.primary.main};
    }
`;

export const CustomBox = styled(Grid)`
    border-radius: 8px;
    background: rgba(186, 191, 208, 0.16);
    display: flex;
    width: 565px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export const CustomStack = styled(Stack)`
    border-radius: 8px;
    border: 1px solid var(--neutral-light, #babfd0);
    padding: 24px;
    padding-bottom: 0;
    margin-right: 24px;
`;


export const CustomInfoContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #babfd0;
    flex-shrink: 0;
    margin-bottom: 24px;
`;
