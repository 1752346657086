import moment from 'moment';
import { string, object } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

export function billingFiltersSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when('optionFilter', {
            is: 'searchString',
            then: string().required(`Filtro: ${genericMessage}.`).typeError('Filtro inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        documentNumber: string().when('optionFilter', {
            is: 'documentNumber',
            then: string()
                .required(`Nº do documento: ${genericMessage}`)
                .typeError('Nº do documento é inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        amount: string().when('optionFilter', {
            is: 'amount',
            then: string()
                .required(`Valor de cobrança: ${genericMessage}`)
                .typeError('Valor de cobrança inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        statusesValue: string().when('optionFilter', {
            is: 'statusesValue',
            then: string().required(`Status: ${genericMessage}`).typeError('Status inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        barCode: string().when('optionFilter', {
            is: 'barCode',
            then: string().required(`Código de barras: ${genericMessage}`).typeError(`Código de barras: ${genericMessage}`),
            otherwise: string().nullable().notRequired(),
        }),
        payerName: string().when('optionFilter', {
            is: 'payerName',
            then: string().required(`Nome do devedor: ${genericMessage}`).typeError(`Nome do devedor: ${genericMessage}`),
            otherwise: string().nullable().notRequired(),
        }),
        payerRegistrationNumber: string().when('optionFilter', {
            is: 'payerRegistrationNumber',
            then: string()
                .required(`CPF/CNPJ do devedor: ${genericMessage}`)
                .typeError(`CPF/CNPJ do devedor: ${genericMessage}`)
                .cpfCnpjValidation('CPF/CNPJ inválido'),
            otherwise: string().nullable().notRequired(),
        }),
        initialDueDate: string().when("optionFilter", {
            is: "initialDueDate",
            then: string()
                .typeError('Data de início precisa ser preenchido.').required('Data de início: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalDueDate: string().when("optionFilter", {
            is: "finalDueDate",
            then: string().typeError('Data final precisa ser preenchido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        firstInstructionDebtCollection: string().when("optionFilter", {
            is: "firstInstructionDebtCollection",
            then: string().typeError('Instrução de protesto precisa ser preenchido.').required('Instrução de protesto: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialSettlementDate: string().when("optionFilter", {
            is: "initialSettlementDate",
            then: string().typeError('Data de início do crédito precisa ser preenchido.').required('Data de início do crédito: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalSettlementDate: string().when("optionFilter", {
            is: "finalSettlementDate",
            then: string().typeError('Data de final do crédito precisa ser preenchido.').required('Data de final do crédito: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        inicialCreatedDate: string().when("optionFilter", {
            is: "inicialCreatedDate",
            then: string().typeError('Data de inicial de criação precisa ser preenchido.').required('Data de inicial de criação: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalCreatedDate: string().when("optionFilter", {
            is: "finalCreatedDate",
            then: string().typeError('Data de final de criação precisa ser preenchido.').required('Data de final de criação: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesBillingFilterSchema = {
    optionFilter: 'searchString',
    documentNumber: null,
    amount: null,
    statusesValue: null,
    barCode: null,
    payerName: null,
    payerRegistrationNumber: null,
    initialDueDate: new Date(moment().subtract(1, 'month').format()),
    finalDueDate: new Date(),
    firstInstructionDebtCollection: null,
    initialSettlementDate: new Date(moment().subtract(1, 'month').format()),
    finalSettlementDate: new Date(),
    inicialCreatedDate: new Date(),
    finalCreatedDate: new Date()
};
