import { string, object, date, number, InferType } from 'yup';
import { generateAddressSchema, validateRequiredFields } from 'helpers/validations/RequiredFieldsValidations';

export const documentTypeOption = [
    { label: 'RG', value: 'RG' },
    { label: 'CNH', value: 'CNH' },
    { label: 'CTPS', value: 'CTPS' },
]

export function validationSchemaNaturalPerson() {
    return object().shape({
        documentType: validateRequiredFields(
            string().typeError('Tipo de documento inválido.'),
            'documentType',
            `Tipo de documento`
        ),
        employeeNumber: validateRequiredFields(
            string().typeError(`Matricula do funcionario inválida.`), 
            'employeeNumber', 
            `Matricula do funcionario`
        ),
        department: validateRequiredFields(
            string().typeError(`Departamento inválido.`), 
            'department',
            `Departamento`
        ),
        healthDeclaration: string().nullable(),
        name: validateRequiredFields(string().typeError(`Nome inválido.`), 'name', `Nome`),
        socialName: string().nullable(),
        registrationNumber: validateRequiredFields(
            string().cpfCnpjValidation('CPF inválido.'),
            'registrationNumber',
            `CPF`
        ),
        phone: validateRequiredFields(
            string().typeError(`telefone inválido.`).phoneNumberValidation('Número de telefone deve conter 11 Caracteres'),
            'phone',
            `Telefone`
        ),
        email: validateRequiredFields(
            string().email('Email inválido.'),
            'email',
            `Email`
        ),
        nationality: validateRequiredFields(
            string().typeError('Nacionalidade inválida.'), 
            'nationality', 
            `Nacionalidade`
        ),
        address: generateAddressSchema(),
        birthDate: validateRequiredFields(
            date().typeError('Data de nascimento inválida.').max(new Date(), 'A data de nascimento não pode ser maior que a data atual.'),
            'birthDate',
            `Data de nascimento`
        ),
        civilStatus: validateRequiredFields(
            string().typeError('Estado civil inválido.'),
            'civilStatus',
            `Estado civil`
        ),
        gender: validateRequiredFields(
            string().typeError(`Gênero inválido.`),
            'gender',
            `Gênero`
        ),
        documentNumber: validateRequiredFields(
            string().typeError('Documento de identidade inválido.'),
            'documentNumber',
            `Documento de identidade`
        ),
        documentIssuer: validateRequiredFields(
            string().typeError('Emissor/UF inválido.'),
            'documentIssuer',
            `Emissor/UF`
        ),
        documentDate: validateRequiredFields(
            date().typeError('Data inválida.'),
            'documentDate',
            `Data de emissão`
        ),
        documentExpiration: date().typeError('Data inválida.').nullable(),
        placeOfBirthCountry: string().nullable(),
        typeOfResidence: string().nullable(),
        workplace: string().nullable(),
        workplaceCompanyRegistrationNumber: string().cpfCnpjValidation('CNPJ inválido.').nullable(),
        occupation: validateRequiredFields(
            string().typeError('Ocupação inválido.'),
            'occupation',
            `A ocupação`
        ),
        natureOfOccupation: string().nullable(),
        commercialPhone: string().nullable(),
        netSalary: validateRequiredFields(
            number().typeError('Valor precisa ser um número.'),
            'netSalary',
            `Salário`
        ),
        mothersName: validateRequiredFields(
            string().typeError('Nome da mãe inválido.'),
            'mothersName',
            `Nome da mãe`
        ),
        fathersName: validateRequiredFields(
            string().typeError('Nome do pai inválido.'),
            'fathersName',
            `Nome do Pai`
        ),
        admissionDate: date().typeError('Data inválida.').nullable(),
        economicActivityCodeId: string().nullable(),
        spouseCPF: string().cpfCnpjValidation().nullable(),
        spouseBirthDate: date().typeError('Data inválida.').nullable(),
        spouseWorkplace: string().cpfCnpjValidation('CNPJ inválido.').nullable(),
        phone2: string().nullable(),
        commercialPhone2: string().nullable(),
        spouseDocumentNumber: string().nullable(),
        spouseCommercialPhone: string().nullable(),
    });
}

let inferedSchema = validationSchemaNaturalPerson();
export type NaturalPersonSchema = InferType<typeof inferedSchema>;

export const defaultValuesNaturalPerson = {
    employeeNumber: null,
    registrationNumber: '',
    phone: '',
    phoneExtension: '',
    email: '',
    name: '',
    socialName: null,
    nationality: null,
    pep: null,
    address: null,
    birthDate: null,
    civilStatus: '',
    gender: '',
    documentType: '',
    documentNumber: '',
    documentIssuer: '',
    documentDate: null,
    documentExpiration: null,
    phone2: '',
    phone2Extension: '',
    mothersName: null,
    fathersName: '',
    placeOfBirthCountry: '',
    placeOfBirthState: '',
    typeOfResidence: '',
    between7And15YearsOld: 0,
    upTo6YearsOld: 0,
    over15YearsOld: 0,
    workplace: '',
    workplaceCompanyRegistrationNumber: '',
    occupation: '',
    natureOfOccupation: '',
    commercialPhone: '',
    commercialPhoneExtension: '',
    netSalary: 0,
    otherIncome: 0,
    admissionDate: null,
    commercialPhone2: '',
    commercialPhone2Extension: '',
    spouseDocumentNumber: '',
    spouseName: '',
    spouseWorkplace: '',
    spouseOccupation: '',
    spouseCommercialPhone: '',
    spouseBirthDate: null,
    spouseNetSalary: 0,
    spouseOtherIncome: 0,
    bankAccounts: [],
};