import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

type CreditNoteActionsProps = {
    isRootTenancy: boolean
}
export const CreditNoteActions = ({ isRootTenancy }: CreditNoteActionsProps) => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Clonar Registro', name: 'actions.Clone' }}
                helpMessage="Permite criar uma cópia idêntica de um registro existente"
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Enviar para aprovação', name: 'actions.SubmitApproval' }}
                helpMessage="Permite enviar uma operação para aprovação, o que a coloca em estado pendente de análise."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Cancelar', name: 'actions.Cancel' }}
                helpMessage="Permite interromper o processo antes que seja finalizado ou aprovado."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Atribuir Registro', name: 'actions.AssignRecord' }}
                helpMessage="Permite designar ou associar um determinado registro a um ambiente específico."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Enviar notificação', name: 'actions.SendNotification' }}
                helpMessage="Permite enviar notificações referente ao processo no qual o registro pertence."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Restaurar Registro', name: 'actions.RestoreRecord' }}
                helpMessage="Permite restourar um registro no qual foi excluído anteriormente."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Consultar Dataset', name: 'actions.ReadDataSet' }}
                helpMessage="Permite consumir conjunto de informações de crédito, pessoas físicas e jurídicas."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Solicitar Dataset', name: 'actions.RequestDataSet' }}
                helpMessage="Permite o usuário solicitar uma informação do seu desejo."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Revisar Pagamento', name: 'actions.LiquidationPaymentRevision' }}
                helpMessage="Permite realizar a revisão de um determinado pagamento."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Aprovar reserva de margem', name: 'actions.ApproveMarginReserve' }}
                helpMessage="Permite aprovar um registro que está na etapa reserva de margem."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Aprovar Garantia', name: 'actions.ApproveWarranty' }}
                helpMessage="Permite aprovar um registro que está na etapa de garantia."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Aprovar Compliance', name: 'actions.ComplianceApproval' }}
                helpMessage="Permite aprovar um registro que está na etapa de aprovação de compliance."
            />

            {isRootTenancy && <>
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Aprovar Crédito', name: 'actions.CreditApproval' }}
                    helpMessage="Permite aprovar um registro que está na etapa de aprovação de crédito."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Aprovar Instrumento', name: 'actions.InstrumentApproval' }}
                    helpMessage="Permite aprovar um registro que está na etapa de aprovação de instrumento."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Aprovar liquidação', name: 'actions.LiquidationApproval' }}
                    helpMessage="Permite aprovar um registro que está na etapa de liquidação."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Salvar documentos', name: 'actions.UploadDocs' }}
                    helpMessage="Permite salvar documentos em alguns momentos específico da operação."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Gerar Contrato', name: 'actions.DraftPreview' }}
                    helpMessage="Você pode escolher entre um contrato com ou sem marca d'água. Permitindo selecionar a opção que melhor atende às suas necessidades."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Gerar Contrato de Cessão', name: 'actions.AssigmentPreview' }}
                    helpMessage="Você pode escolher entre um contrato com ou sem marca d'água. Permitindo selecionar a opção que melhor atende às suas necessidades."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Enviar documentos por e-mail', name: 'actions.SendAssigmentEmail' }}
                    helpMessage="Permite enviar documentos via e-mail informado."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Concluir assinaturas', name: 'actions.FinishSignature' }}
                    helpMessage="Permite concluir um registro que se encontra em assinatura."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Atualização manual', name: 'actions.ManualUpdate' }}
                    helpMessage="Permite realizar a atualização manual do registro, possibilitando alterar status, linha do tempo, descrição e etc."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Remover Bloqueio', name: 'actions.RemoveBlock' }}
                    helpMessage="Remove de um cadastro específico que esta relacionado(a) ao registro."
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Validação de assinaturas', name: 'actions.SignatureValidation' }}
                    helpMessage="Permite validar registros que estão em validação de assinaturas."
                />

                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Iniciar averbação de saque aniversário', name: 'actions.RegisterFgtsSaqueAniversario' }}
                />

                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Atribuir fundo', name: 'actions.SetFund' }}
                    helpMessage="Permite atribuir um fundo na operação depois de ser criada."
                />

                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Integrar com WebCred', name: 'actions.SendToWedCred' }}
                />

                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Cancelamento de admin', name: 'actions.AdminCancel' }}
                />
                <CheckboxWithTooltip
                    checkboxFormField={{ label: 'Reiniciar Esteira', name: 'actions.RestartWorkflow' }}
                    helpMessage="Permite reiniciar esteira de uma operação alterando a etapa atual da operação."
                />
            </>}
        </React.Fragment>
    )
}
