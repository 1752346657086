import { toDataModel } from "helpers/validations/validateAndPreparePayload";
import { BankAccountProductFormSchema } from "pages/Product/BankAccountProduct/BankAccountProductForm/BankAccountProductSchema";
import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { BankAccountProductCreateModel } from "./types/productCreateModel";
import { BankAccountProductUpdateModel, mapBankAccountProductUpdateModelData } from "./types/productUpdateModel";
import { BankAccountProductReadModel } from "./types/productReadModel";

export interface ProductAccFiltersProps {
    page: number;
    size: number;
    searchString?: string;
    name?: string;
    initialDate?: string;
    finalDate?: string;
    orderBy?: string;
    tenant?: string;
};

var url = `/BankAccountProduct`;
export const getBankAccountProductList = async (filters: ProductAccFiltersProps, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await creditAxiosApi.get(url, config);
};


export async function getProductAccById(productId: string, token = "", tenant?: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.get<BankAccountProductReadModel>(url + `/${productId}`, config);
};

type BankAccountProductTypeModel = BankAccountProductCreateModel | BankAccountProductUpdateModel;
export async function editOrCreateBankAccountProduct(
    data: BankAccountProductFormSchema,
    productId: string,
    token = '',
    tenant?: string
) {
    const isCreating = productId === 'novo';
    const urlFull = isCreating ? url : `${url}/${productId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const ProductCreateOrUpdate = isCreating ? BankAccountProductCreateModel : BankAccountProductUpdateModel;
    const payload = toDataModel<BankAccountProductTypeModel>(data, ProductCreateOrUpdate);
    if(payload?.relatedPerson) payload.relatedPerson = mapBankAccountProductUpdateModelData(payload.relatedPerson, 'relatedPerson');
    if(payload?.requiredDoc) payload.requiredDoc = mapBankAccountProductUpdateModelData(payload.requiredDoc, 'requiredDoc');

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<BankAccountProductReadModel>(urlFull, payload, axiosConfig);
};

export const createBankAccountProduct = async (payload: BankAccountProductFormSchema, tenant: string, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.post(url, payload, config)
};

export const deleteBankAccountProductById = async (productId: string, token?: string, tenant?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.delete(url + `/${productId}`, config);
};