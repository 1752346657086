import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { optionsTypeAccountComplete } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask } from 'utils/constants';
import { cnpjMask, cpfMask, cpfMaskAndSpace } from 'utils/constants';
import { useApiRequest } from 'contexts/apiRequestContext';
import { useBankList } from 'contexts/bankList';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import { pixKeyTypeValueOpt } from 'services/accounts/bankAccount/bankAccount.types';
import { activeTheme } from 'services/theme';

type BankAccountFavoredFormProps = {
    onClose: () => void;
};

const theme = activeTheme();
export const BankAccountFavoredForm = ({ onClose }: BankAccountFavoredFormProps) => {
    const { watch, setValue } = useFormContext();
    const { submitting } = useApiRequest();
    const isOperationTypeValueTransfer = watch('operationTypeValue') === 'Transfer' ; 
    const { bankListAutocomplete } = useBankList({ compe: isOperationTypeValueTransfer });
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, '').length > 11;

    return (
        <Stack spacing={3}>
            <TextFormField name="name" variant="outlined" label="Nome do favorecido" fullWidth />

            <SelectFormField
                name="operationTypeValue"
                label="Tipo de Operação"
                options={[
                    { label: 'Transferência', value: 'Transfer' },
                    { label: 'Pix', value: 'Pix' },
                ]}
            />

            {watch('operationTypeValue') === 'Pix' && (
                <Grid item xs={6}>
                    <SelectFormField
                        name="pixKeyTypeValue"
                        label="Tipo de chave Pix"
                        defaultValue={false}
                        options={pixKeyTypeValueOpt}
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'LegalRegistrationNumber' &&
                <TextFormField
                    name="keyPix"
                    label="CNPJ"
                    variant="outlined"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: cnpjMask },
                    }}
                    fullWidth
                />}

            {watch('pixKeyTypeValue') === 'NaturalRegistrationNumber' &&
                <TextFormField
                    name="keyPix"
                    label="CPF"
                    variant="outlined"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: cpfMask },
                    }}
                    fullWidth
                />}

            {watch('pixKeyTypeValue') === 'Phone' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        name="keyPix"
                        label={`Telefone`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: phoneNumberMask },
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'Automatic' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        name="keyPix"
                        label={`Chave aleatória`}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'Email' && (
                <Grid item xs={6}>
                    <TextFormField
                        name="keyPix"
                        label={`Chave Pix Email`}
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event?.target?.value;
                            setValue('keyPix', value);
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {isOperationTypeValueTransfer || watch('pixKeyTypeValue') === 'AgencyAndAccount' ? (
                <>
                    <Grid item xs={6}>
                        <AutocompleteField
                            name="bank"
                            label="Selecione um banco"
                            displayName="bankName"
                            {...bankListAutocomplete}
                        />
                    </Grid>

                    <TextFormField
                        name="registrationNumber"
                        label="CPF/CNPJ"
                        variant="outlined"
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        }}
                        fullWidth
                    />
                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            label="Agência (sem o digito)"
                            name="agency"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            label="Conta (com o digito)"
                            name="accountNumber"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField
                            name="accountType"
                            label="Tipo de conta"
                            defaultValue={false}
                            options={optionsTypeAccountComplete}
                        />
                    </Grid>
                </>
            ) : <></>}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-submit"
                    disabled={submitting}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    type="submit"
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};
