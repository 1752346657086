import { IUploadModel } from 'contexts/apiRequestContext';
import { EAssignmentType } from '../enums';
import { IAssignmentCalculationModel } from 'services/creditNote/types/genericTypes';
import { ESignaturePortalProvider, ESignatureType, ESignatureValidation } from 'utils/enums';
import { IAddressModel, IBankAccountCreateOrUpdateModel } from 'utils/interface';

export class FundBaseModel {
    name!: string;
    externalId!: string;
    registrationNumber!: string;
    companyName!: string;
    cnabType!: string;
    ftpHostName!: string;
    ftpUser!: string;
    ftpPassword!: string;
    pathLastro!: string;
    pathCnab!: string;
    collectAssignmentSignatures: boolean = false;
    allowIndividualAssignment: boolean = false;
    templateDoc!: IUploadModel;
    templateBatchPath!: IUploadModel;
    address!: IAddressModel;
    email!: string;
    titleSpecies?: number;
    administrator!: string;
    administratorPersonId?: string;
    assignorCode!: string;
    assignmentType?: EAssignmentType;
    assignmentEmail!: string;
    bankAccountId?: string;
    bankAccount!: IBankAccountCreateOrUpdateModel;
    signerMinimumQuorum?: number;
    creditApproverTenantName!: string;
    creditApproverNameDisplay!: string;
    instrumentApproverTenantName!: string;
    instrumentApproverNameDisplay!: string;
    batchSchedule!: number;
    administratorPersonIdDisplay!: string;
    relatedDocs: IUploadModel[] = [];
    signaturePortalProvider?: ESignaturePortalProvider;
    signatureType?: ESignatureType;
    signatureValidation?: ESignatureValidation;
    maximumValueWithoutDigitalSignature!: number;
    assignmentCalculation!: IAssignmentCalculationModel;
    codeWalletCerc?: string;
    assignmentExternalFileName?: string;
    walletCode?: string;
    billingSettingId?: string;
    communicationSettingId?: string;

    constructor(init?: Partial<FundBaseModel>) {
        Object.assign(this, init);
    }
}
