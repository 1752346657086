/* eslint-disable react-hooks/exhaustive-deps */
import { FieldValues } from 'react-hook-form';
import { FormStep } from './StepsButtons/StepNewChargesButton';
import { Fragment, ReactNode, useEffect } from 'react';
import { ToastType } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { BillingFull } from 'services/walletManagement/billing/billing.types';
import CardValueBase from './FormStep/CardValueBase';
import { NewChargesStep } from './FormStep/NewCharges/NewChargesStep';
import { SummaryChargesStep } from './FormStep/SummaryCharges/SummaryChargesStep';
import { CreateChargesStep } from './FormStep/CreateCharges/CreateChargesStep';
import { BillingParametersStep } from './FormStep/BillingParameters/BillingParametersStep';
import { BillingSummaryStep } from './FormStep/BillingSummary/BillingSummaryStep';
import { useBillingById } from 'contexts/wallet/Billing/billingContext';
import { isValidString } from 'helpers/formats/StringFormats';
import { isEmpty } from 'lodash';
import { DrawerSkeleton } from 'components/Skeleton/DrawerSkeleton';

interface GenerateFormStepComponentsProps {
    currentStep: number;
    handleClose: () => void;
    handlePrevStep: () => void;
    onSaveAndDoNotChargeNow: (values: FieldValues) => void;
    continueCriatting: (values: FieldValues) => void;
    handleNextStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    rowData: BillingFull
}

const GenerateFormStepComponents: React.FC<GenerateFormStepComponentsProps> = ({
    currentStep,
    handleClose,
    handlePrevStep,
    onSaveAndDoNotChargeNow,
    continueCriatting,
    handleNextStep,
    setToast,
    rowData
}) => {
    const { setValue } = useFormContext();
    const { data: billingData, isLoading } = useBillingById(rowData?.id!);

    useEffect(() => {
        if (!!billingData && currentStep === 2) {
            Object.entries(billingData)?.forEach((element, indice) => {
                const key = element[0];
                const value = element[1];
                if (["id", "amount", "payer", "walletCode", "guarantor", "documentNumber", "discount", "discountLimitDate", "dueDate", "billingSettingId", "billingSettingDisplay", "communicationSettingId", "communicationSettingDisplay"].includes(key)) {
                    if (['discount', 'discountLimitDate'].includes(key) && isValidString(String(value))) {
                        setValue('abatment', true);
                    }
                    setValue(key, value);
                }
            });
        }
    }, [billingData]);

    const formStep: { [key: number]: ReactNode } = {
        [FormStep.NewChargesStep]: (
            <NewChargesStep
                currentStep={currentStep}
                onClose={handleClose}
                onPrevStep={handlePrevStep}
                setToast={setToast}
            />
        ),
        [FormStep.SummaryChargesStep]: (
            <SummaryChargesStep
                currentStep={currentStep}
                onSaveAndDoNotChargeNow={onSaveAndDoNotChargeNow}
                continueCriatting={continueCriatting}
                onNextStep={handleNextStep}
                handleClose={handleClose}
            />
        ),
        [FormStep.CreateChargesStep]: (
            <CreateChargesStep
                currentStep={currentStep}
                onPrevStep={handlePrevStep}
                setToast={setToast}
                handleNextStep={handleNextStep}
            />
        ),
        [FormStep.BillingParametersStep]: (
            <BillingParametersStep
                currentStep={currentStep}
                onPrevStep={handlePrevStep}
                setToast={setToast}
            />
        ),

        // //Obs: Não temos api de Régua no momento.
        // [FormStep.BillingRuleStep]: (
        //     <BillingRuleStep currentStep={currentStep} onPrevStep={handlePrevStep} />
        // ),

        [FormStep.BillingSummaryStep]: (
            <BillingSummaryStep
                currentStep={currentStep}
                onPrevStep={handlePrevStep}
                setValue={setValue}
                setToast={setToast}
            />
        ),
    };

    if (currentStep === 2 && isEmpty(billingData)) {
        return <DrawerSkeleton isLoading={isLoading}><></></DrawerSkeleton>
    }

    const children = formStep[currentStep];
    return (
        <Fragment>
            {currentStep >= 2 && <CardValueBase rowData={rowData} />}
            {children}
        </Fragment>
    )
};
export default GenerateFormStepComponents;
