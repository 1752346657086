import { Button, CloseIcon, Drawer, RightIcon } from '@uy3/web-components';
import { AxiosResponse } from 'axios';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import { ApiResponseError, ToastType, showSuccessToast, useApiRequest } from 'contexts/apiRequestContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { postPayGareIcms } from 'services/accounts/bankAccount/bankAccount';
import { Stack } from '@mui/material';
import { GareIcmsForm } from './GareIcmsForm/GareIcmsForm';
import {
    defaultValuesGareIcmsForm,
    validationvGareIcmsFormSchema,
} from './GareIcmsForm/GareIcmsFormSchema';
import { StepsPayment } from 'services/accounts/bankAccount/bankAccount.types';
import { activeTheme } from 'services/theme';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';

type GareIcmsContainerProps = {
    refetch: () => void;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

const theme = activeTheme();
export const GareIcmsContainer = ({ refetch, onCloseDrawer, setToast }: GareIcmsContainerProps) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [stepPayment, setStepPayment] = useState<StepsPayment>();
    const [formFieldValues, setFormFieldValues] = useState<any>();
    const { setSubmitError, startRequest, endRequest } = useApiRequest();

    const validationGareIcms = validationvGareIcmsFormSchema();
    const defaultValuesGareIcms = defaultValuesGareIcmsForm;
    const validationConfirmMfa = validationConfirmMfaFormSchema();
    const defaultValuesConfirmMfa = defaultValuesConfirmMfaForm;

    const onClose = () => {
        setStepPayment(undefined);
        setSubmitError(undefined);
    };

    const onRequestGareIcms = (values: any) => {
        setFormFieldValues(values);
        setStepPayment('confirmationMfa');
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const onSubmitConfirmationMfa = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: async (response: AxiosResponse<string, any>) => {
                const payload = {
                    name: formFieldValues?.name,
                    receitaFederalCodeId: formFieldValues.receitaFederalCodeId,
                    principalValue: formFieldValues?.principalValue,
                    stateRegistration: formFieldValues?.stateRegistration,
                    financialAddition: formFieldValues?.financialAddition,
                    taxPayer: formFieldValues?.taxPayer,
                    valueOfRevenue: formFieldValues?.valueOfRevenue,
                    installmentNumber: formFieldValues?.installmentNumber,
                    diNumber: formFieldValues?.diNumber,
                    paymentValue: formFieldValues?.paymentValue,
                    interestValue: formFieldValues?.interestValue,
                    totalValue: formFieldValues?.totalValue,
                    paymentDate:
                        moment(formFieldValues?.paymentDate).format('YYYY-MM-DD') + 'T00:00:00',
                    dueDate: moment(formFieldValues?.dueDate).format('YYYY-MM-DD') + 'T00:00:00',
                    referenceNumber: moment(formFieldValues?.referenceNumber).format('YYYY-MM'),
                    taxPayerIdentificationType: 0,
                    registrationNumber: formFieldValues?.registrationNumber,
                    sessionId: response.data,
                    code: values?.code,
                };
                postPayGareIcms(bankAccountId!, payload, token!)
                    .then(() => {
                        endRequest(true);
                        onClose();
                        refetch();
                        onCloseDrawer();
                        const title = "Pagamento realizado com sucesso!";
                        const description = 'Ótimo! Agora é só aguardar a confirmação do pagamento.';
                        showSuccessToast(title, description, setToast);
                    })
                    .catch((error) => {
                        setStepPayment('confirmationMfa');
                        setSubmitError(error as ApiResponseError);
                        endRequest(false);
                        const { description, open, title } = errorHandlingBankAccount(error);
                        endRequest(false);
                        setToast({ title , description , open, severity: 'error' });
                    });
            },
        });
    };

    function gareIcmsForm() {
        return (
            <FormProvider
                validationSchema={validationGareIcms}
                defaultValues={defaultValuesGareIcms}
                onSubmit={onRequestGareIcms}
            >
                <GareIcmsForm onClose={onClose} />
            </FormProvider>
        );
    }

    function mfaForm() {
        return (
            <FormProvider
                validationSchema={validationConfirmMfa}
                defaultValues={defaultValuesConfirmMfa}
                onSubmit={onSubmitConfirmationMfa}
            >
                <ConfirmMfaForm
                    onClose={() => setStepPayment('requestGareIcms')}
                    toggleDrawer={true}
                />
            </FormProvider>
        );
    }

    const formIcms: { [key: string]: React.ReactNode } = {
        requestGareIcms: gareIcmsForm(),
        confirmationMfa: mfaForm(),
    };

    const form = formIcms[stepPayment || 'requestGareIcms'];
    const title = stepPayment === 'requestGareIcms' ? 'ICMS' : 'Confirme a operação';
    const openDrawer = stepPayment === 'requestGareIcms' || stepPayment === 'confirmationMfa';

    return (
        <>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onCloseDrawer}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setStepPayment('requestGareIcms')}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>

            <Drawer anchor="right" title={title} open={openDrawer} onClose={onClose} toggleDrawer>
                {form}
            </Drawer>
        </>
    );
};
