import { RefreshProgress } from 'components/RefreshProgress';
import NaturalPersonFieldsForm from './NaturalPersonForm';
import { useNaturalPersonMutation, useNaturalPersonData, useRegulateRegistratioNumberMutate } from 'contexts/naturalPersonContext';
import { useNavigate, useParams } from 'react-router';
import {
    ApiResponseError,
    IBase,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { FormMode, FormProvider } from 'contexts/formContext';
import {
    validationSchemaNaturalPerson,
    defaultValuesNaturalPerson,
    NaturalPersonSchema,
} from './NaturalPersonSchema';
import AssignDrawer from 'components/Assign/AssignDrawer';
import React, { useState } from 'react';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { Drawer, Modal } from '@uy3/web-components';
import { DeleteCheckListContainer } from 'pages/CreditNote/CreditNoteForm/Modals/DeleteCheckList/DeleteCheckListContainer';
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext';
import Toast from 'components/Toast/Toast';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { ReserveTokenContainer } from 'components/ReserveToken/ReserveTokenContainer';
import { useUserPermissionData } from 'contexts/userContext';
import { CloneRegisterContainer } from 'components/CloneRegister/CloneRegisterContainer';
import { FieldValues } from 'react-hook-form';
import { createNaturalPerson } from 'services/accounts/naturalPerson';
import { useIdentity } from 'contexts/identityContext';
import { redirectCloneRegister } from 'helpers/methods/RedirectCloneRegister';
import { filterNonNullObjectValues } from 'helpers/methods/ValidationObject';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import FraudRecordContainer, {
    FraudRecord,
} from 'components/GenericForms/FraudRecord/FraudRecordContainer';
import FraudRecordAlert from 'components/GenericForms/FraudRecord/Alert';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useFraudRecordMutation } from 'contexts/fraudRecordContext';
import { QueryBasicDataContainer } from 'components/QueryBasicData/QueryBasicDataContainer';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

export default function NaturalPersonFormContainer() {
    let { personId } = useParams();
    const { token } = useIdentity();
    const formMode: FormMode = personId !== 'nova' ? 'update' : 'create';
    const {
        naturalPersonData,
        naturalPersonError,
        naturalPersonStatus,
        refetch,
        isFetching,
        isLoading,
    } = useNaturalPersonData(personId!);
    const { submitError, submitting, setSubmitError } = useApiRequest();
    const { setFormFieldsErrors } = useFormFieldsError();
    const { data: complianceChecklist } = useComplianceChecklistVerifyPersonId(personId!);
    const { hasPermission } = useUserPermissionData();
    const typeLevel = formMode === 'create' ? 'Create' : 'Update';
    const hasPermissionCreateOrUpdate: boolean = hasPermission('NaturalPerson', typeLevel);
    const onClose = () => setOpenDrawer(undefined);
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState<{ open: boolean; type: string } | undefined>(
        undefined
    );
    const [toast, setToast] = useState<ToastType>(toastState);

    const onSuccess = (data: NaturalPersonSchema) => {
        if (formMode === 'create') {
            const id = formMode === 'create' ? data : data.id ?? personId;
            navigate(`/pessoas-fisicas/todas/${id}`);
        }
        if (personId !== 'nova') {
            refetch();
        }
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        const title =
            formMode === 'create'
                ? 'Pessoa física criada com sucesso!'
                : 'Pessoa física atualizada com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage, fieldErrors, warningToastError } = mapErrorResponse(error);
        setSubmitError(error);
        setFormFieldsErrors(fieldErrors ?? []);
        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const cloneCurrentNaturalPerson = async (values: FieldValues) => {
        const currentDataNaturalPerson = filterNonNullObjectValues(
            naturalPersonData
        ) as NaturalPersonReadModel;
        await createNaturalPerson(currentDataNaturalPerson, values?.tenant, token!)
            .then((response) => {
                return redirectCloneRegister({
                    redirect: () => navigate(`/pessoas-fisicas/todas/${response?.data!}`),
                    onClose,
                    setToast,
                });
            })
            .catch((error: ApiResponseError) => onError(error));
    };

    const { mutate } = useNaturalPersonMutation(personId!, onSuccess, onError);
    const onSubmit = async (values: NaturalPersonSchema) => {
        mutate(values);
    };

    const schema = validationSchemaNaturalPerson();
    const defaultValues = naturalPersonData ?? defaultValuesNaturalPerson;

    const onSuccessGenric = (type: 'Fraud' | 'RegulateRegistrationNumber') => {
        const optionsMessage: {[type:string]: {title: string, description?: string}} = {
            "Fraud": {
                title: 'Fraude registrada com sucesso!', 
                description:  'Ótimo! Agora você pode visualizar a fraude registrada.'
            }, 
            "RegulateRegistrationNumber": {
                title: "CPF regularizado com sucesso"
            }
        }
        const messageByResource = optionsMessage[type];; 
        const title = messageByResource?.title;
        const description = messageByResource?.description;
        showSuccessToast(title, description, setToast);
        onClose();
        refetch();
    };

    const { mutateAsync: fraudRecordmutateAsync, status } = useFraudRecordMutation(personId!, () => onSuccessGenric('Fraud'), onError);
    const { mutateAsync: regulateRegistratioNumberMutateAsync } = useRegulateRegistratioNumberMutate(personId!, () => onSuccessGenric('RegulateRegistrationNumber'), onError);
    
    const handleOnFraud = (values: FieldValues) => fraudRecordmutateAsync(values);

    const fraudRecordDetails: FraudRecord = {
        name: naturalPersonData?.name,
        registrationNumber: naturalPersonData?.registrationNumber,
        taxPayer: 'PF',
    };

    return (
        <GenericErrorBoundary
            fallback="menu pessoa fisica"
            status={naturalPersonStatus}
            error={naturalPersonError}
        >
            <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
                <React.Fragment>
                    <Toast toast={toast} setToast={setToast} />
                    {!!submitError && !submitting && <ApiErrorAlert error={submitError} />}
                    {status === 'success' && <FraudRecordAlert />}
                    <RefreshProgress refreshing={submitting} />
                    <FormProvider
                        validationSchema={schema}
                        defaultValues={defaultValues}
                        onSubmit={onSubmit}
                        readOnly={!hasPermissionCreateOrUpdate}
                    >
                        <React.Fragment>
                            <NaturalPersonFieldsForm
                                mode={formMode}
                                complianceChecklist={complianceChecklist}
                                isFetching={isFetching}
                                refetch={refetch}
                                personId={personId!}
                                setOpenDrawer={setOpenDrawer}
                                setToast={setToast}
                            />

                            <QueryBasicDataContainer
                                enableModal={!!openDrawer && openDrawer.type === 'getPersonByRegistrationNumber'}
                                onClose={onClose}
                                resource="NaturalPerson"
                            />
                        </React.Fragment>
                    </FormProvider>

                    <Modal
                        open={!!openDrawer && openDrawer.type === 'deletecomplianceChecklist'}
                        onClose={onClose}
                        align="center"
                        title="Remover restrições"
                        description="Escolha quais restrições deseja remover desse registro"
                        children={
                            <DeleteCheckListContainer onClose={onClose} personId={personId!} />
                        }
                    />

                    <AssignDrawer
                        recordId={personId!}
                        recordType="NaturalPerson"
                        openDrawer={!!openDrawer && openDrawer.type === 'assign'}
                        onClose={onClose}
                        navigate="/pessoas-fisicas/todas"
                    />

                    <Drawer
                        anchor="right"
                        open={!!openDrawer && openDrawer.type === 'reserveToken'}
                        title="Adicionar token consignado"
                        onClose={onClose}
                        children={
                            <ReserveTokenContainer
                                {...{ onClose, personId: personId!, setToast }}
                            />
                        }
                    />

                    <Modal
                        open={!!openDrawer && openDrawer.type === 'regulateRegistratioNumber'}
                        title="Regularizar CPF"
                        description={`Tem certeza que deseja regularizar o CPF ${formatDocumentNumber(naturalPersonData?.registrationNumber ?? "")}?`}
                        onClose={onClose}
                        children={
                            <GenericActionHandler
                                onClose={onClose}
                                handleSubmit={regulateRegistratioNumberMutateAsync}
                                isModal
                                titleConfirm='Sim, regularizar' 
                            />
                        }
                    />

                    <Drawer
                        anchor="right"
                        open={!!openDrawer && openDrawer.type === 'fraudRecord'}
                        title="Registrar Fraude"
                        description="Antes de registar uma fraude, informe o motivo."
                        onClose={onClose}
                        children={
                            <FraudRecordContainer
                                {...{
                                    onClose,
                                    onFraudRecordSubmit: handleOnFraud,
                                    fraudRecordDetails,
                                }}
                            />
                        }
                    />
                    <Modal
                        open={!!openDrawer && openDrawer.type === 'clone'}
                        align="left"
                        title="Clonar pessoa física"
                        description="Realize uma cópia do registro atual informando o correspondente. "
                        onClose={onClose}
                        children={
                            <CloneRegisterContainer
                                data={naturalPersonData as IBase}
                                onClose={onClose}
                                resource="naturalAndLegalPerson"
                                onSubmit={cloneCurrentNaturalPerson}
                            />
                        }
                    />
                </React.Fragment>
            </GenericFormSkeleton>
        </GenericErrorBoundary>
    );
}
