import { Stack } from '@mui/material';
import { Button, ChartWaveIcon, CompleteIcon, LeftIcon } from '@uy3/web-components';
import CardsSelect, { IOption } from 'components/CardSelect/CardSelect';
import { useFormContext } from 'contexts/formContext';
import React  from 'react';
import { IProductMarginQuery, ISiapeMarginQueryFull } from 'services/datasets/datasets.interface';
import { activeTheme } from 'services/theme';
import { TooltipWarrantyProductSelected } from './TooltipWarrantyProductSelected';
import { formatCurrencyInCents, isNegativeValue } from 'helpers/formats/Currency';

type SelectWarrantyProps = {
    marginQueryData: ISiapeMarginQueryFull;
    onClose: () => void;
    handleSiapeMarginQuery: () => void;
};

const color = activeTheme().palette;

export const SelectWarranty: React.FC<SelectWarrantyProps> = ({
    marginQueryData,
    onClose,
    handleSiapeMarginQuery
}) => {
    const { submitting, setValue, watch } = useFormContext();
    
    const vinculoFuncionals = marginQueryData?.result?.VinculoFuncionals ?? [];

    const options = vinculoFuncionals?.map((item) => {
        const vlMargemDisp = showInStrongMarginValue(item?.Produtos);
        const isDisabled = item?.Produtos?.some(p => isNegativeValue(p?.VlMargemDisp));

        return {
            label: `${item?.NmOrgao}`,
            description: `Matrícula: ${item.CdMatricula} | Data da consulta: ${new Date(
                marginQueryData.createdAt
            ).toLocaleDateString()} | Valor da margem: ${vlMargemDisp}`,
            value: `${item?.CnpjOrgao}-${item?.CodOrgao}`, // this value will be compared when finded in the container
            disabled: isDisabled,
            tooltipComponent: {
                title: <TooltipWarrantyProductSelected values={item} />,
                placement: 'left',
            },
        };
    }) as IOption[];

    const BtnSiapLabel = !!marginQueryData ? 'Atualizar margem' : 'Obter margem';
    const isConfirmButtonDisabled = options?.length === 1 && options[0].disabled;

    return (
        <Stack spacing={2}>
            <CardsSelect
                onChange={({ value }) => setValue('warrantySelected', value)}
                options={options}
                value={watch('warrantySelected')}
            />

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<LeftIcon htmlColor={color.primary.main} />}
                    onClick={onClose}
                >
                    Voltar
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<ChartWaveIcon htmlColor={color.primary.main} />}
                    onClick={handleSiapeMarginQuery}
                >
                    {BtnSiapLabel}
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting || isConfirmButtonDisabled}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};


const showInStrongMarginValue = (products: IProductMarginQuery[]) => {
    const margemDisp = products?.map(m => {
        const isNegative = isNegativeValue(m?.VlMargemDisp);
        const vlMargemDisp = formatCurrencyInCents(m?.VlMargemDisp);
        const vlMargem = isNegative ? 'Margem indisponível' : vlMargemDisp;

        return vlMargem;
    });
    return margemDisp.join();
}