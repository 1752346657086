import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import { iconSx } from 'contexts/apiRequestContext';
import { CommissionDetailsProps } from '../CommissionRangeForm/CommissionRangeSchema';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { CommissionRangeType } from 'services/creditProduct/types/creditProductReadModel';

type CommissionRangeProps = {
    rowsPerPage: number;
    queryData: CommissionRangeType[];
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    page: number;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
    enableActionCommission: boolean;
};

const theme = activeTheme();
export const CommissionRangeList = ({
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    queryData,
    onEdit,
    onDelete,
    enableActionCommission,
}: CommissionRangeProps) => {
    const { readOnly } = useFormContext();

    const color = readOnly ? theme.palette?.grey['400'] : theme.palette.common.black;

    const columns: GridColDef[] = [
        {
            field: 'minimumPrincipalAmount',
            headerName: 'Valor mínimo principal',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                return formatCurrencyInCents(value);
            },
        },
        {
            field: 'maximumPrincipalAmount',
            headerName: 'Valor máximo principal',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                return formatCurrencyInCents(value);
            },
        },
        {
            field: 'details',
            headerName: 'Custo de emissão',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                const details = row?.details ?? [];

                const calculateTotal = (details: CommissionDetailsProps[], type: string) => {
                    const filteredDetails = details.filter((item) => item?.type === type);
                    const amounts = filteredDetails.map((item) => item?.amount ?? 0);
                    const total = amounts.reduce((acc, curr) => acc + curr, 0);
                    return total;
                };

                const percentageAmount = calculateTotal(details, 'Percentage');
                const currencyAmount = calculateTotal(details, 'Absolute');

                const hasValueCurrencyAmount = formatCurrencyInCents(currencyAmount);
                const hasValuePercentageAmount = formatPercentage(percentageAmount);

                let result;
                if (hasValueCurrencyAmount !== 'R$ 0,00') {
                    if (hasValueCurrencyAmount && hasValuePercentageAmount) {
                        result = `${hasValueCurrencyAmount} + ${hasValuePercentageAmount}`;
                    } else {
                        result = hasValueCurrencyAmount || hasValuePercentageAmount;
                    }
                } else result = hasValuePercentageAmount;

                return result;
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                const findIndex = queryData.findIndex((item) => item?.id === cellValues.id);
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton>
                            <EditIcon onClick={() => onEdit(findIndex)} sx={iconSx} />
                        </IconButton>
                        {enableActionCommission && (
                            <>
                                <IconButton disabled={readOnly}>
                                    <DeleteIcon
                                        onClick={() => onDelete(findIndex)}
                                        sx={{ ...iconSx, color }}
                                    />
                                </IconButton>
                            </>
                        )}
                    </Stack>
                );
            },
        },
    ];
    return (
        <>
            <DataTable
                NoRowsOverlayNew="Nenhuma comissão cadastrada"
                NoResultsOverlayNew="Nenhuma comissão cadastrada"
                columns={columns}
                rows={queryData}
                page={page}
                rowCount={queryData.length ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};
