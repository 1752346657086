import { BankAccountView } from './BankAccountViewForm';
import {
    useBankAccountBalance,
    useTerminateMutation,
} from 'contexts/bankAccount/bankAccountContext';
import { useParams } from 'react-router';
import Offline from 'components/Offline/Offline';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    toastState,
} from 'contexts/apiRequestContext';
import { BankAccountActiveSkeleton } from 'components/Skeleton/BankAccountActiveSkeleton';
import { Popup } from '@uy3/web-components';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';
import TerminateForm from './Popup/TerminateAccount/Terminate';
import { FieldValues } from 'react-hook-form';
import { FormProvider } from 'contexts/formContext';
import { validationSchemaTerminateAccount } from './Popup/TerminateAccount/TerminateSchema';

export function BankAccountViewContainer() {
    const { bankAccountId } = useParams();
    const { status, data, error, refetch, isLoading } = useBankAccountBalance(bankAccountId!);
    const [openPopup, setOpenPopup] = useState(false);
    const [toast, setToast] = useState<ToastType>(toastState);

    const openPopupTerminateAccount = () => setOpenPopup(true);
    const onClosePopup = () => setOpenPopup(false);

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast('Ops, ocorreu um erro', errorMessage, setToast);
    };

    const onSuccess = () => {
        refetch();
        onClosePopup();
    };

    const { mutateAsync, isLoading: isLoadingTerminate } = useTerminateMutation(
        bankAccountId!,
        onSuccess,
        onError
    );

    const handleTerminateAccount = (payload: FieldValues) => mutateAsync(payload);

    if (status === 'error') {
        const { errorMessage } = mapErrorResponse(error as ApiResponseError);
        return <Offline errorMessage={errorMessage} />;
    }

    const statesLoadingProgress = status === 'loading' || isLoading || isLoadingTerminate;

    const validationSchema = validationSchemaTerminateAccount();

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Popup
                open={openPopup}
                title="Encerrar conta"
                text="Tem certeza que deseja encerrar a conta?"
                onClose={onClosePopup}
                children={
                    <FormProvider
                        onSubmit={handleTerminateAccount}
                        validationSchema={validationSchema}
                        defaultValues={{}}
                    >
                        <TerminateForm onClosePopup={onClosePopup} />
                    </FormProvider>
                }
            />
            <BankAccountActiveSkeleton isLoading={statesLoadingProgress}>
                <BankAccountView
                    bankAccountId={bankAccountId}
                    queryData={data!}
                    refresh={refetch}
                    onTerminateAccount={openPopupTerminateAccount}
                />
            </BankAccountActiveSkeleton>
        </>
    );
}
