import { RefreshProgress } from 'components/RefreshProgress';
import React, { useState } from 'react';
import FundList from './FundList';
import { useFundsList } from 'contexts/fundContext';
import { FilterProvider } from 'contexts/filterContext';
import FundListHeader from './FundListHeader';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import DeleteFund from '../DeleteFund/DeleteFund';
import Toast from 'components/Toast/Toast';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useUserPermissionData } from 'contexts/userContext';
import { runSkeleton } from 'helpers/methods/GenericMethods';
import { useFilterValues } from 'contexts/filterValuesContext';
import { FundReadModel } from 'services/fund/types/fundReadModel';

export function FundListContainer() {
  const { hasPermission } = useUserPermissionData();
  const [rowData, setRowData] = useState<FundReadModel | undefined>(undefined);
  const [toast, setToast] = useState<ToastType>(toastState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );
  const { filterValues } = useFilterValues();

  const { data, error, status, isFetching, refetch, isLoading } = useFundsList({
    page,
    size: rowsPerPage,
  });

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onClosePopup = () => setRowData(undefined);
  const isRunSkeleton =  runSkeleton(isLoading, filterValues); 

  return (
    <GenericErrorBoundary status={status} error={error} fallback="Lista de fundo">
      <Toast toast={toast} setToast={setToast} />
      <FundListHeader isFetching={isFetching} refetch={refetch} hasPermission={hasPermission}/>
      {!isLoading && <RefreshProgress refreshing={isFetching} />}

      <FilterProvider
        availableFilters={{
          searchString: { label: "Todos", type: "text" },
          name: { label: "Nome", type: "text" },
          registrationNumber: { label: "CPF/CNPJ", type: "text" },
          email: { label: "Email", type: "text" },
          initialDate: { label: "Criado em", type: "date" },
        }}
      >
        <ListSkeleton isLoading={isRunSkeleton}>
          <FundList
            hasPermission={hasPermission}
            setOpenPopup={setRowData}
            queryData={data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={onChangeRowsPerPage}
            setPage={onChangePage}
            page={page}
          />
        </ListSkeleton>
      </FilterProvider >

      <DeleteFund
        fundId={rowData!?.id}
        //@ts-ignore
        fundName={rowData?.row?.name}
        openPopup={!!rowData}
        onClosePopup={onClosePopup}
        refetch={refetch}
        setToast={setToast}
      />

    </GenericErrorBoundary>
  );
}
