import { DataTable, GroupedButtons, Typography } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useBankAccountLimitsList } from 'contexts/bankAccount/bankAccountContext';
import { useParams } from 'react-router';
import { RefreshProgress } from 'components/RefreshProgress';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { formatDataMyLimitsList } from 'helpers/formats/BankAccount';
import './EffectLimitAndCurrentLimit.css'
import { recordTypeOptions } from 'services/accounts/bankAccount/bankAccount.types';


interface CurrentLimitProps {
    setGroupedButton?: (group: number) => void;
    getGroupedButton?: number;
    setSteps?: React.Dispatch<React.SetStateAction<{ step: number }>>;
    steps?: { step: number };
    rowDataLimits?: any;
    toggleDrawer?: boolean;
    disableStep?: boolean;
    title?: string;
    isCurrentLimit?: boolean;
}

export interface Header {
    [key: number]: string;
}

const CurrentLimit: React.FC<CurrentLimitProps> = ({
    steps,
    getGroupedButton,
    setGroupedButton,
    rowDataLimits,
    toggleDrawer,
    disableStep,
    title,
    isCurrentLimit = true
}) => {
    const { bankAccountId } = useParams();
    const { limitsListData, isFetching } = useBankAccountLimitsList(bankAccountId!);
    const step = steps?.step || 1;

    const header = (): Header => {
        if (isCurrentLimit) {
            return {
                0: 'Limite atual para a mesma titularidade',
                1: 'Limite atual para pessoa física',
                2: 'Limite atual para pessoa jurídica'
            }
        } else {
            return {
                0: 'Limite solicitado para a mesma titularidade',
                1: 'Limite solicitado para pessoa física',
                2: 'Limite solicitado para pessoa jurídica'
            }
        }
    };

    let rowData = formatDataMyLimitsList(limitsListData ?? rowDataLimits) ?? [];
    const selectedRecordType = recordTypeOptions[getGroupedButton!];

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: `Limites`,
            hideSortIcons: true,
            minWidth: 110,
            editable: false,
            renderCell: (cellValues) => cellValues?.row?.type
        },
        {
            field: 'values.businessHours',
            headerName: `Horário comercial (06h às 20h)`,
            hideSortIcons: true,
            minWidth: 180,
            editable: false,
            renderCell: ({ row }) => {
                const value = row?.values?.businessHours?.[selectedRecordType];
                return value === null ? "Padrão da instituição" : formatCurrencyInCents(value)
            }
        },
        {
            field: 'values.nonBusinessHours',
            headerName: `Fora do horário (20h às 06h)`,
            hideSortIcons: true,
            minWidth: 180,
            editable: false,
            renderCell: ({ row }) => {
                const value = row?.values?.nonBusinessHours?.[selectedRecordType];
                return value === null ? "Padrão da instituição" : formatCurrencyInCents(value)
            }
        }
    ];

    return (
        <>
            {step === 1 && !disableStep && (
                <GroupedButtons
                    groupedButtons={['Mesma titularidade', 'Pessoa física', 'Pessoa Jurídica']}
                    onClick={(value) => {
                        setGroupedButton!(value);
                    }}
                    size="large"
                    width={toggleDrawer ? 110 : 140}
                />
            )}
            {!!title && <Typography
                variant="h5"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="19px"
            >
                {title}
            </Typography>}
            {step < 3 && (
                <div className='currentAndEffectiveLimit'>
                    <RefreshProgress refreshing={isFetching} />
                    {!!rowData && rowData.length > 0 && <DataTable
                        headerBarWrapper={{
                            header: header()[getGroupedButton!],
                        }}
                        columns={columns}
                        rows={rowData ?? []}
                        getRowId={() => Math.random()}
                        loading={false}
                        rowsPerPage={5}
                        setRowsPerPage={() => { }}
                        page={0}
                        setPage={() => { }}
                        hideFooter
                        enableJumpAction={false}
                        hideFooterPagination
                        sx={{
                            '& .MuiDataGrid-cell:first-child': {
                                fontWeight: 800,
                                fontSize: '11px',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                overflow: 'hidden'
                            }
                        }}
                    />}
                </div>
            )}
        </>
    );
};

export default CurrentLimit;
