import { CardValue, TotalValueIcon, BankingIcon } from '@uy3/web-components';
import { Box, Grid, Stack } from '@mui/material';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { useShowValues } from 'contexts/showValuesContext';
import { activeTheme } from 'services/theme';
import { TabBar } from 'components/TabBar/TabBar';
import { useAppArea } from 'contexts/appAreaContext';
import InformationCardHeader from 'components/InformationCardHeader/InformationCardHeader';
import { BankAccountViewActions } from './BankAccountViewActions';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { useAppConfig } from 'contexts/appConfig';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';
import { IBalanceType } from 'contexts/bankAccount/bankAccountContext';
import { isRootTenancy } from 'helpers/methods/GenericMethods';
import { useUserPermissionData } from 'contexts/userContext';

type BankAccountViewProps = {
  queryData: IBalanceType;
  refresh: () => any;
  bankAccountId: string | undefined;
  onTerminateAccount: () => void;
};

const theme = activeTheme();
export function BankAccountView({
  queryData,
  refresh,
  bankAccountId,
  onTerminateAccount
}: BankAccountViewProps) {
  const [toast, setToast] = useState<ToastType>(toastState);
  const { menuWidth } = useAppArea();
  const { showValues } = useShowValues();
  const { hasPermission } = useUserPermissionData();
  const { appConfig } = useAppConfig();
  const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);
  const hasPermissionUpdateBankAccount: boolean = hasPermission('BankAccount', 'Update');

  let accountType = queryData?.bankAccount?.type;
  const bankAccount = queryData.bankAccount;

  let tabs = [
    { label: 'Extrato', href: bankAccountId },
    { label: 'Transferências', href: 'transferencias' },
    { label: 'Pix', href: 'pix' },
    { label: 'Operadores', href: 'operadores' },
    { label: 'Favorecidos', href: 'favorecidos' },
    { label: 'Meus limites', href: 'meus-limites' }
  ];

  if (accountType !== 2) {
    tabs.splice(3, 0, { label: 'Pagamentos', href: 'pagamentos' });
  }

  if (accountType !== 1 && rootTenancy && hasPermissionUpdateBankAccount) {
    tabs.push({ label: 'Credores', href: 'credores' });
  }

  if (appConfig.USE_SIMPLIFIED_INTERNET_BANKING === 1) {
    tabs = tabs.slice(0, 1);
  }

  const isLegalPerson = bankAccount?.personRegistrationNumber?.length > 11;

  return (
    <Grid pl={`${menuWidth - 2}`} ml={menuWidth === 98 ? '20px' : -1}>
      <Toast toast={toast} setToast={setToast} />
      <Grid mr={5} mt={-2} mb={-1}>
        <Grid mb={2}>
          <InformationCardHeader
            title={bankAccount.personName}
            Actions={<BankAccountViewActions bankAccount={bankAccount} onRefresh={refresh} onTerminateAccount={onTerminateAccount} />}
            creditors={bankAccount.creditors}
            details={[
              { label: `${isLegalPerson ? "CNPJ" : "CPF"}`, value: formatDocumentNumber(bankAccount.personRegistrationNumber) },
              { label: 'Banco', value: `${bankAccount.bankCode} - ${bankAccount.bankCodeDisplay}` },
              { label: 'Tipo de conta', value: bankAccount.typeDisplay },
              { label: 'Número da conta', value: bankAccount.account },
              { label: 'Agência', value: bankAccount.agency },
            ]}
          />
        </Grid>
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={theme.spacing(2)}>
          <Grid display='block' width='100%'>
            <CardValue
              sx={{ width: '100%' }}
              color="primary"
              description="Esse é o valor total que você possui em conta."
              icon={<TotalValueIcon htmlColor={theme.palette.primary.main} />}
              title="Valor total da conta"
              isCurrency
              value={
                showValues
                  ? formatCurrencyInCents(queryData.currentBalance).toString()
                  : '------------'
              }
            />
          </Grid>
          <Grid display='block' width='100%'>
            <CardValue
              sx={{ width: '100%' }}
              color="primary"
              description="Baseado em transações pendentes, bloqueios ou aprovações."
              icon={<BankingIcon htmlColor={theme.palette.primary.main} />}
              title="Saldo disponível"
              isCurrency
              value={
                showValues ? formatCurrencyInCents(queryData.available).toString() : '------------'
              }
            />
          </Grid>
        </Stack>
      </Grid>
      <Box mt={theme.spacing(5)} mr={theme.spacing(4)}>
        <TabBar tabs={tabs} />
      </Box>
    </Grid>
  );
}
