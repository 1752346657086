import React from 'react';
import { Grid, Stack } from '@mui/material'
import { Button, Checkbox } from '@uy3/web-components';
import { CheckboxFormField, SelectFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useFormContext } from 'contexts/formContext';
import { useAppConfig } from 'contexts/appConfig';

type GenerateAssignmentFormProps = {
   onClose: () => void;
   setAgioDesagio: (isAgio: boolean) => void;
   agioDesagio: boolean;
}

const GenerateAssignmentForm: React.FC<GenerateAssignmentFormProps> = ({ onClose, agioDesagio, setAgioDesagio }) => {
   const { appConfig } = useAppConfig();
   const { watch, setValue } = useFormContext();
   const isRootTenancy = appConfig.TENANT_TYPE === 0;
   const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setAgioDesagio(checked);
      if (checked) {
         setValue('assignmentCalculation.amount', 0);
      } else {
         setValue('assignmentCalculation', undefined);
      }
   };

   return (
      <Stack spacing={2}>
         {isRootTenancy &&
            <Grid mt={2} mb={-2}>
               <Checkbox
                  onChange={handleCheckboxChange}
                  checked={agioDesagio ? true : false}
                  label="Atualizar Ágio/Deságio das operações"
                  color="primary"
               />
            </Grid>}
         <Grid>
            <CheckboxFormField
               name='sendEmail'
               label="Enviar email"
               color="primary"
            />
         </Grid>
         {agioDesagio && (
            <>
               <Grid item xs={6}>
                  <SelectFormField
                     name="assignmentCalculation.mode"
                     label="Modelo de cálculo"
                     fullWidth
                     required
                     options={[
                        { label: 'Padrão', value: 'Default' },
                        { label: 'Valor Inicial ', value: 'InitialValue' },
                        { label: 'Valor Presente', value: 'PresentValuePayment' },
                        { label: 'Valor Presente Parcela', value: 'PresentValueCreditNote' },
                     ]}
                  />
               </Grid>
               <Grid item xs={6}>
                  <SelectFormField
                     name="assignmentCalculation.type"
                     label="Tipo de taxa"
                     fullWidth
                     options={[
                        { label: 'Valor absoluto', value: 'Absolute' },
                        { label: 'Percentual', value: 'Percentage' },
                     ]}
                  />
               </Grid>
               {watch('assignmentCalculation.type') === 'Absolute' ? (
                  <Grid item xs={6}>
                     <CurrencyFormField
                        label="Custo de cessão"
                        name="assignmentCalculation.amount"
                        variant="outlined"
                        fullWidth
                     />
                  </Grid>
               ) : (
                  <Grid item xs={6}>
                     <PercentageFormField
                        label="Custo de cessão (%)"
                        name="assignmentCalculation.amount"
                        variant="outlined"
                        fullWidth
                     />
                  </Grid>
               )}

            </>

         )}

         <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
         >
            <Button
               variant="outlined"
               color="primary"
               onClick={onClose}
               size="medium"
               sx={{ mt: 3, mr: 2 }}
            >
               Não, cancelar
            </Button>
            <Button
               variant="contained"
               color="primary"
               type='submit'
               size="medium"
               sx={{ mt: 3 }}
            >
               Sim, confirmar
            </Button>
         </Grid>
      </Stack>
   )
}

export default GenerateAssignmentForm
