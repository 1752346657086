import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import { IBillingSettingFull } from "services/walletManagement/billingSetting/billingSetting.types";
import { number, object, string } from "yup";
const genericMessage = 'Precisa ser preenchido.';

export const interestModelSchemaValidation = () => object().shape({
    walletCode: string().nullable().notRequired(),
    name: string()
        .required(`Nome da régua de cobrança: ${genericMessage}`)
        .typeError('Nome da régua de cobrança inválido.'),
    interestTypeValue: string()
        .typeError('O Tipo de juros é inválido.')
        .required(`Tipo de juros: ${genericMessage}`)
        .nullable(),
    fineTypeValue: string()
        .typeError('O Tipo de multa é inválido.')
        .required(`Tipo de multa: ${genericMessage}`)
        .nullable(),
    interestAmount: number()
        .typeError('Valor precisa ser um número.')
        .required(`Valor da taxa em juros por mês: ${genericMessage}`)
        .moreThan(-1, 'Informe um valor válido maior ou igual a 0.')
        .transform(handleNumericNaN)
        .nullable(),
    fineAmount: number()
        .typeError('Valor precisa ser um número.')
        .required(`Multa por atraso: ${genericMessage}`)
        .moreThan(-1, 'Informe um valor válido maior ou igual a 0.')
        .transform(handleNumericNaN)
        .nullable(),
    daysToAutoBankSlipCancel: string().nullable(),
    daysToAutoBankSlipRegister: string().nullable(),
    firstInstructionDebtCollection: string().nullable().notRequired(),
    secondInstructionDebtCollection: number().when("firstInstructionDebtCollection", {
        is: "06",
        then: number()
            .required('Nº de dias após vencimento para envio automático precisa ser preenchido.')
            .typeError('Nº de dias após vencimento para envio automático: precisa ser preenchido.')
            .min(3, 'Dias após vencimento: deve ser no mínimo 3 dias')
            .max(30, 'Dias após vencimento: não pode no máximo 30 dias'),
        otherwise: number().nullable()
    }),
    bankSlipMessage: string()
        .typeError('Mensagem no boleto é inválida.')
        .nullable()
        .notRequired()
        .max(20, 'Mensagem no boleto não pode ter mais de 20 caracteres.'),
});

export const defaultValuesAddInterestModelForm = (values: Partial<IBillingSettingFull>) => {
    return {
        walletCode: values?.walletCode ?? null, 
        walletCodeDisplay: values?.walletCode ?? null, 
        name: null,
        interestTypeValue: null,
        fineTypeValue: null,
        interestAmount: null,
        fineAmount: null,
        daysToAutoBankSlipCancel: null,
        daysToAutoBankSlipRegister: null,
        bankSlipMessage: null,
        firstInstructionDebtCollection: null,
        secondInstructionDebtCollection: null
    }
};
