import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, GetListApiResponse, GetListApiResponseSuccess, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { createCommunicationSettingAsync, deleteCommunicationSettingAsync, getCommunicationSettingByIdAsync, getCommunicationSettingListAsync, updateCommunicationSettingAsync } from "services/walletManagement/communicationSetting";
import { isEmpty } from "lodash";
import { ICommunicationSettingFilters, ICommunicationSettingFull, ICreateCommunicationSetting, IUpdateCommunicationSetting } from "services/walletManagement/communicationSetting/communicationSetting.types";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { useFilterValues } from "contexts/filterValuesContext";
import { useState } from "react";
import { useUserPermissionData } from "contexts/userContext";

export function useCommunicationSettingList(filters?: ICommunicationSettingFilters) {
    const { token } = useIdentity();
    const { hasPermission } = useUserPermissionData();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [communicationList, setCommunicationList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType)
        ? 'communicationSetting'
        : filterValues.recordType;
    const listDataFilters = recordType === 'communicationSetting' && { ...filterValues.filters } as any;
    const filtersComplete = { ...listDataFilters, ...filters };

    const queryContext = useQuery({
        enabled: !!token && hasPermission('BillingAccount', 'Read'),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-communication-setting-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<ICommunicationSettingFull>> => {
            startRequest();
            const { data, status, statusText } = await getCommunicationSettingListAsync(filtersComplete, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            const list = data as GetListApiResponseSuccess<ICommunicationSettingFull>;
            setCommunicationList(
                list.data.map((c: ICommunicationSettingFull) => {
                    return {
                        label: `(${c.walletCode}) - ${c.name}`,
                        value: c.id,
                    };
                })
            );

            return list; 
        },
    });

    const communicationSettingAutocomplete = {
        listOptions: communicationList,
        loading: (queryContext.isLoading || queryContext.isFetching),
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'communicationSetting'),
    };


    return {...queryContext, communicationSettingAutocomplete};
}

export function useCommunicationSettingById(communicationSettingId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token && communicationSettingId !== 'nova' && !isEmpty(communicationSettingId),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-communication-setting-by-id', communicationSettingId],
        queryFn: async (): Promise<ICommunicationSettingFull> => {
            startRequest();
            const { data, status, statusText } = await getCommunicationSettingByIdAsync(communicationSettingId, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as ICommunicationSettingFull;
        },
    });

    return queryContext;
}

export function useUpdateCommunicationSettingMudataion(
    communicationSettingId: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: IUpdateCommunicationSetting) => {
            startRequest();
            const payload = {
                daysToSendBillingBeforeDueDate: values?.submitToAuto ? values?.daysToSendBillingBeforeDueDate : 0,
                name: values?.name,
                notifyBillingAfterOverDueDate: values?.notifyBillingAfterOverDueDate,
                notifyBillingAfterSettlement: values.notifyBillingAfterSettlement,
                notifyBillingUpdate: values?.notifyBillingUpdate,
                notifyByEmail: values?.notifyByEmail,
                resendBillingAfterOverDueDate: values?.resendBillingAfterOverDueDate,
            } as IUpdateCommunicationSetting;
            
            const response = await updateCommunicationSettingAsync(communicationSettingId, payload, token!);
            const data = await response.data;
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useCreateCommunicationSettingMudataion(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: ICreateCommunicationSetting) => {
            startRequest();
            const payload = {
                daysToSendBillingBeforeDueDate: values?.daysToSendBillingBeforeDueDate,
                name: values?.name,
                notifyBillingAfterOverDueDate: values?.notifyBillingAfterOverDueDate,
                notifyBillingAfterSettlement: values.notifyBillingAfterSettlement,
                notifyBillingUpdate: values?.notifyBillingUpdate,
                notifyByEmail: values?.notifyByEmail,
                resendBillingAfterOverDueDate: values?.resendBillingAfterOverDueDate,
                walletCode: values?.walletCode
            } as ICreateCommunicationSetting;

            const response = await createCommunicationSettingAsync(payload, token!);
            const data = await response.data;
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useDeleteCommunicationSettingMudataion(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (communicationSettingId: string) => {
            startRequest();
            const response = await deleteCommunicationSettingAsync(communicationSettingId, token!);
            const data = await response.data;
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

