import { FormProvider } from 'contexts/formContext';
import { validationSchemaRequestLimitForm } from './RequestLimitSchema';
import { Header } from './DrawerDetails/CurrentLimit';
import { useState } from 'react';
import { Alert, Drawer } from '@uy3/web-components';
import ButtonDrawer from './ButtonDrawer';
import { postRequestBankAccountLimits } from 'services/accounts/bankAccount/bankAccount';
import { useParams } from 'react-router';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import {
    ApiResponseError,
    IUploadModel,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import './DrawerDetails.style.css';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { AxiosResponse } from 'axios';
import Toast from 'components/Toast/Toast';
import { FieldValues } from 'react-hook-form';
import { UploadFormWrapper } from 'components/Tabs/Uploads/UploadForm/UploadFormWrapper';
import {
    defaultValuesDocForm,
    validationSchemaDocForm,
} from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { useEnumContext } from 'contexts/enumContext';
import RequestLimitFormContainer from './RequestLimitFormContainer';
import { Grid } from '@mui/material';
import { LimitsReadModel } from 'services/accounts/bankAccount/bankAccount.types';

interface DrawerFormContainerProps {
    openDrawer: boolean;
    limitsListData?: LimitsReadModel;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    limitsListRefetch?: () => void;
    accountType?: string;
}

export const limitsOptions: Header = {
    0: 'valueInCentsOwnOwnership',
    1: 'valueInCentsNaturalPerson',
    2: 'valueInCentsLegalPerson',
};

export const hoursLimitsOptions: Header = {
    0: 'businessHours',
    1: 'nonBusinessHours',
};

const DrawerFormContainer: React.FC<DrawerFormContainerProps> = ({
    openDrawer,
    setOpenDrawer,
    limitsListRefetch,
    limitsListData,
    accountType
}) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [steps, setSteps] = useState({ step: 1 });
    const [actionStep, setActionStep] = useState<string | undefined>(undefined);
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const [getGroupedButton, setGroupedButton] = useState(0);
    const [hoursLimit, setHoursLimit] = useState(0);
    const { data: typeOfDocument } = useEnumContext({ size: 100, enumName: 'FileType' });
    const [toast, setToast] = useState<ToastType>(toastState);

    const { endRequest, startRequest } = useApiRequest();
    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

    const limitOption = limitsOptions[getGroupedButton];
    const hoursLimitOption = hoursLimitsOptions[hoursLimit];

    const saveFormValues = (data: FieldValues) => {
        setFormValues(data);
        setActionStep('sendUploadFiles');
    };

    const resetOptionsFilter = () => {
        setHoursLimit(0);
        setGroupedButton(0);
    };

    const onUploadFile = (data: FieldValues) => {
        const uploadList: IUploadModel[] = [];
        delete data?.id;
        uploadList.push(data as IUploadModel);
        setFormValues({ ...formValues, uploads: uploadList });
        setActionStep('confirmMfa');
    };

    const handleSubmit = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: async function ({ data: sessionId }: AxiosResponse<string>) {
                const payload = {
                    ...formValues,
                    code: values.code,
                    sessionId,
                };
                await postRequestBankAccountLimits(bankAccountId!, payload, token!)
                    .then(() => {
                        const title = 'Limite solicitado com sucesso!';
                        const description =
                            'Ótimo! Sua solicitação foi recebida, em até 48 horas seu limite pode ser liberado.';
                        showSuccessToast(title, description, setToast);

                        setOpenDrawer(false);
                        setActionStep(undefined);
                        limitsListRefetch!();
                        endRequest(true);
                    })
                    .catch((error: ApiResponseError) => {
                        const { errorMessage } = mapErrorResponse(error);
                        const title = 'Ops, ocorreu um erro!';
                        const description = errorMessage;
                        showErrorToast(title, description, setToast);
                        endRequest(false);
                    });
            },
        });
    };

    const validationSchema = validationSchemaRequestLimitForm();
    
    const currentWhenDefaultValues = {
        pixLimit: limitsListData?.pixLimit,
        tedLimit: limitsListData?.tedLimit,
        transferLimit: limitsListData?.transferLimit,
        bankSlipLimit: limitsListData?.bankSlipLimit
    }

    const onCloseDrawer = () => {
        setOpenDrawer(true);
        setActionStep(undefined);
    };
    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <div className="spacing-drawer">
                <Drawer
                    anchor="right"
                    title={
                        steps.step === 1
                            ? 'Solicitar aumento de limite'
                            : steps.step === 2
                                ? 'Aumento de limite para mesma titularidade'
                                : 'Enviar declaração de faturamento'
                    }
                    description={
                        steps.step === 1
                            ? 'Horário comercial: 06h às 20h / Fora do horário: 20h às 06h.'
                            : steps.step === 2
                                ? 'Confirme as informações do novo limite solicitado para prosseguir'
                                : 'Documentações que justifique o aumento de limite'
                    }
                    open={openDrawer}
                    onClose={() => {
                        setOpenDrawer(false);
                        resetOptionsFilter();
                    }}
                >
                    <FormProvider
                        validationSchema={validationSchema}
                        defaultValues={currentWhenDefaultValues}
                        onSubmit={saveFormValues}
                    >
                        <>
                            <RequestLimitFormContainer
                                limitOption={limitOption}
                                currentLimit={limitsListData}
                                hoursLimitOption={hoursLimitOption}
                                setHoursLimit={setHoursLimit}
                                steps={steps}
                                isEscrow={accountType === 'Conta Escrow'}
                            />
                            <ButtonDrawer
                                resetOptionsFilter={resetOptionsFilter}
                                setOpenDrawer={setOpenDrawer}
                                steps={steps}
                                setSteps={setSteps}
                            />
                        </>
                    </FormProvider>
                </Drawer>
            </div>

            <Drawer
                anchor="right"
                title="Enviar declaração de faturamento"
                description="Documentações que justifique o aumento de limite"
                open={actionStep === 'sendUploadFiles'}
                toggleDrawer
                onClose={onCloseDrawer}
            >
                <Grid item mb={2}>
                    <Alert
                        severity="info"
                        text="Pode ser um documento de faturamento, imposto de renda, declaração do contador, ou outro documento comprobatório."
                        variant="filled"
                    />
                </Grid>
                <FormProvider
                    validationSchema={validationSchemaDocForm()}
                    defaultValues={defaultValuesDocForm}
                    onSubmit={onUploadFile}
                >
                    <UploadFormWrapper
                        options={typeOfDocument ?? []}
                        accept="image/*,.pdf,.doc,.docx,.xlsx,.xsl,.rar,.zip"
                        onClose={() => {
                            setOpenDrawer(true);
                            setActionStep(undefined);
                        }}
                    />
                </FormProvider>
            </Drawer>
            <Drawer
                anchor="right"
                title={'Confirmar solicitação de limite'}
                description='Digite sua senha e o código de autenticação'
                open={actionStep === 'confirmMfa'}
                toggleDrawer
                onClose={onCloseDrawer}
            >
                <FormProvider
                    validationSchema={validationConfirmMfaFormSchema()}
                    defaultValues={defaultValuesConfirmMfaForm}
                    onSubmit={handleSubmit}
                >
                    <ConfirmMfaForm
                        onClose={() => {
                            setOpenDrawer(true);
                            setActionStep(undefined);
                        }}
                        toggleDrawer={true}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};

export default DrawerFormContainer;
