import { Drawer } from '@uy3/web-components';
import { BankAccountItemDetails } from 'pages/BankAccount/BankAccountActive/BankAccountDrawerItemsDetails/BankAccountItemDetails';
import {
    useBankAccountCancelPaymentMutation,
    useBankAccountPaymentApproveMutation,
} from 'contexts/bankAccount/bankAccountContext';
import { useState } from 'react';
import { useParams } from 'react-router';
import { PaySlipFormContainer } from './PaySlipForm/PaySlipFormContainer';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { statusBankAccountOptions } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { ConsumerAccountsFormContainer } from './ConsumerAccounts/ConsumerAccountsContainer';
import { useGenerateSessionIdMutation } from 'contexts/identityContext';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import {
    validationConfirmMfaFormSchema,
    defaultValuesConfirmMfaForm,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import { FormProvider } from 'contexts/formContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { AxiosResponse } from 'axios';
import { ActionApproveOrRejectType, CancelPaymentType } from 'services/accounts/bankAccount/bankAccount.types';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import PaymentHeader from './PaymentHeader';
import PaymentListContainer from './PaymentList/PaymentListContainer';
import { useQueryClient } from '@tanstack/react-query';
import TaxSlipForm from './TaxSlip/TaxSlip';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

type PaymentOptionProps = 'bankSlip' | 'taxSlip' | 'consumerAccounts' | undefined;

export const PaymentFormContainer = () => {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [paymentOption, setpaymentOption] = useState<PaymentOptionProps>(undefined);
    const [paymentOptionSelected, setPaymentOptionSelected] = useState('');
    const [stepAction, setStepAction] = useState<string | undefined>(undefined);
    const [paymentId, setPaymentId] = useState<string | undefined>(undefined);
    const { setSubmitError } = useApiRequest();
    const [transferInfo, setTransferInfo] = useState<any>(undefined);
    const queryClient = useQueryClient();

    const onClose = () => {
        setStepAction(undefined);
        setSubmitError(undefined);
        setpaymentOption(undefined);
        setPaymentOptionSelected('');
    };

    const onSuccess = (type: string) => {
        let typeMessage: { [type: string]: string } = {
            cancel: 'cancelado',
            approve: 'aprovado',
            reject: 'reprovado',
        };

        const title = `Pagamento ${typeMessage[type]} com sucesso!`;
        const description = undefined;
        showSuccessToast(title, description, setToast);
        onClose();
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const { mutateApprovePayment } = useBankAccountPaymentApproveMutation(
        () => onSuccess(stepAction!),
        onError
    );
    const { mutateCancelPayment } = useBankAccountCancelPaymentMutation(
        bankAccountId!,
        () => onSuccess('cancel'),
        onError
    );

    const onApprovePayment = (values: IMFAState) => {
        const { code, password } = values;
        mutateGenerateSessionId({
            userPassword: password,
            then: (response: AxiosResponse<string, any>) => {
                const data = {
                    payload: {
                        action: 'Approve',
                        code,
                        sessionId: response?.data,
                        payBankAccounts: [{ BankAccountId: bankAccountId, PaymentId: paymentId }]
                    },
                    resource: 'Pay'
                } as ActionApproveOrRejectType;
                return mutateApprovePayment({
                    ...data,
                    bankAccountId: bankAccountId!,
                    resourceId: paymentId!
                });
            },
        });
    };

    const onCancelPayment = (values: IMFAState) => {
        const { code, password } = values;
        mutateGenerateSessionId({
            userPassword: password,
            then: (response: AxiosResponse<string, any>) => {
                let payload = {
                    code: code,
                    paymentId: paymentId,
                    sessionId: response.data,
                } as CancelPaymentType;
                return mutateCancelPayment(payload);
            },
        });
    };

    const onRejectPayment = (values: IMFAState) => {
        const { code, password } = values;
        mutateGenerateSessionId({
            userPassword: password,
            then: (response: AxiosResponse<string, any>) => {
                const data = {
                    payload: {
                        action: 'Reject',
                        code,
                        sessionId: response?.data,
                        payBankAccounts: [{ BankAccountId: bankAccountId, PaymentId: paymentId }]
                    },
                    resource: 'Pay'
                } as ActionApproveOrRejectType;
                return mutateApprovePayment({
                    ...data,
                    bankAccountId: bankAccountId!,
                    resourceId: paymentId!
                });
            },
        });
    };

    async function paymentRefetch() {
        await queryClient.invalidateQueries(['bank-account-payment-list', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-payment-list', bankAccountId]);
    }

    function paySlipForm() {
        return (
            <PaySlipFormContainer
                refetch={paymentRefetch}
                onCloseDrawer={() => setpaymentOption(undefined)}
                setToast={setToast}
            />
        );
    }

    function taxSlipForm() {
        return (
            <TaxSlipForm
                {...{
                    bankAccountId,
                    onClose,
                    paymentOptionSelected,
                    setPaymentOptionSelected,
                    setToast
                }}
            />
        );
    }

    function consumerAccountsForm() {
        return <ConsumerAccountsFormContainer refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />;
    }

    const formSlip: { [key: string]: JSX.Element } = {
        bankSlip: paySlipForm(),
        taxSlip: taxSlipForm(),
        consumerAccounts: consumerAccountsForm(),
    };

    const titleDrawer: { [key: string]: string } = {
        bankSlip: 'Realizar pagamento',
        taxSlip: 'Realizar pagamento | Tributos',
        consumerAccounts: 'Realizar pagamento | Contas de consumo',
    };
    const title = titleDrawer[paymentOption!];

    const openDrawer: { [key: string]: boolean } = {
        bankSlip: true,
        taxSlip: true,
        consumerAccounts: true,
    };
    const openPaymentOption = openDrawer[paymentOption!];

    const titleDrawerActions: { [key: string]: string } = {
        approve: 'Aprovar pagamento',
        cancel: 'Cancelar pagamento',
        reject: 'Reprovar pagamento',
    };

    const titleActions = titleDrawerActions[stepAction!];

    const openDrawerActions: { [key: string]: boolean } = {
        approve: true,
        cancel: true,
        reject: true,
    };

    const openPaymentOptionActions = openDrawerActions[stepAction!];

    const onSubmitActions =
        stepAction === 'approve'
            ? onApprovePayment
            : stepAction === 'cancel'
                ? onCancelPayment
                : onRejectPayment;

    return (
        <ErrorBoundary fallback='pagamentos'>
            <Toast toast={toast} setToast={setToast} />
            <Drawer
                anchor="right"
                title="Detalhes da aprovação"
                open={transferInfo !== undefined}
                onClose={() => setTransferInfo(undefined)}
            >
                <BankAccountItemDetails
                    informationData={transferInfo}
                    setOpenDrawer={() => setTransferInfo(undefined)}
                    title="Informações da transferência"
                />
            </Drawer>

            <Drawer anchor="right" title={title} open={openPaymentOption} onClose={onClose}>
                {formSlip[paymentOption!]}
            </Drawer>

            <Drawer
                anchor="right"
                title={titleActions}
                open={openPaymentOptionActions}
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={validationConfirmMfaFormSchema()}
                    defaultValues={defaultValuesConfirmMfaForm}
                    onSubmit={onSubmitActions}
                >
                    <ConfirmMfaForm onClose={onClose} />
                </FormProvider>
            </Drawer>

            <PaymentHeader
                paymentOption={paymentOption}
                setpaymentOption={setpaymentOption}
                title="Efetuar um pagamento"
                description="Você pode pagar os seguintes tipos de conta."
            />
            <PaymentListContainer
                {...{
                    bankAccountId,
                    setPaymentId,
                    setStepAction,
                    setTransferInfo,
                    statusBankAccountOptions,
                }}
            />
        </ErrorBoundary>
    );
};
