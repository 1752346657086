import { Grid, Box, Stack, styled } from "@mui/material"
import { Button, Typography, Alert, ShowIcon, ReportIcon, SimulationIcon, GraphicWithBarIcon } from "@uy3/web-components";
import { useFormContext } from "contexts/formContext";
import { useUserPermissionData } from "contexts/userContext";
import { useCreditNoteFormContext } from "pages/Product/CreditProduct/CreditProductForm/CreditProductForm";
import { Link } from "react-router-dom";
import { checkActionsPerms } from "services/permissions";

export const RemovePrintMode = styled('div')(() => ({
    '@media print': {
        display: 'none'
    }
}));

type SimulationFormProps = {
    simulationId: string | undefined;
    handleAction: (action: string) => void;
}

const SimulationForm = ({ simulationId, handleAction }: SimulationFormProps) => {
    const { submitting, validationErrors, watch } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const instrumentType = product?.instrumentType?.toLowerCase();
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();
    const { data: permissionsData } = useUserPermissionData();

    const hasActionPermission = (action: string): boolean => {
        if (checkActionsPerms(action, permissionsData, 'CreditNote')) {
            return true;
        }
        return false;
    };

    const enableSiapeMarginQuery = () => {
        if (
            instrumentType === 'publicpayrollloan' &&
            warrantyRegistrationOffice === 'siape' &&
            hasActionPermission('ReadDataSet')
        ) {
            return true;
        }
        return false;
    };

    const paramsId = () => {
        const warranty = watch('warranty') ?? [];
        if (warranty?.length > 0) {
            return `/ccb/operacoes/nova?simulacao=${simulationId}?warranty=${JSON.stringify(warranty)}`
        }
        return `/ccb/operacoes/nova?simulacao=${simulationId}`

    }

    return (
        <RemovePrintMode>
            {validationErrors && <Box mt={2} mb={2}>
                <Alert
                    severity="error"
                    text={"Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente."}
                    variant="filled"
                />
            </Box>}
            <Box>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        Simulação
                    </Typography>
                    <Grid>
                        <Grid direction='row' alignItems='center'>
                            <Button
                                variant="text"
                                disabled={submitting}
                                size="medium"
                                type="submit"
                                sx={{ mr: 2, color: 'black' }}
                                startIcon={<SimulationIcon htmlColor='black' />}
                                color="primary">
                                Simular
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => handleAction('printSettting')}
                                size="medium"
                                sx={{ mr: 2, color: 'black' }}
                                startIcon={<ReportIcon htmlColor='black' />}
                                color="primary">
                                Imprimir
                            </Button>
                            {simulationId !== undefined &&
                                <Link to={paramsId()}>
                                    <Button
                                        variant="text"
                                        sx={{ color: 'black' }}
                                        startIcon={<ShowIcon htmlColor="black" />}
                                    >
                                        Abrir operação
                                    </Button>
                                </Link>
                            }
                            {enableSiapeMarginQuery() &&
                                <Button
                                    variant="text"
                                    sx={{ color: 'black' }}
                                    onClick={() => handleAction('siapeQueryMargin')}
                                    startIcon={<GraphicWithBarIcon htmlColor={"#000000"} />}
                                >
                                    Consultar margem
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </RemovePrintMode>
    );
};

export default SimulationForm;