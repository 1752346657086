import { creditAxiosApi } from "services/axiosApi/axiosApi";

type DeleteComplianceChecklistType = {
  ids: string[];
  token: string;
}

export type ComplianceChecklistProps = {
  email: string; 
  phone: string;
  documentNumber: string;
  address: { 
      zipCode: string;
  }
}

export type AttributeTypeChecklist = {
  id: string;
  message: string;
  attributeType: string
}

const url = "/complianceChecklist";

export const getCheckListVerifyPersonBy = async (personId: string, token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await creditAxiosApi.get(`${url}/verifyPerson/${personId}`, config);
}

export const deleteComplianceChecklistByIds = async ({ ids, token }: DeleteComplianceChecklistType) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ids }
  };
  return await creditAxiosApi.delete(`${url}`, config);
}