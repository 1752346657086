import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

export const SignatureWorkFlowActions = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Iniciar processo de assinatura', name: 'actions.StartSignatureWorkFlow' }}
                helpMessage="Permite iniciar processo de assinatura."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Reenviar processo de assinatura', name: 'actions.ResendNotificationSignatureWorkFlow' }}
                helpMessage="Permite reenviar processo de assinatura."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Cancelar processo de assinatura', name: 'actions.CancelSignatureWorkFlow' }}
                helpMessage="Permite cancelar processo de assinatura."
            />
        </React.Fragment>
    )
}
