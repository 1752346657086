import { useQuery, useMutation } from "@tanstack/react-query";
import { ApiResponseError, GetListApiResponse, GetListApiResponseSuccess, useApiRequest } from "./apiRequestContext";
import { useIdentity } from "./identityContext";
import { ICreditStatusRequestListProps, getCreditStatusRequestList, postCreditApproval, postCreditStatusRequest } from "services/creditStatusRequest";
import { AxiosError, isAxiosError } from "axios";
import { AproverCreditStatusRequestPost, CreditStatusRequestGet, CreditStatusRequestPost } from "services/creditStatusRequest/creditStatusRequest.types";

export function useCreditStatusRequestList(filters: ICreditStatusRequestListProps) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && !!filters.personId && filters.personId !== "novo",
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["creditStatusRequest-list", filters],
        queryFn: async (): Promise<GetListApiResponse<CreditStatusRequestGet>> => {
            startRequest();
            const { data, status, statusText } = await getCreditStatusRequestList(filters, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetListApiResponseSuccess<CreditStatusRequestGet>
        }

    });

    return { status, data: data as GetListApiResponseSuccess<CreditStatusRequestGet>, error, isFetching, refetch };

};

export function useCreditStatusRequestMutation(onSuccess?: (data: CreditStatusRequestPost) => void, onError?: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: CreditStatusRequestPost) => {
            startRequest();
            const { data, status, statusText } = await postCreditStatusRequest(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            endRequest(true);
            return data as CreditStatusRequestGet;
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutateAsync, isLoading, error };
};



export function useCreditApprovalMutation(onSuccess?: (data: any) => void, onError?: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: AproverCreditStatusRequestPost) => {
            startRequest();
            const { data, status, statusText } = await postCreditApproval(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            endRequest(true);
            return data as CreditStatusRequestGet;
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutateAsync, isLoading, error };
};