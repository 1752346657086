import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import LegalPersonList from './LegalPersonList';
import { useLegalPersonListData } from 'contexts/legalPersonContext';
import { Button, Popup } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { deleteLegalPersonById } from 'services/accounts/legalPerson';
import { useIdentity } from 'contexts/identityContext';
import { FilterProvider } from 'contexts/filterContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import LegalPersonHeader from './LegalPersonHeader';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { runSkeleton } from 'helpers/methods/GenericMethods';

type LegalPersonListContainerProps = {
  isPendingPreApprove?: boolean;
};

export function LegalPersonListContainer({ isPendingPreApprove }: LegalPersonListContainerProps) {
  const [toast, setToast] = useState<ToastType>(toastState);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );
  const [page, setPage] = useState(0);
  const { token } = useIdentity();
  const { endRequest, startRequest, submitting } = useApiRequest();
  const [rowData, setRowData] = useState<any | undefined>(undefined);
  const { filterValues } = useFilterValues();

  const { status, data, error, isFetching, refetch, isLoading } = useLegalPersonListData({
    page,
    size: rowsPerPage,
    pendingPreApprove: isPendingPreApprove,
  });

  if (status === 'error') return <Error error={error} />;

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onClosePopup = () => setRowData(undefined);
  const onDelete = async (personId: string) => {
    startRequest();
    await deleteLegalPersonById(personId, token!)
      .then(() => {
        endRequest(true);
        const title = `Pessoa jurídica excluída com sucesso!`;
        const desc = 'Ótimo! A lista de pessoas jurídicas foi atualizada.';
        showSuccessToast(title, desc, setToast);
        refetch();
        onClosePopup();
      })
      .catch((response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const title = `Erro ao excluir excluir pessoa jurídica!`;
        const desc = errorMessage;
        showErrorToast(title, desc, setToast);
        endRequest(false);
        onClosePopup();
      });
    await refetch();
  };

  const isRunSkeleton = runSkeleton(isLoading, filterValues);

  return (
    <>
      <Toast toast={toast} setToast={setToast} />
      <LegalPersonHeader {...{ isPendingPreApprove, isFetching, refetch }} />
      {!isLoading && <RefreshProgress refreshing={isFetching} />}

      <FilterProvider
        availableFilters={{
          registrationNumber: { label: 'CNPJ', type: 'text' },
          initialDate: {
            label: 'Data de início',
            type: 'dateRange',
            multiple: false,
            dateRangeName: 'finalDate',
          },
          email: { label: 'Email', type: 'text' },
          name: { label: 'Nome fantasia', type: 'text' },
          companyName: { label: 'Razão social', type: 'text' },
          phone: { label: 'Telefone', type: 'text' },
          searchString: { label: 'Todos', type: 'text' },
        }}
      >
        <ListSkeleton isLoading={isRunSkeleton}>
          <LegalPersonList
            queryData={data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={onChangeRowsPerPage}
            setPage={onChangePage}
            page={page}
            setRowData={setRowData}
          />
        </ListSkeleton>
      </FilterProvider>

      <Popup
        open={rowData !== undefined}
        title={rowData ? `Excluir #${rowData?.row?.name}?` : 'Excluir'}
        text={`Tem certeza que deseja excluir este cadastro?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={submitting}
              onClick={() => onDelete(rowData.id)}
              size="medium"
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </>
  );
}
