export function toDataModel<T>(data: Record<string, unknown>, type: new () => T): T {
    const formattedData: Record<string, unknown> = {};
    const instance = new type() as Record<string, unknown>;

    const keys = Object.keys(instance);

    // Filtrar os dados do formulário com base nas chaves da instância da tipagem
    for (const key of keys) {
        if (key in data) {
            formattedData[key] = data[key];
        }
    }

    return formattedData as T;
}

type ClassConstructor<T> = new (...args: any[]) => T;

interface MappingConfig<T> {
    [key: string]: ClassConstructor<T>;
}

export function mapDataToModelInstance<T, M>(
    dataModel: T | T[],
    mappingKey: keyof MappingConfig<M>,
    modelMapping: MappingConfig<M>
): M | M[] {
    const ModelConstructor = modelMapping[mappingKey];

    // Verifica se dataModel é um array
    if (Array.isArray(dataModel)) {
        return (dataModel as T[]).map((item) =>
            toDataModel(item as Record<keyof T, unknown>, ModelConstructor)
        ) as M[];
    }

    return toDataModel(dataModel as Record<keyof T, unknown>, ModelConstructor) as M;
}
