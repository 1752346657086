import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesUploadFileInstruction, validationUploadFileInstructionSchema } from './UploadFileInstructionSchema'
import { UploadFileInstruction } from './UploadFileInstruction'
import { useFileInstructionAsyncMutation, useFileInstructionValidateAsyncMutation } from 'contexts/wallet/files/fileInstruction/fileInstructionContext'
import { FileInstructionParams } from 'services/walletManagement/files/fileInstruction/fileInstruction'
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'

type UploadFileInstructionContainerProps = {
  onClose: () => void;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const UploadFileInstructionContainer = ({ onClose, setToast }: UploadFileInstructionContainerProps) => {

  const onSuccess = (type: string) => {
    const optionsMessage: { [type: string]: string } = {
      "validateFileInstruction": "Arquivo validado com sucesso",
      "fileInstruction": "Arquivo CNAB importado com sucesso"
    }
    showSuccessToast(optionsMessage[type], "", setToast);
    if (['fileInstruction'].includes(type)) {
      onClose();
    }
  }

  const handleError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error);
    showErrorToast("Ops, ocorreu um erro", errorMessage, setToast);
  }

  const { mutateAsync: fileInstructionMutate } = useFileInstructionAsyncMutation(() => onSuccess('fileInstruction'), handleError);
  const { mutateAsync: validateFileInstruction } = useFileInstructionValidateAsyncMutation(() => onSuccess('validateFileInstruction'), handleError);

  const onSubmit = async (values: FileInstructionParams) => {
    await fileInstructionMutate(values);
  }

  const handleValidateFile = async (values: FileInstructionParams) => {
    await validateFileInstruction(values)
  }

  return (
    <React.Fragment>
      <FormProvider
        defaultValues={defaultValuesUploadFileInstruction}
        validationSchema={validationUploadFileInstructionSchema()}
        onSubmit={onSubmit}
      >
        <UploadFileInstruction {...{ onClose, handleValidateFile }} />
      </FormProvider>
    </React.Fragment>
  )
}
