import { useMutation, useQuery } from '@tanstack/react-query';
import { useIdentity } from './identityContext';
import { ApiResponseError, IRefetchOnMountModeGetQuery, useApiRequest } from './apiRequestContext';
import { AxiosError, isAxiosError } from 'axios';
import { editOrCreateFund, getFundById, getFundsList } from 'services/fund';
import { FundFormSchema } from 'pages/Fund/FundForm/FundSchema';
import { useTenant } from './tenantContext';
import { useState } from 'react';
import { useAppConfig } from './appConfig';
import { useFilterValues } from './filterValuesContext';
import { useUserPermissionData } from './userContext';
import { isEmpty } from 'lodash';
import { sendRemessaAsync } from 'services/remessasVortx/remessasVortx';
import { ResponseErrorSendMessageType } from 'services/remessasVortx/remessasVortx.type';
import { FundFiltersProps, FundReadModel } from 'services/fund/types/fundReadModel';

export function useFundsList(filters: FundFiltersProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const isAlways: boolean = refetchOnMount === 'always';
    const { appConfig } = useAppConfig();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const [fundList, setFundList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'Fund' : filterValues.recordType;
    const listDataFilters = recordType === 'Fund' && { ...filterValues.filters };
    const filtersParams = isAlways ? { ...filters, size: 10 } : filters; // it's a specific case
    const filtersComplete = { ...listDataFilters, ...filtersParams, tenant: currentTenantId };
    const isRootTenancy = appConfig.TENANT_TYPE === 0;
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('Fund', 'Read');

    const queryContextFund = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['fund-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getFundsList(filtersComplete, token);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data;
            const options = dataSuccess.data.map((item) => ({
                label: isRootTenancy
                    ? `${item.name || 'Não informado'} (${item.tenantDisplay})`
                    : item.name,
                value: item.id,
            }));
            setFundList(options);

            return dataSuccess;
        },
    });

    const fundAutoCompleteProps = {
        listOptions: fundList,
        loading: !hasReadPermission ? false : queryContextFund.isLoading || queryContextFund.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ searchString }, 'Fund')
    };

    return {
        ...queryContextFund,
        fundAutoCompleteProps,
    };
};

export function useFundMutation(
    fundId: string,
    onSuccess?: (data: FundReadModel) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: FundFormSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateFund(
                values,
                fundId,
                token,
                currentTenantId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
};

export function useFundData(fundId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('Fund', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && fundId !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['fund', fundId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getFundById(fundId, token);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return { fundStatus: status, fundData: data, fundErr: error, fundFetch: isFetching, refetch, isLoading };
};


export function useSendRemessaMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ResponseErrorSendMessageType) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    return useMutation({
        mutationFn: async (filePath: string) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await sendRemessaAsync(filePath, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess: onSuccess,
        onError: (error: ResponseErrorSendMessageType) => {
            endRequest(false);
            onError && onError(error);
        },
    });
}