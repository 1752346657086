import { string, addMethod, StringSchema } from "yup";

declare module 'yup' {
    interface StringSchema {
        cpfCnpjValidation(msg?: string): this;
        barCodeValidation(msg?: string): this;
        phoneNumberValidation(msg?: string): this;
        zipCodeValidation(msg: string): this;
    }
}

addMethod<StringSchema>(
    string,
    'phoneNumberValidation',
    function (msg: string = 'This field must be a number string.') {
        //@ts-ignore
        return this.test({
            name: 'phoneNumberValidation',
            message: msg,
            test: (value) => {
                if (!value) return true;
                const phone = value?.replace(/\D/g, '');
                return phone.length === 11;
            }
        });
    }
);

addMethod<StringSchema>(
    string,
    'zipCodeValidation',
    function (msg: string = 'This field must be a number string.') {
        //@ts-ignore
        return this.test({
            name: 'zipCodeValidation',
            message: msg,
            test: (value) => {
                if (!value) return true;
                const zipCode = value?.replace(/\D/g, '');
                return zipCode?.length === 8;
            }
        });
    }
);

addMethod<StringSchema>(
    string,
    'cpfCnpjValidation',
    function (msg: string = 'This field must be a number string.') {
        //@ts-ignore
        return this.test({
            name: 'cpfCnpjValidation',
            message: msg,
            test: (value) => {
                if (!value)
                    return true;
                if (onlyNumbers(value).length === 14) {
                    return value ? isValidCNPJ(value) : msg;
                } else {
                    return value ? isValidCPF(value) : msg;
                }
            }
        });
    }
);

addMethod<StringSchema>(
    string,
    'barCodeValidation',
    function (msg: string = 'This field must be a number string.') {
        //@ts-ignore
        return this.test({
            name: 'barCodeValidation',
            message: msg,
            test: (value) => {
                if (!value)
                    return true;
                if (onlyNumbers(value).length >= 44) {
                    return value ? isValidBarCode(value) : msg;
                }
                else {
                    return value ? isValidCPF(value) : msg;
                }
            }
        });
    }
);

export const handleNumericNaN = (value: number) => (isNaN(value) ? 0 : value);

export const isValidBarCode = (barCode: string): boolean => {

    if (barCode === "") return false;

    // Elimina barCodes invalidos conhecidos
    if (
        barCode === "00000000000000000000000000000000000000000000" ||
        barCode === "11111111111111111111111111111111111111111111" ||
        barCode === "22222222222222222222222222222222222222222222" ||
        barCode === "33333333333333333333333333333333333333333333" ||
        barCode === "44444444444444444444444444444444444444444444" ||
        barCode === "55555555555555555555555555555555555555555555" ||
        barCode === "66666666666666666666666666666666666666666666" ||
        barCode === "77777777777777777777777777777777777777777777" ||
        barCode === "88888888888888888888888888888888888888888888" ||
        barCode === "99999999999999999999999999999999999999999999"

    ) return false;

    return true;
};

export const isValidDigitalLine = (barCode: string): boolean => {

    if (barCode === "") return false;

    // Elimina barCodes invalidos conhecidos
    if (
        barCode === "000000000000000000000000000000000000000000000000" ||
        barCode === "111111111111111111111111111111111111111111111111" ||
        barCode === "222222222222222222222222222222222222222222222222" ||
        barCode === "333333333333333333333333333333333333333333333333" ||
        barCode === "444444444444444444444444444444444444444444444444" ||
        barCode === "555555555555555555555555555555555555555555555555" ||
        barCode === "666666666666666666666666666666666666666666666666" ||
        barCode === "777777777777777777777777777777777777777777777777" ||
        barCode === "888888888888888888888888888888888888888888888888" ||
        barCode === "999999999999999999999999999999999999999999999999"
    
    ) return false;

    return true;
}

export const onlyNumbers = (str: string) => str?.replace(/\D/g, "");
export const isValidCPF = (cpf: string): boolean => {
    if (!cpf || !cpf.length) {
        return false;
    }

    cpf = onlyNumbers(cpf);
    if (!cpf.length) {
        return false;
    }

    if (
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    )
        return false;

    var add = 0;
    for (var i = 0; i < 9; i++) add += Number(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== Number(cpf.charAt(9))) return false;

    add = 0;
    for (i = 0; i < 10; i++) add += Number(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== Number(cpf.charAt(10))) return false;
    return true;
}

export const isValidCNPJ = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj === "") return false;
    var tamanho, numeros, digitos, pos, soma, i, resultado;
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
    )
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(1))) return false;

    return true;
}

// function customCPFCNPJValidator(this: StringSchema) {
//     return this.test((value: string) => {
//         if (!value)
//             return true;

//         if (value.length === 14) {
//             return isValidCNPJ(value);
//         } else {
//             return isValidCPF(value);
//         }
//     })
// }

