import { isFieldRequired } from 'helpers/methods/formFieldConfiguration';
import { number, object, string } from 'yup';

export type ValidationTypeField = {
  type: string;
};

const options: string[] = ['conditionalRequired'];
export function validateConditionalRequiredBySchema(validationTypeField: ValidationTypeField): boolean {
  if (options.includes(validationTypeField.type)) {
    return true;
  }
  return false;
}

const genericRequired = 'precisa ser preenchido.';

// Verifica se o campo especificado é obrigatório no FORM_FIELDS da configuração
export function validateRequiredFields(yupSchema: any, fieldName: string, errorMsg: string): any {

  const isRequired = isFieldRequired(fieldName);

  return isRequired
    ? yupSchema.required(`${errorMsg}: ${genericRequired}`)
    : yupSchema.optional().nullable();
}

export function generateAddressSchema() {
  return object()
    .shape({
      addressName: validateRequiredFields(
        string().typeError(`Endereço inválido.`),
        'address.addressName',
        `Endereço`
      ),
      zipCode: validateRequiredFields(
        string().typeError(`CEP inválido.`).zipCodeValidation('CEP deve conter 8 dígitos'),
        'address.zipCode',
        `CEP`
      ),
      city: validateRequiredFields(
        string().typeError(`Cidade inválida.`),
        'address.city',
        `Cidade`
      ),
      uf: validateRequiredFields(string().typeError(`UF inválido.`), 'address.uf', `UF`),
      district: validateRequiredFields(
        string().typeError(`Bairro é inválido.`),
        'address.district',
        `Bairro`
      ),
      number: validateRequiredFields(
        number().typeError(`Número inválido.`),
        'address.number',
        `Número`
      ),
      complement: string().nullable(),
    })
    .transform((value, _) => {
      let nothingIsFiled =
        isNullOrEmpty(value?.zipCode) &&
        isNullOrEmpty(value?.uf) &&
        isNullOrEmpty(value?.district) &&
        isNullOrEmpty(value?.number) &&
        isNullOrEmpty(value?.addressName) &&
        isNullOrEmpty(value?.city);

      const isInvalid = nothingIsFiled && !hasFieldAddressRequired();
      return isInvalid ? null : value;
    })
    .nullable();
}

const hasFieldAddressRequired = (): boolean => {
  const address = ['addressName', 'zipCode', 'city', 'uf', 'district', 'number'];
  const addressPrefix = 'address.';
  const isRequiredWithoutPrefix = address.some((x) => isFieldRequired(x));
  const isRequiredWithPrefix = address.some((x) => isFieldRequired(addressPrefix + x));
  return isRequiredWithoutPrefix || isRequiredWithPrefix;
};

function isNullOrEmpty(value: any): boolean {
  return value === null || value === undefined || value === '';
}
