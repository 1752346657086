import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

type CreditProductActionsProps = {
    isRootTenancy: boolean
}

export const CreditProductActions = ({ isRootTenancy }: CreditProductActionsProps) => {
    return (
        <React.Fragment>
            {isRootTenancy &&
                <CheckboxWithTooltip
                    checkboxFormField={{
                        label: 'Incluir beneficiário no produto de crédito',
                        name: 'actions.IncludeBeneficiaryProduct'
                    }}
                    helpMessage="Permite o usuário incluir um beneficiário como envolvido no produto de crédito."
                />
            }
        </React.Fragment>
    )
}
