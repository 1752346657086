import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { IFiltersPayrollAgreements, PayrollAgreementReadModel } from './payrollAgreement.type';
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';

const url = '/PayrollAgreement';
export async function getPayrollAgreementList(params: IFiltersPayrollAgreements, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<GetListApiResponseSuccess<PayrollAgreementReadModel>>(`/PayrollAgreement`, config);
}

export async function getByInternalId(internalId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<PayrollAgreementReadModel>(`${url}/GetByInternalId/${internalId}`, config);
}
