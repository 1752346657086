import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { CreditProductFormSchema } from "pages/Product/CreditProduct/CreditProductForm/CreditProductSchema";
import { toDataModel } from "helpers/validations/validateAndPreparePayload";
import { CreditProductReadModel, ModalityListProps, ProductFiltersProps, SubModalityProps } from "./types/creditProductReadModel";
import { CreditProductUpdateModel } from "./types/creditProductUpdateModel";
import { CreditProductCreateModel } from "./types/creditProductCreateModel";

var url = `/Product`;
export const getProductsList = async (filters: ProductFiltersProps, token?: string) => {
    const isEmpty = (value: any) => value === undefined || value === " " || value === "";
    const filtersWithoutEmptyValues = Object.fromEntries(Object.entries(filters).filter(([_, v]) => !isEmpty(v)));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filtersWithoutEmptyValues
    };

    return await creditAxiosApi.get(url, config);
};

export const getModalityListAsync = async (filters: ModalityListProps, token?: string) => {
    const isEmpty = (value: any) => value === undefined || value === " " || value === "";
    const filtersWithoutEmptyValues = Object.fromEntries(Object.entries(filters).filter(([_, v]) => !isEmpty(v)));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filtersWithoutEmptyValues
    };

    return await creditAxiosApi.get(`${url}/GetModality`, config);
};

export const getSubModalityListAsync = async (filters: SubModalityProps, token?: string) => {
    const isEmpty = (value: any) => value === undefined || value === " " || value === "";
    const filtersWithoutEmptyValues = Object.fromEntries(Object.entries(filters).filter(([_, v]) => !isEmpty(v)));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filtersWithoutEmptyValues
    };

    return await creditAxiosApi.get(`${url}/GetSubModality`, config);
};

type CreditProductTypeModel = CreditProductCreateModel | CreditProductUpdateModel;
export async function editOrCreateCreditProduct(
    data: CreditProductFormSchema,
    productId: string,
    token = '',
    tenant?: string
) {
    const isCreating = productId === 'novo';
    const urlFull = isCreating ? url : `${url}/${productId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const CreditProductCreateOrUpdate = isCreating ? CreditProductCreateModel : CreditProductUpdateModel;
    const payload = toDataModel<CreditProductTypeModel>(data, CreditProductCreateOrUpdate);

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<CreditProductReadModel>(urlFull, payload, axiosConfig);
}

export async function createCreditProduct(data: CreditProductFormSchema, tenant: string, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.post(url, data, config);
};

export async function getCreditProductById(productId: string, token = "", tenant?: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.get(url + `/${productId}`, config);
};


export const deleteCreditProductById = async (productId: string, token?: string, tenant?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    return await creditAxiosApi.delete(url + `/${productId}`, config);
};
