import { Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import { activeTheme } from 'services/theme';
import styled from '@emotion/styled';
import { FilterIcon } from '@uy3/web-components';
import { useState } from 'react';
import SelectColumnsContainer from './SelectColumns/SelectColumnsContainer';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import { iconSx } from 'contexts/apiRequestContext';
import { ShowSelectedValues } from './ShowSelectedValues';
import { SelectedValueRelatedPersons } from './SelectedValueRelatedPersons';

type CurrentValuesSelectedProps = {
    currentValues: any[];
    removeFilterSelected: (indx: number, duplicateRemove?: boolean) => void;
    recordType?: string;
    enableSelectFilter?: boolean;
};

const theme = activeTheme();
const listOfNamesToJoinFilter = ["relatedPersons", "typeOfRelationship"];

export const SelectedValues = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7E2DC',
    color: theme.palette.primary.main,
    borderRadius: '1rem',
    padding: '0 4px 0 10px',
    zIndex: 100,
    marginRight: '10px',
}));

export const CurrentValuesSelected = ({
    currentValues,
    removeFilterSelected,
    recordType,
    enableSelectFilter
}: CurrentValuesSelectedProps) => {
    const { localConfigKey } = useSelectColumns();
    const [open, setOpen] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let hasValueSelected = currentValues?.length !== 0;
    const onClose = () => setOpen(false);

    const hasIncludeFilterRelatedPersons: boolean = currentValues?.some(item => listOfNamesToJoinFilter?.includes(item?.name));

    return (
        <Grid position="relative">
            {!!localConfigKey && enableSelectFilter && (
                <IconButton
                    sx={{
                        top: hasValueSelected ? 43 : 28,
                        left: 1,
                        position: 'absolute',
                        zIndex: 100,
                    }}
                    onClick={() => setOpen(true)}
                >
                    <FilterIcon sx={iconSx} />
                </IconButton>
            )}
            {hasValueSelected ? (
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="left"
                    sx={{
                        m: '10px 0 -20px 0',
                    }}
                >
                    {hasIncludeFilterRelatedPersons && (
                        <SelectedValueRelatedPersons
                            currentValues={currentValues}
                            removeFilterSelected={(index) => removeFilterSelected(index, true)}
                        />
                    )}
                    <>
                        {currentValues?.filter(item => !listOfNamesToJoinFilter.includes(item?.name))?.map((item: any, index: number) => {
                            return (
                                <>
                                    <ShowSelectedValues
                                        item={item}
                                        label={item?.label}
                                        removeFilterSelected={() => removeFilterSelected(index)}
                                    />
                                </>
                            )
                        })}
                    </>
                </Stack>
            ) : (
                <div></div>
            )}

            <SelectColumnsContainer
                recordType={recordType!}
                open={open}
                onClose={onClose}
            />
        </Grid>
    );
};
