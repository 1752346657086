/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { Alert, TabsProps } from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import CreditNoteFormHeader from './CreditNoteFormHeader';
import { TabBar } from 'components/TabBar/TabBar';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { useEffect } from 'react';
import { getRecoverSimulation } from './Modals/RecoverSimulation/RecoverSimulationForm';
import { TimelineType, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { useProductId } from 'contexts/productIdContext';
import { HandleOnSubmitType } from 'contexts/HandleSubmitCreditNote';
import { useAppConfig } from 'contexts/appConfig';
import { WarrantyFullType } from '../WarrantyTab/WarrantyForm/WarrantyTypes';
import { isEmpty } from 'lodash';

type CreditType = {
    id: string;
    mode: FormMode;
    timeline: TimelineType[];
    statusDisplay?: string;
    setHandleSetValue: React.Dispatch<React.SetStateAction<HandleOnSubmitType>>
};

const CreditNoteForm = ({ id, mode, statusDisplay, timeline, setHandleSetValue }: CreditType) => {
    const { validationErrors, setValue, watch } = useFormContext();
    const { appConfig } = useAppConfig();
    const { setProductId } = useProductId();
    const newOp = mode === 'create';
    const { product } = useCreditNoteFormContext();
    const hasFund = !!watch('fundId');

    const { setSubmitError, endRequest } = useApiRequest();
    const { token } = useIdentity();

    const pathname = window.location.href;

    useEffect(() => setHandleSetValue({ handle: setValue }), []);

    useEffect(() => {
        if (pathname.includes('nova?simulacao=')) {
            let warranty: WarrantyFullType[] = [];
            const indexId = pathname.indexOf('=');
            const simulationId: any = pathname.substring(indexId + 1);

            if (pathname.includes('warranty=')) {
                const indexWarranty = pathname.indexOf('warranty=');
                const warrantyJson = pathname.substring(indexWarranty)?.replace('warranty=', '');
                let decodedJsonString = decodeURIComponent(warrantyJson);
                let jsonParsed = JSON.parse(decodedJsonString) as WarrantyFullType[];
                if (!isEmpty(jsonParsed) && jsonParsed.length > 0) {
                    warranty = jsonParsed; 
                }
            }
            
            getRecoverSimulation({
                endRequest,
                setSubmitError,
                setValue,
                setProductId,
                simulationId,
                token,
                setPopupProps: () => { },
                warranty
            });
        }
    }, [pathname, token]);

    const warranty = watch("warranty") ?? []
    const configEnvolvedPerson = appConfig?.SHOW_TAB_ENVOLVED_PERSON;
    const showCollateral = product?.useCollateral || warranty?.length > 0;
    const showEnvolvedPerson = configEnvolvedPerson !== 0;

    const tabs = [
        { label: 'Informações', href: id },
        ...(showCollateral ? [{ label: 'Garantias', href: 'garantias' }] : []),
        ...(showEnvolvedPerson ? [{ label: 'Envolvidos', href: 'envolvidos' }] : []),
        { label: 'Operação', href: 'operacao' },
        { label: 'Documentos', href: 'documentos' },
        ...(!newOp ? [{ label: 'Assinaturas', href: 'assinaturas' }] : []),
        ...(!newOp ? [{ label: 'KYC', href: 'kyc' }] : []),
        ...(!newOp ? [{ label: 'Crédito', href: 'credito' }] : []),
        ...(!newOp && hasFund ? [{ label: 'Cessões', href: 'cessoes' }] : []),
        ...(!newOp ? [{ label: 'Histórico', href: 'historico' }] : []),
    ] as TabsProps[];

    if (validationErrors) endRequest(false);

    return (
        <>
            {validationErrors && (
                <Box mt={2}>
                    <Alert
                        severity="error"
                        text="Não foi possível salvar as informações. Corrija os erros e tente novamente."
                        variant="filled"
                    />
                </Box>
            )}
            <CreditNoteFormHeader newOp={newOp} statusDisplay={statusDisplay} timeline={timeline} />
            <Box p={0} mt={4} mr={3}>
                <TabBar tabs={tabs} />
            </Box>
        </>
    );
};

export default CreditNoteForm;
