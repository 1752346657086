/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useBankAccountBalance, useBankSlipData } from 'contexts/bankAccount/bankAccountContext';
import { IMFAState, PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import moment from 'moment';
import ConfirmInformationDrawer from 'pages/BankAccount/BankAccountActive/BankAccountView/InformationTransferDataDrawer/ConfirmInformationDrawer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { postPay } from 'services/accounts/bankAccount/bankAccount';
import { InfoConfirmPay } from '../InfoConfirmPay/InfoConfirmPay';
import { validationBarCodeSchema, defaultValuesBarCode } from '../../barCodeSchema';
import { AxiosResponse } from 'axios';
import { RefreshProgress } from 'components/RefreshProgress';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import BarCodeForm from '../BarCodeForm/BarCodeForm';
import { StepsPayment } from 'services/accounts/bankAccount/bankAccount.types';
import DrawerMFA from '../../DrawerMFA/DrawerMFA';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';
import { PaySlipDetails } from './PaySlipDetails';
import { defaultValuesPaySliptDetails, validationPaySliptDetailsSchema } from './PaySliptDetailsSchema';

type PayInformationProps = {
    refetch: () => void;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const PaySlipFormContainer = ({ refetch, onCloseDrawer, setToast }: PayInformationProps) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [stepPayment, setStepPayment] = useState<StepsPayment>();
    const [formFieldValues, setFormFieldValues] = useState<any>();
    const [barCode, setBarCode] = useState<string | undefined>(undefined);
    const { setSubmitError, startRequest, endRequest } = useApiRequest();
    const [hideBtnClose, setHideBtnClose] = useState(true);

    const { bankSlipData, isFetching, bankSlipStatus, bankSlipError } = useBankSlipData({
        bankAccountId: bankAccountId!,
        barCode,
    });
    const { data: dataBalance } = useBankAccountBalance(bankAccountId!);
    const schema = validationBarCodeSchema();
    const defaultValues = defaultValuesBarCode;

    useEffect(() => {
        if (bankSlipStatus === 'error') {
            const { errorMessage } = mapErrorResponse(bankSlipError as ApiResponseError);
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }

        // clean up
        return () => {
            setSubmitError(undefined);
        };
    }, [bankSlipError]);

    const onClose = () => {
        setStepPayment(undefined);
        setSubmitError(undefined);
    };

    const onSubmitBarCode = (value: PaymentBankSlip) => {
        setHideBtnClose(false);
        let onlyNumbers = /\D/g;
        return setBarCode(value.barCode.replace(onlyNumbers, ''));
    };

    const onSubmitInformation = (value: any) => {
        setFormFieldValues({
            paymentDate: moment(value?.paymentDate).format(),
            paymentValue: value?.paymentValue
        });
        setStepPayment('confirmationPayment');
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const onSubmitConfirmationMfa = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password, then: (response: AxiosResponse<string, any>) => {
                let payload = {
                    barCodeOrDigitableLine: bankSlipData.barCode,
                    code: values?.code,
                    sessionId: response.data,
                    amount: dataBalance?.available,
                    paymentValue: formFieldValues?.paymentValue,
                    paymentDate: moment(formFieldValues?.paymentDate).format('YYYY-MM-DD') + 'T00:00:00',
                    totalValue: bankSlipData?.nominalValue,
                    paymentPurpose: 99999
                };
                postPay(bankAccountId!, payload, token!).then(() => {
                    endRequest(true);
                    onClose();
                    onCloseDrawer();
                    refetch();
                    const title = "Pagamento realizado com sucesso!";
                    const description = 'Ótimo! Agora é só aguardar a confirmação do pagamento.';
                    showSuccessToast(title, description, setToast);

                }).catch((error: ApiResponseError) => {
                    const { description, open, title } = errorHandlingBankAccount(error);
                    endRequest(false);
                    setToast({ title, description, open, severity: 'error' });
                });
            }
        });
    };

    const paymentValue = formFieldValues?.paymentValue;
    const formStep: { [key: string]: JSX.Element } = {
        confirmationMfa: <DrawerMFA onSubmit={onSubmitConfirmationMfa} onClose={() => setStepPayment("confirmationPayment")} toggleDrawer={true} />,
        confirmationPayment: (
            <ConfirmInformationDrawer
                open={true}
                onClose={onClose}
                setStep={setStepPayment}
                dataBalance={dataBalance}
                valueOfOperation={paymentValue ?? bankSlipData?.totalValue}
                CardInfoComponent={<InfoConfirmPay bankSlipData={bankSlipData} />}
            />
        ),
    };

    const currentStep = formStep[stepPayment!];
    const open = stepPayment === 'confirmationPayment' || stepPayment === 'confirmationMfa';
    const title = stepPayment === 'confirmationPayment' ? 'Resumo da operação' : 'Confirmar operação';

    return (
        <>
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={onSubmitBarCode}
            >
                <BarCodeForm
                    isFetching={isFetching}
                    onClose={onCloseDrawer}
                    hideBtnClose={hideBtnClose}
                />
            </FormProvider>

            {bankSlipData !== undefined && (
                <FormProvider
                    validationSchema={validationPaySliptDetailsSchema(bankSlipData)}
                    defaultValues={defaultValuesPaySliptDetails(bankSlipData.totalValue)}
                    onSubmit={onSubmitInformation}
                >
                    <PaySlipDetails {...{ bankSlipData: bankSlipData!, onCloseDrawer }} />
                </FormProvider>
            )}

            <Drawer anchor="right" title={title} open={open} onClose={onClose} toggleDrawer>
                {currentStep}
            </Drawer>
            <RefreshProgress refreshing={isFetching} />
        </>
    );
};
