import { mixed, number, object, string } from 'yup';
import { channelOptionsList } from '../Options';
import { handleNumericNaN } from 'helpers/validations/formFieldValidator';

const channelOptions = channelOptionsList.map((e) => e.value);

type Channel = (typeof channelOptions)[number];

export const locationSchema = object().shape({
    channel: mixed<Channel>()
        .oneOf(channelOptions, 'Canal inválido.')
        .typeError('Canal inválido.')
        .required('Canal: Precisa ser preenchido.'),
    local: string().nullable().typeError('Local deve ser no formato "Logradouro e CEP".'),
    ipAddress: number()
        .integer('Endereço de IP deve ser um número inteiro.')
        .typeError('Endereço de IP inválido.')
        .nullable()
        .notRequired()
        .transform(handleNumericNaN),
    device: string().nullable().default(undefined),
});
