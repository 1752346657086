import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { FilterProvider } from 'contexts/filterContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useDownloadFileConfirmationAsyncMutation, useGetAllFileConfirmation } from 'contexts/wallet/files/FileConfirmation/fileConfirmationContext';
import React, { useState } from 'react'
import { FileConfirmationList } from './FileConfirmationList';
import FileConfirmationHeader from './FileConfirmationHeader';
import { Grid } from '@mui/material';
import { RefreshProgress } from 'components/RefreshProgress';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useDownloadReportFileConfirmationAsyncMutation } from 'contexts/wallet/files/fileInstruction/fileInstructionContext';
import { useParams } from 'react-router';
import { useQueryCacheContext } from 'contexts/queryCacheContext';

export const FileConfirmationListContainer = () => {
    const { id } = useParams();
    const { getQueryCache } = useQueryCacheContext();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { filterValues } = useFilterValues();

    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [page, setPage] = useState(0);

    const queryDataByKey = getQueryCache(["get-wallet-by-id", id]); 

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onSuccess = (type:string) => {
        refetch();
        const message: {[type:string]: string} = {
            "download": "Sucesso ao realizar o download do arquivo", 
            "report": "Sucesso ao realizar o download da francesinha", 
        }
        const title = message[type];
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const onChangePage = (newPage: number) => setPage(newPage);

    const { data: queryData, isLoading, isFetching, refetch } = useGetAllFileConfirmation({
        page,
        size: rowsPerPage,
        walletsCode: Number(queryDataByKey?.walletCode)
    });

    const { mutateAsync: downloadMutateAsync } = useDownloadFileConfirmationAsyncMutation(() => onSuccess("download"), onError)
    const { mutateAsync: downloadReportMutation } = useDownloadReportFileConfirmationAsyncMutation(() => onSuccess("report"), onError);

    const downloadReportFileConfirmation = async (rowData: any) => {
        await downloadReportMutation({
            fileConfirmationIds: [rowData?.id],
            walletsCode: [rowData?.walletCode]
        });
    }

    const handleDownloadFileConfirmation = async (fileInstructionId: string) => {
        await downloadMutateAsync(fileInstructionId)
    }

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={2}>
                <FileConfirmationHeader refresh={refetch} />
            </Grid>
            <RefreshProgress refreshing={isFetching && !isLoading} />
            <FilterProvider
                availableFilters={{
                    walletsCode: {
                        label: 'Código da carteira',
                        type: 'text',
                        multiple: true
                    },
                    initialDate: {
                        label: 'Data',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDate',
                    },
                }}
            >
                <ListSkeleton isLoading={isLoading && Object.values(filterValues.filters)?.length === 0}>
                    <FileConfirmationList
                        {...{
                            page,
                            queryData,
                            rowsPerPage,
                            setPage: onChangePage,
                            setRowsPerPage: onChangeRowsPerPage,
                            handleDownloadFileConfirmation: handleDownloadFileConfirmation,
                            downloadReport: downloadReportFileConfirmation
                        }}
                    />
                </ListSkeleton>
            </FilterProvider>
        </React.Fragment>
    )
}
