import React, { useState } from 'react'
import { CostsList } from './CostsList'
import { CostsListHeader } from './CostsListHeader'
import { useCostsList, useExportWalletCostsMudataion } from 'contexts/wallet/costs/costsContext'
import { RefreshProgress } from 'components/RefreshProgress'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton'
import { FilterProvider } from 'contexts/filterContext'
import { useParams } from 'react-router'
import { useQueryCacheContext } from 'contexts/queryCacheContext'
import { useFilterValues } from 'contexts/filterValuesContext'
import { handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext'
import Toast from 'components/Toast/Toast'
import { Error } from 'components/Errors/Error'
import { confirmationTransactionOptions } from 'services/walletManagement/costs/costs.type'

export const CostsListContainer = () => {
  const { id } = useParams();
  const [toast, setToast] = useState<ToastType>(toastState);
  const { filterValues } = useFilterValues();
  const [page, setPage] = useState(0);
  const { getQueryCache } = useQueryCacheContext();

  const queryDataByKey = getQueryCache(["get-wallet-by-id", id]);

  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onSuccess = () => {
    const title = "Sucesso ao exportar tarifas";
    const description = undefined;
    showSuccessToast(title, description, setToast);
    refetch();
  };

  const onChangePage = (page: number) => setPage(page);;

  const { data: costsData, refetch, isLoading, isFetching, error: costsError } = useCostsList({ page, size: rowsPerPage, walletsCode: queryDataByKey?.walletCode });
  const { mutateAsync: exportWalletsCostsMudateAsync } = useExportWalletCostsMudataion({walletsCode: queryDataByKey?.walletCode}, onSuccess, (error) => handleOnError(error, setToast));
  const enableSkeleton = isLoading && Object?.values(filterValues.filters ?? {}).length === 0

  if (costsData?.type === 'error') return <Error error={costsError}/>

  return (
    <React.Fragment>
      <Toast toast={toast} setToast={setToast} />

      <CostsListHeader
        refetch={refetch}
        hasData={(costsData?.data?.length ?? 0) > 0}
        handleExportCsv={() => exportWalletsCostsMudateAsync()}
      />
      <RefreshProgress refreshing={!isLoading && isFetching} />

      <FilterProvider
        availableFilters={{
          initialBillingDate: {
            label: 'Data de cobrança',
            type: 'dateRange',
            multiple: false,
            dateRangeName: 'finalBillingDate'
          },
          initialCreationDate: {
            label: 'Data de lançamento',
            type: 'dateRange',
            multiple: false,
            dateRangeName: 'finalCreationDate',
          },
          confirmationTransactionType: {
            label: "Tipo de instrução",
            type: 'select',
            multiple: false,
            options: confirmationTransactionOptions
          },
          hasBillingDate: {
            label: "Ver tarifas cobradas",
            type: 'select',
            multiple: false,
            options: [
              { label: "Sim", value: 'true' },
              { label: "Não", value: 'false' },
            ]
          },
        }}
      >
        <ListSkeleton isLoading={enableSkeleton}>
          <CostsList {...{
            page,
            queryData: costsData,
            rowsPerPage,
            setPage: onChangePage,
            setRowsPerPage: onChangeRowsPerPage,
          }} />
        </ListSkeleton>
      </FilterProvider>
    </React.Fragment>
  )
}
