import { useQueryClient } from "@tanstack/react-query";
import { ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from "contexts/apiRequestContext";
import { FormProvider } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { useParams } from "react-router";
import { cancelCreditNoteById } from "services/creditNote";
import { validationSchemaCancelForm, defaultValuesCancelForm } from "./CancelSchema";
import { FieldValues } from 'react-hook-form';
import { RefreshProgress } from "components/RefreshProgress";
import { CancelForm } from "./CancelForm";
import { useState } from "react";
import { mapErrorResponse } from "contexts/responseErrorContext";
import Toast from "components/Toast/Toast";

type CancelFormContainerProps = {
    setPopupProps: (props: any) => void;
    navigate: () => void;
    statusCreditNote: string 
};

export const CancelFormContainer = ({ setPopupProps, navigate, statusCreditNote }: CancelFormContainerProps) => {
    const { token } = useIdentity();
    const { id } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const queryClient = useQueryClient();
    const { startRequest, setSubmitError, endRequest, submitting } = useApiRequest();
    const hasWarrantyStatus = statusCreditNote.toLowerCase() === 'warranty'; 

    const schema = validationSchemaCancelForm();
    const defaultValues = defaultValuesCancelForm;

    const onSubmit = async (values: FieldValues) => {
        startRequest();
        let payload = {
            message: values?.message,
            insertBlock: values?.insertBlock,
            blockUntil: values?.blockUntil, 
            rejectionReasonCode: values?.rejectionReasonCode
        }

        await cancelCreditNoteById(payload, id!, token!).then(() => {
            queryClient.invalidateQueries({
                queryKey: ["credit-note-list"],
                refetchType: 'active',
            });
            navigate();
            setPopupProps(undefined);
            endRequest(true);
            const title = "Operação cancela com sucesso!";
            const description = undefined;
            showSuccessToast(title, description, setToast);

        }).catch((error) => {
            endRequest(false);
            setPopupProps(undefined);
            setSubmitError(error);

            const { errorMessage } = mapErrorResponse(error);
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);

        });
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={submitting} />
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onChangeField={
                    [{
                        fieldName: "insertBlock",
                        delegate: (value: string | null, setValue: (name: any, value: any) => void) => setValue("insertBlock", value)
                    }]}
                onSubmit={onSubmit}>
                <CancelForm
                    hasWarrantyStatus={hasWarrantyStatus}
                    setPopupProps={setPopupProps}
                />
            </FormProvider>
        </>
    );
};