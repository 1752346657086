import { Drawer } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import React, { useState } from 'react';
import { AssignForm } from './AssignForm';
import { defaultValuesAssignForm, validationSchemaAssignForm } from './AssignSchema';
import { assign } from 'services/assign';
import { useIdentity } from 'contexts/identityContext';
import { useOperatorList } from 'contexts/OperatorContext';
import { useGroupListData } from 'contexts/groupContext';
import { useNavigate } from 'react-router';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
} from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IAssignDrawerProps {
    openDrawer: boolean;
    onClose: () => void;
    recordType: string;
    recordId: string;
    navigate: string;
}

const AssignDrawer: React.FC<IAssignDrawerProps> = ({
    openDrawer,
    onClose,
    recordType,
    recordId,
    navigate,
}) => {
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [selectedTenant, setSelectedTenant] = useState<{
        label: string | undefined;
        value: string | number | undefined;
    } | undefined>(undefined);
    const { operatorsAutoCompleteProps } = useOperatorList({ page: 0, size: 10 }, 'always', selectedTenant?.value as string);
    const { groupAutoCompleteProps } = useGroupListData({ page: 0, size: 10, tenant: selectedTenant?.value as string }, 'always');
    const nav = useNavigate();

    const schemaAssignProduct = validationSchemaAssignForm();
    const assignDefaultValues = defaultValuesAssignForm;

    const onAssignProduct = async (values: FieldValues) => {
        const { userId, groupName } = values;
        let data = {
            groupName,
            recordId: recordId,
            recordType: recordType,
            selectedTenant: selectedTenant?.value as string,
            userId,
        };

        await assign(data, token!)
            .then(() => {
                const title = 'Atibuição relizada com sucesso!';
                const desc = 'Ótimo! Agora você pode visualizar o registro atribuído.';
                showSuccessToast(title, desc, setToast);
                setTimeout(() => nav(`${navigate}/${recordId}`), 1000);
            })
            .catch((response: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(response);
                const title = 'Ops, ocorreu um erro!';
                const desc = errorMessage;
                showErrorToast(title, desc, setToast);
            })
            .finally(() => onClose());
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Drawer anchor="right" title="Atribuir" open={openDrawer} onClose={onClose}>
                <FormProvider
                    validationSchema={schemaAssignProduct}
                    defaultValues={assignDefaultValues}
                    onSubmit={onAssignProduct}
                >
                    <AssignForm
                        setSelectedTenant={setSelectedTenant}
                        groupList={groupAutoCompleteProps}
                        listOperator={operatorsAutoCompleteProps}
                        onClose={onClose}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};

export default AssignDrawer;
