import { useQuery } from '@tanstack/react-query';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { getBankList } from 'services/bankList/bankList';
import { isAxiosError } from 'axios';
import { useState } from 'react';

type UseBankListProps = {
    compe: boolean;
}

const invalidCode = (code: number) => {
    return code === undefined || code === null || code === 0;
}

export function useBankList({
    compe = false
}: UseBankListProps) {
    const { token } = useIdentity();
    const [bankList, setBankList] = useState<{ label: string; value: string }[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { data, isFetching, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['use-bank-list', search],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getBankList(search!, compe, token!);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const bankList = data as any;

            let bankListData = bankList?.data?.map((bank: any) => {
                const value = invalidCode(bank?.code) ? bank?.ispb : bank?.code;
                return { label: `${value} - ${bank?.codeDisplay}`, value: value };
            });

            setBankList(bankListData);

            endRequest(true);
            return data as any;
        },
    });

    const bankListAutocomplete = {
        listOptions: bankList,
        loading: isLoading || isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString)
    };

    return { data, bankListAutocomplete };
}
