import { Drawer, Typography } from '@uy3/web-components';
import { IUploadModel } from 'contexts/apiRequestContext';
import { useEnumContext } from 'contexts/enumContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useFundsList } from 'contexts/fundContext';
import { useState } from 'react';
import InformationCreditProduct from './InformationTabCreditProduct';
import { Alert } from '@mui/material';
import { defaultValuesDocForm, validationSchemaDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { UploadFormWrapper } from 'components/Tabs/Uploads/UploadForm/UploadFormWrapper';
import { useTenant } from 'contexts/tenantContext';
import { useGetModalityData, useGetSubModalityData } from 'contexts/creditProductContext';

export const InformationCreditProductContainer = () => {
    const [open, setOpen] = useState(false);
    const { watch, setValue } = useFormContext();
    const { tenantAutoCompleteProps } = useTenant();
    const { autoCompleteProps: typeOfDocument } = useEnumContext({ enumName: 'FileType' });
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');
    const { modalityAutoCompleteProps } = useGetModalityData({ page: 0, size: 10 });
    const [getCodeModalite, setGetCodeModalite] = useState<number | undefined>();
    const { subModalityAutoCompleteProps } = useGetSubModalityData({
        page: 0,
        size: 10,
        codeModalite: getCodeModalite,
    });
    const templateDoc = watch('templateDoc');

    const setNewUpload = (values: IUploadModel) => {
        setValue('templateDoc', values);
        setOpen(false);
    };

    const defaultFormValue = templateDoc ?? defaultValuesDocForm;

    return (
        <>
            <Drawer
                anchor="right"
                title="Modelo de contrato"
                open={open}
                onClose={() => setOpen(false)}
            >
                <FormProvider
                    validationSchema={validationSchemaDocForm()}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewUpload}
                >
                    <UploadFormWrapper
                        options={typeOfDocument.listOptions}
                        accept=".docx"
                        onClose={() => setOpen(false)}
                        currentValues={templateDoc}
                    />
                </FormProvider>
                <Alert
                    sx={{
                        mt: 4,
                        borderRadius: 2,
                        alignItems: 'center'
                    }}
                    severity="warning">
                    <Typography
                        variant="md"
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-all'
                        }}>
                        {templateDoc?.fileName ? <p>Atenção, o documento<strong>&nbsp;{templateDoc.fileName}&nbsp;</strong>
                                será atualizado!</p> : <>Carregando novo documento.</>}
                    </Typography>
                </Alert>
            </Drawer>

            <InformationCreditProduct
                tenantAutoCompleteProps={tenantAutoCompleteProps}
                fundList={fundAutoCompleteProps}
                modalityList={modalityAutoCompleteProps}
                subModalityList={subModalityAutoCompleteProps}
                openDrawerDocument={() => setOpen(true)}
                setGetCodeModalite={setGetCodeModalite}
            />
        </>
    );
};
