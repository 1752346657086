/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useFormContext } from "contexts/formContext";
import { useEffect, useMemo, useState } from "react";
import { useFundsList } from "contexts/fundContext";
import { usePersonsList } from "contexts/personContext";
import { useCreditProdutList } from "contexts/creditProductContext";
import InformationCreditNoteTab from "./InformationTab";
import { useCreditNoteFormContext } from "pages/Product/CreditProduct/CreditProductForm/CreditProductForm";
import { useRelatedBankAccountData } from "contexts/relatedBankAccountContext";
import { Error } from "components/Errors/Error";
import { useLegalPersonData } from "contexts/legalPersonContext";
import { Modal } from "@uy3/web-components";
import { ImportSignatories } from "./Modals/ImportSignatories";
import { defaultValuesImportSignatoriesSchema, validationApproveImportSignatoriesSchemaSchema } from "./Modals/ImportSignatoriesSchema";
import { SelectOption } from "components/Forms/FormFields";
import { FieldValues } from "react-hook-form";
import { useParams } from "react-router";
import { useIdentity } from "contexts/identityContext";
import { getLegalPersonById } from "services/accounts/legalPerson";
import DrawerAddPerson from "components/DrawerAddPerson/DrawerAddPerson";
import { importRelatedPersonByPersonId } from "helpers/methods/ImportRelatedPerson";
import { assignToRelatedPersons } from "helpers/formats/StringFormats";
import { removeDuplicateData } from "helpers/formats/ArrayFormats";
import { RelatedPerson, RelatedPersonFull } from "services/creditProduct/types/creditProductReadModel";

function InformationCreditNoteTabContainer() {
    const { id } = useParams();
    const { token } = useIdentity();
    const { watch, setValue } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isAddPerson, setIsAddPerson] = useState<boolean>(false);
    const [isAddBankAccount, setIsAddBankAccount] = useState<boolean>(false);
    const [changeAccount, setChangeAccount] = useState<boolean>(false);
    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, 'always');
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');
    const [
        beneficiaryId,
        personId,
        beneficiaryDiscriminator,
        personDiscriminator,
        personsToImport,
    ] = watch([
        'beneficiaryId',
        'personId',
        'beneficiaryDiscriminator',
        'personDiscriminator',
        'personsToImport',
    ]);
    const { legalPersonData } = useLegalPersonData(personDiscriminator === 'LegalPerson' ? personId : 'novo');

    const { data: personBankList, status: personBankListStatus, error: personBankListError } = useRelatedBankAccountData(personId, personDiscriminator);
    const { data: beneficiaryBankList, status: beneficiaryBankListStatus, error: beneficiaryBankListError } = useRelatedBankAccountData(beneficiaryId, beneficiaryDiscriminator);
    const relatedPersonCreditNote = watch('relatedPersonCreditNote') ?? [];
    const isNewOperation = id === 'nova';

    const saveRelatedPersonAutomatic = (personId: string, currentRelatedPerson: RelatedPerson[]) => {
        getLegalPersonById(personId, token!)
            .then(({ data: { relatedPerson } }) => {
                const { allRelatedPersons } = importRelatedPersonByPersonId({
                    currentRelatedPerson,
                    personId,
                    relatedPersonToImport: relatedPerson,
                    setValue,
                    watch
                });
                if (allRelatedPersons.length > 0) {
                    setValue('relatedPersonCreditNote', []);
                    const relatedPersonByLegalPerson = watch('relatedPersonByLegalPerson') ?? [];
                    const involvedFiltered = relatedPersonCreditNote?.filter((item: RelatedPerson) => item.productId === product?.id)
                    const relatedPerson = [
                        ...involvedFiltered,
                        ...relatedPersonByLegalPerson,
                        ...currentRelatedPerson,
                        ...allRelatedPersons
                    ];
                    setValue('relatedPersonCreditNote', assignToRelatedPersons(removeDuplicateData(relatedPerson, "personId"), product!?.id));
                }
            });
    };

    useEffect(() => {
        if (product && !product.isConsumerCreditNote && personId !== beneficiaryId) {
            setValue('beneficiaryId', personId);
            setValue('beneficiaryDiscriminator', personDiscriminator);
            setValue('beneficiaryDisplay', watch('personDisplay'));
        }

        // clean up 
        return () => {
            setValue('personsToImport', []);
            setValue('relatedPersonByLegalPerson', []);
            setValue('isImport', false);
        }

    }, [personId, beneficiaryId, product]);

    useMemo(() => {
        if (isNewOperation && (product && !!product.id)) {
            const relatedPerson = legalPersonData?.relatedPerson;
            if (relatedPerson?.length > 0 && personDiscriminator === 'LegalPerson') {
                setValue('personsToImport', relatedPerson);
                if (!watch('isImport') && watch('hideImport') !== true) setOpenModal(true);
            }
        }
    }, [personId, legalPersonData]);

    const personsToImportList: SelectOption[] = !!personsToImport && personsToImport.map((person: RelatedPerson) => {
        return { label: person.personIdDisplay, value: person.personId }
    });

    const onImportPerson = async (values: FieldValues) => {
        const personsToImportIds = values?.personsToImport;
        const relatedPersonByProduct: RelatedPerson[] = product?.relatedPerson ?? [];
        const relatedPersonFiltered = relatedPersonByProduct.filter(related => related.typeOfRelationship !== 'Beneficiary')
        const relatedPersonByLegalPerson = personsToImport?.filter((p: RelatedPerson) => personsToImportIds?.includes(p.personId));
        const relatedPerson = relatedPersonByLegalPerson.map((person: RelatedPersonFull) => {
            return {
                ...person,
                relatedToId: legalPersonData?.id,
                relatedToIdDisplay: legalPersonData?.name,
            }
        });
        const relatedPersonComplete = assignToRelatedPersons([...relatedPerson], product?.id!);

        const relatedPersonTypeLegalPerson = relatedPersonFiltered.filter(person => person.discriminator === 'LegalPerson')
        if (relatedPersonTypeLegalPerson.length > 0) {
            relatedPersonTypeLegalPerson.map(({ personId }: RelatedPerson) => {
                saveRelatedPersonAutomatic(personId, relatedPersonFiltered);
            });
        } else setValue('relatedPersonCreditNote', assignToRelatedPersons([...relatedPersonComplete, ...relatedPersonFiltered], product?.id!));
        setValue('relatedPersonByLegalPerson', relatedPersonComplete);
        setValue('isImport', true);
        setValue('hideImport', true);
        setOpenModal(false);
    }

    const description = !!watch('personDisplay') ? `Selecione abaixo os signatários referente ${watch('personDisplay')}` : '';

    if (beneficiaryBankListStatus === "error" || personBankListStatus === "error") <Error error={personBankListError ?? beneficiaryBankListError} />

    const onCancel = () => {
        setValue('hideImport', true);
        setOpenModal(false)
    }

    return (
        <>
            <DrawerAddPerson
                open={isAddPerson}
                onClose={() => {
                    setIsAddPerson(false);
                    setIsAddBankAccount(false);
                    setChangeAccount(false);
                }}
                isAddBankAccount={isAddBankAccount}
                changeAccount={changeAccount}
                isAddPerson={isAddPerson}
            />
            <InformationCreditNoteTab
                productList={creditProductAutoCompleteProps}
                fundList={fundAutoCompleteProps}
                tomadorList={personAutoCompleteProps}
                personBankList={personBankList?.relatedBankAccountData}
                beneficiaryBankList={beneficiaryBankList?.relatedBankAccountData}
                watch={watch}
                setIsAddPerson={setIsAddPerson}
                setIsAddBankAccount={setIsAddBankAccount}
                setChangeAccount={setChangeAccount}
                isNewOperation={isNewOperation}
            />

            <Modal
                title="Importar signatários como envolvido"
                description={description}
                open={openModal}
                onClose={() => setOpenModal(false)}
                sizeModal="small"
                children={
                    <FormProvider
                        defaultValues={defaultValuesImportSignatoriesSchema}
                        validationSchema={validationApproveImportSignatoriesSchemaSchema()}
                        onSubmit={onImportPerson}
                    >
                        <ImportSignatories
                            onClose={() => setOpenModal(false)}
                            onCancel={onCancel}
                            personsToImportList={personsToImportList}
                        />
                    </FormProvider>
                }
            />
        </>
    );
}

export default InformationCreditNoteTabContainer;
