import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { GetViewApiResponse, useApiRequest } from "contexts/apiRequestContext";
import { useAppConfig } from "contexts/appConfig";
import { useIdentity } from "contexts/identityContext";
import { getCheckListVerifyPersonBy } from "services/creditNote/complianceChecklist/complianceChecklist";

export function useComplianceChecklistVerifyPersonId(personId: string) {
  const {appConfig} = useAppConfig();
  const isRootTenancy = appConfig.TENANT_TYPE === 0;
  const { token } = useIdentity();
  const { startRequest, endRequest, setSubmitError } = useApiRequest();

  const { status, data, error, isFetching, refetch } = useQuery({
      enabled: !!token && !!personId && personId !== 'novo' && personId !== 'nova' && isRootTenancy,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryKey: ['batchAssignment-data', personId],
      queryFn: async (): Promise<GetViewApiResponse<any>> => {
          startRequest();
          const { data, status, statusText } = await getCheckListVerifyPersonBy(personId, token!);
          endRequest(true);

          if (isAxiosError(data)) {
              setSubmitError({
                  type: 'error',
                  code: status + '' + statusText,
                  message: data.message,
                  errors: data.response?.data?.errors,
              });
              throw data;
          }

          if (status >= 400 && status <= 599) {
              throw data;
          }
          return data; 
      },
  });

  return { status, data, error, refetch, isFetching };
};