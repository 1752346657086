import { NotificationsReadModel } from 'services/notifications/types/notificationsReadModel';
import { Notification } from './Notification';
import { NotificationsListStack } from './notification.styled';
import { getRelativeDate } from 'helpers/formats/DateFormat';
import { Fragment } from 'react/jsx-runtime';
import AccordionMessage from './AccordionNotification/AccordionNotification';
import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';

type ListNotificationsProps = {
    notificationsData: NotificationsReadModel[] | undefined;
    lastRead?: string;
    handleLastReadAndRedirect: (notification: NotificationsReadModel) => void
};

export const ListNotifications = ({
    lastRead,
    notificationsData,
    handleLastReadAndRedirect
}: ListNotificationsProps) => {
    const notificationsList = (notificationsData as NotificationsReadModel[]) ?? [];

    const checkNotificationIsNew = (createdAt: string) => {
        if (lastRead === null || lastRead === undefined) {
            return true;
        }
        const createdAtDate = new Date(createdAt).getTime();
        const lastReadDate = new Date(lastRead).getTime();
        return createdAtDate > lastReadDate;
    }

    const mapperListNotifications = notificationsList.map((notification) => {
        return {
            ...notification,
            groupDisplay: getRelativeDate(notification.createdAt),
            isNewNotification: checkNotificationIsNew(notification.createdAt)
        };
    });

    const groupedNotifications = mapperListNotifications.reduce((acc: any, notification: any) => {
        const { groupDisplay } = notification as NotificationsReadModel;
        if (!acc[groupDisplay]) {
            acc[groupDisplay] = [];
        }
        acc[groupDisplay].push(notification);
        return acc;
    }, {});


    return (
        <NotificationsListStack>
            {(notificationsData?.length ?? 0) > 0 ? (
                <Fragment>
                    {Object.entries(groupedNotifications)?.map((value, index) => {
                        const notificationsByPeriod = value[1] as NotificationsReadModel[];
                        const title = value[0] as string;
                        return (
                            <Fragment>
                                <AccordionMessage {...{
                                    title: title,
                                    expandedIndex: index,
                                    content: (
                                        <Fragment>
                                            {notificationsByPeriod.map((item, index) => {
                                                return (
                                                    <Notification
                                                        key={index}
                                                        notification={item}
                                                        handleLastReadAndRedirect={() => handleLastReadAndRedirect(item)}
                                                    />
                                                );
                                            })}
                                        </Fragment>
                                    ),
                                }} />
                            </Fragment>
                        );
                    })}
                </Fragment>
            ) : (
                <Stack textAlign='center' justifyContent='center' mt={5} p={3}>
                    <Typography variant='h5' fontWeight='700'>
                        Sem nenhuma novidade por aqui. Avisaremos quando algo precisar da sua atenção.
                    </Typography>
                </Stack>
            )}

        </NotificationsListStack>
    );
};
