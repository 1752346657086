import { useMutation } from "@tanstack/react-query";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { getZipCodeByBillingApi } from "services/walletManagement/zipCode/zipCode";
import { IAddressBilling } from "services/walletManagement/zipCode/zipCode.type";


export function useGetZipCodeBillingMutation(
    onSuccess: (data: IAddressBilling) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (zipCode: string) => {
            startRequest();
            const { data } = await getZipCodeByBillingApi(zipCode, token!);
            endRequest(true);
            return data as IAddressBilling;

        },
        onSuccess(data: any, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

}