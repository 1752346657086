import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { ReactNode } from 'react';
import { activeTheme } from 'services/theme';

type InvolvedListHeaderProps = {
    enableAction: boolean;
    onClick: () => void;
    title: string;
    titleButton: string;
    otherActionsComponent?: ReactNode;
};

const theme = activeTheme();

export const GenericListHeader = ({
    enableAction,
    onClick: handlerAddInvolved,
    title,
    titleButton,
    otherActionsComponent = <></>,
}: InvolvedListHeaderProps) => {
    const { submitting } = useApiRequest();

    const color = submitting ? theme.palette?.grey['400'] : theme.palette.common.black;

    const startIcon =
        titleButton === 'Atualizar' ? (
            <UpdateIcon htmlColor={color} sx={iconSx} />
        ) : (
            <AddIcon htmlColor={color} sx={iconSx} />
        );

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mr={2} my={4}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    {title}
                </Typography>
            </Grid>

            <Stack direction="row" alignItems="center">
                {enableAction && (
                    <Button
                        name="btn-add-involved-list"
                        variant="text"
                        onClick={handlerAddInvolved}
                        sx={{ color }}
                        startIcon={startIcon}
                    >
                        {titleButton}
                    </Button>
                )}
                {otherActionsComponent}
            </Stack>
        </Stack>
    );
};
