import { isValidString } from 'helpers/formats/StringFormats';
import { string, object, boolean } from 'yup';

export function validationSchemaInstallmentCreditNote() {
    return object().shape({
        useFundSetting: boolean().nullable(),
        walletCode: string().nullable().notRequired(),
        billingSettingId: string().when("walletCode", {
            is: (value: string) => isValidString(value),
            then: string().typeError('Régua de cobrança: precisa ser preenchida').required("Régua de cobrança: precisa ser preenchida"),
            otherwise: string().nullable().notRequired()
        }),
        communicationSettingId: string().when("walletCode", {
            is: (value: string) => isValidString(value),
            then: string().typeError('Régua de comunicação: precisa ser preenchida').required("Régua de comunicação: precisa ser preenchida"),
            otherwise: string().nullable().notRequired()
        })
    })
};

export const defaultValuesInstallmentCreditNote = {
    walletCode: null,
    billingSettingId: null,
    communicationSettingId: null,
    useFundSetting: false
};