import { Stack } from '@mui/material';
import { SelectWarranty } from './SelectWarranty';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultvaluesSelectWarrantyForm,
    validationSchemaSelectWarrantyForm,
} from './SelectWarrantySchema';
import { ISiapeMarginQueryFull } from 'services/datasets/datasets.interface';
import React from 'react';
import { isEmpty } from 'lodash';
import { ToastType } from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import { WarrantyFullType } from 'pages/CreditNote/WarrantyTab/WarrantyForm/WarrantyTypes';
import { useIdentity } from 'contexts/identityContext';
import { getByInternalId } from 'services/payrollAgreement';
import { PayrollAgreementReadModel } from 'services/payrollAgreement/payrollAgreement.type';
import { AxiosError } from 'axios';

type SelectWarrantyContainerProps = {
    marginQueryData: ISiapeMarginQueryFull;
    onBackStep: () => void;
    onCloseAll: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    handleSiapeMarginQuery: () => void;
};

export const SelectWarrantyContainer: React.FC<SelectWarrantyContainerProps> = ({
    marginQueryData,
    onBackStep,
    setToast,
    onCloseAll,
    handleSiapeMarginQuery,
}) => {
    const { setValue, watch } = useFormContext();
    const { token } = useIdentity();

    const currentWarranty = (watch('warranty') ?? []) as WarrantyFullType[];
    const existsWarrantyPublicPayroll = currentWarranty.some(
        (x: WarrantyFullType) => x.warrantyType === 'PublicPayroll'
    );

    const setToastMessage = (message: string, severity: 'warning' | 'error' | 'info') => {
        setToast({
            open: true,
            severity,
            title: message,
        });
    };

    const mapWarrantyModel = (
        list: typeof marginQueryData.result.VinculoFuncionals,
        data: PayrollAgreementReadModel
    ) => {
        return list.map((item) => ({
            warrantyType: 'PublicPayroll',
            payrollAgreementId: data?.id,
            payrollAgreementName: data?.name,
            paymentTransferDay: data?.paymentTransferDay,
            employeeCode: item.CdMatricula,
            agreementCode: item.Produtos[0]?.CdConvenio,
            employeerInstCode: item?.OrgMatInst,
            paymentAmountInCents: item.Produtos[0]?.VlMargemDisp,
            employmentStatusCode: item.CodSituacaoFuncional,
            employmentStatusDescription: item.DescSituacaoFuncional,
            codOrgao: item?.CodOrgao
        }));
    };

    const handleSubmitForm = async (values: FieldValues) => {
        const codeSelected = values?.warrantySelected;
        if (isEmpty(codeSelected)) {
            return setToastMessage('Selecione ao menos 1 modelo de margem', 'warning');
        }

        const selectedItems = marginQueryData?.result.VinculoFuncionals.filter(
            (item) => `${item?.CnpjOrgao}-${item?.CodOrgao}` === codeSelected
        );

        if (!selectedItems.length) {
            return setToastMessage('Nenhum item selecionado encontrado', 'warning');
        }

        const internalId = selectedItems[0]?.CodOrgao;

        try {
            const { data } = await getByInternalId(internalId!, token!);
            const mappedWarrantyModels = mapWarrantyModel(selectedItems, data);

            const updatedWarrantyList = existsWarrantyPublicPayroll
                ? currentWarranty.filter((x) => x.warrantyType !== 'PublicPayroll')
                : currentWarranty;

            setValue('warranty', [...updatedWarrantyList, ...mappedWarrantyModels]);
            setValue('amortization.calculateByValueType', 'Payment');
            setValue('amortization.requestedAmount', selectedItems[0].Produtos[0]?.VlMargemDisp);
            onCloseAll();
        } catch (error) {
            const errorMessage = error instanceof AxiosError
                ? error.response?.data?.message || 'Erro desconhecido'
                : error;

            setToastMessage(
                `Ops! Ocorreu um erro ao buscar os dados do convênio. Erro: ${errorMessage}`,
                'error'
            );
        }
    };

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultvaluesSelectWarrantyForm}
                validationSchema={validationSchemaSelectWarrantyForm()}
                onSubmit={handleSubmitForm}
            >
                <SelectWarranty
                    marginQueryData={marginQueryData}
                    onClose={onBackStep}
                    handleSiapeMarginQuery={handleSiapeMarginQuery}
                />
            </FormProvider>
        </Stack>
    );
};
