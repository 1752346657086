import { CheckboxFormField } from 'components/Forms/FormFields'
import React from 'react'

export const PermissionsActions = () => {
    return (
        <React.Fragment>
            <CheckboxFormField
                label='Permitir atualizações das permissões no ambiente do operador'
                name='actions.AllowUpdatePermissionsSameTenant'
            />
        </React.Fragment>
    )
}
