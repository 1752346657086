import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { CommunicationSettingListHeader } from './CommunicationSettingListHeader';
import { CommunicationSettingList } from './CommunicationSettingList';
import {
    useCommunicationSettingList,
    useCreateCommunicationSettingMudataion,
    useDeleteCommunicationSettingMudataion,
    useUpdateCommunicationSettingMudataion,
} from 'contexts/wallet/communicationSetting/communicationSettingContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { Error } from 'components/Errors/Error';
import { handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext';
import { Modal } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import {
    ICommunicationSettingFull,
    ICreateCommunicationSetting,
    IUpdateCommunicationSetting,
} from 'services/walletManagement/communicationSetting/communicationSetting.types';
import Toast from 'components/Toast/Toast';
import { RefreshProgress } from 'components/RefreshProgress';
import {
    defaultValuesCommunicationSetting,
    validationSchemaCommunicationSetting,
} from './Drawers/CommunicationSettingForm/CommunicationSettingFormSchema';
import { FilterProvider } from 'contexts/filterContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { CommunicationSettingFormContainer } from './Drawers/CommunicationSettingForm/CommunicationSettingFormContainer';

export const CommunicationSettingContainer = () => {
    const [handleAction, setHandleAction] = useState<
        { action: string; row?: ICommunicationSettingFull } | undefined
    >(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const { filterValues: { filters } } = useFilterValues();
    const [page, setPage] = useState(0);
    const [toast, setToast] = useState<ToastType>(toastState);
    const onClose = () => setHandleAction(undefined);
    const hasFilters = Object.values(filters)?.length > 0;

    const onSuccess = (value: string) => {
        const mapMessage: { [type: string]: string } = {
            edit: 'Sucesso ao a editar régua de cobrança',
            delete: 'Sucesso ao a exluir régua de cobrança',
            create: 'Sucesso ao adicionar régua de cobrança '
        };
        refetchList();
        onClose();
        return showSuccessToast(mapMessage[value!], '', setToast);
    };

    const {
        data: queryDataList,
        isLoading: isLoadingList,
        isFetching: isFetchingList,
        refetch: refetchList,
        error: errorList,
    } = useCommunicationSettingList({
        page,
        size: rowsPerPage
    });


    const { mutateAsync: editMutateAsync, isLoading: isLoadingEdit } =
        useUpdateCommunicationSettingMudataion(
            handleAction?.row?.id!,
            () => onSuccess('edit'),
            (error) => handleOnError(error, setToast)
        )
    const { mutateAsync: createMutateAsync, isLoading: isLoadingCreate } =
        useCreateCommunicationSettingMudataion(
            () => onSuccess('create'),
            (error) => handleOnError(error, setToast)
        )
    const { mutateAsync: deleteCommunicationSetting, isLoading: isLoadingDelete } =
        useDeleteCommunicationSettingMudataion(
            () => onSuccess('delete'),
            (error) => handleOnError(error, setToast)
        );

    if (queryDataList?.type === 'error') {
        return <Error error={errorList} />;
    }

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const createCommunicationSetting = async (values: ICreateCommunicationSetting) => await createMutateAsync(values);
    const editCommunicationSetting = async (values: IUpdateCommunicationSetting) => await editMutateAsync(values);

    const handleSubmit = async (values: ICreateCommunicationSetting) => {
        if (enableStepAction('edit')) {
            return await editCommunicationSetting(values);
        }
        return createCommunicationSetting(values);
    };

    const enableStepAction = (value: string) => !!handleAction && handleAction.action === value;

    const defaultValuesForm = defaultValuesCommunicationSetting(handleAction?.row);
    return (
        <Stack spacing={2}>
            <Toast {...{ toast, setToast }} />

            <CommunicationSettingListHeader
                {...{
                    refetch: refetchList,
                    handleAction: (action) => setHandleAction({ action })
                }}
            />

            <RefreshProgress {...{ refreshing: (!isLoadingList && isFetchingList) || isLoadingDelete || isLoadingEdit || isLoadingCreate }} />

            <FilterProvider {...{
                availableFilters: {
                    walletsCode: {
                        label: 'Código do convênio',
                        type: 'text',
                        multiple: true
                    },
                    Name: {
                        label: "Nome",
                        type: 'text'
                    },
                    walletNumber: {
                        label: 'Número da carteira',
                        type: 'text'
                    },
                    daysToSendBillingBeforeDueDate: {
                        label: 'Dias para envio',
                        type: 'text'
                    }
                }
            }}>
                <Stack marginRight={4}>
                    <ListSkeleton isLoading={isLoadingList && !hasFilters}>
                        <CommunicationSettingList
                            queryData={queryDataList!}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={onChangeRowsPerPage}
                            setPage={(page: number) => setPage(page)}
                            page={page}
                            handleAction={setHandleAction}
                        />
                    </ListSkeleton>
                </Stack>
            </FilterProvider>

            <CommunicationSettingFormContainer {...{
                title: enableStepAction('edit') ? `Editar ${handleAction?.row?.name ?? ''}` : "Nova régua de cobrança",
                open: handleAction?.action === 'add' || enableStepAction('edit'),
                onSubmit: handleSubmit,
                schema: {
                    defaultValues: defaultValuesForm,
                    validation: validationSchemaCommunicationSetting()
                },
                onClose
            }}/>

            <Modal
                title={`Excluir ${handleAction?.row?.name ?? ''}`}
                description="Tem certeza que deseja excluir essa régua?"
                open={enableStepAction('delete')}
                align="left"
                onClose={onClose}
            >
                <GenericActionHandler
                    handleSubmit={() => deleteCommunicationSetting(handleAction?.row?.id!)}
                    onClose={onClose}
                    isModal
                />
            </Modal>

        </Stack>
    );
};
