import { ContentCopy } from '@mui/icons-material';
import { Stack } from '@mui/material';
import {
    HideIcon,
    RemoveRectangleIcon,
    ShowIcon,
    TransactionalLimitsIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { useShowValues } from 'contexts/showValuesContext';
import { activeTheme } from 'services/theme';
import { useState } from 'react';
import {
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { IBankAccountFromBalance } from 'contexts/bankAccount/bankAccountContext';
import { useAppConfig } from 'contexts/appConfig';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import { isRootTenancy } from 'helpers/methods/GenericMethods';

type BankAccountViewActionsProps = {
    onRefresh: () => void;
    bankAccount: IBankAccountFromBalance;
    onTerminateAccount: () => void;
};

const theme = activeTheme();

export function BankAccountViewActions({
    onRefresh,
    bankAccount,
    onTerminateAccount,
}: BankAccountViewActionsProps) {
    const { showValues, setShowValues } = useShowValues();
    const [toast, setToast] = useState<ToastType>(toastState);

    const sxIcon = { width: 20, height: 20 };

    async function onCopy() {
        const data: { [type: string]: string } = {
            Titular: bankAccount.personName,
            'CPF/CNPJ': `${formatDocumentNumber(bankAccount.personRegistrationNumber)}`,
            Banco: `${bankAccount.bankCode} - ${bankAccount.bankCodeDisplay}`,
            Agência: bankAccount.agency,
            'Número da Conta': bankAccount.account,
            'Tipo de conta': bankAccount.typeDisplay,
        };
        const stringToCopy = Object.entries(data)
            .map((item) => {
                return `${item[0]}: ${item[1]}`;
            })
            .join(', ');

        await navigator.clipboard
            .writeText(stringToCopy)
            .then(() => {
                const description =
                    'As informações bancárias foram copiadas com sucesso para a sua área de transferência.';
                showSuccessToast('Sucesso', description, setToast);
            })
            .catch(() => {
                showErrorToast(
                    'Ops, houve um erro',
                    'Não foi possível copiar suas informações bancárias',
                    setToast
                );
            });
    }

    const { appConfig } = useAppConfig();
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);

    const resource = 'BankAccount';
    const typePermission = 'TerminateAccount';
    const { data } = useUserPermissionData();
    const hasPerm = checkActionsPerms(typePermission, data, resource);

    return (
        <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mr={-4}
        >
            <Toast toast={toast} setToast={setToast} />

            <Actions
                buttonsActionsList={[
                    {
                        enable: hasPerm && rootTenancy,
                        label: 'Encerrar conta',
                        action: onTerminateAccount,
                        icon: <RemoveRectangleIcon sx={sxIcon} />,
                    },
                    {
                        enable: true,
                        label: 'Copiar informações bancárias',
                        action: onCopy,
                        icon: (
                            <ContentCopy
                                sx={{ height: 20, width: 20, color: theme.palette.common.black }}
                            />
                        ),
                    },
                    {
                        enable: true,
                        label: 'Atualizar valores',
                        action: onRefresh,
                        icon: <TransactionalLimitsIcon sx={sxIcon} />,
                    },
                    {
                        enable: true,
                        label: showValues ? 'Ocultar valores' : 'Mostrar valores',
                        action: () => setShowValues(!showValues),
                        icon: showValues ? <ShowIcon sx={sxIcon} /> : <HideIcon sx={sxIcon} />,
                    },
                ]}
                numberOfButtons={3}
            />
        </Stack>
    );
}
