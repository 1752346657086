import React, { useState } from 'react'
import { WalletsList } from './WalletsList'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { WalletsListHeader } from './WalletsListHeader';
import { Grid } from '@mui/material';
import { FilterProvider } from 'contexts/filterContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { Modal } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { Error } from 'components/Errors/Error';
import { useDeleteWalletByIdAsyncMutation, useGetWalletListToBankSlipCosts } from 'contexts/wallet/walletContext/walletContext';

export const WalletsListContainer = () => {
    const { filterValues } = useFilterValues();
    const [stepAction, setStepAction] = useState<{step: string, data: unknown} | undefined>(undefined)
    const hasFilters = Object.values(filterValues.filters).length > 0
    const [toast, setToast] = useState<ToastType>(toastState);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );

    const onCloseStep = () => setStepAction(undefined); 

    const { data, isLoading, refetch, error} = useGetWalletListToBankSlipCosts({ page, size: rowsPerPage! });

    const onSuccess = () => {
        refetch();
        const title = "Sucesso ao exluir a carteira";
        const description = undefined;
        onCloseStep();
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutateAsync: deleteMutateAsync } = useDeleteWalletByIdAsyncMutation(onSuccess, onError);

    if (data?.type === 'error') return <Error error={error}/>

    const walletSelected = data?.data?.find(item => item?.id ===  stepAction?.data); 

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => setPage(page);;

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={3}>
                <WalletsListHeader refresh={refetch} />
            </Grid>
            <FilterProvider
                availableFilters={{
                    name: { label: 'Nome', type: 'text' },
                    walletsCode: { label: 'Código convênio', type: 'text',  multiple: true}
                }}
            >
                <ListSkeleton
                    isLoading={isLoading && !hasFilters}
                >
                    <WalletsList {...{
                        page,
                        queryData: data,
                        rowsPerPage,
                        setRowsPerPage: onChangeRowsPerPage,
                        setPage: onChangePage,
                        deleteWalleById: (walletId) => setStepAction({
                            data: walletId as string, 
                            step: 'delete'
                        })
                    }} />
                </ListSkeleton>
            </FilterProvider>

            <Modal
                title={`Excluir carteira ${walletSelected?.beneficiaryName} - #${walletSelected?.walletCode}`}
                description='Tem certeza que deseja exluir essa carteira?'
                open={stepAction?.step === 'delete'}
                onClose={onCloseStep}
                sizeModal="large"
            >
                <GenericActionHandler 
                    handleSubmit={() => deleteMutateAsync(stepAction?.data as string)}
                    onClose={onCloseStep}
                    isModal
                    titleCancel='Não, cancelar'
                    titleConfirm='Sim, confirmar'
                />
            </Modal>
        </React.Fragment>
    )
}
