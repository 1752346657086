import { Drawer } from '@uy3/web-components';
import { useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
  ToastType,
  showErrorToast,
  showSuccessToast,
  toastState,
} from 'contexts/apiRequestContext';
import { useEnumContext } from 'contexts/enumContext';
import { RefreshProgress } from 'components/RefreshProgress';
import {
  defaultValuesBankAccInvolvedForm,
  validationSchemaBankAccInvolvedForm,
} from './InvolvedBankAccRequestSchema';
import { usePersonsList } from 'contexts/personContext';
import InvolvedBankAccountRequestList from './InvolvedBankAccRequestList';
import { InvolvedBankAccRequestForm } from './InvolvedBankAccRequestForm';
import { BankAccountRelatedPersonType } from 'services/accounts/bankAccountRequest/bankAccountRequest.types';
import Toast from 'components/Toast/Toast';
import { assignToRelatedPersons } from 'helpers/formats/StringFormats';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { useUserPermissionData } from 'contexts/userContext';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import { RelatedPerson, RelatedPersonFull } from 'services/creditProduct/types/creditProductReadModel';
import { useOperatorList } from 'contexts/OperatorContext';

export const InvolvedBankAccountRequestTabContainer = () => {
  const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
  const { operatorsAutoCompleteProps } = useOperatorList({ page: 0, size: 10 }, 'always', selectedTenant);

  const {
    personAutoCompleteProps,
    status: personStatus,
    error: personError,
    isFetching: personFetch,
  } = usePersonsList({ page: 0, size: 10 }, 'always');

  const {
    status: enumStatus,
    data: typeOfRelationship,
    error: enumError,
    isFetching: enumFetch,
  } = useEnumContext({ enumName: 'TypeOfRelationship', size: 50 });

  const { bankAccountId } = useParams();
  const { hasPermission } = useUserPermissionData();
  const [selectedInvolvedIndex, setSelectedInvolvedIndex] = useState<number | undefined>();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [toast, setToast] = useState<ToastType>(toastState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { setValue, watch, readOnly } = useFormContext();
  const bankAccountProductId: string = watch('bankAccountProductId') ?? 'novo';
  const watchedRelatedPerson = watch('relatedPerson') ?? [];
  const [relatedToId, setRelatedToId] = useState<string | null>('');
  const isNewBankAccountRequest = bankAccountId === 'nova';

  const typePermission = isNewBankAccountRequest ? 'Create' : 'Update';
  const hasPermissionCreateOrEdit = hasPermission('BankAccountRequest', typePermission);
  const enableActions = hasPermissionCreateOrEdit && !readOnly;

  const isEscrow = watch('type') === 'Escrow';
  const isActive = watch('status') === 'Active';

  const setNewRelatedPerson = (values: BankAccountRelatedPersonType) => {
    let newRelatedPerson = watchedRelatedPerson !== undefined ? [...watchedRelatedPerson] : [];

    if (isEscrow && isActive && values.typeOfRelationship !== 'Creditor') {
      const description = 'Tipo de relação do envolvido precisa ser credor';
      return showErrorToast('Ops, houve um erro', description, setToast);
    }

    if (selectedInvolvedIndex === undefined) newRelatedPerson?.push(values);
    else newRelatedPerson[selectedInvolvedIndex] = values;
    setValue('relatedPerson', assignToRelatedPersons(newRelatedPerson, bankAccountProductId));
    const title = 'Lista de envolvidos atualizada com sucesso!';
    const description = undefined;
    showSuccessToast(title, description, setToast);
    closeDrawer();
  };

  const closeDrawer = () => {
    setSelectedInvolvedIndex(undefined);
    setOpenDrawer(false);
  };

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  function onDelete(index: number) {
    let rowData = [...watchedRelatedPerson];
    rowData?.splice(index, 1);
    setValue('relatedPerson', rowData);
    const title = 'Envolvido excluído com sucesso!';
    const description = undefined;
    showSuccessToast(title, description, setToast);
  }

  const involvedsList = watchedRelatedPerson?.map((relatedPerson: RelatedPerson) => {
    return {
      id: Math.random().toString(),
      ...relatedPerson
    };
  });

  const currentInvolved = watchedRelatedPerson[selectedInvolvedIndex!];
  const currentPercentage = currentInvolved?.participationPercentage;

  const getParticipationPercentage = (person: RelatedPersonFull): number => {
    return person?.participationPercentage ?? 0;
  };

  const calculateTotalPercentage = (relatedPerson: RelatedPersonFull[]): number => {
    return relatedPerson.reduce((total, person) => total + getParticipationPercentage(person), 0);
  };

  const calculateRelatedToIdPercentage = (relatedPerson: RelatedPersonFull[]): number => {
    const relatedToIdPeople = relatedPerson.filter((person) => person?.relatedToId === relatedToId);
    return calculateTotalPercentage(relatedToIdPeople);
  };

  const recalculatePercentages = (watchedRelatedPerson: RelatedPersonFull[], currentInvolved: RelatedPersonFull) => {
    // Atualiza a lista de pessoas relacionadas sem a relação atual
    const updatedPerson: any[] = watchedRelatedPerson.map(person => {
      if (person?.personId === currentInvolved?.personId) {
        return { ...person, relatedToId: null };
      }
      return person;
    });

    const unlinkedPeople = updatedPerson.filter((person) => !person?.relatedToId) || [];

    // Calcula a porcentagem total de participação das pessoas vinculadas apenas à solicitação de conta
    const totalUnlinkedPercentage = calculateTotalPercentage(unlinkedPeople);

    // Calcula a porcentagem total de participação das pessoas vinculadas a um determinado `relatedToId`
    const relatedToIdPercentage = calculateRelatedToIdPercentage(watchedRelatedPerson);

    return { totalUnlinkedPercentage, relatedToIdPercentage };
  };

  const { totalUnlinkedPercentage, relatedToIdPercentage } = recalculatePercentages(watchedRelatedPerson, currentInvolved);

  const involvedSchema = validationSchemaBankAccInvolvedForm(
    totalUnlinkedPercentage,
    currentPercentage,
    relatedToIdPercentage
  );

  const defaultFormValue =
    selectedInvolvedIndex !== undefined ? currentInvolved : defaultValuesBankAccInvolvedForm;

  const status = personStatus || enumStatus;
  const error = enumError || personError;
  const isFetched = enumFetch || personFetch;

  return (
    <GenericErrorBoundary status={status} error={error} fallback="envolvidos">
      <RefreshProgress refreshing={isFetched} />
      <Toast toast={toast} setToast={setToast} />
      <Grid mt={3} mb={3}>
        <GenericListHeader
          title="Envolvidos"
          titleButton="Adicionar envolvido"
          enableAction={enableActions}
          onClick={() => setOpenDrawer(true)}
        />
      </Grid>

      <InvolvedBankAccountRequestList
        queryData={involvedsList}
        enableAction={enableActions}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onChangeRowsPerPage}
        setPage={onChangePage}
        page={page}
        setSelectedInvolvedIndex={(rowNumber: number | undefined) =>
          setSelectedInvolvedIndex(rowNumber ?? watchedRelatedPerson?.length)
        }
        setOpenDrawer={setOpenDrawer}
        onDelete={onDelete}
        typeOfRelationship={typeOfRelationship ?? []}
      />

      <Drawer
        anchor="right"
        title="Envolvido"
        description="Confira as informações antes de adicionar o envolvido"
        open={selectedInvolvedIndex !== undefined || openDrawer}
        onClose={closeDrawer}
      >
        <FormProvider
          validationSchema={involvedSchema}
          defaultValues={defaultFormValue}
          onSubmit={setNewRelatedPerson}
          readOnly={readOnly}
          onChangeField={[
            {
              fieldName: 'relatedToId',
              delegate: (value: string | null) => {
                setRelatedToId(value);
              },
            },
            {
              fieldName: 'tenantName',
              delegate: (value: string) => setSelectedTenant(value)
            }
          ]}
        >
          <InvolvedBankAccRequestForm
            currentInvolved={currentInvolved}
            typeOfRelationship={typeOfRelationship ?? []}
            onClose={closeDrawer}
            personList={personAutoCompleteProps}
            isLoading={false}
            listOperator={operatorsAutoCompleteProps}
          />
        </FormProvider>
      </Drawer>
    </GenericErrorBoundary>
  );
};
