import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { SelectOption } from 'components/Forms/FormFields';
import {
    ApiResponseError,
    GetListApiResponse,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';
import { responseBlobFile } from 'helpers/methods/ResponseBlobFile';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
    registerBillingAsync,
    getBillingByIdAsync,
    getBillingList,
    createBillingAsync,
    updateBillingByPatch,
    getTagsByWalletCode,
    downloadBatchByBillingList,
    exportBillingsAsync,
} from 'services/walletManagement/billing';
import {
    BillingFiltersProps,
    BillingFull,
    IBillingUpdatePatch,
    IBillingCrate
} from 'services/walletManagement/billing/billing.types';

export function useBillingById(billingId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token && billingId !== 'nova' && !isEmpty(billingId),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-billing-by-id', billingId],
        queryFn: async (): Promise<BillingFull> => {
            startRequest();
            const { data, status, statusText } = await getBillingByIdAsync(billingId, token);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as BillingFull;
        },
    });

    return queryContext;
}

export function useGetTagsAsync(walletCode: string) {
    const { token } = useIdentity();
    const [ search, setSearch ] = useState<string | undefined>(undefined)
    const [tagsList, setTagsList] = useState<SelectOption[]>([])
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-tags-by-wallet-code', search, walletCode],
        queryFn: async (): Promise<string[]> => {
            startRequest();
            const { data, status, statusText } = await getTagsByWalletCode({
                searchString: search, 
                walletCode 
            }, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const list = (data as string[]).map(item => {
                return {
                    label: item,
                    value: item
                }
            }) as SelectOption[];
            setTagsList(list);

            return data as string[];
        },
    });

    const tagsAutocomplete = {
        listOptions: tagsList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString)
    };

    return { ...queryContext, tagsAutocomplete };
}

export function useBillingListData(
    filters: BillingFiltersProps,
    refetchOnMount?: IRefetchOnMountModeGetQuery
) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [chargesList, setBankSlipList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'Cobranca' : filterValues.recordType;
    const listDataFilters = recordType === 'Cobranca' && { ...filterValues.filters };
    const filtersComplete = { ...listDataFilters, ...filters, tenant: currentTenantId };

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-charges-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<BillingFull>> => {
            startRequest();
            const resp = await getBillingList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            var list = data as any;
            setBankSlipList(
                list[0]?.data.map((c: BillingFull) => {
                    return {
                        label: `(${c.walletCode}) - (${c.payer?.name})`,
                        value: c.id,
                    };
                })
            );

            return list as GetListApiResponse<BillingFull>;
        },
    });

    const billingAutocompleteProps = {
        listOptions: chargesList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'Cobranca'),
    };

    return {
        ...queryContext,
        billingAutocompleteProps,
    };
}

export function useCreateBillingAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void, 
    idempotence?: string,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (values: IBillingCrate) => {
            startRequest();

            const { data, status, statusText } = await createBillingAsync(values, token!, idempotence);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;

        },
        onSuccess(data: any, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useBillingRegisterMutation(
    onSuccess: (data: BillingFull | string) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const rest = useMutation({
        mutationFn: async (chargesId: string) => {
            startRequest();
            try {
                const { data, status, statusText } = await registerBillingAsync(chargesId, token!);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data as BillingFull;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return rest;
}

export function usePatchBillingAsyncMutation(
    chargesId: string,
    onSuccess: (data: BillingFull | string) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const rest = useMutation({
        mutationFn: async (payload: IBillingUpdatePatch) => {
            startRequest();
            try {
                const { data, status, statusText } = await updateBillingByPatch(chargesId, payload, token!);

                if (isAxiosError(data)) {
                    setSubmitError({
                        type: 'error',
                        code: status + '' + statusText,
                        message: data.message,
                        errors: data.response?.data?.errors,
                    });
                    throw data;
                }

                if (status >= 400 && status <= 599) {
                    throw data;
                }

                endRequest(true);
                return data as BillingFull;
            } catch (error) {
                handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return rest;
}

export function useDownloadBatchBillingMutation(onSuccess: (data: any) => void, onError: (error: ApiResponseError) => void) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    return useMutation({
        mutationFn: async (billingIds: string[]) => {
            startRequest();

            return await downloadBatchByBillingList(billingIds, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess&&onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });
}

export function useExportBillingsMutation(onSuccess: (data: any) => void, onError: (error: ApiResponseError) => void) {
    const { filterValues } = useFilterValues();
    const paramsByFilters = filterValues?.filters as any; 
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    return useMutation({
        mutationFn: async () => {
            startRequest();

            return await exportBillingsAsync(token!, paramsByFilters)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess&&onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });
}