import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { SelectOption } from 'components/Forms/FormFields';
import { createContext, SetStateAction, useContext, useState, Dispatch } from 'react';
import { getTenantList } from 'services/tenant/tenant';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';

type TenantSelectedProps = {
    currentTenantId: string | undefined;
    selectedTenant: SelectOption | undefined;
    setSelectedTenant: Dispatch<SetStateAction<{ label: string | undefined, value: string | number | undefined } | undefined>>,
    tenantAutoCompleteProps: AutocompleteOptionList;
    queryContext: UseQueryResult<any, unknown>;
    isLoading: boolean;
    isParentTenant: boolean;
};

const TenantContext = createContext<TenantSelectedProps>({
    currentTenantId: undefined,
    selectedTenant: { label: '', value: '' },
    setSelectedTenant: () => { },
    tenantAutoCompleteProps: { listOptions: [], loading: false },
    queryContext: {} as UseQueryResult<any, unknown>, 
    isLoading: false,
    isParentTenant: false
});

interface TenantProviderProps {
    children: JSX.Element;
};

export const TenantProvider = ({ children }: TenantProviderProps) => {
    const [selectedTenant, setSelectedTenant] = useState<any>({ label: undefined, value: undefined });
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [tenantList, setTenantList] = useState<{ label: string, value: string }[]>([])
    const filtersComplete = { page: 0, size: 10 };

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['tenant-list', filtersComplete],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getTenantList(filtersComplete, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            let tenantDataList = data as any;
            tenantDataList = tenantDataList.map((item: { name: string, id: string }) => {
                return {
                    label: item?.name,
                    value: item?.id
                }
            });
            setTenantList(tenantDataList);
            return tenantDataList;
        }
    });

    const tenantAutoCompleteProps = {
        listOptions: tenantList,
        loading: queryContext.isLoading || queryContext.isFetching
    };

    return (
        <TenantContext.Provider
            value={{
                currentTenantId: selectedTenant?.value ?? undefined,
                selectedTenant,
                setSelectedTenant,
                tenantAutoCompleteProps: tenantAutoCompleteProps,
                queryContext, 
                isLoading: queryContext.isLoading,
                isParentTenant: queryContext.data?.length > 1 ? true : false
            }}
        >
            {children}
        </TenantContext.Provider>
    );
};

export function useTenant() {
    const context = useContext(TenantContext);
    const {
        currentTenantId,
        selectedTenant,
        setSelectedTenant,
        tenantAutoCompleteProps,
        queryContext, 
        isLoading,
        isParentTenant
    } = context;

    return {
        currentTenantId,
        selectedTenant,
        setSelectedTenant,
        tenantAutoCompleteProps,
        queryContext, 
        isLoading,
        isParentTenant
    };
};