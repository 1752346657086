import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { AproverCreditStatusRequestPost, CreditStatusRequestPost } from "./creditStatusRequest.types";

export interface ICreditStatusRequestListProps {
    page: number;
    size: number;
    searchString?: string;
    personId: string;
};

var url = "/CreditStatusRequest";
export const getCreditStatusRequestList = async (filters: ICreditStatusRequestListProps, token?: string) => {   

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await creditAxiosApi.get(url + `?id=${filters.personId}`, config);
};


export async function postCreditStatusRequest(data: CreditStatusRequestPost, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, data, config);
};

export async function postCreditApproval(data: AproverCreditStatusRequestPost, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url + "/approval", data, config);
};