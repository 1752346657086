import { IUploadModel } from 'contexts/apiRequestContext';
import { EAssignmentCalculationMode } from '../enum';
import { ESignatureType, ESignatureValidation } from 'utils/enums';
import { ICommissionModel } from 'utils/interface';
import { AmortizationTypes } from 'services/Amortization';

export interface IWarranty {
    warrantyType: string;
    renavam: string;
    typeOfVehicle: string;
    chassis: string;
    board: string;
    supplier: string;
    taxNumberOfSupplier: string;
    brand: string;
    color: string;
    model: string;
    yearOfManufacture: number;
    yearOfModel: number;
    typeOfFuel: string;
    registryOffice: string;
    registrationNumber: string;
    width: number;
    length: number;
    addressName: string;
    zipCodeAddress: string;
    city: string;
    uf: string;
    district: string;
    addressNumber: string;
    complementAddress: string;
    propertyFeatures: string;
    number: string;
    court: string;
    valueOfCause: number;
    claimant: string;
    claimed: string;
    typeOfProcess: string;
    downPayment: number;
    sellerCompstringName: string;
    sellerRegistrationNumber: string;
    sellerCompanyName?: string;
    valueInCash: string;
    subtotalAmount?: string;
    totalAmountFinancedTaxFree: string;
    register: string;
    type: string;
    splitMethodType: string;
    paymentNetworks: string;
    constituted: boolean;
    totalValue?: string;
    employeeCode: string;
    agreementCode: string;
    employeerInstCode: string;
    payrollAgreementId: string;
    payrollAgreementName: string;
    warrantyCode: string;
    paymentTransferDay: number;
    paymentAmountInCents: number;
    employmentStatusCode: string;
    employmentStatusDescription: string;
    payerName: string;
    payerRegistrationNumber: string;
    contractNumber: string;
    invoiceNumber: string;
    invoiceValue: number;
    discountValue: number;
    presentValue: number;
    dueDate: string;
    paymentItemIndex?: number;
    paymentItemTotal?: number;
    yourNumber?: string;
    storeCode?: number;
    productCode?: number;
    creditType?: string;
    identification?: string;
    emissionDate?: string;
    index?: number;
    payerDescription?: string;
    payerAdress?: string;
    payerZipCodeAdress?: number;
    contractFee?: number;
}

export interface IBasicRelatedPersonCreditNote {
    personId: string;
    typeOfRelationship: string;
    relatedToId?: string;
    signatureType: ESignatureType;
    signatureValidation: ESignatureValidation;
    percentage: number;
}

export interface ICommissionReadModel extends ICommissionModel { }

export interface IPaymentPeriodicityReadModel {
    every: number;
    periodicity: string;
}

export type CreditNoteOpStatus =
    | 'Draft'
    | 'ComplianceApproval'
    | 'Signatures'
    | 'Liquidation'
    | 'Finished'
    | 'Disapproved'
    | 'Error'
    | 'Revision'
    | 'CreditApproval'
    | 'InstrumentApproval'
    | 'Canceled'
    | 'WaitLiquidation'
    | 'SignaturesValidation'
    | 'PaymentRevision'
    | 'ManualLiquidation'
    | 'Warranty'
    | 'ManualWarranty'
    | 'MarginReserveApproval';

export type LiquidationType = 'Invoice' | 'EletronicTransfer';

type AssignmentCalculationType = 'Absolute' | 'Percentage';

export interface IAssignmentCalculationModel {
    amount: number;
    type: AssignmentCalculationType;
    mode: EAssignmentCalculationMode;
}

export interface IPaymentScheduleItemCreateModel {
    dueDate: string;
    principalAmountInCents: number;
    amortization: number;
    interest: number;
    financeTax: number;
    addedFixedCosts: number;
    payment: number;
}

export class CreditNoteOpBaseModel {
    productId!: string;
    fundId?: string;
    warranty: IWarranty[] = [];
    emissionDate?: string;
    amortization: AmortizationTypes = {} as AmortizationTypes;
    personId!: string;
    liquidationType: LiquidationType = {} as LiquidationType;
    invoiceBarCode?: string;
    beneficiaryId?: string;
    collateral?: string;
    observations?: string;
    status?: CreditNoteOpStatus;
    bankAccountId!: string;
    tokenName?: string;
    isRegistered: boolean = false;
    bulkAssignment: boolean = false;
    invoiceValue?: number;
    invoiceNumber?: string;
    changeBankAccountId?: string;
    issueNumber?: number;
    emissionNumber?: number;
    quantity?: number;
    unitPrice?: number;
    assignmentCalculation: IAssignmentCalculationModel = {} as IAssignmentCalculationModel;
    relatedPersonCreditNote: IBasicRelatedPersonCreditNote[] = [];
    uploads: IUploadModel[] = [];
    paymentScheduleItems: IPaymentScheduleItemCreateModel[] = [];
    unicoSignScore?: number;
    registrationNumber?: string;

    constructor(init?: Partial<CreditNoteOpBaseModel>) {
        Object.assign(this, init);
    }
}
