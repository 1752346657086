import { Grid } from '@mui/material'
import { Typography } from '@uy3/web-components';
import { CheckboxFormField, CheckboxFormFieldProps } from 'components/Forms/FormFields'
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import { TooltipComponent } from 'components/Tooltip/Tooltop'
import { ReactNode } from 'react';

type CheckboxWithTooltipProps = {
    checkboxFormField: CheckboxFormFieldProps;
    helpMessage?: ReactNode;
}

export const CheckboxWithTooltip = ({ checkboxFormField, helpMessage }: CheckboxWithTooltipProps) => {
    return (
        <TooltipComponent
            placement='left'
            title={helpMessage ? (
                <Typography fontSize='14px'>
                    <SpanCustom>{`${checkboxFormField.label}`}</SpanCustom> - &nbsp;
                    {helpMessage}
                </Typography>
            ) : ""}
        >
            <Grid>
                <CheckboxFormField {...checkboxFormField} />
            </Grid>
        </TooltipComponent>
    )
}
