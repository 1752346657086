import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Alert, Button, CloseIcon, SaveIcon, Typography } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { activeTheme } from 'services/theme';
import { RefreshProgress } from 'components/RefreshProgress';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useFormContext } from 'contexts/formContext';
import { isEmpty } from 'lodash';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { CreditProductReadModel, RelatedPerson } from 'services/creditProduct/types/creditProductReadModel';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';

interface Person {
  discriminator: string | undefined;
  id: string | undefined;
}

type importedSignatoriesProps = {
  label: string;
  value: string;
}

type InvolvedProps = {
  typeOfRelationship: SelectOption[];
  signatureType: SelectOption[];
  signatureValidation: SelectOption[];
  onClose: () => void;
  personList: AutocompleteOptionList;
  name: string;
  setSelectedPerson?: React.Dispatch<React.SetStateAction<Person | undefined>>;
  hasSignatories?: boolean;
  legalPersonData?: any;
  importedSignatories: importedSignatoriesProps[];
  setImportedSignatories: React.Dispatch<React.SetStateAction<importedSignatoriesProps[]>>;
  creditProductData: CreditProductReadModel
  hasPermissionIncludeBeneficiaryProduct: boolean;
}

const theme = activeTheme();
export const InvolvedForm: FunctionComponent<InvolvedProps> = ({
  name,
  typeOfRelationship,
  signatureType,
  importedSignatories,
  setImportedSignatories,
  signatureValidation,
  setSelectedPerson,
  onClose,
  personList,
  hasSignatories,
  legalPersonData,
  creditProductData,
  hasPermissionIncludeBeneficiaryProduct
}) => {
  const { setValue, watch } = useFormContext();
  const { submitting } = useApiRequest();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const relatedPerson = legalPersonData?.relatedPerson;

  const typeOfRelationshipValue = watch('typeOfRelationship');

  const signatoriesList = relatedPerson?.map((item: RelatedPerson) => {
    return {
      label: `${item?.personIdDisplay} - ${item?.typeOfRelationshipDisplay}`,
      value: item?.personId
    }
  })

  const signatoriesAutocompleteList = {
    listOptions: signatoriesList || [],
    loading: false,
    onSearch: () => { }
  };

  const handlerSignerChange = (_: any, option: any) => {
    if (!isEmpty(option)) {
      setImportedSignatories(option);
      let valuesToImport = option?.map((option: importedSignatoriesProps) => option?.value);
      setValue("personsToImport", valuesToImport);

    } else {
      setImportedSignatories([]);
    }
  };

  const handlerInvolvedChange = (_: any, option: any) => {
    setSelectedPerson && setSelectedPerson({
      discriminator: option?.discriminator,
      id: option?.value
    });
    setValue('personId', option?.value);
    setValue('personIdDisplay', option?.label);
  }

  const personId = watch('personId');
  const discriminator = watch('personDiscriminator');
  const handlePersonLink = (): string => {
    if (discriminator === 'LegalPerson') {
      return `/pessoas-juridicas/todas/${personId}`;
    } else return `/pessoas-fisicas/todas/${personId}`;
  };

  const isNotPermissionIncludeBeneficiaryProduct = hasPermissionIncludeBeneficiaryProduct === false &&
    String(typeOfRelationshipValue).toLowerCase() === 'beneficiary' &&
    name !== 'relatedPersonCreditNote';

  const isCreditNoteTab: boolean = name === 'relatedPersonCreditNote';
  return (
    <Stack spacing={4}>
      <RefreshProgress refreshing={submitting} />
      <AutocompleteField
        name="personId"
        label="Envolvido"
        displayName="personIdDisplay"
        required
        endIconType="link"
        onChange={handlerInvolvedChange}
        link={handlePersonLink()}
        showEndIcon={personId}
        preserveLastResult
        {...personList!}
      />

      {isCreditNoteTab && (
        <AutocompleteField
          name="relatedToId"
          label="Relacionada a"
          displayName="relatedToIdDisplay"
          preserveLastResult
          {...personList!}
        />
      )}

      <SelectFormField
        name="typeOfRelationship"
        label="Tipo de relação"
        variant="outlined"
        options={sortToSelectOptionList(typeOfRelationship)}
        fullWidth
        required
        disabled={submitting}
      />

      {typeOfRelationshipValue === "Beneficiary" && isCreditNoteTab &&
        (creditProductData?.paymentFixedCosts > 0 || creditProductData?.insuranceCoverAmount > 0) && (
          <Grid>
            <PercentageFormField
              label="% de distribuição"
              name="percentage"
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}

      {discriminator !== 'LegalPerson' && !hasSignatories && <>
        <SelectFormField
          name="signatureType"
          label="Forma de Envio"
          variant="outlined"
          options={signatureType}
          fullWidth
          disabled={submitting}
        />
        <SelectFormField
          name="signatureValidation"
          label="Forma de Validação"
          variant="outlined"
          options={signatureValidation}
          fullWidth
          disabled={submitting}
        />
      </>}


      {hasSignatories &&
        <Stack spacing={2} direction='column' mt={2}>
          <Typography variant='lg'>Importar signatários</Typography>
          <AutocompleteField
            name="signatories"
            multiple
            label="Signatários"
            value={importedSignatories}
            onChange={handlerSignerChange}
            {...signatoriesAutocompleteList}
          />
        </Stack>
      }

      {!!isNotPermissionIncludeBeneficiaryProduct &&
        <Alert text="Você não possui permissão para inserir beneficiário em produtos de crédito." severity='warning'/>}

      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="flex-end"
        spacing={3}
      >
        <Button
          startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
          variant="outlined"
          size="medium"
          name='btn-close-involved-form'
          onClick={onClose}
          fullWidth={isMobile}
        >
          Fechar
        </Button>
        <Button
          name='btn-add-involved-form'
          type="submit"
          startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />}
          variant="contained"
          size="medium"
          disabled={(submitting || !!isNotPermissionIncludeBeneficiaryProduct)}
          fullWidth={isMobile}
        >
          Adicionar envolvido
        </Button>
      </Stack>
    </Stack>
  );
};
