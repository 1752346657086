import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, PersonIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

interface OperatorHeaderProps {
    isFetching: boolean;
    operatorListRefetch: () => void;
    setStepOperator: (step: string) => void;
}

const theme = activeTheme();
const OperatorHeader: React.FC<OperatorHeaderProps> = ({
    isFetching,
    operatorListRefetch,
    setStepOperator,
}) => {
    const { bankAccountId } = useParams();
    const { hasPermission } = useUserPermissionData();
    const queryClient = useQueryClient();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const hasPermissionFromReadPerson = hasPermission('Person', 'Read'); 

    async function operatorRefetch() {
        operatorListRefetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction="row" justifyContent="space-between" mb={3} mt={2} alignItems="center">
            <Typography variant="xxl" color="neutral.dark" lineHeight="38.4px">
                Operadores
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color, fontSize: 18 }}
                    variant="text"
                    size="medium"
                    onClick={operatorRefetch}
                    disabled={isFetching}
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                    color="primary"
                >
                    Atualizar
                </Button>
                {hasPermissionFromReadPerson &&
                    <Button
                        name="btn-add-operator"
                        sx={{ color, fontSize: 18 }}
                        startIcon={<PersonIcon htmlColor={color} sx={iconSx} />}
                        variant="text"
                        onClick={() => setStepOperator('bankAccountOperatorForm')}
                    >
                        Adicionar operador
                    </Button>}
            </Grid>
        </Stack>
    );
};
export default OperatorHeader;
