import { NotificationsCreateModel } from "./types/notificationsCreateModel";
import { NotificationsLastReadCreateModel } from "./types/notificationsLastReadCreateModel";
import { IMetadataNotifications, NotificationsReadModel } from "./types/notificationsReadModel";
import { GetListApiElasticSearch } from "contexts/apiRequestContext";
import { FiltersGetNotificationType } from "./types/generic";
import { searchAxiosApi } from "services/axiosApi/axiosApi";

const url = `/Notification`;

export const getNotificationsAsync = async (params: FiltersGetNotificationType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };

    return await searchAxiosApi.get<GetListApiElasticSearch<NotificationsReadModel, IMetadataNotifications>>(url, config);
}

export const getNotificationsSettingsAsync = async (token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await searchAxiosApi.get(`${url}/Settings`, config);
}

export const postNotificationSettingsAsync = async (payload: NotificationsCreateModel, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await searchAxiosApi.post(url, payload, config);
}

export const postNotificationSetLastRead = async (payload: NotificationsLastReadCreateModel, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await searchAxiosApi.post(`${url}/SetLastRead`, payload, config);
}
