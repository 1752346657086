import { Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import {
    defaultvaluesSiapeQueryForm,
    validationSchemaSiapeQueryForm,
} from './SiapeQueryFormSchema';
import { SiapeQueryForm } from './SiapeQueryForm';
import { FieldValues } from 'react-hook-form';
import { ISiapeMarginQueryFull, ResultMarginQuery } from 'services/datasets/datasets.interface';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { useSiapeMarginMutation, useSiapeMarginQuery } from 'contexts/datasetContext';
import { Alert, Drawer } from '@uy3/web-components';
import { SelectWarrantyContainer } from './SelectWarranty/SelectWarrantyContainer';
import { isValidCPF, onlyNumbers } from 'helpers/validations/formFieldValidator';

type SiapeQueryFormContainerProps = {
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const SiapeQueryFormContainer: React.FC<SiapeQueryFormContainerProps> = ({
    onClose,
    setToast,
}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const { getValues } = useFormContext();
    const [getRegistrationNumber, setGetRegistrationNumber] = useState<string>(
        getValues('registrationNumber') ?? ''
    );
    const registrationNumber = onlyNumbers(getRegistrationNumber!);
    const { data: marginQueryData, refetch } = useSiapeMarginQuery({ registrationNumber });

    const successMessage = 'Consulta de margem realizada com sucesso!';
    const onSuccess = (props: ISiapeMarginQueryFull[]) => {
        refetch();
        const response = props[0] as ISiapeMarginQueryFull;
        if (resultIncludeWarnningMessage(response.result)) return;
        showSuccessToast(successMessage, undefined, setToast);
    };

    const { mutateAsync: siapeMarginMutation, isLoading } = useSiapeMarginMutation(
        onSuccess,
        (error: ApiResponseError) => handleOnError(error, setToast)
    );

    const resultIncludeWarnningMessage = (result: ResultMarginQuery): boolean => {
        if (result.Nome === null || !result.DsRetCode.includes('realizado com sucesso')) {
            setToast({
                open: true,
                severity: 'info',
                title: result.DsRetCode,
                description: `Código de retorno: ${result.CdRetCode} - data consulta: ${result.DtOperacao}`,
            });
            return true;
        }
        return false;
    };

    const handleSubmit = (values: FieldValues) => {
        const registrationNumber = values?.registrationNumber?.replace(/\D/g, '');
        setGetRegistrationNumber(registrationNumber);
        refetch();
        setOpenDrawer(true);
    };

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultvaluesSiapeQueryForm}
                validationSchema={validationSchemaSiapeQueryForm()}
                onSubmit={handleSubmit}
                onChangeField={[
                    {
                        fieldName: 'registrationNumber',
                        delegate: (value: string) => {
                            if (isValidCPF(value)) {
                                setGetRegistrationNumber(value);
                            }
                        },
                    },
                ]}
            >
                <SiapeQueryForm
                    {...{
                        onClose,
                        isLoading,
                        registrationNumber,
                    }}
                />
            </FormProvider>

            <Drawer anchor="right" open={openDrawer} title="Selecione uma das margens disponíveis">
                <Stack spacing={2}>
                    {!marginQueryData && (
                        <Alert
                            text="Não há dados de margem disponíveis para esse CPF. Clique no botão 'Obter margem' para fazer a primeira consulta"
                            severity="info"
                        />
                    )}
                    <SelectWarrantyContainer
                        setToast={setToast}
                        marginQueryData={marginQueryData?.[0] as ISiapeMarginQueryFull}
                        onBackStep={() => setOpenDrawer(false)}
                        onCloseAll={onClose}
                        handleSiapeMarginQuery={async () =>
                            await siapeMarginMutation({ registrationNumber })
                        }
                    />
                </Stack>
            </Drawer>
        </Stack>
    );
};
