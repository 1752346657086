import { PersonFull } from 'contexts/personContext';
import { RelatedPersonFull } from 'services/creditProduct/types/creditProductReadModel';

type RedirectByLineType = PersonFull & RelatedPersonFull;

export const handleRedirectByLine = (row: RedirectByLineType) => {
    const discriminator = row?.personDiscriminator ?? row?.discriminator;
    const isNaturalPerson = discriminator === 'NaturalPerson';
    const pathLink = isNaturalPerson
        ? `/pessoas-fisicas/todas/${row?.personId}`
        : `/pessoas-juridicas/todas/${row?.personId}`;
    return pathLink;
};

export const isSupportedFileType = (fileType: string): boolean => {
    const supportedFileTypes = [
        'Draft',
        'RegistrationForm',
        'Assignment',
        'Addition',
        'FiduciaryAssignment',
        'Procuration',
        'TermOfOwnership',
        'Collateral',
        'PLDFT',
        'Receipt',
        'AssignmetReport',
    ];
    return supportedFileTypes.includes(fileType);
};

const TENANT_TYPE_ROOT = 0;
export const isRootTenancy = (tenantType: number): boolean => tenantType === TENANT_TYPE_ROOT;

export const isActionAllowed = (
    readOnly: boolean,
    hasPermissionCustom: boolean,
    rootTenancy: boolean
): boolean => !readOnly && hasPermissionCustom && rootTenancy;


type HasPermissionFunc = (permission: PermissionType, type: string) => boolean;
type PermissionType =
    | 'CreditNote'
    | 'CreditProduct'
    | 'BankAccountProduct'
    | 'Fund'
    | 'NaturalPerson'
    | 'LegalPerson'
    | 'BatchAssignment'
    | 'BankAccount'
    | 'BankAccountRequest';

// Mapeamento de nomes para tipos de permissão
const permissionMap: { [key: string]: PermissionType } = {
    CreditNote: 'CreditNote',
    CreditProduct: 'CreditProduct',
    BankAccountProduct: 'BankAccountProduct',
    Fund: 'Fund',
    NaturalPerson: 'NaturalPerson',
    LegalPerson: 'LegalPerson',
    BatchAssignment: 'BatchAssignment',
    BankAccount: 'BankAccount',
    BankAccountRequest: 'BankAccountRequest',
};

// Função genérica para verificar a permissão customizada
export const hasCustomPermission = (resource: PermissionType, typePermission: string, hasPermission: HasPermissionFunc): boolean => {
    const permissionType: PermissionType = permissionMap[resource]
    return hasPermission(permissionType, typePermission);
};

export const runSkeleton = (isLoading: boolean, filters: Record<string, any> | undefined): boolean => {
    return isLoading && Object.values(filters?.filters ?? {}).length === 0;
};

export function localPaginateDataTable<T>(array: T[], pageNumber: number, pageSize: number): T[] {
    const startIndex = (pageNumber) * pageSize;
    return array.slice(startIndex, startIndex + pageSize);
}


export const handlePersonLink = (personId: string, discriminator: string): string => {
    if (discriminator === 'LegalPerson') {
        return `/pessoas-juridicas/todas/${personId}`;
    } else return `/pessoas-fisicas/todas/${personId}`;
};
