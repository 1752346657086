/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer } from '@uy3/web-components';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, useApiRequest } from 'contexts/apiRequestContext';
import { useBankAccountBalance, useGetBankslipConsumptionBillOrTaxes } from 'contexts/bankAccount/bankAccountContext';
import { IMFAState, PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import moment from 'moment';
import ConfirmInformationDrawer from 'pages/BankAccount/BankAccountActive/BankAccountView/InformationTransferDataDrawer/ConfirmInformationDrawer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { postPayTaxWithBarCode } from 'services/accounts/bankAccount/bankAccount';
import { InfoConfirmPay } from '../InfoConfirmPay/InfoConfirmPay';
import { defaultValuesPaymentDetailsForm, validationPaymentDetailsSchema } from '../PaymentDetails/PaymentDetailsSchema';
import { validationBarCodeSchema, defaultValuesBarCode } from '../../barCodeSchema';
import { RefreshProgress } from 'components/RefreshProgress';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import BarCodeForm from '../BarCodeForm/BarCodeForm';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import DrawerMFA from '../../DrawerMFA/DrawerMFA';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';
import { StepsPayment } from 'services/accounts/bankAccount/bankAccount.types';

type TaxWithBarCodeFormContainerProps = {
   refetch: () => void;
   onCloseDrawer: () => void;
   setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const TaxWithBarCodeFormContainer: React.FC<TaxWithBarCodeFormContainerProps> = ({ refetch, onCloseDrawer, setToast }) => {
   const { bankAccountId } = useParams();
   const { token } = useIdentity();
   const [stepPayment, setStepPayment] = useState<StepsPayment>();
   const [formFieldValues, setFormFieldValues] = useState<any>();
   const [barCode, setBarCode] = useState<string | undefined>(undefined);
   const { setSubmitError, startRequest, endRequest } = useApiRequest();
   const [hideBtnClose, setHideBtnClose] = useState(true);
   
   const { bankSlipData, bankSlipStatus, bankSlipError, isFetching } = useGetBankslipConsumptionBillOrTaxes({ 
      bankAccountId: bankAccountId!, 
      barCodeOrDigitableLine: barCode 
   });

   const { data: dataBalance } = useBankAccountBalance(bankAccountId!);

   useEffect(() => {
      if (bankSlipStatus === 'error') {
         const { errorMessage } = mapErrorResponse(bankSlipError as ApiResponseError)
         const title = 'Ops, ocorreu um erro!';
         const description = errorMessage;
         showErrorToast(title, description, setToast);
      }

      // clean up
      return () => {
         setBarCode(undefined);
      }
   }, [bankSlipError]);

   const onClose = () => {
      setStepPayment(undefined);
      setSubmitError(undefined);
   };

   const onSubmitTaxtWithBarCode = (value: PaymentBankSlip) => {
      setHideBtnClose(false);
      let barCode = value.barCode?.replace(/\D/g, '');
      return setBarCode(barCode);
   };

   const onSubmitInformation = (value: any) => {
      setFormFieldValues({
         paymentDate: moment(value?.paymentDate).format(),
         paymentValue: value?.paymentValue
      });
      setStepPayment('confirmationPayment');
   };

   const { mutateGenerateSessionId, isLoading } = useGenerateSessionIdMutation();
   const onSubmitConfirmationMfa = async (values: IMFAState) => {
      startRequest();
      mutateGenerateSessionId({
         userPassword: values.password, then: (response: any) => {
            const data = {
               barCodeOrDigitableLine: barCode,
               paymentValue: formFieldValues?.paymentValue,
               principalValue: bankSlipData?.paymentValue,
               paymentDate: formFieldValues?.paymentDate,
               sessionId: response.data,
               code: values.code,
            };
            postPayTaxWithBarCode(bankAccountId!, data, token!).then(() => {
               endRequest(true);
               onClose();
               onCloseDrawer();
               refetch();
               const title = "Pagamento realizado com sucesso!";
               const description = 'Ótimo! Agora é só aguardar a confirmação do pagamento.';
               showSuccessToast(title, description, setToast);

            }).catch((response: ApiResponseError) => {
               const { description, open, title } = errorHandlingBankAccount(response);
               endRequest(false);
               setToast({ title , description , open, severity: 'error' });
            });
         }
      });
   };

   const paymentValue = formFieldValues?.paymentValue;
   const formStep: { [key: string]: JSX.Element } = {
      confirmationMfa: <DrawerMFA onSubmit={onSubmitConfirmationMfa} onClose={() => setStepPayment('confirmationPayment')} toggleDrawer={true}/>,
      confirmationPayment: (
         <ConfirmInformationDrawer
            open={true}
            onClose={onClose}
            setStep={setStepPayment}
            dataBalance={dataBalance}
            valueOfOperation={paymentValue ?? bankSlipData?.value}
            CardInfoComponent={
               <InfoConfirmPay bankSlipData={bankSlipData} />
            }
         />
      ),
   };

   const currentStep = formStep[stepPayment!];
   const open = stepPayment === 'confirmationPayment' || stepPayment === 'confirmationMfa';
   const title = stepPayment === 'confirmationPayment' ? 'Resumo da operação' : 'Confirmar operação';

   return (
      <>
         <FormProvider
            validationSchema={validationBarCodeSchema('lengthTributes')}
            defaultValues={defaultValuesBarCode}
            onSubmit={onSubmitTaxtWithBarCode}
         >
            <BarCodeForm onClose={onCloseDrawer} hideBtnClose={hideBtnClose} isFetching={isFetching} />
         </FormProvider>

         {bankSlipData !== undefined &&
            <FormProvider
               validationSchema={validationPaymentDetailsSchema()}
               defaultValues={defaultValuesPaymentDetailsForm({ paymentValue: bankSlipData?.totalValue })}
               onSubmit={onSubmitInformation}
            >
               <PaymentDetails
                  bankSlipData={bankSlipData}
                  onCloseDrawer={onCloseDrawer}
               />
            </FormProvider>
         }

         <Drawer anchor="right" title={title} open={open} onClose={onClose} toggleDrawer>
            {currentStep}
         </Drawer>

         <RefreshProgress refreshing={isFetching || isLoading} />
      </>
   )
}
