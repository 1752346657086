import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, EditIcon, Typography } from '@uy3/web-components';
import { RenderColumnStatusBilling } from 'components/RenderColumnStatusBilling/RenderColumnStatusBilling';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { localPaginateDataTable } from 'helpers/methods/GenericMethods';
import { useNavigate } from 'react-router';

type InsatallmentBillingsListProps = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
};

export const InsatallmentBillingsList = ({
    queryData,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage
}: InsatallmentBillingsListProps) => {
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'installmentNumber',
            headerName: 'Parcela',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => value,
        },
        {
            field: 'dueDate',
            headerName: 'Data de vencimento',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: (cellValue) => new Date(cellValue?.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'documentNumber',
            headerName: 'Número do documento',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D",
        },
        {
            field: 'amount',
            headerName: 'Valor da cobrança',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => formatCurrencyInCents(value),
        },
        {
            field: 'discount',
            headerName: 'Desconto',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => formatCurrencyInCents(value),
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            align: "center",
            headerAlign: 'center',
            renderCell: ({ value, id, row: { statusValue } }) => {
                return <RenderColumnStatusBilling {...{
                    onClick: () => navigate(`/gestao-carteira/cobrancas/${id}`),
                    statusDisplay: value!,
                    statusValue: statusValue!,
                    resource: 'BillingList'
                }} />
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Stack
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <RowActions
                            listButtons={[
                                {
                                    action: () => navigate(`/gestao-carteira/cobrancas/${row?.billingId}`),
                                    label: "Ver cobrança",
                                    disabled: false,
                                    hide: false,
                                    icon: <EditIcon sx={iconSx} />
                                }
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];
    return (
        <Stack spacing={2} pr={2}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="25px"
                lineHeight="38.4px"
            >
                Cobranças do parcelamento
                <Typography
                    variant="h6"
                    color="neutral.medium"
                    sx={{
                        lineHeight: { xs: '20px', md: '20px' },
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        opacity: '87%',
                    }}
                >
                    Confira a lista de cobranças referente ao parcelamento
                </Typography>
            </Typography>            <DataTable
                columns={columns}
                rows={localPaginateDataTable(queryData, page, rowsPerPage)}
                page={page}
                rowCount={queryData?.length}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                getRowId={(row) => row?.billingId}
                enableJumpAction={false}
            />
        </Stack>
    )
}
