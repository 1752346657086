import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, isAxiosError } from 'axios';
import { useIdentity } from 'contexts/identityContext';
import {
    getBankAccountBeneficiariesList,
    postBankAccountStatementExportPfd,
    getBankAccountOperatorList,
    getBankAccountStatement,
    getBankSlipData,
    getPaymentsList,
    getTransfersList,
    postBankAccountStatementSpreadsheet,
    postPaymentreceipt,
    postTransferreceipt,
    getBankAccountOperatorsPeding,
    getBankSlipFGTS,
    getBankSlipDARF,
    postBankAccountCnab240,
    getBankAccountLimits,
    putCancelPayment,
    getBankAccountList,
    getBankslipConsumptionBillOrTaxes,
    getBankAccountCreditorId,
    getBankAccountPixQRCode,
    postBankAccountPixQRCodeStatic,
    postBankAccountPixQRCodeImmediateDynamic,
    approveOrRejectBankAccountByResource,
    postCreateCreditor,
    deleteCreditorByBankAccount,
    terminateAsync,
} from 'services/accounts/bankAccount/bankAccount';
import {
    ApiResponseError,
    GetViewApiResponse,
    GetViewApiResponseSuccess,
    useApiRequest,
} from '../apiRequestContext';
import { useAppConfig } from '../appConfig';
import {
    FiltersBeneficiaries,
    FiltersTableTransfer,
    StatementQueryFiltersType,
} from './bankAccountType';
import moment from 'moment';
import { useTenant } from 'contexts/tenantContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import {
    ActionApproveOrRejectType,
    CancelPaymentType,
    GetPixQRcodeType,
    OperatorListFilters,
    OperatorsPedingFilters,
    ParamsAndFiltersStatement,
    PaymentListFilters,
    PixQRcodeImmediateDynamic,
    PixQRcodeStatic,
} from 'services/accounts/bankAccount/bankAccount.types';
import { isEmpty } from 'lodash';
import { paymentRevisionCreditNoteById } from 'services/creditNote';
import { DonePaymentRevision } from 'services/creditNote/types/creditNoteReadModel';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { FieldValues } from 'react-hook-form';

const apiPath = '/BankAccount';
type BankSlipDataType = {
    bankAccountId: string;
    barCode: string | undefined;
};
type BankSlipDataConsumptionBillOrTaxesType = {
    bankAccountId: string;
    barCodeOrDigitableLine: string | undefined;
};

export interface IBalanceType {
    currentBalance: number;
    available: number;
    bankAccount: IBankAccountFromBalance;
}

export interface IBankAccountFromBalance {
    bankCode: number;
    bankIspb: number;
    bankCodeDisplay: string;
    type: number;
    typeDisplay: string;
    modality: number;
    modalityDisplay: string;
    account: string;
    accountDigit: string;
    agency: string;
    agencyDigit: string;
    personRegistrationNumber: string;
    personName: string;
    creditors: ICreditor[];
}

export interface ICreditor {
    id: string;
    name: string;
    tenant: string;
}

type QueryGetQrCodeType = {
    bankAccountId: string;
    qrCode?: string;
    onSuccess: () => void;
    onError: (error: ApiResponseError) => void;
};

type BankAccountLisProps =
    | {
          page: number;
          size: number;
          searchString?: string;
          f_personRegistrationNumber?: string;
          f_personName?: string;
      }
    | undefined;

export function useBankAccountListData(filters: BankAccountLisProps, getSaldo = false) {
    const { currentTenantId } = useTenant();
    const { filterValues } = useFilterValues();
    const filtersComplete = {
        ...filters,
        ...filterValues.filters,
        tenant: currentTenantId,
        getSaldo,
    };
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-accounts-list', filtersComplete],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getBankAccountList(filtersComplete, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data;
        },
    });
}

export function useBankAccountBalance(bankAccountId: string) {
    const { appConfig } = useAppConfig();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-balance', bankAccountId],
        queryFn: async (): Promise<IBalanceType> => {
            startRequest();
            const { data, status, statusText } = await axios.get(
                `${appConfig.CREDIT_API_URL}${apiPath}/${bankAccountId}/Balance`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as IBalanceType;
        },
    });
}

export function useBankAccountTransferOrPixList(accountId: string, filters?: FiltersTableTransfer) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const filtersComplete = { ...filterValues.filters, ...filters! };
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-transfer-list', accountId, filtersComplete, filterValues],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getTransfersList(
                accountId,
                filtersComplete,
                token!
            );
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return { status, data, error, isFetching, refetch };
}

export function useBankAccountBeneficiariesList(accountId: string, filters: FiltersBeneficiaries) {
    const { filterValues } = useFilterValues();
    const filtersComplete = { ...filterValues.filters, ...filters };
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-beneficiaries-list', filtersComplete, accountId],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankAccountBeneficiariesList(
                accountId,
                filtersComplete,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        beneficiarieStatus: status,
        beneficiarieData: data,
        beneficiarieError: error,
        isFetching,
        refetch,
        isLoading,
    };
}

export function useBankAccountStatement(accountId: string, filters: StatementQueryFiltersType) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const filterDate =
        Object.values(filterValues.filters).length > 0 ? filterValues.filters : filters;

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-statement', filterValues],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankAccountStatement(
                accountId,
                filterDate,
                token
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return { status, data, error, isFetching, refetch };
}

export function useBankAccountOperatorsPeding(filters: OperatorsPedingFilters) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues } = useFilterValues();
    const { currentTenantId } = useTenant();
    const recordType = isEmpty(filterValues.recordType)
        ? 'ViewPendingOperators'
        : filterValues.recordType;
    const listDataFilters = recordType === 'ViewPendingOperators' && { ...filterValues.filters };
    const filtersComplete = { ...filters, ...listDataFilters, tenant: currentTenantId };

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-operators-peding', filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data, statusText, status } = await getBankAccountOperatorsPeding(
                filtersComplete,
                token
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

			endRequest(true);
			return data as GetViewApiResponseSuccess<any>;
		}
	});

    return { status, data, error, isFetching, refetch, isLoading };
}

export function useBankSlipData({ bankAccountId, barCode }: BankSlipDataType) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError, isLoading } = useQuery({
        enabled: !!token && !!barCode,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-bank-slip', barCode],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankSlipData(
                bankAccountId,
                barCode,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        bankSlipStatus: status,
        bankSlipData: data,
        bankSlipError: error,
        isFetching,
        refetch,
        isError,
        isLoading,
    };
}

export function useGetBankslipConsumptionBillOrTaxes({
    bankAccountId,
    barCodeOrDigitableLine,
}: BankSlipDataConsumptionBillOrTaxesType) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError, isLoading } = useQuery({
        enabled: !!token && !!barCodeOrDigitableLine,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-consumption-bill-or-taxes', barCodeOrDigitableLine],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankslipConsumptionBillOrTaxes(
                bankAccountId,
                barCodeOrDigitableLine,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        bankSlipStatus: status,
        bankSlipData: data,
        bankSlipError: error,
        isFetching,
        refetch,
        isError,
        isLoading,
    };
}

export function useBankSlpFGTS(bankAccountId: string, barCode: number) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-bank-slip', barCode],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankSlipFGTS(
                bankAccountId,
                barCode,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        bankSlipFGTSStatus: status,
        bankSlipFGTSData: data,
        bankSlipFGTSError: error,
        isFetching,
        refetch,
    };
}

export function usePaymentList(bankAccountId: string, filters: PaymentListFilters) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const filtersComplete = { ...filterValues.filters, ...filters };
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-payment-list', filtersComplete, bankAccountId],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getPaymentsList(
                bankAccountId,
                filtersComplete,
                token!
            );
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        paymentListStatus: status,
        paymentListData: data,
        paymentListError: error,
        isFetching,
        paymentRefetch: refetch,
    };
}
export function useDarfWithBarCode(bankAccountId: string, filters: any) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && (!!filters.digitableLine || !!filters?.barCode),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-darf-with-barcode', filters],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankSlipDARF(
                bankAccountId,
                filters,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        darfWithBarCodeStatus: status,
        darfWithBarCodeData: data,
        darfWithBarCodeError: error,
        isFetching,
        paymentRefetch: refetch,
    };
}

export function useBankAccountOperatorList(
    bankAccountId: string | undefined,
    filters: OperatorListFilters
) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const filtersComplete = { ...filters, ...filterValues.filters };
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && !!bankAccountId,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-oparator-list', filtersComplete, bankAccountId],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankAccountOperatorList(
                bankAccountId!,
                filtersComplete,
                token!
            );
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        operatorListStatus: status,
        operatorListData: data,
        operatorListError: error,
        isFetching,
        operatorListRefetch: refetch,
    };
}

export function useBankAccountLimitsList(bankAccountId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!bankAccountId,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bank-account-limits-list', bankAccountId],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, statusText, status } = await getBankAccountLimits(bankAccountId, token!);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        limitsListStatus: status,
        limitsListData: data,
        limitsListError: error,
        isFetching,
        limitsListRefetch: refetch,
        isLoading,
    };
}
export function useGetBankAccountPixQRCode({
    bankAccountId,
    onError,
    onSuccess,
    qrCode,
}: QueryGetQrCodeType) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token && !!bankAccountId && !!qrCode && !isEmpty(qrCode),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        onError: (error: ApiResponseError) => {
            qrCode = undefined;
            onError(error);
        },
        onSuccess,
        refetchOnWindowFocus: false,
        queryKey: ['get-bank-account-pix-QRCode', bankAccountId, qrCode],
        queryFn: async (): Promise<GetPixQRcodeType> => {
            startRequest();
            const { data, statusText, status } = await getBankAccountPixQRCode(
                bankAccountId,
                token!,
                qrCode
            );
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as GetPixQRcodeType;
        },
    });
}

export function useBankAccountStatementMutation(
    paramsfilters: ParamsAndFiltersStatement,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (): Promise<any> => {
            startRequest();
            return await postBankAccountStatementExportPfd(paramsfilters)
                .then((result: any) => {
                    endRequest(true);
                    const file = new Blob([result.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                })
                .catch((error: ApiResponseError) => {
                    setSubmitError(error);
                    endRequest(false);
                });
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateStatementExportPdf: mutate, isLoading };
}

export function useBankAccountStatementSpreadsheetMutation(
    paramsfilters: ParamsAndFiltersStatement,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (): Promise<any> => {
            startRequest();
            return await postBankAccountStatementSpreadsheet(paramsfilters)
                .then((result: any) => {
                    endRequest(true);
                    const file = new Blob([result.data], { type: 'application/csv' });
                    const fileURL = URL.createObjectURL(file);
                    const path = document.createElement('a');
                    path.href = URL.createObjectURL(file);
                    path.download = `${fileURL.replace(`blob:${window.origin}/`, '')}.csv`;
                    path.click();
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                    endRequest(false);
                });
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateStatementSpreadsheet: mutate, isLoading };
}

export function useBankAccountCnab240Mutation(
    paramsfilters: ParamsAndFiltersStatement,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { bankAccountId, finalDate, initialDate, token } = paramsfilters;
    const { mutate, isLoading } = useMutation({
        mutationFn: async (): Promise<any> => {
            startRequest();
            return await postBankAccountCnab240(
                bankAccountId!,
                moment(initialDate).format(),
                moment(finalDate).format(),
                token!
            )
                .then((result: any) => {
                    endRequest(true);
                    const url = result?.data?.tempUrl;
                    return window.open(url, '_Blank');
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                    endRequest(false);
                });
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateCnab240: mutate, isLoading };
}

export function useBankAccountPaymentReceiptMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: any): Promise<any> => {
            startRequest();
            return await postPaymentreceipt(bankAccountId, values?.id, token!)
                .then((result: any) => {
                    endRequest(true);
                    const file = new Blob([result?.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                    endRequest(false);
                });
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateTransferAndPayment: mutate, isLoading };
}

export function usePostBankAccountPixQRCodeStaticMutation(
    bankAccountId: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (payload: PixQRcodeStatic): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await postBankAccountPixQRCodeStatic({
                bankAccountId,
                payload,
                token: token!,
            });

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
    return { mutateAsync, isLoading };
}

export function usePostBankAccountPixQRCodeImmediateDynamic(
    bankAccountId: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (payload: PixQRcodeImmediateDynamic): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await postBankAccountPixQRCodeImmediateDynamic({
                bankAccountId,
                payload,
                token: token!,
            });

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
    return { mutateAsync, isLoading };
}

export function useBankAccountPaymentApproveMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutate, isLoading } = useMutation({
        mutationFn: async ({
            bankAccountId,
            resourceId,
            ...values
        }: ActionApproveOrRejectType & {
            resourceId: string;
            bankAccountId: string;
        }): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await approveOrRejectBankAccountByResource(
                bankAccountId,
                resourceId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateApprovePayment: mutate, isLoading };
}

export function useBankAccountCancelPaymentMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: CancelPaymentType): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await putCancelPayment(
                bankAccountId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateCancelPayment: mutate, isLoading };
}

export function useBankAccountTransferReceiptMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: any): Promise<any> => {
            startRequest();
            return await postTransferreceipt(bankAccountId, values?.id, token!)
                .then((result: any) => {
                    endRequest(true);
                    const file = new Blob([result?.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                })
                .catch((response: ApiResponseError) => {
                    setSubmitError(response);
                    endRequest(false);
                });
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateTransferAndPayment: mutate, isLoading };
}

export function useDonePaymentRevisionMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: DonePaymentRevision): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await paymentRevisionCreditNoteById(
                id,
                values,
                token!
            );

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateDoneRevision: mutate, isLoading };
}

export function useCreateCreditorMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (personId: string): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await postCreateCreditor(
                bankAccountId,
                personId,
                token!
            );

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mudateCreateCreditorAsync: mutateAsync, isLoading };
}

export function useDeleteCreditorMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (personId: string): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await deleteCreditorByBankAccount(
                bankAccountId,
                personId,
                token!
            );

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateDeleteCreditorAsync: mutateAsync, isLoading };
}

export function useBankAccountRequestCreditor(bankAccountId: string, creditorId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!bankAccountId && !!creditorId,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['bankAccountRequest-Creditor', bankAccountId],
        queryFn: async (): Promise<GetViewApiResponse<any>> => {
            startRequest();
            const { data, status, statusText } = await getBankAccountCreditorId(
                bankAccountId,
                creditorId,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
    });

    return {
        statusCreditor: status,
        bankAccountRequestCreditor: data as GetViewApiResponseSuccess<any>,
        errorCreditor: error,
        isLoading,
        isFetching,
        refetch,
    };
}

export function useTerminateMutation(
    bankAccountId: string,
    onSuccess: (data: string) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (value: FieldValues) => {
            startRequest();
            const { data, status, statusText } = await terminateAsync(bankAccountId, value, token);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as any;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
}

export type BankAccount = {
    id: string;
    person: any;
    agency: string;
    account: string;
    modalityDisplay: string;
};

export type BankAccountDetails = {
    approvals: [any];
    transfer: [any];
} & BankAccount;
