import { FieldValues } from 'react-hook-form';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { FraudRecordFull } from './fraudRecord.interface';

export async function postFraudRecordAsync(payload: FieldValues, personId?: string, token = '') {
    const url = `/ClearSaleR6/RegisterFraud?personId=${personId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post<FraudRecordFull>(url, payload, axiosConfig);
}
