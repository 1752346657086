import { useMutation, useQuery } from '@tanstack/react-query';
import { useIdentity } from './identityContext';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from './apiRequestContext';
import { AxiosError, isAxiosError } from 'axios';
import { useTenant } from './tenantContext';
import {
    ProductAccFiltersProps,
    editOrCreateBankAccountProduct,
    getProductAccById,
    getBankAccountProductList,
} from 'services/bankAccountProduct';
import { BankAccountProductFormSchema } from 'pages/Product/BankAccountProduct/BankAccountProductForm/BankAccountProductSchema';
import { useState } from 'react';
import { useFilterValues } from './filterValuesContext';
import { useUserPermissionData } from './userContext';
import { isEmpty } from 'lodash';
import { BankAccountProductReadModel } from 'services/bankAccountProduct/types/productReadModel';

export function useProductAccountData(productId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BankAccountProduct', 'Read');

    const { status, data, error, isFetching, ...rest } = useQuery({
        enabled: !!token && productId !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['product-acc', productId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getProductAccById(
                productId,
                token,
                currentTenantId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        productAccStatus: status,
        productAccData: data,
        productAccErr: error as ApiResponseError,
        productAccFetch: isFetching,
        ...rest,
    };
}

export function useBankAccountProductsList(
    filters: ProductAccFiltersProps,
    refetchOnMount?: IRefetchOnMountModeGetQuery
) {
    const [bankAccountProductList, setBankAccountProductList] = useState<
        { label: string; value: string }[]
    >([]);
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const recordType = isEmpty(filterValues.recordType)
        ? 'BankAccountProduct'
        : filterValues.recordType;
    const listDataFilters = recordType === 'BankAccountProduct' && { ...filterValues.filters };
    const isAlways: boolean = refetchOnMount === 'always';
    const filtersParams = isAlways ? { ...filters, size: 10, tenant: currentTenantId } : filters; // it's a specific case
    const filtersComplete = { ...filtersParams, ...listDataFilters, tenant: currentTenantId };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BankAccountProduct', 'Read');

    const queryContextBankAccountProduct = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['products-account-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<BankAccountProductReadModel>> => {
            startRequest();
            const resp = await getBankAccountProductList(filtersComplete, token);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data as GetListApiResponseSuccess<BankAccountProductReadModel>;
            const options = dataSuccess.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setBankAccountProductList(options);

            return dataSuccess;
        },
    });

    const bankAccountProductAutoCompleteProps = {
        listOptions: bankAccountProductList,
        loading: !hasReadPermission
            ? false
            : queryContextBankAccountProduct.isLoading || queryContextBankAccountProduct.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'BankAccountProduct'),
    };
    return { ...queryContextBankAccountProduct, bankAccountProductAutoCompleteProps };
}

export function useProductAccMutation(
    productId: string,
    onSuccess?: (data: BankAccountProductReadModel) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: BankAccountProductFormSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateBankAccountProduct(
                values,
                productId,
                token,
                currentTenantId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
}
