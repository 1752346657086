import React from 'react';
import { Stack } from '@mui/material';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { taxPayerList } from '../Options';
import { useFormContext } from 'contexts/formContext';
import TagField from 'components/Forms/FormFields/TagField/TagField';

interface DetailsBaseFormProps {
    prefix: string;
    disabled?: boolean;
}

const DetailsBaseForm: React.FC<DetailsBaseFormProps> = ({ prefix, disabled }) => {
    const { watch } = useFormContext();

    const documentType = watch(`${prefix}.taxPayer`);
    const isCNPJ = documentType === 'PJ';

    return (
        <Stack spacing={3}>
            <SelectFormField
                name={`${prefix}.taxPayer`}
                variant="outlined"
                label="Contribuinte"
                fullWidth
                required
                options={taxPayerList}
                disabled={disabled}
            />
            <TextFormField
                name={`${prefix}.documentNumber`}
                variant="outlined"
                label={`Número do Documento ${isCNPJ ? '(CNPJ)' : '(CPF)'}`}
                fullWidth
                required
                disabled={disabled}
            />
            <TextFormField
                name={`${prefix}.fullName`}
                variant="outlined"
                label="Nome Completo"
                fullWidth
                required
                disabled={disabled}
            />
            {isCNPJ && (
                <TextFormField
                    name={`${prefix}.tradeName`}
                    variant="outlined"
                    label="Nome Fantasia da empresa"
                    fullWidth
                />
            )}
            {isCNPJ && (
                <TagField
                    name={`${prefix}.cpfLegalRepresentatives`}
                    label="Representantes Legais (CPF)"
                    placeholder='Digite o CPF do representante legal'
                />
            )}
            <TextFormField
                name={`${prefix}.email`}
                variant="outlined"
                label="Email"
                fullWidth
            />
            <TextFormField
                name={`${prefix}.phoneCountryCode`}
                variant="outlined"
                label="Código do País (Telefone)"
                fullWidth
            />
            <TextFormField
                name={`${prefix}.phoneAreaCode`}
                variant="outlined"
                label="Código de Área (DDD)"
                fullWidth
            />
            <TextFormField
                name={`${prefix}.phoneNumber`}
                variant="outlined"
                label="Número do Telefone"
                fullWidth
            />
            <TextFormField
                name={`${prefix}.phoneFullValue`}
                variant="outlined"
                label="Número Completo do Telefone"
            />
        </Stack>
    );
};

export default DetailsBaseForm;
