/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { CheckboxFormField, DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { useFormContext } from 'contexts/formContext';
import { useFieldArray } from 'react-hook-form';
import { CommissionFormCostsAccordionMetadataKey } from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { useEffect } from 'react';
import { Alert } from '@uy3/web-components';
import { BillingsListCreateForm } from './BillingsListCreateForm';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { isValidString } from 'helpers/formats/StringFormats';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import moment from 'moment';
import { IBillingToInstallment } from 'services/walletManagement/installment/installment.types';
import { ToastType } from 'contexts/apiRequestContext';


type BillingInstallmentCreateFormType = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const BillingInstallmentCreateForm = ({ setToast }: BillingInstallmentCreateFormType) => {
    const { control, setValue, watch, validationErrors } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name: 'billings' });
    const numberInstallment = Number(watch('numberInstallment'));
    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);
    const hasErrorValidations: boolean = (validationErrors?.['billings'] as unknown as any[])?.length >= 1;

    const [
        documentNumber,
        period,
        dueDate,
        amount
    ] = watch(['documentNumber', 'period', 'dueDate', "amount"]);

    const billlingsValues = (watch('billings') as IBillingToInstallment[]) ?? [];

    const appendDueDate = (date: string, index: number) => {
        switch (String(watch('period')).toLowerCase()) {
            case "month":
                return moment(date).add(index, 'month').format('YYYY-MM-DDTHH:mm:ssZ');
            case "fifteendays":
                return moment(date).add(15 * index, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
            default:
                return date;
        }
    };

    useEffect(() => {
        const currentValue = Number(watch('numberInstallment'));
        const defaultValuesInArray = Array.from({ length: currentValue }, () => (
            {
                dueDate: null,
                documentNumber,
                amount
            }
        )) as unknown as Partial<IBillingToInstallment>[];

        const formFields = defaultValuesInArray.map((x, index) => {
            const dueDateFormated = index === 0 ? dueDate : appendDueDate(toIsoStringWithTimezone(new Date(dueDate)), index)
            return {
                ...x,
                dueDate: dueDateFormated,
                documentNumber: `${x.documentNumber}-${index + 1}`, 
                amount: parseInt((Number(x?.amount) / numberInstallment)?.toString())
            }
        });

        remove();
        append([...formFields]);
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length + currentValue);
    }, [numberInstallment, documentNumber, period, dueDate, amount]);

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
        setValue('numberInstallment', billlingsValues?.length - 1)
    };

    const optionsPeriod = [
        { label: `Mensal`, value: "month" },
        { label: `Quinzenal`, value: "fifTeenDays" }
    ];

    return (
        <Stack spacing={3} mt={1} mb={3}>
            <CustomTitle title="Valor e parcela da cobrança" variant="lg" />

            <Grid>
                <CurrencyFormField
                    variant='outlined'
                    label="R$"
                    name='amount'
                    required
                    fullWidth
                />
            </Grid>

            <Grid xs={3}>
                <TextFormField
                    label="Em quantas vezes deseja parcelar a cobrança?"
                    name="numberInstallment"
                    variant="outlined"
                />
            </Grid>

            <CustomTitle title="Configurações de parcelas" variant="lg" />

            <Grid xs={3}>
                <TextFormField
                    label="Número do documento"
                    name="documentNumber"
                    variant="outlined"
                />
            </Grid>

            <Grid xs={3}>
                <SelectFormField
                    label="Período de pagamento"
                    name="period"
                    variant="outlined"
                    required
                    fullWidth
                    options={optionsPeriod}
                />
            </Grid>
            <Grid xs={3}>
                <DatePickerFormField
                    label="Data de vencimento da 1º parcela"
                    name="dueDate"
                    required
                    disablePast
                    fullWidth
                />
            </Grid>

            <CheckboxFormField
                name='regiserInstallmentDue'
                label='Registrar parcelas vencidas?'
            />

            {
                isValidString(String(numberInstallment)) &&
                isValidString(String(documentNumber)) &&
                isValidString(String(period)) &&
                isValidString(String(amount)) &&
                isValidString(dueDate) &&
                fields.length > 0 &&
                <Stack spacing={2}>
                    <CustomTitle title="Parcelas" variant="lg" />
                    <BillingsListCreateForm fields={fields} remove={removeComm} />
                </Stack>}

            {hasErrorValidations &&
                <Alert severity='warning' text='Certifique-se de que os campos do formulário estão preenchidos.' />}


            <HorizontalInfo
                type='currency'
                hasInstallmentValue={!!isValidString(String(numberInstallment)) && numberInstallment !== 0}
                numberInstallment={numberInstallment}
                value={amount}
                title='Valor total da cobrança'
            />
        </Stack>
    );
};
