/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider } from 'contexts/formContext';
import { useEffect, useState } from 'react';
import SelectColumns from './SelectColumns';
import { Modal } from '@uy3/web-components';
import { selectColumnsSchema, defaultValuesSelectColumnsSchema } from './SelectColumnsSchema';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import { ToastType, showSuccessToast, showWarningToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';

type SelectColumnsContainerProps = {
    onClose: () => void;
    open: boolean;
    recordType: string;
};

const SelectColumnsContainer = ({ onClose, open, recordType }: SelectColumnsContainerProps) => {
    const [toast, setToast] = useState<ToastType>(toastState);

    const {
        setSelectedColumns,
        selectedColumns,
        setLocalConfigKey,
        localConfigKey,
        columns,
        defaultValues: defaultValuesColumns,
    } = useSelectColumns();

    useEffect(() => setLocalConfigKey(recordType), [recordType]);

    const options = columns
        ?.filter((item) => item.field !== 'actions')
        ?.map((column: any) => ({
            label: column.headerName,
            value: column.field,
        }));

    const optionsListAutocomplete = {
        listOptions: options || [],
        loading: false,
        onSearch: () => { }
    };

    const onSubmit = (values: any) => {
        if (selectedColumns.length === 0) {
            showWarningToast(
                'Hmmm, parece que você não selecionou nenhuma coluna.',
                'Por favor, selecione ao menos 1 coluna.',
                setToast
            );
            return;
        }

        if (!!localConfigKey) {
            localStorage.setItem(localConfigKey, JSON.stringify(selectedColumns));
            showSuccessToast(
                'Filtros adicionados com sucesso.',
                'Ótimo! Agora você pode visualizar o registro filtrado.',
                setToast
            );
        }
        onClose();
    };

    const cancelAndClearFilters = () => {
        localStorage.removeItem(localConfigKey!);
        showSuccessToast(
            'Filtros removidos com sucesso.',
            'Ótimo! Agora você pode visualizar o registro sem filtros.',
            setToast
        );

        const newSelectedColumns = defaultValuesColumns.length > 0 ? defaultValuesColumns : [];
        setSelectedColumns(newSelectedColumns);

        onClose();
    };

    const defaultSelectedValues = { optionsSelected: selectedColumns };

    const defaultValues = selectedColumns.length > 0 ? defaultSelectedValues : defaultValuesSelectColumnsSchema;
    const schema = selectColumnsSchema();

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Modal
                open={open}
                onClose={onClose}
                sizeModal="large"
                children={
                    <FormProvider
                        defaultValues={defaultValues}
                        validationSchema={schema}
                        onSubmit={onSubmit}
                    >
                        <SelectColumns
                            options={optionsListAutocomplete}
                            cancelAndClearFilters={() => cancelAndClearFilters()}
                        />
                    </FormProvider>
                }
            />
        </>
    );
};

export default SelectColumnsContainer;
