import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { validationSchemaReprocessByStatusForm, defaultValuesReprocessByStatus } from './ReprocessByStatusSchema'
import { Stack } from '@mui/material'
import { SelectFormField } from 'components/Forms/FormFields'
import { Button, CloseIcon, RightIcon } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import { activeTheme } from 'services/theme'

type ReprocessByStatusContainerProps = {
    onClose: () => void
    handleSubmit: (creditNoteSequence: string) => void 
}

const theme = activeTheme();

export const ReprocessByStatusContainer = ({ onClose, handleSubmit }: ReprocessByStatusContainerProps) => {
    return (
        <FormProvider
            validationSchema={validationSchemaReprocessByStatusForm()}
            defaultValues={defaultValuesReprocessByStatus}
            onSubmit={(values) => handleSubmit(values?.creditNoteSequence)}
        >
            <Stack spacing={4} mt={3}>
                <SelectFormField
                    label='Etapa da operação'
                    name='creditNoteSequence'
                    fullWidth
                    options={[
                        { label: "Todos", value: "All" },
                        { label: "Assinatura", value: "Signature" },
                        { label: "Garantia", value: "Warranty" },
                        { label: "Liquidação", value: "Liquidation" },
                    ]}
                />

                <Stack direction='row' alignItems='center' justifyContent='right' spacing={2}>
                    <Button
                        startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                        variant="outlined"
                        size="medium"
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<RightIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />}
                        variant="contained"
                        size="medium"
                    >
                        Confirmar
                    </Button>
                </Stack>
            </Stack>
        </FormProvider>
    )
}
