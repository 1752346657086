import { Box, Button as MuiButton } from '@mui/material';
import {
    DataTable,
    EditIcon,
    ShowIcon,
    DeleteIcon,
    MoreOptionsIcon,
    CompleteIcon,
    ReportIcon,
    Typography,
    CloseIcon,
    PersonIcon,
    UpdateIcon,
} from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { UploadGet, iconSx, invalidateAndRefetch } from 'contexts/apiRequestContext';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { EnumItem } from 'contexts/enumContext';
import { useFormContext } from 'contexts/formContext';
import { RowActions } from 'components/RowActions/RowActions';
import { activeTheme } from 'services/theme';
import { useLocation } from 'react-router';
import StatusSignature from '../DrawerWrapper/Signature/StatusSignature/StatusSignature';
import SignersLength from '../DrawerWrapper/Signature/SignersLength/SignersLength';
import { isSupportedFileType } from 'helpers/methods/GenericMethods';
import { isEmpty } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

type DocsProps = {
    uploads: UploadGet[];
    name?: string;
    titleHeader?: string;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    setOpenDrawer: (type: string, rowData?: GridValidRowModel) => void;
    setSelectedUploadIndex: (rowIndex?: number | undefined) => void;
    page: number;
    onDelete: (rowIndex: number) => void;
    typeOfDocument: EnumItem[];
    enableAction: boolean;
    statusSignature?: string;
};

const buttonSx = { height: 20, width: 20, ml: 0.5, mr: 0.5 };
const theme = activeTheme();
const UploadWithRelatedList = ({
    titleHeader = 'Documentos',
    rowsPerPage,
    setRowsPerPage,
    setPage,
    setSelectedUploadIndex,
    page,
    onDelete,
    typeOfDocument,
    uploads,
    name,
    setOpenDrawer,
    enableAction,
    statusSignature,
}: DocsProps) => {
    const { watch } = useFormContext();
    const { pathname } = useLocation();
    const isCreditNoteMenu = pathname.includes('/ccb/operacoes');
    const queryClient = useQueryClient();

    const columnsDocumentList = [
        {
            field: 'displayName',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues?.value}
                    </div>
                );
            },
        },
        {
            field: 'fileType',
            headerName: 'Tipo de documento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues?.value &&
                            typeOfDocument?.find((file) => file.value === cellValues?.value)?.label}
                    </div>
                );
            },
        },
        titleHeader === 'Documentos da operação'
            ? {
                field: 'signers',
                headerName: 'Signatários',
                hideSortIcons: true,
                minWidth: 150,
                align: 'left',
                headerAlign: 'left',
                flex: 1,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <SignersLength
                            signatureWorkFlowId={cellValues?.row?.signatureWorkFlowId}
                        />
                    );
                },
            }
            : undefined,
        {
            field: 'createdAt',
            headerName: 'Data de criação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues?.value && new Date(cellValues?.value).toLocaleString('pt-BR')}
                    </div>
                );
            },
        },
        titleHeader === 'Documentos da operação'
            ? {
                field: 'signatureStatusDisplay',
                headerName: 'Status da assinatura',
                hideSortIcons: true,
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                editable: false,
                renderCell: (cellValues: any) => {
                    const row = cellValues?.row;
                    const isSignedContract = row?.fileType === 'SignedContract';

                    return (
                        <>
                            {isSignedContract ? (
                                <Typography>-</Typography>
                            ) : (
                                <StatusSignature signatureWorkFlowId={row?.signatureWorkFlowId} />
                            )}
                        </>
                    );
                },
            }
            : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues: any) => {
                const row = cellValues?.row;
                const rowIndex = uploads?.findIndex((file: UploadGet) => row.id === file.id);

                const isNotWaitingForStart = statusSignature !== 'Aguardando Início';
                const invalidFileOrMissingCreatedAt = isEmpty(row.createdAt) || !isSupportedFileType(row?.fileType);
                const isRequestSignature = isEmpty(statusSignature) || statusSignature === 'moreOptions';
                const isWaitingForSignature = statusSignature === 'Aguardando Assinatura';
                const signatureWorkFlowId = cellValues?.row?.signatureWorkFlowId;

                async function signatureRefetch() {
                    await invalidateAndRefetch(queryClient, ['get-signature-workflow-signrs', signatureWorkFlowId]);
                }

                const listActions = [
                    {
                        label: 'Atualizar status',
                        action: signatureRefetch,
                        icon: <UpdateIcon sx={{ ...iconSx, mx: 0.5 }} />,
                        disabled: false,
                        hide: isEmpty(signatureWorkFlowId) && !enableAction && !isEmpty(statusSignature),
                    },
                    {
                        label: 'Editar',
                        action: () => {
                            setSelectedUploadIndex(rowIndex);
                            setOpenDrawer && setOpenDrawer('edit', row);
                        },
                        icon: <EditIcon sx={buttonSx} />,
                        disabled: titleHeader !== 'Documentos da operação',
                    },
                    {
                        label: 'Ver detalhes ',
                        action: () => {
                            setOpenDrawer && setOpenDrawer('viewDetails', row);
                        },
                        icon: <ReportIcon sx={buttonSx} />,
                        disabled: titleHeader.includes(name!),
                        hide: !isCreditNoteMenu && titleHeader !== 'Documentos da operação',
                    },
                    {
                        label: 'Excluir',
                        action: () => onDelete(rowIndex),
                        icon: <DeleteIcon sx={buttonSx} />,
                        disabled: titleHeader.includes(name!),
                    },
                    {
                        label: 'Solicitar assinatura',
                        action: () => setOpenDrawer('requestSignature', row),
                        icon: <CompleteIcon sx={buttonSx} />,
                        disabled: false,
                        hide: invalidFileOrMissingCreatedAt || !isRequestSignature,
                    },
                    {
                        label: 'Cancelar assinatura',
                        action: () => setOpenDrawer && setOpenDrawer('cancelSignature', row),
                        icon: <CloseIcon sx={buttonSx} />,
                        disabled: false,
                        hide: isEmpty(row?.createdAt) || !isWaitingForSignature,
                    },
                    {
                        label: 'Adicionar Signatarios',
                        action: () => setOpenDrawer && setOpenDrawer('signature', row),
                        icon: <PersonIcon sx={buttonSx} />,
                        disabled: false,
                        hide: isEmpty(row?.createdAt) || isNotWaitingForStart,
                    },
                ];

                return (
                    <>
                        <MuiButton
                            sx={{ ml: 0, mr: -2 }}
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<ShowIcon sx={buttonSx} />}
                            href={cellValues?.row.tempGetUrl}
                            target="_blank"
                        />
                        {watch('statusDisplay') !== 'Encerrado' && enableAction && (
                            <>
                                <RowActions
                                    icon={
                                        <MoreOptionsIcon
                                            sx={iconSx}
                                            htmlColor={theme.palette.common.black}
                                        />
                                    }
                                    listButtons={listActions}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsDocumentList as GridColDef[];
    return (
        <>
            <RefreshProgress refreshing={false} />
            <Box p={0} mt={4}>
                <DataTable
                    headerBarWrapper={{
                        header: titleHeader,
                    }}
                    NoRowsOverlayNew="Nenhum documento encontrado"
                    NoResultsOverlayNew="Nenhum documento encontrado"
                    getRowId={(row) => row.id ?? row.fileName ?? row.displayName}
                    columns={columns}
                    rows={uploads}
                    page={page}
                    rowCount={5}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Box>
        </>
    );
};

export default UploadWithRelatedList;
