import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

type NaturalAndLegalPersonActionsProps = {
    recordType: string
}

export const NaturalAndLegalPersonActions = ({ recordType }: NaturalAndLegalPersonActionsProps) => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Consultar Dataset', name: 'actions.ReadDataSet' }}
                helpMessage={`Permite consultar dataset para obter as informações relacionada à ${recordType}.`}
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Solicitar Dataset', name: 'actions.RequestDataSet' }}
                helpMessage={`Permite solicitar informações de dataset relacionada à ${recordType}.`}
            />
        </React.Fragment>
    )
}
