import { Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { iconSx } from 'contexts/apiRequestContext';

type SignatoriesProps = {
    typeOfRelationship: SelectOption[];
    signatureType: SelectOption[];
    signatureValidation: SelectOption[];
    onClose: () => void;
    personList: AutocompleteOptionList;
    isLoading: boolean;
};

const theme = activeTheme();

export const SignatoriesForm: FunctionComponent<SignatoriesProps> = ({
    typeOfRelationship,
    signatureType,
    signatureValidation,
    onClose,
    personList,
    isLoading,
}) => {
    const { submitting } = useFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let onlyNaturalPerson = personList.listOptions?.filter(
        (person: any) => person?.discriminator === 'NaturalPerson'
    );

    return (
        <Stack spacing={4}>
            {isLoading && <RefreshProgress refreshing={isLoading} />}
            <AutocompleteField
                name="personId"
                label="Envolvido"
                displayName="personIdDisplay"
                required
                listOptions={onlyNaturalPerson!}
                loading={personList.loading}
                onSearch={personList.onSearch}
            />

            <SelectFormField
                name="typeOfRelationship"
                label="Tipo de relação"
                variant="outlined"
                options={typeOfRelationship}
                fullWidth
                required
                disabled={isLoading}
            />

            <SelectFormField
                name="signatureType"
                label="Forma de Envio"
                variant="outlined"
                options={signatureType}
                fullWidth
                disabled={isLoading}
            />

            <SelectFormField
                name="signatureValidation"
                label="Forma de Validação"
                variant="outlined"
                options={signatureValidation}
                fullWidth
                disabled={isLoading}
            />

            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                    variant="contained"
                    size="medium"
                    disabled={submitting}
                    fullWidth={isMobile}
                >
                    Adicionar signatário
                </Button>
            </Stack>
        </Stack>
    );
};
