import { Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { ApiResponseError, handleOnError, iconSx, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { Button, CloseIcon, CompleteIcon, Drawer } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { IInstallmentFull, ICreateInstallment } from 'services/walletManagement/installment/installment.types';
import { defaultValuesInstallmentCreateForm, validationInstallmentCreateFormSchema } from './InstallmentCreateFormShema';
import { useCreateInstallmentMutate } from 'contexts/wallet/Installment/installmentContext';
import { defaultValuesBillingInstallmentCreateForm, validationSchemaBillingBillingInstallmentCreateForm } from '../BillingInstallmentCreateForm/BillingInstallmentSchema';
import { BillingInstallmentCreateForm } from '../BillingInstallmentCreateForm/BillingInstallmentCreateForm';
import { InstallmentCreateForm } from './InstallmentCreateForm';

interface IInstallmentCreateFormContainerProps extends IProps {
    refetch: () => void;
    onClose: () => void;
}
interface IProps {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

const palette = activeTheme()?.palette;
export const InstallmentCreateFormContainer = ({ refetch, onClose, setToast }: IInstallmentCreateFormContainerProps) => {
    const [actionStep, setActionStep] = useState<string | undefined>(undefined);
    const [formValues, setFormValues] = useState<ICreateInstallment | null>(null)

    const onSuccess = (response: IInstallmentFull) => {
        refetch();
        showSuccessToast("Parcelamento criado com sucesso", '', setToast);
        onClose();
    }

    const onError = (response: ApiResponseError) => handleOnError(response, setToast);

    const { mutateAsync: createInstallmentMutateAsync } = useCreateInstallmentMutate(onSuccess, onError);

    const handleSubmitForm = (values: ICreateInstallment) => {
        setActionStep('billings');
        setFormValues({
            billingSettingId: values?.billingSettingId,
            communicationSettingId: values?.communicationSettingId,
            payer: values?.payer,
            walletCode: values?.walletCode
        });
    }

    const handleSubmitInstallments = async (values: FieldValues) => {
        await createInstallmentMutateAsync({
            ...formValues!,
            billings: values?.billings
        });
    }

    return (
        <Stack>
            <FormProvider {...{
                validationSchema: validationInstallmentCreateFormSchema(),
                defaultValues: defaultValuesInstallmentCreateForm,
                onSubmit: handleSubmitForm,
                children: (
                    <React.Fragment>
                        <InstallmentCreateForm />
                        <ButtonsActionsInstallmentForm {...{ onClose }} />
                    </React.Fragment>
                )
            }} />

            <Drawer {...{
                anchor: 'right',
                open: actionStep === 'billings',
                title: "Adicionar parcelas",
                toggleDrawer: true,
                children:
                    <FormProvider {...{
                        validationSchema: validationSchemaBillingBillingInstallmentCreateForm(),
                        defaultValues: defaultValuesBillingInstallmentCreateForm,
                        onSubmit: handleSubmitInstallments,
                        children: (
                            <React.Fragment>
                                <BillingInstallmentCreateForm {...{
                                    setToast: setToast
                                }}/>
                                <ButtonsActionsInstallmentForm {...{
                                    onClose: () => setActionStep(undefined)
                                }} />
                            </React.Fragment>
                        )
                    }} />
            }} />
        </Stack>
    )
}

export const ButtonsActionsInstallmentForm = ({ onClose }: { onClose: () => void }) => {
    const { submitting } = useFormContext();
    return (
        <Stack direction='row' alignItems="center" justifyContent="flex-end" spacing={2}>
            <Button
                startIcon={<CloseIcon htmlColor={palette.primary.main} sx={iconSx} />}
                variant="outlined"
                size="medium"
                onClick={onClose}
            >
                Fechar
            </Button>
            <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                size="medium"
                startIcon={<CompleteIcon sx={iconSx} htmlColor={palette.common.white} />}
                type="submit"
            >
                Avançar
            </Button>
        </Stack>
    )
}