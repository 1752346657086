import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';

interface IAddBillingSettingFormProps {
    onCloseDrawer: () => void;
    walletCodeAutoCompleteProps: AutocompleteOptionList;
    isEdit: boolean
    disableWalletCodeField?: boolean
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
const colorWhite = theme.palette.common.white;

const AddBillingSettingForm: React.FC<IAddBillingSettingFormProps> = ({ onCloseDrawer, walletCodeAutoCompleteProps, isEdit, disableWalletCodeField = false  }) => {
    const { watch } = useFormContext();
    const interestTypeValue = watch(`interestTypeValue`);
    const fineTypeValue = watch(`fineTypeValue`);

    return (
        <Stack spacing={3} direction={'column'}>
            <AutocompleteField
                label="Selecione a carteira"
                name="walletCode"
                disabled={isEdit || disableWalletCodeField}
                displayName={isEdit ? "walletCode" : "walletCodeDisplay"}
                required
                {...walletCodeAutoCompleteProps!}
            />

            <Stack spacing={2} direction={'column'}>
                <TextFormField
                    required
                    fullWidth
                    label="Nome da régua de cobrança"
                    name="name"
                    variant="outlined"
                />

                <SelectFormField
                    fullWidth
                    required
                    label="Tipo de juros"
                    name="interestTypeValue"
                    options={[
                        { label: 'Isento', value: 'Free' },
                        { label: 'Percentual', value: 'Percent' },
                    ]}
                />

                {interestTypeValue === 'Percent' && (
                    <Grid>
                        <PercentageFormField
                            variant='outlined'
                            required
                            fullWidth
                            label='Taxa da porcentagem em juros por mês'
                            name='interestAmount'
                        />
                    </Grid>
                )}

                {interestTypeValue === 'Free' && (
                    <>
                        <CurrencyFormField
                            label="Taxa absoluta em juros por mês"
                            name="interestAmount"
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </>
                )}

                <SelectFormField
                    fullWidth
                    required
                    label="Tipo de multa"
                    name="fineTypeValue"
                    options={[
                        { label: 'Isento', value: 'Free' },
                        { label: 'Percentual', value: 'Percent' },
                    ]}
                />

                {fineTypeValue === 'Free' && (
                    <>
                        <CurrencyFormField
                            variant="outlined"
                            name="fineAmount"
                            label="Multa por atraso"
                            required
                        />
                    </>
                )}

                {fineTypeValue === 'Percent' && (
                    <Grid>
                        <PercentageFormField
                            variant='outlined'
                            required
                            fullWidth
                            label='Multa por atraso'
                            name='fineAmount'
                        />
                    </Grid>
                )}

                <SelectFormField
                    name="daysToAutoBankSlipCancel"
                    label="Quantidade de dias para baixa automática"
                    showButtonClearValue
                    options={[
                        { label: '5 dias', value: 5 },
                        { label: '10 dias', value: 10 },
                        { label: '15 dias', value: 15 },
                    ]}
                />

                <SelectFormField
                    name="daysToAutoBankSlipRegister"
                    label="Quantidade de dias para registro automático do boleto"
                    showButtonClearValue
                    options={[
                        { label: '5 dias', value: 5 },
                        { label: '10 dias', value: 10 },
                        { label: '15 dias', value: 15 },
                    ]}
                />
                <SelectFormField
                    label="Instrução de protesto"
                    name="firstInstructionDebtCollection"
                    options={firstInstructionDebtCollectionOptions}
                />
                {watch('firstInstructionDebtCollection') === "06" &&
                    <TextFormField
                        label="Nº de dias após vencimento para envio automático"
                        variant="outlined"
                        required
                        name="secondInstructionDebtCollection"
                        fullWidth
                        type='number'
                    />
                }

                <TextFormField
                    variant="outlined"
                    name="bankSlipMessage"
                    label="Mensagem no boleto"
                />
            </Stack>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={colorMain} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onCloseDrawer}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-continuar"
                    startIcon={<RightIcon htmlColor={colorWhite} sx={iconSx} />}
                    type="submit"
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};
export default AddBillingSettingForm;
