import { FormProvider } from 'contexts/formContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { DescontoFormDefaultValues, DescontoFormSchema } from './DescontoFormSchema';
import Toast from 'components/Toast/Toast';
import { useState } from 'react';
import { ApiResponseError, ToastType, handleOnError, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import { useDiscountReadMutation } from 'contexts/discount/discountReadMutation';
import DescontoForm from './DescontoForm';
import { IDesconto, PaymentScheduleItemReadModel  } from 'services/creditNote/types/creditNoteReadModel';
import { Drawer } from '@uy3/web-components';
import { openCreditNoteSchema, openCreditNoteDefaultValues, OpenCreditNoteType } from './Drawers/OpenCreditNote/OpenCreditNoteFormSchema';
import { OpenCreditNoteForm } from './Drawers/OpenCreditNote/OpenCreditNoteForm';
import { usePersonsList } from 'contexts/personContext';
import { useCreditProdutList } from 'contexts/creditProductContext';
import { useFundsList } from 'contexts/fundContext';
import { useCreditNoteMutation } from 'contexts/creditNote/creditContext';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router';

const DescontoFormContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const [descontoData, setDescontoData] = useState<IDesconto | null>({ loaded: false });
    const onClose = () => setAction(undefined)
    const navigate = useNavigate()

    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10, amortizationType: 'Discount' }, 'always');
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');

    const activeToast = (type: 'read' | 'creditNote') => {
        const optionsMessage: { [type: string]: string } = {
            "read": "Remessa lida com sucesso",
            "creditNote": "Sucesso ao abrir operação"
        };
        return showSuccessToast(optionsMessage[type!], undefined, setToast);
    }

    const onSuccess = (data: FieldValues, type: 'read' | 'creditNote') => {
        if (type === 'read') {
            setDescontoData({ loaded: true, ...data });
            return activeToast('read');
        }
        
        if (type === 'creditNote') {
            const creditNoteId = data;
            activeToast('creditNote');
            return navigate(`/ccb/operacoes/${creditNoteId}`);
        }
    }

    const onError = (responseError: ApiResponseError) => handleOnError(responseError, setToast);

    const handleSubmitFile = (file: any) => {
        if (file == null) {
            return setToast({
                open: true,
                severity: 'info',
                title: 'Selecione o arquivo de remessa'
            })
        }
        mutate(file);
    }


    const mapPaymentScheduleToFutureValues = (x: PaymentScheduleItemReadModel ) => {
        return {
            dueDate: x.dueDate,
            interest: x.interest,
            payment: x.payment
        }
    }

    const handleSubmit = (values: OpenCreditNoteType) => {
        const amortization = descontoData?.amortization;

        const payload = {
            ...values,
            liquidationType: 2, 
            warranty: descontoData?.warranty, 
            amortization: {
                apr: amortization?.apr, 
                amortizationType: "discount",
                futureValues: descontoData?.paymentScheduleItems?.map(mapPaymentScheduleToFutureValues),
                requestedAmount: amortization?.requestedAmount, 
                principalAmountInCents: amortization?.principalAmountInCents, 
                startDate: amortization?.startDate,
                firstPaymentDate: amortization?.firstPaymentDate,
            }
        };
        //@ts-ignore 
        createCreditNoteMutation(payload)
    }

    const { mutate: createCreditNoteMutation, isLoading: isLoadingCreate } = useCreditNoteMutation(
        "nova",
        (values: FieldValues) => onSuccess(values, 'creditNote'),
        (error: ApiResponseError) => handleOnError(error, setToast)
        ,
    )

    const { mutate, isLoading } = useDiscountReadMutation((values: FieldValues) => onSuccess(values, 'read'), onError);
    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isLoading} />

            <FormProvider
                readOnly={false}
                validationSchema={DescontoFormSchema()}
                defaultValues={DescontoFormDefaultValues}
                onSubmit={handleSubmitFile}
            >
                <DescontoForm {
                    ...{
                        clear: () => setDescontoData({ loaded: false }),
                        descontoData,
                        handleSubmitFile,
                        handleAction: (action: string) => setAction(action)
                    }
                } />
            </FormProvider >

            <Drawer
                anchor='right'
                open={action === 'openCreditNote'}
                title='Abrir operação'
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={openCreditNoteSchema()}
                    defaultValues={openCreditNoteDefaultValues(descontoData)}
                    onSubmit={handleSubmit}
                    readOnly={isLoadingCreate}
                >
                    <OpenCreditNoteForm
                        productAutocomplete={creditProductAutoCompleteProps}
                        fundAutocomplete={fundAutoCompleteProps}
                        personAutocomplete={personAutoCompleteProps}
                        onClose={onClose}
                        descontoData={descontoData!}
                    />
                </FormProvider>
            </Drawer>
        </>

    );
};

export default DescontoFormContainer;