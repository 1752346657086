import { Grid, Stack } from '@mui/material'
import { Button, CardData, LeftIcon, RightIcon, Typography } from '@uy3/web-components'
import { RefreshProgress } from 'components/RefreshProgress';
import React from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type ConfirmPixKeyProps = {
  onClose: () => void;
  formValues: any;
  onSubmitCreateKeyPix: () => void;
  isFething: boolean
}

export const ConfirmPixKey = ({ onClose, formValues, onSubmitCreateKeyPix, isFething }: ConfirmPixKeyProps) => {
  return (
    <Stack spacing={4}>

      <Grid sx={{
        border: '1.5px solid #BABFD0',
        borderRadius: 3,
        padding: 3,
        mt: -2
      }}>
        <Typography display='block' fontSize={17} fontWeight='bold'>
          Ao informar sua chave para um pagamento, outros usuários irão visualizar
        </Typography>
        <ul style={{ lineHeight: 3 }}>
          <li>
            <Typography fontSize={15}>
              Seu nome completo e alguns digitos do seu CNPJ;
            </Typography>
          </li>
          <li>
            <Typography fontSize={15}>
              Nome do banco ou aplicativo que contéma sua chave;
            </Typography>
          </li>
          <li>
            <Typography fontSize={15}>
              Verifique se as notificações push do seu celular estão ativas para receber avisos sobre suas transações Pix;
            </Typography>
          </li>
        </ul>
      </Grid>

      <CardData
        listItem={[
          { id: "1", title: "Tipo", value: formValues?.typeDisplay },
          { id: "2", title: "Chave Pix", value: formValues?.pixKey },
          { id: "3", title: "Ação", value: "Cadastro" },
        ]}
      />
      {isFething && <RefreshProgress refreshing={isFething} />}
      <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
        <Button
          startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Fechar
        </Button>
        <Button
          onClick={onSubmitCreateKeyPix}
          variant="contained"
          size="medium"
          disabled={isFething}
          startIcon={
            <RightIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: 23,
                width: 23,
              }}
            />
          }
        >
          Avançar
        </Button>
      </Stack>
    </Stack>
  )
}