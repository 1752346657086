import Grid from '@mui/material/Grid';
import { Typography } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields/TextFormField/TextFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { CheckboxFormField } from 'components/Forms/FormFields/CheckboxFormField/CheckboxFormField';
import { Box, Stack } from '@mui/material';
import { DatePickerFormField, SelectFormField } from 'components/Forms/FormFields';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useFormContext } from 'contexts/formContext';
import { cnpjMask, zipCodeMask, phoneNumberMask } from 'utils/constants';
import { useAppConfig } from 'contexts/appConfig';
import { useParams } from 'react-router';
import { ToastType } from 'contexts/apiRequestContext';
import { hasValidRegistrationNumber } from 'helpers/formats/StringFormats';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { isEmpty } from 'lodash';

interface InformationLegalPersonTabProps {
  personId: string;
  UFList: any[];
  cnaeList: AutocompleteOptionList;
  legalNatureList: any
  handleOnSubmitByChangeField: (registrationNumber: string) => void
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const InformationLegalPersonTab = ({
  UFList,
  cnaeList,
  legalNatureList,
  handleOnSubmitByChangeField,
  setToast
}: InformationLegalPersonTabProps) => {
  const { personId } = useParams();
  const { appConfig } = useAppConfig();
  const { watch, setValue } = useFormContext();
  const isAutoQueryBasucData = appConfig?.AUTO_QUERY_BASIC_DATA === 1;
  const isNew = personId === "novo";

  const handleChangeByBasicData = async (event: any) => {
    const registrationNumber = String(event?.currentTarget?.value)?.replace(/\D/g, '');
    setValue('registrationNumber', registrationNumber);

    if (registrationNumber?.length !== 14) return;

    if (!hasValidRegistrationNumber(registrationNumber)) {
      return setToast({
        open: true,
        severity: 'info',
        title: `Ops!`,
        description: `CNPJ informado não é válido (${formatDocumentNumber(registrationNumber)})`
      })
    };

    handleOnSubmitByChangeField(registrationNumber);
  }

  const disableRegistrationNumberField = watch('loadValuesByRegistrationNumber') === true && isNew;

  const renderCurrentValueUf = () => {
    const [ufDisplay, uf] = watch(['address.ufDisplay', 'address.uf']); 
    return (!isEmpty(ufDisplay) && ufDisplay?.length === 2) ? ufDisplay : uf;
  }

  return (
    <>
      <Box p={0} mt={3}>
        <Typography
          variant="h5"
          color="neutral.dark"
          sx={{
            lineHeight: { xs: '24px', md: '24px' },
            fontWeight: { xs: '700', md: '700' },
            fontStyle: { xs: 'normal', md: 'normal' },
            m: "20px 0 "
          }}
        >
          Dados da Empresa
        </Typography>
        <Grid container spacing={5} columns={10}>
          <Grid item xs={3}>
            <TextFormField
              name="registrationNumber"
              label="CNPJ"
              fullWidth
              disabled={disableRegistrationNumberField || !isNew}
              placeholder="00.000.000/0000-00"
              variant="outlined"
              InputProps={isAutoQueryBasucData && isNew ?
                {
                  inputComponent: MaskedInput,
                  inputProps: { mask: cnpjMask },
                  onChange: handleChangeByBasicData
                } : {
                  inputComponent: MaskedInput,
                  inputProps: { mask: cnpjMask }
                }}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextFormField
              name="companyName"
              label="Razão Social"
              placeholder="Razão Social"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TextFormField
              name="name"
              label="Nome Fantasia"
              placeholder="Nome Fantasia"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TextFormField name="email" label="E-mail" variant="outlined" fullWidth required />
          </Grid>
          <Grid item xs={4}>
            <SelectFormField
              name="legalNature"
              label="Natureza Jurídica"
              variant="outlined"
              options={legalNatureList}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <DatePickerFormField name="openDate" label="Data de Abertura" fullWidth required />
          </Grid>
          <Grid item xs={3}>
            <AutocompleteField
              name="economicActivityCodeId"
              label="CNAE"
              displayName="economicActivityCode.description"
              {...cnaeList}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectFormField
              name="typeOfActivity"
              label="Ramo de Atividade"
              variant="outlined"
              options={[
                { label: 'Industria', value: 'Industry' },
                { label: 'Serviços', value: 'services' },
                { label: 'Negócios', value: 'business' },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextFormField
              name="b3Account"
              label="Conta B3"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextFormField name="site" label="Website" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextFormField
              name="municipalRegistration"
              label="Inscrição Municipal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextFormField
              name="stateRegistration"
              label="Inscrição Estadual"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextFormField
              name="externalEmployerId"
              label="Código do Empregador"
              variant="outlined"
              type='number'
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={0} mt={3}>
        <Typography
          variant="h5"
          color="neutral.dark"
          sx={{
            lineHeight: { xs: '24px', md: '24px' },
            fontWeight: { xs: '700', md: '700' },
            fontStyle: { xs: 'normal', md: 'normal' },
            m: "20px 0 "
          }}
        >
          Telefone
        </Typography>
        <Stack m='20px 0'>
          <Grid container spacing={5} columns={10}>
            <Grid item xs={2}>
              <TextFormField
                name="phone"
                variant="outlined"
                fullWidth
                label="Telefone Celular"
                placeholder="(00) 0000-0000"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: { mask: phoneNumberMask },
                }}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <TextFormField name="phoneExtension" fullWidth label="Ramal" variant="outlined" />
            </Grid>
            <Grid item xs={2}>
              <TextFormField
                name="phone2"
                variant="outlined"
                fullWidth
                label="Telefone Opcional"
                placeholder="(00) 0000-0000"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: { mask: phoneNumberMask },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextFormField name="phone2Extension" fullWidth label="Ramal" variant="outlined" />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box p={0} mt={3}>
        <Typography
          variant="h5"
          color="neutral.dark"
          sx={{
            lineHeight: { xs: '24px', md: '24px' },
            fontWeight: { xs: '700', md: '700' },
            fontStyle: { xs: 'normal', md: 'normal' },
            m: "20px 0 "
          }}
        >
          Endereço
        </Typography>
        <Stack m='20px 0'>
          <Grid container spacing={5} columns={10}>
            <Grid item xs={3}>
              <TextFormField
                name="address.zipCode"
                id="zipCode"
                label="CEP"
                variant="outlined"
                placeholder="00000-000"
                fullWidth
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: { mask: zipCodeMask },
                  onChange: (event) => {
                    let keyBoard = event?.currentTarget?.value.replace(/\D/g, '');
                    queryZipCodeAndFillForm(keyBoard, (fieldName, value) => {
                      setValue(`address.${fieldName}`, value);
                    });
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                name="address.addressName"
                fullWidth
                label="Endereço"
                variant="outlined"
                value={watch('address.addressName') ? watch('address.addressName') : ""}
                InputLabelProps={{
                  shrink: watch('address.addressName') ? true : false
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextFormField
                name="address.number"
                fullWidth
                type='number'
                label="Número"
                variant="outlined"
                value={watch('address.number') ? watch('address.number') : ""}
                InputLabelProps={{
                  shrink: watch('address.number') ? true : false
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <SelectFormField
                name="address.uf"
                label="UF"
                variant="outlined"
                options={UFList}
                value={renderCurrentValueUf()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFormField
                name="address.district"
                fullWidth
                label="Bairro"
                variant="outlined"
                value={watch('address.district') ? watch('address.district') : ""}
                InputLabelProps={{
                  shrink: watch('address.district') ? true : false
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                name="address.city"
                fullWidth
                label="Cidade"
                variant="outlined"
                value={watch('address.city') ? watch('address.city') : ""}
                InputLabelProps={{
                  shrink: watch('address.city') ? true : false
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFormField
                name="address.complement"
                fullWidth
                label="Complemento"
                variant="outlined"
                value={watch('address.complement') ? watch('address.complement') : ""}
                InputLabelProps={{
                  shrink: watch('address.complement') ? true : false
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>


      <Box p={0} mt={3}>
        <Typography
          variant="h5"
          color="neutral.dark"
          sx={{
            lineHeight: { xs: '24px', md: '24px' },
            fontWeight: { xs: '700', md: '700' },
            fontStyle: { xs: 'normal', md: 'normal' },
            m: "20px 0 "
          }}
        >
          Dados financeiros
        </Typography>
        <Stack m='20px 0'>
          <Grid container spacing={5} columns={10}>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Faturamento médio mensal (Últimos 12 Meses)"
                name="averageMonthlyRevenue"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Margem média de Lucro Bruto"
                name="averageGrossProfitMargin"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Capital social inicial"
                name="initialShareCapital"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Patrimônio Líquido"
                name="netWorth"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Faturamento médio mensal (Arranjo)"
                name="purchases"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Valor Alugado/Financiado"
                name="amountRentedFinanced"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <PercentageFormField
                label="Exportação (em %)"
                name="exportPercentage"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Custo com Fornecedores"
                name="costWithSuppliers"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextFormField
                label="Número de Funcionários"
                name="numberOfEmployees"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Valor da Folha de Pagamento"
                name="payrollValue"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                label="Valor gasto com imposto"
                name="spendingAmountWithTax"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <SelectFormField
                name="typeOfResidence"
                label="Local da Sede"
                variant="outlined"
                options={[
                  { label: 'Própria', value: 'Owner' },
                  { label: 'Alugada', value: 'Rent' },
                ]}
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencyFormField
                name="averageSalesPeriod"
                label="PMV"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <PercentageFormField
                label="Potencial Fomentável"
                name="fosterablePotential"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <PercentageFormField
                label="Percentual de Venda em Atraso"
                name="delayedSalePercentage"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item mt={2}>
            <CheckboxFormField
              color="primary"
              label="Permitir contato"
              name="allowAccessToSchedule"
            />
            <CheckboxFormField
              label="Permitir acesso aos recebíveis"
              color="primary"
              name="allowAccessToReceivables"
            />
            <CheckboxFormField
              label="Permitir acesso a API parceira"
              color="primary"
              name="allowPartnerApiSubscription"
            />
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default InformationLegalPersonTab;
