import { useQueryClient } from '@tanstack/react-query';
import { Button, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';
import { Stack } from '@mui/material';

interface PaymentHeaderListProps {
    isFetching: boolean;
    refetch: () => void;
    bankAccountId: string | undefined;
}

const theme = activeTheme();
const PaymentHeader: React.FC<PaymentHeaderListProps> = ({ isFetching, refetch, bankAccountId }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const queryClient = useQueryClient();

    async function paymentRefetch() {
        refetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction={'row'} justifyContent="flex-end" spacing={2} mr={2} mb={2}>
            <Button
                sx={{ color }}
                variant="text"
                size="medium"
                onClick={paymentRefetch}
                disabled={isFetching}
                startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                color="primary"
            >
                Atualizar
            </Button>
        </Stack>
    );
};
export default PaymentHeader;