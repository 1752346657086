import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

export const BillingAccountActions = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Aprovar Liquidação', name: 'actions.LiquidationApproval' }}
                helpMessage="Permite o usuário liquidar e liquidar STR no menu de cobrança nas informações de boleto."
            />
        </React.Fragment>
    )
}
