import { handleNumericNaN } from 'helpers/validations/formFieldValidator';
import { object, InferType, number } from 'yup';

const valueInCentsSchema = () =>
    object().shape({
        valueInCentsOwnOwnership: number().nullable().transform(handleNumericNaN),
        valueInCentsNaturalPerson: number().nullable().transform(handleNumericNaN),
        valueInCentsLegalPerson: number().nullable().transform(handleNumericNaN),
    });

export const validationSchemaRequestLimitForm = () => {
    return object().shape({
        pixLimit: object()
            .shape({
                businessHours: valueInCentsSchema().nullable(),
                nonBusinessHours: valueInCentsSchema().nullable(),
            })
            .nullable(),
        bankSlipLimit: object()
            .shape({
                businessHours: valueInCentsSchema().nullable(),
                nonBusinessHours: valueInCentsSchema().nullable(),
            })
            .nullable(),
        transferLimit: object()
            .shape({
                businessHours: valueInCentsSchema().nullable(),
                nonBusinessHours: valueInCentsSchema().nullable(),
            })
            .nullable(),
        tedLimit: object()
            .shape({
                businessHours: valueInCentsSchema().nullable(),
                nonBusinessHours: valueInCentsSchema().nullable(),
            })
            .nullable(),
    });
};

let inferedSchema = validationSchemaRequestLimitForm();
export type RequestLimitFormSchema = InferType<typeof inferedSchema>;

// Função para criar limites com valores padrão
function createLimitObject() {
    return {
        valueInCentsOwnOwnership: null,
        valueInCentsNaturalPerson: null,
        valueInCentsLegalPerson: null,
    };
}

// Função para criar estrutura de limite com valores padrão
function createDefaultLimitStructure() {
    return {
        businessHours: createLimitObject(),
        nonBusinessHours: createLimitObject(),
    };
}

// Estrutura principal com valores padrão para limites
export const defaultValuesRequestLimitForm = {
    pixLimit: createDefaultLimitStructure(),
    bankSlipLimit: createDefaultLimitStructure(),
    transferLimit: createDefaultLimitStructure(),
    tedLimit: createDefaultLimitStructure(),
    upload: null,
};
