import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import { Button, Popup } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { useBankAccountProductsList } from 'contexts/bankAccountProduct';
import BankAccountProductList from './BankAccountProductList';
import { deleteBankAccountProductById } from 'services/bankAccountProduct';
import { useIdentity } from 'contexts/identityContext';
import { FilterProvider } from 'contexts/filterContext';
import BankAccountProductHeader from './BankAccountPronductHeader';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { runSkeleton } from 'helpers/methods/GenericMethods';
import { useFilterValues } from 'contexts/filterValuesContext';

function BankAccountProductListContainer() {
  const { hasPermission } = useUserPermissionData(); 
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );
  const [page, setPage] = useState(0);
  const { token } = useIdentity();
  const [rowData, setRowData] = useState<any | undefined>(undefined);
  const { filterValues } = useFilterValues();

  const { data, error, status, isFetching, refetch, isLoading } = useBankAccountProductsList({
    page,
    size: rowsPerPage,
  });

  if (status === 'error') return <Error error={error} />;

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onClosePopup = () => setRowData(undefined);
  const onDelete = async (productId: string) => {
    await deleteBankAccountProductById(productId, token!);
    onClosePopup();
    await refetch();
  };
  
  const isRunSkeleton =  runSkeleton(isLoading, filterValues); 

  return (
    <>

      <Stack mb={3}>
        <BankAccountProductHeader 
        isFetching={isFetching} 
        refetch={refetch} 
        enableCreateBankAccountProduct={hasPermission('BankAccountProduct', 'Create')}
        />
      </Stack>
      {!isLoading && <RefreshProgress refreshing={isFetching} />}

      <FilterProvider
        availableFilters={{
          searchString: { label: "Todos", type: "text" },
          name: { label: "Nome do produto", type: "text" },
          orderBy: { label: "Proprietário", type: "text" },
          initialDate: { label: "Data da ocorrência", type: "date" }
        }}>
        <ListSkeleton isLoading={isRunSkeleton}>
          <BankAccountProductList
            queryData={data}
            hasPermission={hasPermission}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={onChangeRowsPerPage}
            setPage={onChangePage}
            page={page}
            setOpenPopup={setRowData}
          />
        </ListSkeleton>
      </FilterProvider>

      <Popup
        open={rowData !== undefined}
        title={rowData ? `Excluir #${rowData?.row?.name}?` : 'Excluir'}
        text={`Tem certeza que deseja excluir este produto de conta?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDelete(rowData.id)}
              size="medium"
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </>
  );
}

export default BankAccountProductListContainer;
