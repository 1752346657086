/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { Button, PersonIcon, Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { RefreshProgress } from 'components/RefreshProgress';
import { zipCodeMask } from 'utils/constants';
import { ApiResponseError, ToastType, iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useGetNaturalOrLegalPerson } from 'contexts/personContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useGetZipCodeBillingMutation } from 'contexts/wallet/zipCode/zipCoddeContext';
import { handlePersonLink } from 'pages/CreditNote/CreditNoteForm/Tabs/InformationTab';
import { FC, Fragment, useEffect } from 'react';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { activeTheme } from 'services/theme';
import { WalletsFull } from 'services/walletManagement/wallet/wallets.type';
import { IAddressBilling } from 'services/walletManagement/zipCode/zipCode.type';

interface NewChargesFormProps {
    assignorList: AutocompleteOptionList;
    personList: AutocompleteOptionList;
    setIsAddPayer: React.Dispatch<React.SetStateAction<boolean>>;
    assignorData: WalletsFull[]
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

const theme = activeTheme();
const NewChargesForm: FC<NewChargesFormProps> = ({ assignorList, personList, setIsAddPayer, assignorData, setToast }) => {
    const { watch, setValue } = useFormContext();

    const handleAddPayer = () => {
        setIsAddPayer(true);
        setValue('personId', null);
        setValue('personIdDisplay', null);
    };

    const personId = watch('personId');
    const discriminator = watch('personDiscriminator');
    const walletCodeValueForm = watch('walletCode');


    const onSuccess = (response: IAddressBilling) => {
        setValue('payer.addressName', response.address);
    }

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        setToast({
            open: true,
            severity: 'error',
            title: 'Ops, ocorreu um erro ao consultar o CEP',
            description: errorMessage
        })
    }

    const { mutate: queryZipCodeMutate, isLoading: isLoadingZipCode } = useGetZipCodeBillingMutation(onSuccess, onError);
    const handleChangeZipCode = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = String(event.currentTarget.value)?.replace(/\D/g, '');
        setValue('payer.zipCode', value);

        if (value?.length === 8) {
            queryZipCodeMutate(value);
        }
    }

    const { data, isFetching } = useGetNaturalOrLegalPerson({ discriminator, personId });

    function handleSetFormValuesToPayer<T extends NaturalPersonReadModel | LegalPersonReadModel>(data: T, personTypeValue: string) {
        const address = data?.address;
        const fullZipCode = address?.zipCode;

        const registrationNumber = data?.registrationNumber ?? null;
        const addressName = address?.addressName
        const addressNumber = address?.number;
        const addressComplement = address?.complement;

        const email = data?.email ?? null;

        setValue('payer', {
            name: data?.name,
            personTypeValue,
            email,
            registrationNumber,
            zipCode: fullZipCode,
            addressName,
            addressNumber,
            addressComplement
        });
    };

    const payerLegalPerson = (data: LegalPersonReadModel) => {
        const cnpj = 'Legal';
        handleSetFormValuesToPayer<LegalPersonReadModel>(data, cnpj);
        setValue('payer', { ...watch('payer'), companyName: data?.companyName })
    };

    const payerNaturalPerson = (data: NaturalPersonReadModel) => {
        const cpf = 'Natural';
        handleSetFormValuesToPayer<NaturalPersonReadModel>(data, cpf);
    };

    useEffect(() => {
        if (discriminator === 'LegalPerson' && data) {
            payerLegalPerson(data as LegalPersonReadModel);
        }
        if (discriminator === 'NaturalPerson' && data) {
            payerNaturalPerson(data as NaturalPersonReadModel);
        }
    }, [data]);

    useEffect(() => {
        if (!!walletCodeValueForm) {
            const findByWallet = assignorData?.find(item => item.walletCode === walletCodeValueForm);
            setValue('walletSelected', findByWallet);
        }
    }, [walletCodeValueForm]);

    const amount = watch('amount');

    return (
        <Fragment>
            <RefreshProgress refreshing={isFetching} />

            <Stack spacing={3.5} direction="column">
                <Typography variant="lg" color="common.black">
                    Dados da carteira
                </Typography>

                <AutocompleteField
                    label="Selecione a carteira"
                    name="walletCode"
                    displayName="walletCodeDisplay"
                    required
                    {...assignorList!}
                />

                <Typography variant="lg" color="common.black">
                    Dados do pagador
                </Typography>
                <AutocompleteField
                    label="Selecione o pagador"
                    name="personId"
                    displayName="personIdDisplay"
                    required
                    endIconType="link"
                    link={handlePersonLink({
                        personId,
                        personDiscriminator: discriminator,
                    })}
                    showEndIcon={personId}
                    {...personList!}
                />

                <Grid container sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        size="large"
                        variant="text"
                        color="primary"
                        onClick={handleAddPayer}
                        style={{ cursor: 'pointer' }}
                        startIcon={
                            <PersonIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                        }
                    >
                        Adicionar pagador
                    </Button>
                </Grid>

                {personId && (
                    <>
                        <CardInfo
                            name={watch('payer')?.name ?? ""}
                            registrationNumber={watch('payer')?.registrationNumber ?? ""}
                            companyName={watch('payer')?.companyName ?? ""}
                        />

                        <TextFormField
                            label="E-mail"
                            variant="outlined"
                            name="payer.email"
                            required
                            fullWidth
                        />
                        <TextFormField
                            name="payer.zipCode"
                            label="CEP"
                            variant="outlined"
                            placeholder="00000-000"
                            disabled={isLoadingZipCode}
                            fullWidth
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: zipCodeMask },
                                onChange: handleChangeZipCode
                            }}
                        />
                        <TextFormField
                            label="Endereço"
                            required
                            variant="outlined"
                            name="payer.addressName"
                            disabled={isLoadingZipCode}
                            fullWidth
                        />
                        <TextFormField
                            required
                            label="Número"
                            variant="outlined"
                            name="payer.addressNumber"
                            fullWidth
                        />
                        <TextFormField
                            label="Complemento"
                            variant="outlined"
                            name="payer.addressComplement"
                            fullWidth
                        />

                        <Typography variant="h5" fontWeight="700">
                            Dados adicionais
                        </Typography>

                        <DatePickerFormField
                            name="dueDate"
                            fullWidth
                            label="Data de vencimento"
                            required
                            disablePast
                        />

                        <TextFormField
                            name="documentNumber"
                            variant="outlined"
                            label="Número do Documento"
                            fullWidth
                            required
                        />

                        <TextFormField
                            name="description"
                            variant="outlined"
                            label="Descrição do título"
                            fullWidth
                        />

                        <Typography variant="h5" fontWeight="700">
                            Valor da cobrança
                        </Typography>

                        <Grid>
                            <CurrencyFormField
                                label="R$"
                                name="amount"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>

                        <HorizontalInfo type="currency" value={amount} />
                    </>
                )}
            </Stack>
        </Fragment>
    );
};

export default NewChargesForm;
