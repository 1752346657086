import { Stack } from '@mui/material'
import { CardData, Typography } from '@uy3/web-components'
import { useAppConfig } from 'contexts/appConfig';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';
import { formatWitTenant } from 'helpers/formats/StringFormats';

type ApprovalsDetailsProps = {
  detailsData: any;
  showPersonalData?: boolean;
}

export const ApprovalsDetails = ({ detailsData, showPersonalData = true }: ApprovalsDetailsProps) => {
	const { appConfig } = useAppConfig();
  let naturalPersonOrLegalPerson = detailsData?.owner?.ownerDiscriminator;
	const isRootTenancy = appConfig.TENANT_TYPE === 0;

  const listData = [
    {
      id: '1',
      title: 'Tipo da solicitação',
      value: `${detailsData?.approvalTypeDisplay ?? 'N/D'}`
    },
    {
      id: '2',
      title: 'Criado por',
      value: `${formatWitTenant(detailsData?.createdBy, isRootTenancy) ?? 'N/D'}`
    },
    {
      id: '3',
      title: 'Data da criação',
      value: `${new Date(detailsData?.createdAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  ]

  if (!!detailsData?.updatedBy) listData.push(
    {
      id: '4',
      title: 'Atualizado por',
      value: `${formatWitTenant(detailsData?.updatedBy, isRootTenancy)  ?? 'N/D'}`
    },
    {
      id: '5',
      title: 'Data da atualização',
      value: `${new Date(detailsData?.updatedAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  ); 

  return (
    <Stack>
      {showPersonalData &&
        <>
          <Typography variant='lg' mb={2}>Dados pessoais</Typography>
          <CardData
            listItem={[
              {
                id: '1',
                title: naturalPersonOrLegalPerson === 'NaturalPerson' ? "Pessoa física" : "Pessoa jurídica",
                value: ""
              },
              {
                id: '2',
                title: 'Nome',
                value: `${detailsData?.bankAccountRequest?.owner?.name ?? 'N/D'}`
              },
              {
                id: '3',
                title: 'Contato',
                value: formatPhoneNumber(detailsData?.bankAccountRequest?.owner?.phone)
              }
            ]}
          />
        </>
      }
      <Typography variant='lg' mb={2}>Informações do solicitante</Typography>
      <CardData
        listItem={listData}
      />
    </Stack>
  )
}
