import { Grid, Stack } from '@mui/material';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';

interface CategorieFormFieldsProps {
    index: number;
}

const InterestModelForm: React.FC<CategorieFormFieldsProps> = ({ index }) => {
    const { watch } = useFormContext();

    const nome = watch(`interestModel.${index}.name`);
    const interestTypeValue = watch(`interestModel.${index}.interestTypeValue`);
    const fineTypeValue = watch(`interestModel.${index}.fineTypeValue`);

    return (
        <CustomAccordionContainer
            index={index}
            description={nome ?? `Régua #${index + 1}`}
        >
            <Stack spacing={2} direction={'column'}>
                <TextFormField
                    required
                    fullWidth
                    label="Nome da régua de cobrança"
                    name={`interestModel.${index}.name`}
                    variant="outlined"
                />

                <SelectFormField
                    fullWidth
                    required
                    label="Tipo de juros"
                    name={`interestModel.${index}.interestTypeValue`}
                    options={[
                        { label: 'Isento', value: 'Free' },
                        { label: 'Percentual', value: 'Percent' },
                    ]}
                />

                {interestTypeValue === 'Percent' && (
                    <Grid>
                        <PercentageFormField
                            variant='outlined'
                            required
                            fullWidth
                            label="Taxa da porcentagem em juros por mês"
                            name={`interestModel.${index}.interestAmount`}
                        />
                    </Grid>
                )}

                {interestTypeValue === 'Free' && (
                    <>
                        <CurrencyFormField
                            label="Taxa absoluta em juros por mês"
                            name={`interestModel.${index}.interestAmount`}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </>
                )}

                <SelectFormField
                    fullWidth
                    required
                    label="Tipo de multa"
                    name={`interestModel.${index}.fineTypeValue`}
                    options={[
                        { label: 'Isento', value: 'Free' },
                        { label: 'Percentual', value: 'Percent' },
                    ]}
                />

                {fineTypeValue === 'Free' && (
                    <>
                        <CurrencyFormField
                            variant="outlined"
                            name={`interestModel.${index}.fineAmount`}
                            label="Multa por atraso"
                            required
                        />
                    </>
                )}

                {fineTypeValue === 'Percent' && (
                    <Grid>
                        <PercentageFormField
                            variant='outlined'
                            required
                            fullWidth
                            label='Multa por atraso'
                            name={`interestModel.${index}.fineAmount`}
                        />
                    </Grid>

                )}

                <SelectFormField
                    name={`interestModel.${index}.daysToAutoBankSlipCancel`}
                    label="Quantidade de dias para baixa automática"
                    showButtonClearValue
                    options={[
                        { label: '5 dias', value: 5 },
                        { label: '10 dias', value: 10 },
                        { label: '15 dias', value: 15 },
                    ]}
                />

                <SelectFormField
                    name={`interestModel.${index}.daysToAutoBankSlipRegister`}
                    label="Quantidade de dias para registro automático do boleto"
                    showButtonClearValue
                    options={[
                        { label: '5 dias', value: 5 },
                        { label: '10 dias', value: 10 },
                        { label: '15 dias', value: 15 },
                    ]}
                />

                <SelectFormField
                    label="Instrução de protesto"
                    name={`interestModel.${index}.firstInstructionDebtCollection`}
                    options={firstInstructionDebtCollectionOptions}
                />
                {watch(`interestModel.${index}.firstInstructionDebtCollection`) === "06" &&
                    <TextFormField
                        label="Nº de dias após vencimento para envio automático"
                        variant="outlined"
                        required
                        name={`interestModel.${index}.secondInstructionDebtCollection`}
                        fullWidth
                        type='number'
                    />
                }

                <TextFormField
                    variant="outlined"
                    name={`interestModel.${index}.bankSlipMessage`}
                    label="Mensagem no boleto"
                />
            </Stack>
        </CustomAccordionContainer>
    );
};

export default InterestModelForm;
