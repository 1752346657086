import { Error } from 'components/Errors/Error';
import NaturalPersonList from './NaturalPersonList';
import { useNaturalPersonList } from 'contexts/naturalPersonContext';
import { useState } from 'react';
import { deleteNaturalPersonById } from 'services/accounts/naturalPerson';
import { RefreshProgress } from 'components/RefreshProgress';
import { Grid } from '@mui/material';
import { Button, Popup } from '@uy3/web-components';
import { useIdentity } from 'contexts/identityContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { FilterProvider } from 'contexts/filterContext';
import { useTenant } from 'contexts/tenantContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import NaturalPersonListHeader from './NaturalPersonListHeader';
import { runSkeleton } from 'helpers/methods/GenericMethods';
import { useFilterValues } from 'contexts/filterValuesContext';

type NaturalPersonListContainerProps = {
  isPendingPreApprove?: boolean;
};

export function NaturalPersonListContainer({
  isPendingPreApprove,
}: NaturalPersonListContainerProps) {
  const { token } = useIdentity();
  const { tenantAutoCompleteProps } = useTenant();
  const [toast, setToast] = useState<ToastType>(toastState);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );
  const { endRequest, startRequest, submitting } = useApiRequest();
  const [rowData, setRowData] = useState<any>(undefined);
  const [page, setPage] = useState(0);
  const onClosePopup = () => setRowData(undefined);
  const { filterValues } = useFilterValues();

  const {
    status: naturalPersonStatus,
    data: naturalPersonData,
    error,
    isFetching,
    refetch,
    isLoading
  } = useNaturalPersonList({
    page,
    size: rowsPerPage,
    pendingPreApprove: isPendingPreApprove,
  });

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onDelete = async (personId: string) => {
    startRequest();
    await deleteNaturalPersonById(personId, token!)
      .then(() => {
        endRequest(true);
        const title = `Pessoa física excluída com sucess!`;
        const description = 'Ótimo! A lista de pessoas física foi atualizada.';
        showSuccessToast(title, description, setToast);
        refetch();
        onClosePopup();
      })
      .catch((response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const title = `Erro ao excluir excluir pessoa física!`;
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        endRequest(false);
        onClosePopup();
      });
  };

  if (naturalPersonStatus === 'error') return <Error error={error} />;

  let optionsTenantList =
    tenantAutoCompleteProps?.listOptions?.map((item: any) => {
      return { label: item?.label, value: item?.value };
    }) || [];

  const isRunSkeleton = runSkeleton(isLoading, filterValues);

  return (
    <>
      <Toast toast={toast} setToast={setToast} />
      <Grid mb={3}>
        <NaturalPersonListHeader
          {...{
            isFetching,
            refetch,
            isPendingPreApprove,
          }}
        />
      </Grid>

      {!isLoading && <RefreshProgress
        refreshing={isFetching || submitting}
      />}

      {optionsTenantList?.length > 0 ? (
        <FilterProvider
          availableFilters={{
            registrationNumber: { label: 'CPF', type: 'text' },
            initialDate: {
              label: 'Data de início',
              type: 'dateRange',
              multiple: false,
              dateRangeName: 'finalDate',
            },
            email: { label: 'Email', type: 'text' },
            personDisplay: { label: 'Nome', type: 'text' },
            tenant: {
              label: 'Correspondente',
              type: 'autocomplete',
              multiple: false,
              options: optionsTenantList,
            },
            phone: { label: 'Telefone', type: 'text' },
            searchString: { label: 'Todos', type: 'text' },
          }}
        >
          <ListSkeleton isLoading={isRunSkeleton}>
            <NaturalPersonList
              queryData={naturalPersonData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={onChangeRowsPerPage}
              setPage={onChangePage}
              setRowData={setRowData}
              page={page}
            />
          </ListSkeleton>
        </FilterProvider>
      ) : (
        <></>
      )}

      <Popup
        open={rowData !== undefined}
        title={rowData ? `Excluir #${rowData?.row?.name}?` : 'Excluir'}
        text={`Tem certeza que deseja excluir este usuário ?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDelete(rowData.id)}
              size="medium"
              disabled={submitting}
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </>
  );
}
