import React, { useState } from 'react'
import { FileInstructionList } from './FileInstructionList'
import { useDownloadFileInstructionAsyncMutation, useGenerateCNAB400FileInstructionAsyncMutation, useGetAllFileInstruction } from 'contexts/wallet/files/fileInstruction/fileInstructionContext'
import { GridValidRowModel } from '@mui/x-data-grid';
import { Drawer, DrawerProps } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { FilterProvider } from 'contexts/filterContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { UploadFileInstructionContainer } from './Drawers/UploadFileInstruction/UploadFileInstructionContainer';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useParams } from 'react-router';
import { useQueryCacheContext } from 'contexts/queryCacheContext';
import { GenerateCnabContainer } from './Drawers/GenerateCnab400/GenerateCnabContainer';
import { GenerateCNAB400FileInstructionType } from 'services/walletManagement/files/fileInstruction/fileInstruction.type';
import FileInstructionHeader from './FileInstructionHeader';


export const FileInstructionListContainer = () => {
    const { id } = useParams();
    const { getQueryCache } = useQueryCacheContext();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { filterValues } = useFilterValues();
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [handleAction, setHandleAction] = useState<{ action: string, data: GridValidRowModel } | undefined>(undefined);
    const [page, setPage] = useState(0);
    const queryDataByKey = getQueryCache(["get-wallet-by-id", id]);

    const onClose = () => setHandleAction(undefined)
    const onSuccess = (type: string) => {
        refetch();
        const message: { [type: string]: string } = {
            "download": "Sucesso ao realizar o download do arquivo", 
            "generateCnab": "CNAB 400 gerado com sucesso"
        }
        const title = message[type];
        const description = undefined;
        setHandleAction(undefined); 
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };
    const { mutateAsync: downloadMutateAsync, isLoading: isLoadingDownload } = useDownloadFileInstructionAsyncMutation(() => onSuccess("download"), onError)
    const { mutateAsync: generateCnab400Async, isLoading: isLoadingCnab } = useGenerateCNAB400FileInstructionAsyncMutation(() => onSuccess("generateCnab"), onError)

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onChangePage = (newPage: number) => setPage(newPage);

    const { data: queryData, isLoading, isFetching, refetch } = useGetAllFileInstruction({
        page,
        size: rowsPerPage,
        walletsCode: Number(queryDataByKey?.walletCode)
    });

    const handleSubmitCnab400 = async (values: GenerateCNAB400FileInstructionType) => {
        let payload: GenerateCNAB400FileInstructionType = {
            endDate: values.endDate, 
            initDate: values.initDate,  
            walletCode: queryDataByKey?.walletCode
        }; 

        if (!values?.selectManualDate) {
            delete payload.endDate; 
            delete payload.initDate;
        }

        return await generateCnab400Async(payload);
    }

    const optionsRenderAction: { [action: string]: DrawerProps } = {
        "generate-cnab": {
            anchor: "right",
            open: true,
            title: "Importar arquivo CNAB",
            children: <UploadFileInstructionContainer onClose={onClose} setToast={setToast} />,
            onClose
        }, 
        "generate-cnab400": {
            anchor: "right",
            open: true,
            title: "Gerar CNAB 400",
            children: <GenerateCnabContainer handleSubmit={handleSubmitCnab400} onClose={onClose}/>,
            onClose
        }
    }

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={3}>
                <FileInstructionHeader
                    refresh={refetch}
                    setHandleAction={setHandleAction!}
                    handleGenerateCNAB400={() => setHandleAction((prev) => ({...prev!, action: 'generate-cnab400'}))}
                />
            </Grid>
            <RefreshProgress refreshing={(isFetching && !isLoading) || isLoadingDownload || isLoadingCnab} />
            <FilterProvider
                availableFilters={{
                    initialDate: {
                        label: 'Data',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDate',
                    },
                }}
            >
                <ListSkeleton isLoading={isLoading && Object.values(filterValues.filters)?.length === 0}>
                    <FileInstructionList
                        {...{
                            page,
                            queryData,
                            rowsPerPage,
                            setPage: onChangePage,
                            setRowsPerPage: onChangeRowsPerPage,
                            setHandleAction,
                            handleDownloadFile: (id) => downloadMutateAsync(id)
                        }}
                    />
                </ListSkeleton>
            </FilterProvider>

            <Drawer
                {...{
                    ...optionsRenderAction[handleAction?.action!],
                    anchor: "right"
                }}
            />

        </React.Fragment>
    )
}
