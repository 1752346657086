import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useAppConfig } from "contexts/appConfig";
import { useIdentity } from "contexts/identityContext";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { getNotificationsAsync, getNotificationsSettingsAsync, postNotificationSetLastRead, postNotificationSettingsAsync } from "services/notifications";
import { FiltersGetNotificationType } from "services/notifications/types/generic";
import { NotificationsCreateModel } from "services/notifications/types/notificationsCreateModel";
import { NotificationsLastReadCreateModel } from "services/notifications/types/notificationsLastReadCreateModel";
import { NotificationsReadModel } from "services/notifications/types/notificationsReadModel";
import { isValidString } from "helpers/formats/StringFormats";

export function useGetNotifications(params?: FiltersGetNotificationType) {
    const { token } = useIdentity();
    const { appConfig } = useAppConfig();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const hasSearchApiUrl = isValidString(String(appConfig.SEARCH_API_URL));
    
    const filters = {
        from: params?.from ?? 0, 
        size: params?.size ?? 20, 
        onlyNew: params?.onlyNew ?? false
    } as FiltersGetNotificationType; 

    const queryContext = useQuery({
        enabled: !!token && !!hasSearchApiUrl,
        refetchIntervalInBackground: false,
        retry: false,
        refetchInterval: (appConfig.TIMER_TO_NOTIFICATION_IN_SECONDS ?? 30) * 1000, 
        refetchOnWindowFocus: false,
        queryKey: ['get-notifications-list'],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getNotificationsAsync(filters, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            
            return data;
        },
    });

    return queryContext
}

export function useGetNotificationsSettings() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-notifications-settings-list'],
        queryFn: async (): Promise<NotificationsReadModel> => {
            startRequest();
            const { data, status, statusText } = await getNotificationsSettingsAsync(token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as NotificationsReadModel;
        },
    });
}

export function useNotificationSettingMutations(
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: NotificationsCreateModel) => {
            startRequest();
            const { data } = await postNotificationSettingsAsync(payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useNotificationLastReadMutation(
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: NotificationsLastReadCreateModel) => {
            startRequest();
            const { data } = await postNotificationSetLastRead(payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, () => onError&&onError(error as ApiResponseError));
        },
    });
}