/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { Grid, IconButton, Stack } from "@mui/material";
import { Avatar, NotificationRingingIcon, Typography } from "@uy3/web-components";
import { useIdentity } from "contexts/identityContext";
import { Box } from "@mui/system";
import moment from "moment";
import 'moment/locale/pt-br';
import { useTenant } from "contexts/tenantContext";
import { Autocomplete } from "components/Forms/Autocomplete";
import { PrimarySpan } from "components/PrimarySpan/PrimarySpan";
import { activeTheme } from "services/theme";
import { useAppConfig } from "contexts/appConfig";
import { removeDuplicateData } from "helpers/formats/ArrayFormats";
import { HeaderSkeleton } from "components/Skeleton/HeaderSkeleton";
import { useShowNotification } from "contexts/showNotificationsContext";
import NotificationsContainer from "pages/Notifications/NotificationsContainer";
import { useGetNotifications } from "contexts/notifications/notificationsContext";
import { NotificationCustomIcon } from "./NotificationCustomIcon";
import { useEffect } from "react";
import { isValidString } from "helpers/formats/StringFormats";

const date = moment().locale('pt-br');
const theme = activeTheme();

const dayOfWeek = () => date.format('dddd')
const month = () => date.format('MMMM')
const day = () => date.format('DD')
const year = () => date.format('yyyy')

const VCenteredBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	'@media print': {
		display: 'none',
	}
}));

const RightBox = styled(VCenteredBox)(() => ({
	display: 'flex',
	justifyContent: 'right',
	'@media print': {
		display: 'none',
	}
}));


export const firstName = (name: string | undefined) => {
	if (!!name) {
		return name?.split(' ')[0]
	}
	return undefined;
}

function Hello() {
	const { user } = useIdentity();
	const { appConfig } = useAppConfig();
	const { data: notificationsData, refetch, isLoading: isLoadingNotifications } = useGetNotifications();
	const hasSearchApiUrl = isValidString(String(appConfig.SEARCH_API_URL));
	const { enableNotifications, setEnableNotifications } = useShowNotification();
	const name = firstName(user?.name);
	const isRootTenancy = appConfig.TENANT_TYPE === 0;
	const showTenantFilter = appConfig.USE_TENANT_FILTER === 1;

	const { 
		setSelectedTenant, 
		tenantAutoCompleteProps, 
		isLoading: isLoadingTenant 
	} = useTenant();
	
	useEffect(() => {
	    if (!!hasSearchApiUrl) {
			refetch();
		}
	}, [enableNotifications]); 

	const handleOpenOrClose = (enable: boolean) => {
		if (!!hasSearchApiUrl) {
			setEnableNotifications(enable);
		}
	}

	return (
		<Grid sx={{ borderBottom: `0.75px solid ` + theme.palette.grey[200] }}>
			<Stack direction='row' alignItems='center' justifyContent='space-between' m='20px 0'>
				<Grid item>
					<VCenteredBox>
						<Typography variant="h4">
							Olá <PrimarySpan> {name ? ` ${name}` : ''}</PrimarySpan>, hoje é {dayOfWeek()}, <PrimarySpan>{day()} de {month()} de {year()}</PrimarySpan>
						</Typography>
					</VCenteredBox>
				</Grid>
				<Grid>
					<HeaderSkeleton {...{ isLoading: isLoadingTenant }}>
						<RightBox
							mr={2}>
							{(isRootTenancy || showTenantFilter) && <RightBox
								mr={4}>
								<Grid item sx={{ width: 220 }}>
									<Autocomplete
										name="tenant"
										label="Correspondente"
										onChange={(_, option, reason) => {
											setSelectedTenant({ label: option?.label, value: option?.value as any });
											if (reason === 'clear') {
												setSelectedTenant(undefined);
											}
										}}
										listOptions={removeDuplicateData(tenantAutoCompleteProps.listOptions, "label")}
										loading={tenantAutoCompleteProps.loading}
									/>
								</Grid>
							</RightBox>}
							<RightBox mr={3}>
								{enableNotifications ? (
									<IconButton onClick={(e) => handleOpenOrClose(false)}>
										<NotificationRingingIcon
											htmlColor={theme.palette.grey['600']}
											sx={{ height: () => theme.spacing(3.5), width: (theme) => theme.spacing(3.5) }}
										/>
									</IconButton>
								) : (
									<NotificationCustomIcon {...{
										showNotifications: () => handleOpenOrClose(true),
										notificationsData
									}} />
								)}
							</RightBox>
							<Avatar></Avatar>
						</RightBox>
					</HeaderSkeleton>
				</Grid>
			</Stack>

			<NotificationsContainer {...{
				isLoading: isLoadingNotifications, 
				notificationsData
			}}/>
		</Grid>
	);
}

export default Hello;
