import { Grid, Stack } from '@mui/material'
import { Button } from '@uy3/web-components';
import { ModalState, PopupState } from 'contexts/creditNote/creditNoteActions.interface';
import React from 'react'
import { TextFormField } from 'components/Forms/FormFields';

type ApproveSignatureType = {
    setPopupProps: (value: React.SetStateAction<PopupState | undefined>) => void
    setModalProps: (value: React.SetStateAction<ModalState | undefined>) => void
}

const ApproveSignature = ({ setModalProps, setPopupProps }: ApproveSignatureType) => {
    return (
        <Stack direction='column' mt={3}>
            <TextFormField
                name="message"
                label="Mensagem"
                multiline
                rows={3}
                variant='outlined'
                fullWidth
            />
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => { setPopupProps(undefined); setModalProps(undefined); }}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type='submit'
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    )
}

export default ApproveSignature
