enum ESignatureValidation {
    EletronicSignatureAndDocument = 'EletronicSignatureAndDocument', // Assinatura eletrônica + Documento
    SelfieSignature = 'SelfieSignature', // Assinatura com selfie
    SelfieDocumentSignature = 'SelfieDocumentSignature', // Assinatura com selfie + documento
    FacialBiometrics = 'FacialBiometrics', // Biometria facial
    DigitalCertificate = 'DigitalCertificate', // Certificado digital
    EletronicSignature = 'EletronicSignature', // Assinatura eletrônica (manuscrita)
}

enum ESignatureType {
    Email = 'email',
    SMS = 'sms',
    WhatsApp = 'whatsapp',
}

enum EViewType {
    BottomToTop = 0, //Fechado
    TopToBottom = 1, //Aberto
}

enum ESignaturePortalProvider {
    RBM = 0,
    ClickSign = 1,
    QuickSoft = 2,
    UnicoCheck = 3,
}

enum ETypeOfRelationship {
    Issuer = 0,
    PartnerGuarantor = 1,
    Guarantor = 2,
    Proxy = 3,
    ProxyGuarantor = 4,
    Partner = 5,
    Spouse = 6,
    Consenter = 7,
    Witness = 8,
    Assignor = 9,
    Assignee = 10,
    Endorser = 11,
    Employer = 12,
    Creditor = 13,
    Debtor = 14,
    PartnerDebtor = 15,
    BillingAgent = 16,
    BillingAgentApproverUser = 17,
    Beneficiary = 18,
    NotaryAgent = 19,
    SettlementAgent = 20,
    CreditorEndorser = 21,
    FaithfulCustodian = 22,
    JointGuarantor = 23,
}

enum ECommissionType {
    Absolute = 0,
    Percentage = 1,
}

enum ECommissionBaseValue {
    InitialValue = 0,
    RequestedValue = 1,
}

enum ECalculationType {
    V252DiasUteis = 'V252DiasUteis',
    V252MesesX21 = 'V252MesesX21',
    V360DiasCorridos = 'V360DiasCorridos',
    V360Meses = 'V360Meses',
    V365DiasCorridos = 'V365DiasCorridos',
    V365Meses = 'V365Meses',
    Irregular = 'Irregular',
}

enum EPaymentMonth {
    NotSet = 'NotSet',
    January = 'January',
    February = 'February',
    March = 'March',
    April = 'April',
    May = 'May',
    June = 'June',
    July = 'July',
    August = 'August',
    September = 'September',
    October = 'October',
    November = 'November',
    December = 'December',
}

enum EPeriodicity {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Daily = 'Daily',
}

enum ECalculateByValueType {
    Gross = 'Gross',
    Liquid = 'Liquid',
    Payment = 'Payment',
}

enum EOperationType {
    Transfer = 1,
    Pix = 2,
}

enum EPixKeyType {
    NaturalRegistrationNumber = 1,
    LegalRegistrationNumber = 2,
    Phone = 3,
    Email = 4,
    Automatic = 5,
    AgencyAndAccount = 6,
}

enum ERelatedBankAccountType {
    NaturalCheckingAccount = 1, // Conta Corrente de Pessoa Física
    NaturalSimpleAccount = 2, // Conta Pagamento de Pessoa Física
    LegalCheckingAccount = 3, // Conta Corrente de Pessoa Jurídica
    PublicEntities = 6, // Entidades Públicas
    FinancialInstitutionsDeposits = 7, // Depósitos Instituições Financeiras
    NaturalSavingsAccount = 13, // Poupança de Pessoa Física
    LegalSavingsAccount = 22, // Poupança de Pessoa Jurídica
    RealEstateSavingsAccount = 28, // Poupança de Crédito Imobiliário
    LotteryDeposits = 43, // Depósitos Lotéricos
    EscrowAccount = 44, // Conta Escrow
}

export {
    ESignatureValidation,
    ESignatureType,
    EViewType,
    ESignaturePortalProvider,
    ETypeOfRelationship,
    ECommissionType,
    ECommissionBaseValue,
    ECalculationType,
    EPaymentMonth,
    EPeriodicity,
    ECalculateByValueType,
    EOperationType,
    EPixKeyType,
    ERelatedBankAccountType
};
