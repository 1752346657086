import { FunctionComponent } from 'react';
import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { TextFormField, SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { MaskedInput } from 'components/Forms/MaskedInput';
import {
    typeAccountsLegalPerson,
    typeAccountsNaturalPerson,
} from 'contexts/bankAccount/bankAccountType';
import Grid from '@mui/material/Grid';
import { activeTheme } from 'services/theme';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useBankList } from 'contexts/bankList';
import { phoneNumberMask } from 'utils/constants';
import { cnpjMask, cpfMask } from 'utils/constants';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { pixKeyTypeOpt } from 'services/accounts/bankAccount/bankAccount.types';
import { useAppConfig } from 'contexts/appConfig';
import { getFieldOptions } from 'helpers/methods/formFieldConfiguration';

export interface FavorecidoDrawerProps {
    onClose?: () => void;
    isLegalPerson: boolean;
}
const theme = activeTheme();

export const BankDetails: FunctionComponent<FavorecidoDrawerProps> = ({
    isLegalPerson,
    ...props
}) => {
    const { onClose } = props;
    const { appConfig } = useAppConfig();
    const { watch, setValue } = useFormContext();
    const isOperationTypeValueTransfer = watch('operationTypeValue') === 'Transfer';
    const { bankListAutocomplete } = useBankList({ compe: isOperationTypeValueTransfer });
    const { submitting } = useApiRequest();

    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const operationTypeValueOption =
        getFieldOptions('operationTypeValue', formFieldsConfig) ||
        ([
            { label: 'Transferência', value: 'Transfer' },
            { label: 'Pix', value: 'Pix' },
        ].filter((i) => i !== undefined) as SelectOption[]);

    const fieldOptions = getFieldOptions('type', formFieldsConfig);
    const filteredOptions =
        !!fieldOptions &&
        fieldOptions.map((option) => ({ label: option.label, value: option.value }));

    const typeAccountOptions =
        filteredOptions || (isLegalPerson ? typeAccountsLegalPerson : typeAccountsNaturalPerson);

    return (
        <Stack direction="column" spacing={4}>
            <SelectFormField
                id="operationTypeValue"
                name="operationTypeValue"
                label="Tipo de Operação"
                options={operationTypeValueOption}
            />

            {watch('operationTypeValue') === 'Pix' && (
                <Grid item xs={6}>
                    <SelectFormField
                        name="pixKeyTypeValue"
                        label="Tipo de chave Pix"
                        defaultValue={false}
                        options={pixKeyTypeOpt(isLegalPerson)}
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'Phone' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        id="keyPix"
                        name="keyPix"
                        label={`Telefone`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: phoneNumberMask },
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'Automatic' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        id="keyPix"
                        name="keyPix"
                        label={`Chave aleatória`}
                        fullWidth
                    />
                </Grid>
            )}

            {watch('pixKeyTypeValue') === 'NaturalRegistrationNumber' ? (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        id="keyPix"
                        name="keyPix"
                        label={`CPF`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: cpfMask },
                        }}
                        fullWidth
                    />
                </Grid>
            ) : (
                <></>
            )}

            {watch('pixKeyTypeValue') === 'LegalRegistrationNumber' && (
                <Grid item xs={6}>
                    <TextFormField
                        style={{ width: '100%' }}
                        name="keyPix"
                        variant="outlined"
                        label="CNPJ"
                        placeholder="00.000.000/0000-00"
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: cnpjMask },
                        }}
                    />
                </Grid>
            )}
            {watch('pixKeyTypeValue') === 'Email' && (
                <Grid item xs={6}>
                    <TextFormField
                        id="keyPix"
                        name="keyPix"
                        label={`Email`}
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event?.target?.value;
                            setValue('keyPix', value);
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {isOperationTypeValueTransfer || watch('pixKeyTypeValue') === 'AgencyAndAccount' ? (
                <>
                    <Grid item xs={6}>
                        <AutocompleteField
                            name="bankCode"
                            label="Banco"
                            displayName="bankCodeDisplay"
                            required
                            {...bankListAutocomplete}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <SelectFormField
                            name="type"
                            id="type"
                            required
                            label="Tipo de conta"
                            defaultValue={false}
                            options={typeAccountOptions}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            id="agency"
                            label="Agência (sem o digito)"
                            placeholder='Ex: "1234"'
                            name="agency"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            id="account"
                            label="Conta"
                            name="account"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            variant="outlined"
                            label="Dígito da conta"
                            placeholder='Ex: "1"'
                            name="accountDigit"
                            fullWidth
                        />
                    </Grid>
                </>
            ) : (
                <></>
            )}

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} mt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    disabled={submitting}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-submit-bank-details"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
