import { Grid, Box, Stack } from '@mui/material';
import {
    Typography,
    SaveIcon,
    Alert,
    PersonIcon,
    DeleteIcon,
    UpdateIcon,
    ShareIcon,
} from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { activeTheme } from 'services/theme';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType, showSuccessToast } from 'contexts/apiRequestContext';
import { Actions } from 'components/Actions/Actions';
import { useWatch } from 'react-hook-form';
import { useAppConfig } from 'contexts/appConfig';
import {
    hasCustomPermission,
    isActionAllowed,
    isRootTenancy,
} from 'helpers/methods/GenericMethods';
import { CreditProductReadModel } from 'services/creditProduct/types/creditProductReadModel';
import moment from 'moment';

type ProdcuctCreditType = {
    productId: string;
    mode: FormMode;
    setAction: (action: string) => void;
    refetch: () => void;
    isFetching: boolean;
    setToast: Dispatch<SetStateAction<ToastType>>;
    hasPermission: (resource: string | string[], type: any) => boolean;
};

const theme = activeTheme();
const CreditProductForm = ({
    productId,
    mode,
    setAction,
    refetch,
    isFetching,
    setToast,
    hasPermission,
}: ProdcuctCreditType) => {
    const { validationErrors, isDirty, control, readOnly, getValues, watch } = useFormContext();
    const { appConfig } = useAppConfig();

    const newProduct = mode === 'create';
    const productName = useWatch({
        control,
        name: 'name',
    });

    const handleOnUpdate = () => {
        refetch();
        const title = 'Produto atualizado com sucesso!';
        const desc = 'As informações do produto foram atualizadas com sucesso.';
        showSuccessToast(title, desc, setToast);
    };

    const resource = 'CreditProduct';
    const typePermission = newProduct ? 'Create' : 'Update';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, rootTenancy);
    const instrumentType = getValues('instrumentType');
    const showPublicPayrollLoan = instrumentType === 'PublicPayrollLoan';
    const createdBy = watch('createdBy.userIdDisplay');
    const createdAt = `Criado em ${moment(watch('createdAt')).format('DD/MM/YYYY')} ${createdBy ? ` por ${createdBy}` : ''}`;

    const tabs = [
        {
            label: 'Informações',
            href: productId,
        },
        {
            label: 'Envolvidos',
            href: 'envolvidos',
        },
        {
            label: 'Documentos obrigatórios',
            href: 'documentos',
        },
        {
            label: 'Custo de Emissão',
            href: 'custo-de-emissao',
        },
        ...(showPublicPayrollLoan ? [{ label: 'Orgão', href: 'orgao' }] : []),
    ];

    return (
        <>
            {validationErrors && (
                <Box mt={2} mb={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box>
                <NotSavedChangesAlert isDirty={isDirty} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                    <Grid>
                        <Typography
                            variant="h4"
                            color="neutral.dark"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="32px"
                            lineHeight="38.4px"
                        >
                            {newProduct ? 'Novo cadastro' : productName}
                        </Typography>
                        <Grid xs={6} lineHeight='2rem'>
                            <Typography color="neutral.medium" variant="xs">
                                {!newProduct ? `Status de vigência: ${watch('termStatusDisplay') ?? ""}` : ''}
                            </Typography>
                            <br />
                            <Typography color="neutral.medium" variant="xs">
                                {createdAt}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Actions
                        numberOfButtons={4}
                        buttonsActionsList={[
                            {
                                label: 'Salvar',
                                enable: enableAction,
                                type: 'submit',
                                icon: <SaveIcon htmlColor={theme.palette.common.black} />,
                            },
                            {
                                enable: !newProduct,
                                label: 'Atualizar',
                                action: handleOnUpdate,
                                icon: <UpdateIcon htmlColor={theme.palette.common.black} />,
                            },
                            {
                                enable: !newProduct && enableAction,
                                label: 'Atribuir',
                                action: () => setAction('assign'),
                                icon: <PersonIcon htmlColor={theme.palette.common.black} />,
                            },
                            {
                                enable: !newProduct && enableAction,
                                label: 'Clonar produto',
                                action: () => setAction('cloneProduct'),
                                icon: <ShareIcon htmlColor={theme.palette.common.black} />,
                            },
                            {
                                enable:
                                    !newProduct &&
                                    hasPermission('CreditProduct', 'Delete') &&
                                    rootTenancy,
                                label: 'Excluir',
                                action: () => setAction('delete'),
                                icon: <DeleteIcon htmlColor={theme.palette.common.black} />,
                            },
                        ]}
                    />
                </Stack>
                <OperatorViewInfo />
                <Grid sx={{ mr: 3 }}>
                    <TabBar tabs={tabs} />
                </Grid>
            </Box>
        </>
    );
};

type ProductInFormContextProps = {
    product: CreditProductReadModel | undefined;
};

const CreditNoteInformationFormContext = createContext<ProductInFormContextProps>({
    product: undefined,
});

interface ProductInFormContextProviderProps {
    children: JSX.Element;
    product: CreditProductReadModel | undefined;
}

export const CreditNoteInformationFormContextProvider = ({
    children,
    product,
}: ProductInFormContextProviderProps) => {
    return (
        <CreditNoteInformationFormContext.Provider
            value={{
                product,
            }}
        >
            {children}
        </CreditNoteInformationFormContext.Provider>
    );
};

export function useCreditNoteFormContext() {
    const context = useContext(CreditNoteInformationFormContext);
    const { product } = context;
    return {
        product,
    };
}

export default CreditProductForm;
