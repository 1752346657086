import { FormProvider } from 'contexts/formContext'
import { DeleteCheckListForm } from './DeleteCheckListForm'
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext'
import { AttributeTypeChecklist, deleteComplianceChecklistByIds } from 'services/creditNote/complianceChecklist/complianceChecklist'
import { validationSchemaDeleteCheckList, defaultvaluesDeleteCheckList } from './DeleteCheckListSchema'
import { SelectOption } from 'components/Forms/FormFields'
import { useIdentity } from 'contexts/identityContext'
import { useState } from 'react'
import { ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import Toast from 'components/Toast/Toast'
import { attributesTypeFormat } from 'helpers/formats/StringFormats'

type DeleteCheckListContainerProps = {
  onClose: () => void;
  personId: string;
};

export const DeleteCheckListContainer: React.FC<DeleteCheckListContainerProps> = ({ onClose, personId }) => {
  const [toast, setToast] = useState<ToastType>(toastState);
  const { token } = useIdentity();
  const { data, refetch } = useComplianceChecklistVerifyPersonId(personId!);

  const options = data?.map((option: AttributeTypeChecklist) => {
    return { label: attributesTypeFormat(option.attributeType), value: option.id }
  });

  const onDeleteComplianceChecklist = async (values: any) => {
    const complianceChecklist = values?.complianceChecklist;
    const getAttributes = options.filter((options: SelectOption) => complianceChecklist.includes(options.value));
    const ids = getAttributes.map((option: SelectOption) => option.value);
    await deleteComplianceChecklistByIds({ ids, token: token! })
      .then(() => {
        const title = "Restrições removidas com sucesso!";
        const description = undefined;
        showSuccessToast(title, description, setToast);
        refetch();
        onClose();
      })
      .catch((error) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
      });
  }

  return (
    <>
      <Toast toast={toast} setToast={setToast} />
      <FormProvider
        validationSchema={validationSchemaDeleteCheckList()}
        defaultValues={defaultvaluesDeleteCheckList}
        onSubmit={onDeleteComplianceChecklist}
      >
        <DeleteCheckListForm options={options} onClose={onClose} />
      </FormProvider>
    </>
  )
}