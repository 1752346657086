import { useState } from 'react';
import { useParams } from 'react-router-dom';
import TableTransfer from './TableTransfer';
import {
    useBankAccountTransferOrPixList,
    useBankAccountTransferReceiptMutation,
} from 'contexts/bankAccount/bankAccountContext';
import {
    ApiResponseError,
    BankAccountOperationType,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { Drawer } from '@uy3/web-components';
import { BankAccountItemDetails } from 'pages/BankAccount/BankAccountActive/BankAccountDrawerItemsDetails/BankAccountItemDetails';
import { FilterProvider } from 'contexts/filterContext';
import { statusBankAccountOptions } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import { FormProvider } from 'contexts/formContext';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { AxiosResponse } from 'axios';
import {
    approveOrRejectBankAccountByResource,
    putCancelTransfer,
} from 'services/accounts/bankAccount/bankAccount';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import Offline from 'components/Offline/Offline';
import TransferHeader from './TransferHeaderList';
import ButtonExport from './ButtonExport';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { ActionApproveOrRejectType } from 'services/accounts/bankAccount/bankAccount.types';

export type TableTransferContainerProps = {
    operationType: BankAccountOperationType;
    isRefreshing?: any;
    setIsRefreshing?: any;
    isLoading?: boolean
};

export function TableTransferContainer({
    operationType,
    isRefreshing,
    setIsRefreshing,
    isLoading
}: TableTransferContainerProps) {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [page, setPage] = useState(0);
    const [transferId, setTransferId] = useState();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [stepActionTransfer, setStepActionTransfer] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const [transferOrPixInfo, setTransferOrPixInfo] = useState<any>(undefined);
    const { startRequest, setSubmitError, endRequest, submitting } = useApiRequest();
    const {
        status: statusTransfer,
        data: dataListTransfer,
        error: errorTranfer,
        isFetching,
        refetch,
    } = useBankAccountTransferOrPixList(bankAccountId!, {
        page,
        size: rowsPerPage,
        f_operationType: operationType,
    });

    const onClose = () => {
        setStepActionTransfer(undefined);
        setSubmitError(undefined);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const { mutateTransferAndPayment } =
        useBankAccountTransferReceiptMutation(bankAccountId!);

    if (isRefreshing) {
        refetch();
        setIsRefreshing(false);
    }

    const handleTransferReceipt = async (values: any) => mutateTransferAndPayment(values);

    if (statusTransfer === 'error') {
        const { errorMessage } = mapErrorResponse(errorTranfer as ApiResponseError);
        return <Offline errorMessage={errorMessage} highlightedText='Transferência' />;
    }

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onCancelTransfer = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: (response: AxiosResponse<string, any>) => {
                let payload = {
                    code: values?.code,
                    sessionId: response.data,
                    token: token!,
                    bankAccountId: bankAccountId!,
                    beneficiaryId: transferId!,
                };
                putCancelTransfer(payload)
                    .then(() => {
                        endRequest(true);
                        const title = 'Transferência cancelada com sucesso!';
                        const description = 'Ótimo! Agora você pode realizar uma nova transferência.';
                        showSuccessToast(title, description, setToast);
                        onClose();
                    })
                    .catch((error) => {
                        endRequest(false);
                        const { errorMessage } = mapErrorResponse(error);
                        const title = 'Ops, ocorreu um erro!';
                        const description = errorMessage;
                        showErrorToast(title, description, setToast);

                        setSubmitError(error);
                        endRequest(false);
                    });
            },
        });
    };

    const onApproveOrRejectTransfer = async (
        values: IMFAState,
        approveOrReject: 'Approve' | 'Reject'
    ) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then:  async (response: AxiosResponse<string, any>) => {
                let data = {
                    payload: {
                        action: approveOrReject, 
                        code: values?.code, 
                        sessionId: response.data, 
                        transferBankAccounts: [{BankAccountId: bankAccountId, TransferId: transferId!}]
                    }, 
                    resource: 'Transfer'
                } as ActionApproveOrRejectType;

                await approveOrRejectBankAccountByResource(bankAccountId!, transferId!, data, token!)
                    .then(() => {
                        endRequest(true);
                        const title = `Transferência ${approveOrReject === 'Approve' ? 'aprovada' : 'rejeitada'
                            } com sucesso!`;
                        const description =
                            'Ótimo! Agora você pode realizar uma nova transferência.';
                        showSuccessToast(title, description, setToast);

                        onClose();
                    })
                    .catch((error: ApiResponseError) => {
                        endRequest(false);
                        const { errorMessage } = mapErrorResponse(error);
                        const title = 'Ops, ocorreu um erro!';
                        const description = errorMessage;
                        showErrorToast(title, description, setToast);

                        setSubmitError(error);
                        endRequest(false);
                    });
            },
        });
    };

    const mfaSchema = validationConfirmMfaFormSchema();
    const mfaDefaultValues = defaultValuesConfirmMfaForm;


    const drawerForm: { [key: string]: any } = {
        cancelTransfer: {
            title: 'Cancelar transferência',
            onSubmit: onCancelTransfer,
            onClose: onClose,
            open: true,
        },
        approveTransfer: {
            title: 'Aprovar transferência',
            onSubmit: (values: IMFAState) => onApproveOrRejectTransfer(values, 'Approve'),
            onClose: onClose,
            open: true,
        },
        rejectTransfer: {
            title: 'Rejeitar transferência',
            onSubmit: (values: IMFAState) => onApproveOrRejectTransfer(values, 'Reject'),
            onClose: onClose,
            open: true
        },
    }

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <TransferHeader isFetching={isFetching || submitting} refetch={refetch} bankAccountId={bankAccountId} />

            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    f_transferDate: { label: 'Data da ocorrência', type: 'date' },
                    f_beneficiaryName: { label: 'Favorecido', type: 'text' },
                    f_beneficiaryRegistrationNumber: { label: 'CPF/CNPJ', type: 'text' },
                    f_status: {
                        label: 'Status',
                        type: 'select',
                        multiple: false,
                        options: statusBankAccountOptions,
                    },
                }}
            >
                <ListSkeleton isLoading={isLoading!}>
                    <TableTransfer
                        setStepActionTransfer={setStepActionTransfer}
                        setTransferId={setTransferId}
                        handleTransferReceipt={handleTransferReceipt}
                        setTransferAndPixInfo={setTransferOrPixInfo}
                        queryData={dataListTransfer !== undefined ? dataListTransfer : []}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                    />
                </ListSkeleton>
            </FilterProvider>
            <ButtonExport />

            <Drawer
                anchor="right"
                title="Detalhes da aprovação"
                open={transferOrPixInfo !== undefined}
                onClose={() => setTransferOrPixInfo(undefined)}
            >
                <BankAccountItemDetails
                    informationData={transferOrPixInfo}
                    setOpenDrawer={() => setTransferOrPixInfo(undefined)}
                    title="Informações da transferência"
                />
            </Drawer>

            <Drawer anchor="right" title={drawerForm[stepActionTransfer!]?.title} open={drawerForm[stepActionTransfer!]?.open} onClose={onClose}>
                <FormProvider
                    validationSchema={mfaSchema}
                    defaultValues={mfaDefaultValues}
                    onSubmit={drawerForm[stepActionTransfer!]?.onSubmit}
                >
                    <ConfirmMfaForm onClose={drawerForm[stepActionTransfer!]?.onClose} />
                </FormProvider>
            </Drawer>

        </>
    );
}
