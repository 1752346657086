import { FormProvider } from "contexts/formContext";
import { defaultValuesCreditLimitForm, validationSchemaCreditLimitForm } from "../CreditLimitSchema";
import { useCreditStatusRequestMutation } from "contexts/creditStatusRequest";
import { useCreditProdutList } from "contexts/creditProductContext";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { CreditStatusRequestPost } from "services/creditStatusRequest/creditStatusRequest.types";
import { useParams } from "react-router";
import CreditRequestForm from "./CreditRequestForm";

type CreditRequestContainerProps = {
    closeModal: () => void;
    refetch: () => void;
    id: string | undefined;
};

function CreditRequestFormContainer({ refetch, closeModal, id }: CreditRequestContainerProps) {
    const { setSubmitError } = useApiRequest();
    const { personId } = useParams();
    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, "always");

    const schema = validationSchemaCreditLimitForm();

    const onSuccess = () => { refetch(); closeModal(); };
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutateAsync } = useCreditStatusRequestMutation(onSuccess, onError);
    const handleSubmit = async (values: CreditStatusRequestPost) => {
        mutateAsync({ ...values, personId: id ?? personId! });
    };

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValuesCreditLimitForm}
            onSubmit={handleSubmit}>
            <CreditRequestForm productList={creditProductAutoCompleteProps} closeModal={closeModal} />
        </FormProvider>
    );

};

export default CreditRequestFormContainer;