import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { creditAxiosApi } from "services/axiosApi/axiosApi";

type AssignProps = {
    recordId: string | undefined,
    recordType: string,
    userId: string,
    groupName: string,
    selectedTenant: string | undefined
}

export async function assign(props: AssignProps, token: string) {
    const { recordId, recordType, userId, groupName, selectedTenant } = props;

    var url = `/${recordType}/${recordId}/assign`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const payload = { 
        userId, 
        groupName, 
        tenant: selectedTenant 
    }; 

    if (['Wallet'].includes(recordType)) {
        return billingAxiosApi.post(url, payload, config);
    }

    return creditAxiosApi.post(url, payload, config);
}