import { IDesconto } from "services/creditNote/types/creditNoteReadModel";
import { InferType, object, string } from "yup";

export const openCreditNoteSchema = () => {
    return object().shape({
        productId: string().typeError('Selecione um produto').required('Selecione um produto'),
        personId: string().typeError('Tomador inválido').required('Tomador: Precisa ser preenchido.'),
        bankAccountId: string().typeError('Conta inválida').required('Valor obrigatório para liquidações via TED.'),
        fundId: string().typeError("Dundo: precisa ser preenchido").required("Dundo: precisa ser preenchido")
    });
};

export const openCreditNoteDefaultValues = (descontoData: IDesconto | null) => {
    return {
        personId: descontoData?.personId ?? null,
        personDisplay:  descontoData?.personId !== null ? descontoData?.personDisplay ?? null : null,
        personDiscriminator: descontoData?.personDiscriminator ?? null, 
        productId: null,
        fundId: null,
        bankAccountId: null,
    }
};

let inferedSchema = openCreditNoteSchema();

export type OpenCreditNoteType = InferType<typeof inferedSchema>;