import { IconButton } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid/';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { ProductFiltersContainer } from 'components/DataTableFilters/ProductFilters/ProductFiltersContainer';
import { useAppConfig } from 'contexts/appConfig';
import { iconSx } from 'contexts/apiRequestContext';

interface IBankAccountProductListProps {
    queryData: any;
    rowsPerPage: number;
    page: number;
    setOpenPopup: (rowData: any) => void;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    hasPermission: (resource: string | string[], type: any) => boolean
}

const BankAccountProductList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setOpenPopup,
    hasPermission
}: IBankAccountProductListProps) => {
    const { appConfig } = useAppConfig();
    const isRootTenancy = appConfig.TENANT_TYPE === 0;
    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columnsBankAccountProduct = [
        isRootTenancy
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                minWidth: 120,
                flex: 2,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <div
                            style={{
                                textAlign: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            {cellValues.value}
                        </div>
                    );
                },
            }
            : undefined,
        {
            field: 'name',
            headerName: 'Produto',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'bankingAccountType',
            headerName: 'Tipo de Conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value === 'Payment' ? 'Conta de Livre Movimentação' : 'Escrow'}
                    </div>
                );
            },
        },
        {
            field: 'packageValue',
            headerName: 'Valor do Pacote',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {formatCurrencyInCents(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Link to={`/cadastro/produtos-conta/${cellValues.id}`}>
                            <IconButton name='btn-edit' aria-label='Editar'>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {hasPermission('BankAccountProduct', 'Delete') &&
                            <IconButton onClick={() => setOpenPopup(cellValues)} name='btn-delete'>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsBankAccountProduct as GridColDef[];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <ProductFiltersContainer typeFilter="bankAccountProduct" />,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default BankAccountProductList;
