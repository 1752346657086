import { Grid, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataTable, DeleteIcon, SearchIcon } from '@uy3/web-components';
import { WalletFiltersContainer } from 'components/DataTableFilters/WalletFilters/WalletFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import { useAppConfig } from 'contexts/appConfig';
import { useUserPermissionData } from 'contexts/userContext';
import { useNavigate } from 'react-router';

interface WalletsListProps {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    deleteWalleById: (walletId: string) => void;
}

export const WalletsList: React.FC<WalletsListProps> = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    deleteWalleById
}) => {
    const { hasPermission } = useUserPermissionData();
    const navigate = useNavigate();
    const { appConfig } = useAppConfig();

    const isRootTenancy = appConfig.TENANT_TYPE === 0;

    const columns = [
        isRootTenancy
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                align: 'left',
                editable: false,
                minWidth: 150,
                flex: 2,
                renderCell: (cellValues: GridRenderCellParams) => cellValues.value,
            }
            : undefined,
        {
            field: 'walletCode',
            headerName: 'Código do Convênio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'beneficiaryName',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'bankAccount',
            headerName: 'Conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'ownerGroup.groupDisplay',
            headerName: 'Grupo proprietário',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({row}: GridRenderCellParams) => {
                return row?.ownerGroup?.groupDisplay ?? "N/D"
            }
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ value }: GridRenderCellParams) => {
                return new Date(value).toLocaleDateString('pt-BR')
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            flex: 0,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }: GridRenderCellParams) => {
                const walletId = row?.id
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <RowActions
                            listButtons={[
                                {
                                    action: () => navigate(`/gestao-carteira/carteiras/${walletId}`),
                                    disabled: false,
                                    label: 'Visualizar',
                                    hide: false,
                                    icon: <SearchIcon sx={iconSx} />
                                },
                                {
                                    action: () => deleteWalleById(walletId),
                                    disabled: false,
                                    label: 'Excluir',
                                    hide: !hasPermission('BillingAccount', 'Delete'),
                                    icon: <DeleteIcon sx={iconSx} />
                                },
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columnsWalletList = columns as GridColDef[];


    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <WalletFiltersContainer />
            }}
            columns={columnsWalletList}
            rowsPerPage={rowsPerPage}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};