import { Drawer, TextField } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import { WarrantyForm } from './WarrantyForm';
import { Grid } from '@mui/material';
import { UF } from 'services/zipCode/zipcode.types';
import { WarrantyFullType, WarrantyItem, WarrantyType } from './WarrantyTypes';
import { defaultValuesWarranty, validationSchemaWarranty } from './WarrantyFormSchema';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { onlyNumbers } from "helpers/validations/formFieldValidator";

interface WarrantyFormContainerProps {
    selectedWarranty: number | undefined;
    onCloseDrawer: () => void
}

const WarrantyFormContainer: React.FC<WarrantyFormContainerProps> = ({
    selectedWarranty,
    onCloseDrawer
}) => {
    const [warrantyType, setWarrantyType] = useState<WarrantyType | undefined>(undefined)
    const { setValue, watch, validationErrors } = useFormContext();
    const { product } = useCreditNoteFormContext();

    const instrumentType = product?.instrumentType?.toLowerCase();
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();

    const warrantyData = (watch('warranty') ?? [])?.map((item: any) => {
        if (item?.warrantyType?.toLowerCase() === 'invoicefactoring') {
            return {
                ...item,
                invoiceValue: Number(item?.invoiceValue) ?? null,
                discountValue: Number(item?.discountValue) ?? null,
                presentValue: Number(item?.presentValue) ?? null,
                paymentAmountInCents: Number(item?.paymentAmountInCents) ?? null,
            }
        }
        return item;
    });
    const existsWarrantyPublicPayroll = warrantyData?.filter((x: WarrantyFullType) => x.warrantyType === 'PublicPayroll')?.length > 0;

    const willUpdateRequestedAmount = instrumentType === 'publicpayrollloan' && warrantyRegistrationOffice === 'siape';


    const onSubmit = (item: WarrantyItem) => {
        let newRelatedPerson = [...warrantyData];
        const warrantyType = item.warrantyType;

        //@ts-ignore
        const paymentAmountInCents = Number(item?.paymentAmountInCents) ?? 0;
        const isPublicPayroll = warrantyType === 'PublicPayroll';
        const isConsortium = warrantyType === 'Consortium';

        if (isPublicPayroll && existsWarrantyPublicPayroll) {
            const index = warrantyData.findIndex((x: WarrantyFullType) => x?.warrantyType === "PublicPayroll");
            newRelatedPerson[index!] = { ...item, paymentAmountInCents };
        }
        else if (isConsortium) {
            //@ts-ignore
            const administratorRegistrationNumber = (onlyNumbers(item?.administratorRegistrationNumber)) ?? null;
            //@ts-ignore
            const administratorName = (item?.administratorName ?? null)
            newRelatedPerson[selectedWarranty!] = {
                ...item,
                administratorRegistrationNumber, administratorName
            };
        }
        else {
            newRelatedPerson[selectedWarranty!] = item;
        }

        if (willUpdateRequestedAmount && isPublicPayroll) {
            setValue('amortization.requestedAmount', paymentAmountInCents)
        }

        setValue('warranty', newRelatedPerson);
        onCloseDrawer();
    };

    const defaultValue = (selectedWarranty !== undefined && warrantyData?.length !== selectedWarranty && warrantyData[selectedWarranty!]) ?
        warrantyData[selectedWarranty!] : defaultValuesWarranty(warrantyType);

    let errorMsg = '';
    if (validationErrors !== undefined) {
        Object.keys(validationErrors).forEach((key) => {
            if (key === 'collateral') {
                errorMsg = validationErrors[key]!.message as string;
            }
        });
    }


    return (
        <>
            <Drawer
                anchor="right"
                title="Adicionar/Alterar Garantia"
                open={selectedWarranty !== undefined}
                onClose={onCloseDrawer}
            >
                <FormProvider
                    validationSchema={validationSchemaWarranty}
                    defaultValues={defaultValue}
                    onSubmit={onSubmit}
                    onChangeField={[{
                        fieldName: "warrantyType",
                        delegate: (value: WarrantyType) => setWarrantyType(value)
                    }]}
                >
                    <WarrantyForm
                        onClose={onCloseDrawer}
                        ufList={UF}

                    />
                </FormProvider>
            </Drawer>
            <Grid mt={2} mr={3}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={watch('collateral')}
                    onChange={({ target: { value } }) => setValue('collateral', value)}
                    label="Observações *"
                    margin="dense"
                    multiline={true}
                    error={errorMsg.length > 0}
                    helperText={errorMsg}
                    rows={4}
                />
            </Grid>
        </>
    );
};

export default WarrantyFormContainer;
