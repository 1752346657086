import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip';

type BatchAssignmentActionsProps = {
    isRootTenancy: boolean;
}

export const BatchAssignmentActions = ({ isRootTenancy }: BatchAssignmentActionsProps) => {
    return (
        <React.Fragment>
            {isRootTenancy &&
                <React.Fragment>
                    <CheckboxWithTooltip
                        checkboxFormField={{ label: 'Salvar documentos', name: 'actions.UploadDocs' }}
                        helpMessage="Permite o usuário salvar os documentos da cessão."
                    />
                </React.Fragment>}
        </React.Fragment>
    )
}
