import { formatPhoneNumberWithNone } from "components/QueryBasicData/SelectOptions/SelectOptions";
import { ApiResponseError } from "contexts/apiRequestContext";
import { IQueryBasicData } from "contexts/personContext";
import { isEmpty } from "lodash";
import moment from "moment";
import { FieldValues } from "react-hook-form";
import { LegalPersonReadModel } from "services/accounts/legalPerson/types/legalPersonReadModel";
import { NaturalPersonReadModel } from "services/accounts/naturalPerson/types/naturalPersonReadModel";
import { getPersonsByRegistrationNumberAsync } from "services/person";

export function queryBasicDataPersonMethods(
    setValue: (name: string, value: any) => void,
    resource: "NaturalPerson" | "LegalPerson",
    onSuccess: () => void
) {

    const formatDateBeforeSave = (date: string) => {
        const dateFormated = date.slice(0, date.indexOf('T'));
        return moment(dateFormated).utcOffset('-03:00').format();
    }

    const setValueCustom = (name: string, value: any) => {
        if (isEmpty(value)) {
            return setValue(name, null)
        }
        setValue(name, value)
    };

    const setValuesFromNaturalPerson = (data: NaturalPersonReadModel) => {
        setValueCustom('gender', data?.gender === "M" ? "Male" : data?.gender === "F" ? "Female" : "NotInformed");
        setValueCustom('birthDate', formatDateBeforeSave(data?.birthDate));
        setValueCustom('mothersName', data?.mothersName);
        setValueCustom('nationality', data?.nationality);
        setValueCustom('documentType', data?.documentType);
        setValueCustom('name', data?.name);
    }

    const setValuesFromLegalPerson = (data: LegalPersonReadModel) => {
        setValueCustom('companyName', data?.companyName);
        setValueCustom('name', data?.companyName);
        setValueCustom('openDate', formatDateBeforeSave(data?.openDate));
    }

    const handleSetValuesPerson = (
        queryData: IQueryBasicData,
        alternativePhone?: string,
        alternativeAddress?: any,
        alternativeEmail?: string,
    ) => {

        if (resource === 'NaturalPerson')
            setValuesFromNaturalPerson(queryData?.naturalPerson);
        else if (resource === 'LegalPerson')
            setValuesFromLegalPerson(queryData.legalPerson);

        const phone = queryData?.phones[0];
        const phoneNumber = !isEmpty(phone) ? formatPhoneNumberWithNone(phone) : null;

        setValueCustom('phone', alternativePhone ?? phoneNumber);
        setValueCustom('address', alternativeAddress ?? queryData?.addresses[0]);
        setValueCustom('email', alternativeEmail ?? queryData?.emails[0]);
        onSuccess();
        setValue('loadValuesByRegistrationNumber', true);
    }

    const handleSaveValuesByDrawer = (values: FieldValues, queryData: IQueryBasicData) => {
        const phone = values?.phoneNumberSelected;
        const address = queryData.addresses.find(item => `${item?.zipCode + item?.number + item?.addressName}` === values?.addressSelected) ?? null;
        const email = values?.emailSelected;

        handleSetValuesPerson(queryData!, phone, address, email);
    };

    return {
        handleSetValuesPerson, 
        handleSaveValuesByDrawer
    }
}

export const handleSubmitQueryData = async (
    registrationNumber: string,
    token: string,
    onSuccess: (queryData: IQueryBasicData) => void,
    onError: (error: ApiResponseError) => void
) => {
    try {
        var response = await getPersonsByRegistrationNumberAsync(registrationNumber, token!);
        const personData = response?.data as IQueryBasicData;
        onSuccess(personData);
    } catch (error) {
        onError(error as ApiResponseError)
    };

}