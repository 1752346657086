import { useState } from 'react';
import { CommissionRangeList } from './CommissionRangeList';
import { Drawer } from '@uy3/web-components';
import { FormMode, FormProvider, useFormContext } from 'contexts/formContext';
import { CommissionRangeForm } from '../CommissionRangeForm/CommissionRangeForm';
import {
    commissionRangeValidationSchema,
    defaultValuesCommissionRange,
} from '../CommissionRangeForm/CommissionRangeSchema';
import CommissionRangeHeader from './CommissionRangeHeader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { useParams } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import { useAppConfig } from 'contexts/appConfig';
import { hasCustomPermission, isRootTenancy } from 'helpers/methods/GenericMethods';
import { CommissionRangeType } from 'services/creditProduct/types/creditProductReadModel';

export const CommissionRangeContainer = () => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    const [page, setPage] = useState(0);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch, setValue } = useFormContext();
    const { hasPermission } = useUserPermissionData();
    const { productId } = useParams();
    const isNewProduct = productId === 'novo';
    let commissionRange: CommissionRangeType[] = watch('commissionRange') ?? [];
    let min: number = watch('minimumPrincipalAmount') ?? 0;
    let max: number = watch('maximumPrincipalAmount') ?? undefined;

    const onClose = () => {
        setOpenDrawer(false);
        setSelectedIndex(undefined);
    };

    const onSubmit = (values: CommissionRangeType) => {
        const newCommission: CommissionRangeType[] =
            commissionRange.length > 0 ? [...commissionRange] : [];
        if (selectedIndex === undefined) newCommission.push(values);
        else newCommission[selectedIndex!] = values;
        setValue('commissionRange', newCommission);
        onClose();
    };

    const onDelete = (index: number) => {
        const newCommission = [...commissionRange];
        newCommission.splice(index, 1);
        setValue('commissionRange', newCommission);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => setPage(page);

    const validationSchema = commissionRangeValidationSchema(min, max, commissionRange, selectedIndex);
    const defaultValues =
        selectedIndex === undefined
            ? defaultValuesCommissionRange(min, max, commissionRange)
            : commissionRange[selectedIndex!];

    const queryData = commissionRange?.map((cmm) => {
        return {
            ...cmm,
            id: cmm?.id ?? Math.random().toString(),
        };
    });

    const formMode: FormMode = !isNewProduct ? 'update' : 'create';
    const { appConfig } = useAppConfig();
    const resource = "CreditProduct";
    const typePermission = formMode === 'create' ? 'Create' : 'Update';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);
    const enableActionCommission = permissionCustom && rootTenancy;

    const isReadOnly = (): boolean => {     
        if (!permissionCustom || !rootTenancy) return true
        return false;
    }

    return (
        <ErrorBoundary fallback="emissões">
            <Toast toast={toast} setToast={setToast} />
            <CommissionRangeHeader
                isFetching={false}
                setOpenDrawer={() => setOpenDrawer(true)}
                enableActionCommission={enableActionCommission}
            />
            <CommissionRangeList
                queryData={queryData}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                enableActionCommission={enableActionCommission}
                onEdit={(index) => {
                    setSelectedIndex && setSelectedIndex(index ?? commissionRange?.length);
                }}
                onDelete={onDelete}
                page={page}
            />

            <Drawer
                anchor="right"
                title={selectedIndex ? 'Editar emissão' : 'Adicionar emissão'}
                description="Preencha os campos abaixo para adicionar uma emissão"
                open={openDrawer || selectedIndex !== undefined}
                onClose={onClose}
                children={
                    <FormProvider
                        validationSchema={validationSchema}
                        defaultValues={defaultValues}
                        onSubmit={onSubmit}
                        readOnly={isReadOnly()}
                    >
                        <CommissionRangeForm onClose={onClose} />
                    </FormProvider>
                }
            />
        </ErrorBoundary>
    );
};
