import { Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, Typography } from '@uy3/web-components';
import { StatementFiltersContainer } from 'components/DataTableFilters/StatementFilters/StatementFiltersContainer';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { DateFilterType } from './AccountStatementTabContainer';
import { StatementQueryDataType } from 'services/accounts/bankAccount/bankAccount.types';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { ToastType } from 'contexts/apiRequestContext';

type AccountStatementTabListProps = {
    queryData: StatementQueryDataType[];
    setPeriodSelected: React.Dispatch<React.SetStateAction<string>>;
    setDateFilter: React.Dispatch<React.SetStateAction<DateFilterType>>;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (page: number) => void;
    page: number;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
};

const AccountStatementTabList = ({
    queryData,
    setDateFilter,
    setPeriodSelected,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    page,
    setToast
}: AccountStatementTabListProps) => {
    const columnsAccountStatementTabList: GridColDef[] = [
        {
            field: 'issueDate',
            headerName: 'Data da Ocorrência',
            hideSortIcons: true,
            flex: 2,
            renderCell: ({value}) => {
                const formattedDate = new Date(value).toLocaleDateString('pt-BR');
                return value && formattedDate;
            }
        },
        {
            field: 'description',
            headerName: 'Lançamento',
            hideSortIcons: true,
            flex: 2,
            renderCell: (cellValues) =>
                cellValues.row.information
                    ? cellValues.row.description + ' para ' + cellValues.row.information
                    : cellValues.row.description,
        },
        {
            field: 'registrationNumber',
            headerName: 'Descrição',
            hideSortIcons: true,
            flex: 2,
            renderCell: (cellValues) => formatDocumentNumber(cellValues.value),
        },
        {
            field: 'amount',
            headerName: 'Valor',
            hideSortIcons: true,
            flex: 2,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.movementDisplay === 'DEBITO' ? (
                            <Typography
                                variant="h6"
                                fontWeight="400"
                                fontFamily='"Lato", sans-serif'
                                color="#F50057"
                            >
                                - {formatCurrencyInCents(cellValues.row.amount)}
                            </Typography>
                        ) : (
                            <Typography
                                variant="h6"
                                fontWeight="400"
                                fontFamily='"Lato", sans-serif'
                                color="#00CD2D"
                            >
                                + {formatCurrencyInCents(cellValues.row.amount)}
                            </Typography>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter:
                        <Stack
                            component={Grid}
                            justifyContent="right"
                            alignItems="center"
                            direction="row"
                            mb={2}
                        >
                            <StatementFiltersContainer
                                setPeriodSelected={setPeriodSelected}
                                setDateFilter={setDateFilter}
                                setToast={setToast}
                            />
                        </Stack>,
                }}
                columns={columnsAccountStatementTabList}
                rows={queryData}
                page={page}
                paginationMode={'client'}
                rowCount={queryData?.length}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                enableJumpAction={false}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};

export default AccountStatementTabList;
