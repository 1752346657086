/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { FunctionComponent, useEffect } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { BankAccountRelatedPersonType } from 'services/accounts/bankAccountRequest/bankAccountRequest.types';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { useTenant } from 'contexts/tenantContext';
import { OperatorsListType } from 'contexts/OperatorContext';
import { PersonListType } from 'contexts/personContext';
import { handlePersonLink } from 'helpers/methods/GenericMethods';

type InvolvedProps = {
  typeOfRelationship: SelectOption[];
  currentInvolved: BankAccountRelatedPersonType;
  onClose: () => void;
  personList: AutocompleteOptionList;
  isLoading: boolean;
  listOperator: AutocompleteOptionList;
};

const theme = activeTheme();

export const InvolvedBankAccRequestForm: FunctionComponent<InvolvedProps> = ({
  typeOfRelationship: typeOfRelationshipList,
  onClose,
  personList,
  isLoading,
  currentInvolved,
  listOperator,
}) => {
  const { tenantAutoCompleteProps } = useTenant();
  const { submitting, watch, setValue } = useFormContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [personDiscriminator, personId, relatedToId, relatedToDiscriminator, typeOfRelationship] =
    watch([
      'personDiscriminator',
      'personId',
      'relatedToId',
      'relatedToDiscriminator',
      'typeOfRelationship',
    ]);

  const selectedTypeOfRelationship = typeOfRelationship;
  const proxyOrPartner = selectedTypeOfRelationship === 'Partner' || selectedTypeOfRelationship === 'Proxy';
  const naturalPersonDiscriminator = personDiscriminator === 'NaturalPerson';
  const legalPersonDiscriminator = personDiscriminator === 'LegalPerson';
  const billingAgentApproverUser = 'BillingAgentApproverUser';
  const isBillingAgent = selectedTypeOfRelationship === billingAgentApproverUser;

  useEffect(() => {
    if (naturalPersonDiscriminator) {
      if (proxyOrPartner) {
        setValue('isSigner', true);
      } else if (selectedTypeOfRelationship === billingAgentApproverUser) {
        setValue('isSigner', false);
      }
    }
  }, [selectedTypeOfRelationship]);

  const discriminator = currentInvolved?.personDiscriminator ?? currentInvolved?.discriminator;
  const isNaturalPerson = discriminator === 'NaturalPerson' || naturalPersonDiscriminator;
  const isDisabledSigner = legalPersonDiscriminator || selectedTypeOfRelationship === 'Proxy' || isBillingAgent;

  return (
    <Stack spacing={4}>
      <RefreshProgress refreshing={isLoading} />
      <AutocompleteField
        name="personId"
        label="Envolvido"
        displayName="personIdDisplay"
        required
        link={handlePersonLink(personId, personDiscriminator)}
        showEndIcon={personId}
        preserveLastResult
        endIconType="link"
        {...personList!}
        onChange={(_, options) => {
          const selected = options as PersonListType;

          setValue('personId', selected?.value);
          setValue('personDisplay', selected?.label);
          if (selected?.discriminator)
            setValue('personDiscriminator', selected.discriminator);
          if (selected?.registrationNumber)
            setValue('personRegistrationNumber', selected.registrationNumber);
        }}
      />

      <SelectFormField
        name="typeOfRelationship"
        label="Tipo de relação"
        variant="outlined"
        options={sortToSelectOptionList(typeOfRelationshipList)}
        fullWidth
        required
        disabled={isLoading}
      />

      {isNaturalPerson && (
        <AutocompleteField
          name="relatedToId"
          label="Relacionada a"
          displayName="relatedToIdDisplay"
          link={handlePersonLink(relatedToId, relatedToDiscriminator)}
          showEndIcon={relatedToId}
          endIconType="link"
          preserveLastResult
          {...personList!}
        />
      )}

      {isNaturalPerson && (
        <>
          <Grid item>
            <SelectFormField
              name="isSigner"
              label="Signatário"
              disabled={isDisabledSigner}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
              fullWidth
            />
          </Grid>
          {!['BillingAgent', 'BillingAgentApproverUser', 'Proxy'].includes(typeOfRelationship) && (
              <Grid item>
                <PercentageFormField
                  name="participationPercentage"
                  format="integer"
                  label="Participação na Empresa"
                  variant="outlined"
                  fullWidth
                  disabled={legalPersonDiscriminator}
                />
              </Grid>
            )}
        </>
      )}

      {isBillingAgent && (
        <>
          <AutocompleteField
            name="tenantName"
            displayName="tenantNameDisplay"
            label="Correspondente"
            required
            {...tenantAutoCompleteProps}
          />

          <AutocompleteField
            label="Operadores"
            name="userId"
            displayName="userIdDisplay"
            required
            {...listOperator}
            onChange={(_, options) => {
              const selected = options as OperatorsListType;

              setValue('userId', selected?.value);
              setValue('userIdDisplay', selected?.label);
              if (selected?.registrationNumber)
                setValue('userIdRegistrationNumber', selected.registrationNumber);
            }}
          />

          <SelectFormField
            name="level"
            label="Nível de permissão"
            required
            options={[
              { label: 'Visualizador', value: 'Viewer' },
              { label: 'Aprovador em Conjunto', value: 'JointApprover' },
              { label: 'Aprovador Master', value: 'MasterApprover' },
              { label: 'Solicitante', value: 'Requester' },
            ]}
          />
        </>
      )}

      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="flex-end"
        spacing={3}
      >
        <Button
          startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
          fullWidth={isMobile}
        >
          Fechar
        </Button>
        <Button
          type="submit"
          startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
          variant="contained"
          size="medium"
          disabled={submitting}
          fullWidth={isMobile}
        >
          Adicionar envolvido
        </Button>
      </Stack>
    </Stack>
  );
};
