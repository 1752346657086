import { Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import { Button, SaveIcon, Typography, CloseIcon, ShowIcon, HideIcon } from '@uy3/web-components';
import { MfaFormField, TextFormField } from 'components/Forms/FormFields';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { activeTheme } from 'services/theme';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useState } from 'react';

type ConfirmationTransferDrawerProps = {
    onClose: () => void;
    toggleDrawer?: boolean
};

const theme = activeTheme();
const ConfirmMfaForm = ({ onClose, toggleDrawer = false }: ConfirmationTransferDrawerProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { submitError, submitting } = useApiRequest();
    const color = !submitting ? theme.palette.common.black : theme.palette?.grey['400'];

    return (
        <Stack spacing={2} direction="column" margin='auto' maxWidth={550}>
            <ApiErrorAlert error={submitError} />
            <Typography variant="xs" fontSize='16px' color="neutral.medium">
                Digite a sua senha
            </Typography>
            <Grid item>
                <TextFormField
                    name="password"
                    variant="outlined"
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        <HideIcon htmlColor={color} sx={iconSx} />
                                        :
                                        <ShowIcon htmlColor={color} sx={iconSx} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Stack direction="column">
                <Typography variant="lg" fontSize='18px' fontWeight={700}>
                    Confirmação de código MFA
                </Typography>
                <Typography variant="xs" fontSize='16px' mt={1.5} color="neutral.medium">
                    Informe o código que está presente no seu aplicativo MFA
                </Typography>
            </Stack>

            <Grid item>
                <MfaFormField name='code' toggleDrawer={toggleDrawer} />
            </Grid>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    name='btn-close-mfa'
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm-mfa"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};

export default ConfirmMfaForm;
