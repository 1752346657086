import { FormProvider, useFormContext } from 'contexts/formContext';
import React from 'react';
import { UploadFormWrapper } from '../UploadForm/UploadFormWrapper';
import { defaultValuesDocForm, validationSchemaDocForm } from '../UploadForm/UploadSchema';
import { ToastType, IUploadModel, showSuccessToast } from 'contexts/apiRequestContext';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { isSupportedFileType } from 'helpers/methods/GenericMethods';

interface UploadWithRelatedFormProps {
    selectedUploadIndex: number | undefined;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    closeDrawer: () => void;
    selectedAction?: string;
    rowData?: any;
    typeOfDocument: any[];
    setSelectedAction: (values: string | undefined) => void;
}

export default function UploadOrRelatedDocsForm({
    selectedUploadIndex,
    setToast,
    closeDrawer,
    selectedAction,
    rowData,
    typeOfDocument,
    setSelectedAction
}: UploadWithRelatedFormProps) {
    const { watch, setValue } = useFormContext();
    const { pathname } = useLocation();

    const [relatedDocs, uploads] = watch(['relatedDocs', 'uploads']);
    const watchUploads = uploads ?? relatedDocs ?? [];

    let name = pathname.includes('cadastro/fundos') ? 'relatedDocs' : 'uploads';
    const setNewUpload = (values: IUploadModel) => {
        let newUploads = watchUploads !== undefined ? [...watchUploads] : [];
        if (selectedUploadIndex === undefined) newUploads?.push(values);
        else newUploads[selectedUploadIndex!] = values;

        if (relatedDocs || name === 'relatedDocs') setValue('relatedDocs', newUploads);
        else setValue('uploads', newUploads);
        const title = 'Documento enviado com sucesso';
        const description = 'Ótimo! A lista de documentos foi atualizada.';
        showSuccessToast(title, description, setToast);
        closeDrawer();
    };
    

    const isSendSignature = (): boolean => {
        if (
            selectedAction === 'edit' &&
            !isEmpty(rowData?.createdAt) &&
            isSupportedFileType(rowData?.fileType)
        ) {
            return true;
        }
        return false;
    };
    
    const docSchema = validationSchemaDocForm();
    const defaultValues =
        selectedUploadIndex !== undefined
            ? watchUploads[selectedUploadIndex!]
            : defaultValuesDocForm;

    return (
        <FormProvider
            validationSchema={docSchema}
            defaultValues={defaultValues}
            onSubmit={setNewUpload}
        >
            <UploadFormWrapper
                options={typeOfDocument ?? []}
                accept="image/*,.pdf,.doc,.docx,.xlsx,.xsl,.rar,.zip"
                onClose={closeDrawer}
                isSendSignature={isSendSignature()}
                startSignature={() => setSelectedAction('requestSignature')}
            />
        </FormProvider>
    );
}
