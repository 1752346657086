import App from 'App';
import {
	createBrowserRouter, createRoutesFromElements, Navigate, Route,
} from "react-router-dom";
import {
	DashboardIcon,
	LegalPersonIcon,
	NaturalPersonIcon,
	OperatorIcon,
	BankingIcon,
	SettingsIcon,
	DigitalAccountIcon,
	NotFound,
	RegisterIcon,
	ApprovalIcon,
	WalletIcon,
	InvoiceReceivableIcon
} from "@uy3/web-components";
import AuthenticatedLayout from 'components/AuthenticatedLayout/AuthenticatedLayout';
import { createHandle, hiddenHandle } from 'components/RouteHandle/RouteHandle';
import { Login } from 'pages/Login';
import { LoginFormContainer } from 'pages/Login/LoginForm/LoginFormContainer';
import { MfaFormContainer } from 'pages/Login/MfaForm/MfaFormContainer';
import { Logout } from 'pages/Login/Logout';
import { MfaSetupFormContainer } from 'pages/Login/MfaSetupForm/MfaSetupFormContainer';
import { ChangePasswordFormContainer } from 'pages/Login/ChangePasswordForm/ChangePasswordFormContainer';
import { ConfirmEmailFormContainer } from 'pages/Login/ConfirmEmailForm/ConfirmEmailFormContainer';
import { RecoverPasswordFormContainer } from 'pages/Login/RecoverPasswordForm/RecoverPasswordFormContainer';
import { BankAccountListContainer } from 'pages/BankAccount/BankAccountActive/BankAccountDrawerItemsDetails/BankAccountList/BankAccountListContainer';
import { BankAccountViewContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/BankAccountViewContainer';
import { UserFormContainer } from 'pages/Security/Users/UserForm/UserFormContainer';
import { InformationTab } from 'pages/Security/Users/UserForm/Tabs/InformationTab';
import { UserListContainer } from 'pages/Security/Users/UsersList/UserListContainer';
import { TransferTabContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/TransferTab/TransferTabContainer';
import { NaturalPersonListContainer } from 'pages/NaturalPerson/NaturalPersonList/NaturalPersonListContainer';
import NaturalPersonFormContainer from 'pages/NaturalPerson/NaturalPersonForm/NaturalPersonFormContainer';
import { GroupTabContainer } from 'pages/Security/Users/UserForm/Tabs/GroupTab/GroupTabContainer';
import { PermissionTabContainer } from 'pages/Security/Users/UserForm/Tabs/PermissionTab/PermissionTabContainer';
import MemberTabContainer from 'pages/Security/Groups/GroupForm/Tabs/MembersTab/MemberTabContainer';
import { LegalPersonFormContainer } from 'pages/LegalPerson/LegalPersonForm/LegalPersonFormContainer';
import { LegalPersonListContainer } from 'pages/LegalPerson/LegalPersonList/LegalPersonListContainer';
import { CreditNoteListContainer } from 'pages/CreditNote/CreditNoteList/CreditNoteListContainer';
import { OperationsListContainer } from 'components/Tabs/Operations/OperationListContainer';
import { CreditNoteFormContainer } from 'pages/CreditNote/CreditNoteForm/CreditNoteFormContainer';
import GroupFormContainer from 'pages/Security/Groups/GroupForm/GroupFormContainer';
import { NaturalPersonInformationTabContainer } from 'pages/NaturalPerson/NaturalPersonForm/Tabs/NaturalPersonInformationTab/NaturalPersonInformationTabContainer';
import { GroupListContainer } from 'pages/Security/Groups/GroupsList/GroupListContainer';
import InformationGroupTab from 'pages/Security/Groups/GroupForm/Tabs/InformationTab';
import { FundListContainer } from 'pages/Fund/FundList/FundListContainer';
import { FundFormContainer } from 'pages/Fund/FundForm/FundFormContainer';
import { CreditProductListContainer } from 'pages/Product/CreditProduct/CreditProductList/CreditProductContainer';
import { BankDetailsContainer } from 'pages/BankAccount/BankDetailsTab/BankDetailsContainer';
import { InformationFundTabContainer } from 'pages/Fund/FundForm/Tabs/InformationTabContainer';
import { InformationLegalPersonTabContainer } from 'pages/LegalPerson/LegalPersonForm/Tabs/InformationTabContainer';
import InformationCreditNoteTabContainer from 'pages/CreditNote/CreditNoteForm/Tabs/InformationTabContainer';
import OperationTabContainer from 'pages/CreditNote/CreditNoteForm/Tabs/Operation/OperationTabContainer';
import { BankAccountProductFormContainer } from 'pages/Product/BankAccountProduct/BankAccountProductForm/BankAccountProductFormContainer';
import BankAccountProductListContainer from 'pages/Product/BankAccountProduct/BankAccountProductList/BankAccountProductContainer';
import { InformationBankAccountProductTabContaienr } from 'pages/Product/BankAccountProduct/BankAccountProductForm/Tabs/InformationBankAccountProductTabContainer';
import { RequiredDocumentsTabContainer } from 'components/Tabs/RequiredDocuments/RequiredDocumentsTabContainer';
import { InformationCreditProductContainer } from 'pages/Product/CreditProduct/CreditProductForm/Tabs/InformationTabCreditProductContainer';
import { InvolvedBankAccountProductContainer } from 'pages/Product/BankAccountProduct/BankAccountProductForm/Tabs/InvolvedBankAccountProductTab/InvolvedBankAccountProductContainer';
import SimulationFormContainer from 'pages/CreditNote/Simulation/SimulationFormContainer';
import { OperatorTabContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/OperatorTab/OperatorTabContainer';
import { InvolvedTabContainer } from 'components/Tabs/Involved/InvolvedTabContainer';
import { CreditProductFormContainer } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductFormContainer';
import { BankAccountRequestListContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestList/BankAccountRequestListContainer';
import { BankAccountRequestFormContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/BankAccountRequestContainer';
import { DeletedCreditListContainer } from 'pages/CreditNote/DeletedCreditList/DeletedCreditListContainer';
import { InformationBankAccountRequestTabContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/Tabs/InformationTabContainer';
import { DatasetLegalPersonContainer } from 'pages/LegalPerson/LegalPersonForm/Tabs/DatasetContainer/DatasetContainer';
import { DatasetNaturalPersonContainer } from 'pages/NaturalPerson/NaturalPersonForm/Tabs/DatasetContainer/DatasetContainer';
import { InvolvedBankAccountRequestTabContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/Tabs/Involved/InvolvedBankAccountRequestTabContainer';
import { KycTabContainer } from 'components/Datasets/Kyc';
import { AccountOperatorsContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/Tabs/AccountOperators/AccountOperatorsContainer';
import { CreditTabContainer } from 'components/Tabs/CreditTab/CreditTabContainer';
import { BankAccountOperatorContainer } from 'pages/BankAccount/BankAccountOperator/BankAccountOperatorContainer';
import { PixTransferFormContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/PixTab/PixTransferFormContainer';
import { SignatoriesTabContainer } from 'pages/LegalPerson/LegalPersonForm/Tabs/SignatoriesTab/SignatoriesTabContainer';
import { CreditNoteTabContainer } from 'pages/CreditNote/CreditNoteForm/Tabs/Credit/CreditNoteTabContainer';
import { Home } from 'pages/Home';
import { InvolvedFundTabContainer } from 'pages/Fund/FundForm/Tabs/Involved/InvolvedFundContainer';
import { activeTheme } from 'services/theme';
import { WarrantyTabContainer } from 'pages/CreditNote/WarrantyTab/WarrantyTabContainer';
import { DocumentsContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/Tabs/Documents/DocumentsContainer';
import { ConfirmPasswordContainer } from 'pages/Login/ConfirmPasswordForm/ConfirmPasswordContainer';
import { AccountStatementTabContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/AccountStatementTab/AccountStatementTabContainer';
import { FavoredTabContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/FavoredTab/FavoredTabContainer';
import { MyLimitsContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/MyLimits/MyLimitsContainer';
import { PaymentFormContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/PaymentTab/PaymentTabContainer';
import { UploadWithRelatedContainer } from 'components/Tabs/Uploads/UploadWithRelated/UploadWithRelatedContainer';
import { UploadTabContainer } from 'components/Tabs/Uploads/UploadDocs/UploadTabContainer';
import { TimelineTabContainer } from 'pages/CreditNote/CreditNoteForm/Tabs/TimelineTab';
import { TimelineBankAccountTabContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm/Tabs/TimelineTab/TimelineTab';
import { ManagePixKeyListContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/PixTab/ManagePixKey/ManagePixKeyList/ManagePixKeyListContainer';
import { BatchAssignmentCreditNoteListContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentList/BatchAssignmentListContainer';
import { BatchAssignmentFormContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/BatchAssignmentFormContainer';
import { InformationBatchAssignmentContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Tabs/InformationBatchAssignment/InformationBatchAssignmentContainer';
import { OperationBatchAssigmentContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Tabs/OperationBatchAssigment/OperationBatchAssigmentContainer';
import { ParcelsBatchAssignmentContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Tabs/ParcelsBatchAssignment/ParcelsBatchAssignmentContainer';
import { TimelineCessaoTabContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Tabs/TimelineTab/TimelineCessaoTab';
import { BatchAssignmentListContainer } from 'components/Tabs/BatchAssignment/BatchAssignmentContainer';
import { ApprovalsFormContainer } from 'pages/Approvals/ApprovalsForm/ApprovalsFormContainer';
import { ApprovalsBankAccountRequestListContainer } from 'pages/Approvals/ApprovalsLists/BankAccuntRequestList/BankAccuntRequestListContainer';
import { ApprovalsBankAccountListContainer } from 'pages/Approvals/ApprovalsLists/BankAccountList/BankAccountListContainer';
import { CreditNoteListApprovalsContainer } from 'pages/Approvals/ApprovalsLists/CreditNote/CreditNoteListApprovalsContainer';
import { SignaturesCreditNoteContainer } from 'pages/CreditNote/SignaturesCreditNote/SignaturesCreditNoteContainer';
import { CommissionRangeContainer } from 'pages/Product/CreditProduct/CreditProductForm/Tabs/CommissionRange/CommissionRangeList/CommissionRangeContainer';
import { ErrorElement } from 'components/Errors/ErrorElement';
import { ConciliacaoLiquidacaoCaixa } from 'pages/Vortx/ConciliacaoLiquidacaoCaixa/ConciliacaoLiquidacaoCaixa';
import { DashboardContainer } from 'pages/WalletManagement/Dashboard/DashboardContainer/DashboardContainer';
import { activeConfig } from 'services/config';
import BillingListContainer from 'pages/WalletManagement/Billing/BillingList/BillingListContainer';
import { WalletsListContainer } from 'pages/WalletManagement/Wallets/WalletList/WalletsListContainer';
import { InformationsWalletContainer } from 'pages/WalletManagement/Wallets/WalletForm/Tabs/Informations/InformationsWalletContainer';
import { WalletFormContainer } from 'pages/WalletManagement/Wallets/WalletForm/WalletFormContainer';
import { FileLinesContainer } from 'components/FileLines/FileLinesContainer';
import { FileInstructionListContainer } from 'pages/WalletManagement/Wallets/WalletForm/Tabs/FileInstruction/FileInstructionListContainer';
import { CostsListContainer } from 'pages/WalletManagement/Wallets/WalletForm/Tabs/Costs/CostsListContainer';
import { CreditorsListContainer } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/Creditors/CreditorsListContainer';
import { PayrollAgreementsListContainer } from 'pages/Product/CreditProduct/CreditProductForm/Tabs/PayrollAgreements/PayrollAgreementsListContainer';
import { GenerateFileLiquidationContainer } from 'pages/Vortx/GenerateFileLiquidation/GenerateFileLiquidationContainer';
import { VortxContainer } from 'pages/Vortx/VortxContainer';
import { CalculateLiquidationContainer } from 'pages/Vortx/CalculateLiquidation/CalculateLiquidationContainer';
import DescontoFormContainer from 'pages/Desconto/DescontoFormContainer';
import { BillingSettingListContainer } from 'pages/WalletManagement/BillingSetting/BillingSettingListContainer';
import { CommunicationSettingContainer } from 'pages/WalletManagement/CommunicationSetting/CommunicationSettingContainer';
import { InstallmentListContainer } from 'pages/WalletManagement/Installment/InstallmentListContainer';
import { InstallmentFormContainer } from 'pages/WalletManagement/Installment/InstallmentForm/InstallmentFormContainer';
import { FileConfirmationListContainer } from 'pages/WalletManagement/Wallets/WalletForm/Tabs/FileConfirmation/FileConfirmationListContainer';
import BillingAndBankSlipContainer from 'pages/WalletManagement/Billing/BillingAndBankslip/BillingAndBankSlipContainer';

const hidden = { handle: hiddenHandle };

const theme = activeTheme();
const config = activeConfig();
const appRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<App />} {...hidden}>
			<Route path="/login" errorElement={<ErrorElement fallback='login' />} element={<Login />} {...hidden}>
				<Route index element={<LoginFormContainer />} {...hidden} />
				<Route path="/login/confirmar-email" errorElement={<ErrorElement fallback='confirmar email' />} element={<ConfirmEmailFormContainer />} {...hidden} />
				<Route path="/login/recuperar-senha" errorElement={<ErrorElement fallback='recuperar senha' />} element={<RecoverPasswordFormContainer />} {...hidden} />
				<Route path="/login/mfa" errorElement={<ErrorElement fallback='MFA' />} element={<MfaFormContainer />} {...hidden} />
				<Route path="/login/mfa/setup" errorElement={<ErrorElement fallback='mfa setup' />} element={<MfaSetupFormContainer />} {...hidden} />
				<Route path="/login/confirmar-senha" errorElement={<ErrorElement fallback='confirmar senha' />} element={<ConfirmPasswordContainer />} {...hidden} />
				<Route path='/login/alterar-senha' errorElement={<ErrorElement fallback='alterar senha' />} element={<ChangePasswordFormContainer />} {...hidden} />
				<Route path="/login/logout" errorElement={<ErrorElement fallback='logout' />} element={<Logout />} {...hidden} />
				<Route path="*" element={<NotFound />} {...hidden} />
			</Route>

			<Route element={<AuthenticatedLayout />} {...hidden}>
				<Route index path="/" element={<Home />} handle={createHandle({ displayName: 'Dashboard', icon: <DashboardIcon htmlColor='#666666' />, permission: { resource: "CreditNote", type: "Read" } })} />
				<Route path="/contas-digitais" handle={createHandle({ displayName: 'Contas Digitais', icon: <BankingIcon htmlColor='#666666' />, permission: [{ resource: "BankAccount", type: "Read" }, { resource: "BankAccountRequest", type: "Read" }] })}>
					<Route index element={<Navigate replace to="/contas-digitais/ativas" />} {...hidden} />
					<Route path="/contas-digitais/ativas" errorElement={<ErrorElement fallback='contas digitais' />} handle={createHandle({ displayName: 'Ativas', permission: { resource: "BankAccount", type: "Read" } })}>
						<Route index element={<BankAccountListContainer />} {...hidden} />
						<Route path="/contas-digitais/ativas/:bankAccountId" element={<BankAccountViewContainer />} handle={createHandle({ displayName: 'Conta Digital', showNav: false })}>
							<Route index element={<AccountStatementTabContainer />} handle={createHandle({ displayName: 'Extrato', showNav: false })} />
							{!config.USE_SIMPLIFIED_INTERNET_BANKING && <>
								<Route path="/contas-digitais/ativas/:bankAccountId/transferencias" element={<TransferTabContainer />} handle={createHandle({ displayName: 'Transferências', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/pix" element={<PixTransferFormContainer />} handle={createHandle({ displayName: 'Pix', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/pagamentos" element={<PaymentFormContainer />} handle={createHandle({ displayName: 'Pagamentos', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/operadores" element={<OperatorTabContainer />} handle={createHandle({ displayName: 'Operadores', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/favorecidos" element={<FavoredTabContainer />} handle={createHandle({ displayName: 'Favorecidos', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/meus-limites" element={<MyLimitsContainer />} handle={createHandle({ displayName: 'Meus limites', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/credores" element={<CreditorsListContainer />} handle={createHandle({ displayName: 'Credores', showNav: false })} />
								<Route path="/contas-digitais/ativas/:bankAccountId/pix/gerenciar-chaves" errorElement={<ErrorElement fallback='gerenciamento de chaves' />} element={<ManagePixKeyListContainer />} handle={createHandle({ displayName: 'Gerenciar chaves', showNav: false })} />
							</>}
						</Route>
					</Route>
					<Route path="/contas-digitais/solicitacoes" errorElement={<ErrorElement fallback='solicitações de conta' />} handle={createHandle({ displayName: 'Solicitações', permission: { resource: "BankAccountRequest", type: "Read" } })} >
						<Route index element={<BankAccountRequestListContainer />} {...hidden} />
						<Route path="/contas-digitais/solicitacoes/:bankAccountId" element={<BankAccountRequestFormContainer />} handle={createHandle({ displayName: 'Atualizar solicitação de conta', showNav: false })}>
							<Route index element={<InformationBankAccountRequestTabContainer />} {...hidden} />
							<Route path="/contas-digitais/solicitacoes/:bankAccountId/envolvidos" element={<InvolvedBankAccountRequestTabContainer />} handle={createHandle({ displayName: 'Envolvidos', showNav: false })} />
							<Route path="/contas-digitais/solicitacoes/:bankAccountId/documentos" element={<DocumentsContainer />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
							<Route path="/contas-digitais/solicitacoes/:bankAccountId/kyc" element={<KycTabContainer />} handle={createHandle({ displayName: 'KYC', showNav: false })} />
							<Route path="/contas-digitais/solicitacoes/:bankAccountId/acessos-concedidos" element={<AccountOperatorsContainer />} handle={createHandle({ displayName: 'Acessos concedidos', showNav: false })} />
							<Route path="/contas-digitais/solicitacoes/:bankAccountId/historico" element={<TimelineBankAccountTabContainer />} handle={createHandle({ displayName: 'Histórico', showNav: false })} />
						</Route>
					</Route>
				</Route>
				<Route path="/aprovacoes" errorElement={<ErrorElement fallback='aprovações' />} handle={createHandle({ displayName: 'Aprovações', icon: <ApprovalIcon htmlColor='#666666' />, permission: { resource: "PendingApprovals", type: "Read" }, isRootTenancy: true })}>
					<Route index element={<Navigate replace to="/aprovacoes/gestao-aprovacoes" />} {...hidden} />
					<Route path="/aprovacoes/gestao-aprovacoes" element={<ApprovalsFormContainer />} {...hidden} >
						<Route index element={<ApprovalsBankAccountRequestListContainer />} handle={createHandle({ displayName: 'Solicitações de conta', showNav: false })} />
						<Route path='/aprovacoes/gestao-aprovacoes/internet-banking' element={<ApprovalsBankAccountListContainer />} handle={createHandle({ displayName: 'Conta digital', showNav: false })} />
						<Route path='/aprovacoes/gestao-aprovacoes/operacoes' element={<CreditNoteListApprovalsContainer />} handle={createHandle({ displayName: "Operações", showNav: false })} />
						<Route path="/aprovacoes/gestao-aprovacoes/operadores" element={<BankAccountOperatorContainer />} handle={createHandle({ displayName: 'Operadores', showNav: false, permission: { resource: "BankAccount", type: "ViewPendingOperators" }, isRootTenancy: true })} />
					</Route>
				</Route>
				{config.CONCILIATION_MENU === 1 && <>
					<Route path="/concilicao" errorElement={<ErrorElement fallback='Conciliação' />} handle={createHandle({ displayName: 'Conciliação', icon: <InvoiceReceivableIcon htmlColor='#666666' />, isRootTenancy: true })}>
						<Route index element={<Navigate replace to="/concilicao/fgts" />} {...hidden} />
						<Route path="/concilicao/fgts" element={<VortxContainer />} {...hidden} >
							<Route index element={<ConciliacaoLiquidacaoCaixa />} handle={createHandle({ displayName: 'Conciliação FGTS', showNav: false })} />
							<Route path='/concilicao/fgts/gerar-arquivo-liquidacao-caixa' element={<GenerateFileLiquidationContainer />} handle={createHandle({ displayName: 'Gerar arquivo liquidação vortx', showNav: false })} />
							<Route path='/concilicao/fgts/calcular-liquidacao-antecipada' element={<CalculateLiquidationContainer />} handle={createHandle({ displayName: "Calcular liquidação antecipada", showNav: false })} />
						</Route>
					</Route>
				</>}
				<Route path="/pessoas-fisicas" errorElement={<ErrorElement fallback='pessoas físicas' />} handle={createHandle({ displayName: 'Pessoas Físicas', icon: <NaturalPersonIcon htmlColor='#666666' />, permission: { resource: "NaturalPerson", type: "Read" } })}>
					<Route index element={<Navigate replace to="/pessoas-fisicas/todas" />} {...hidden} />
					<Route path="/pessoas-fisicas/todas" handle={createHandle({ displayName: 'Todas' })}>
						<Route index element={<NaturalPersonListContainer isPendingPreApprove={false} />} {...hidden} />
						<Route path="/pessoas-fisicas/todas/:personId" element={<NaturalPersonFormContainer />} handle={createHandle({ displayName: 'Atualizar cadastro', showNav: false })}>
							<Route index element={<NaturalPersonInformationTabContainer />} {...hidden} />
							<Route path="/pessoas-fisicas/todas/:personId/dados-bancarios" element={<BankDetailsContainer resource='NaturalPerson' />} handle={createHandle({ displayName: 'Dados bancários', showNav: false })} />
							<Route path='/pessoas-fisicas/todas/:personId/documentos' element={<UploadTabContainer name='uploads' resource='NaturalPerson' />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
							<Route path='/pessoas-fisicas/todas/:personId/compliance' element={<DatasetNaturalPersonContainer />} handle={createHandle({ displayName: "Compliance", showNav: false })} />
							<Route path='/pessoas-fisicas/todas/:personId/credito' element={<CreditTabContainer />} handle={createHandle({ displayName: "Crédito", showNav: false })} />
							<Route path='/pessoas-fisicas/todas/:personId/operacoes' element={<OperationsListContainer />} handle={createHandle({ displayName: "Operações", showNav: false })} />
						</Route>
					</Route>
					<Route path="/pessoas-fisicas/pendentes" element={<NaturalPersonListContainer isPendingPreApprove={true} />} handle={createHandle({ displayName: 'Pendentes', permission: { resource: "NaturalPerson", type: "ComplianceApproval" } })} />
				</Route>
				<Route path="/pessoas-juridicas" errorElement={<ErrorElement fallback='pessoas jurídicas' />} handle={createHandle({ displayName: 'Pessoas Jurídicas', icon: <LegalPersonIcon htmlColor='#666666' />, permission: { resource: "LegalPerson", type: "Read" } })}>
					<Route index element={<Navigate replace to="/pessoas-juridicas/todas" />} {...hidden} />
					<Route path="/pessoas-juridicas/todas" handle={createHandle({ displayName: 'Todas' })} >
						<Route index element={<LegalPersonListContainer isPendingPreApprove={false} />} {...hidden} />
						<Route path="/pessoas-juridicas/todas/:personId" element={<LegalPersonFormContainer />} handle={createHandle({ displayName: 'Atualizar cadastro', showNav: false })}>
							<Route index element={<InformationLegalPersonTabContainer />} {...hidden} />
							<Route path="/pessoas-juridicas/todas/:personId/dados-bancarios" element={<BankDetailsContainer resource='LegalPerson' />} handle={createHandle({ displayName: 'Dados bancários', showNav: false })} />
							<Route path="/pessoas-juridicas/todas/:personId/documentos" element={<UploadTabContainer name='uploads' resource='LegalPerson' />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
							<Route path="/pessoas-juridicas/todas/:personId/compliance" element={<DatasetLegalPersonContainer />} handle={createHandle({ displayName: 'Compliance', showNav: false })} />
							<Route path="/pessoas-juridicas/todas/:personId/credito" element={<CreditTabContainer />} handle={createHandle({ displayName: 'Crédito', showNav: false })} />
							<Route path="/pessoas-juridicas/todas/:personId/operacoes" element={<OperationsListContainer />} handle={createHandle({ displayName: 'Operações', showNav: false })} />
							<Route path="/pessoas-juridicas/todas/:personId/signatarios" element={<SignatoriesTabContainer />} handle={createHandle({ displayName: 'Signatários', showNav: false })} />
						</Route>
					</Route>
					<Route path="/pessoas-juridicas/pendentes" element={<LegalPersonListContainer isPendingPreApprove={true} />} handle={createHandle({ displayName: 'Pendentes', permission: { resource: "LegalPerson", type: "ComplianceApproval" } })} />
				</Route>
				<Route path="/ccb" errorElement={<ErrorElement fallback='operações' />} handle={createHandle({ displayName: 'Crédito', icon: <DigitalAccountIcon htmlColor='#666666' />, permission: { resource: "CreditNote", type: "Read" } })}>
					<Route index element={<Navigate replace to="/ccb/operacoes" />} {...hidden} />
					<Route path="/ccb/operacoes" handle={createHandle({ displayName: 'Operações' })}>
						<Route index element={<CreditNoteListContainer />} {...hidden} />
						<Route path="/ccb/operacoes/:id" element={<CreditNoteFormContainer />} handle={createHandle({ displayName: 'Atualizar Operação', showNav: false })}>
							<Route index element={<InformationCreditNoteTabContainer />} {...hidden} />
							<Route path="/ccb/operacoes/:id/envolvidos" element={<InvolvedTabContainer fieldName='relatedPersonCreditNote' enableImportSignatories={true} />} handle={createHandle({ displayName: 'Envolvidos', showNav: false })} />
							<Route path="/ccb/operacoes/:id/operacao" element={<OperationTabContainer />} handle={createHandle({ displayName: 'Operação', showNav: false })} />
							<Route path="/ccb/operacoes/:id/garantias" element={<WarrantyTabContainer />} handle={createHandle({ displayName: 'Garantias', showNav: false })} />
							<Route path="/ccb/operacoes/:id/documentos" element={<UploadWithRelatedContainer />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
							<Route path="/ccb/operacoes/:id/assinaturas" element={<SignaturesCreditNoteContainer />} handle={createHandle({ displayName: 'Assinaturas', showNav: false })} />
							<Route path="/ccb/operacoes/:id/kyc" element={<KycTabContainer />} handle={createHandle({ displayName: 'KYC', showNav: false })} />
							<Route path="/ccb/operacoes/:id/credito" element={<CreditNoteTabContainer />} handle={createHandle({ displayName: 'Crédito', showNav: false })} />
							<Route path="/ccb/operacoes/:id/cessoes" element={<BatchAssignmentListContainer recordType='creditNote' />} handle={createHandle({ displayName: 'Cessões', showNav: false })} />
							<Route path="/ccb/operacoes/:id/historico" element={<TimelineTabContainer />} handle={createHandle({ displayName: 'Histórico', showNav: false })} />
						</Route>
					</Route>
					{config.DESCONTO_MENU === 1 && <Route path="/ccb/desconto" errorElement={<ErrorElement fallback='desconto' />} element={<DescontoFormContainer />} handle={createHandle({ displayName: 'Desconto' })} />}
					<Route path="/ccb/cessoes" errorElement={<ErrorElement fallback='cessões' />} handle={createHandle({ displayName: 'Cessões', permission: { resource: "BatchAssignment", type: "Read" } })}>
						<Route index element={<BatchAssignmentCreditNoteListContainer />} {...hidden} />
						<Route path="/ccb/cessoes/:id" element={<BatchAssignmentFormContainer />} handle={createHandle({ displayName: 'Cessão', showNav: false })}>
							<Route index element={<InformationBatchAssignmentContainer />} {...hidden} />
							<Route path="/ccb/cessoes/:id/documentos" element={<UploadTabContainer name='uploads' resource='BatchAssignment' />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
							<Route path="/ccb/cessoes/:id/operacoes" element={<OperationBatchAssigmentContainer />} handle={createHandle({ displayName: 'Operações', showNav: false })} />
							<Route path="/ccb/cessoes/:id/parcelas" element={<ParcelsBatchAssignmentContainer />} handle={createHandle({ displayName: 'Parcelas', showNav: false })} />
							<Route path="/ccb/cessoes/:id/historico" element={<TimelineCessaoTabContainer />} handle={createHandle({ displayName: 'Histórico', showNav: false })} />
						</Route>
					</Route>
					<Route path="/ccb/simular" errorElement={<ErrorElement fallback='simulação' />} element={<SimulationFormContainer />} handle={createHandle({ displayName: 'Simular' })} />
					<Route path="/ccb/excluidos" errorElement={<ErrorElement fallback='operações excluídas' />} element={<DeletedCreditListContainer />} handle={createHandle({ displayName: 'Excluídos' })} />
				</Route>
				<Route path="/gestao-carteira" handle={createHandle({ displayName: 'Gestão de carteira', icon: <WalletIcon htmlColor='#666666' />, permission: { resource: "BillingAccount", type: "Read" } })}>
					<Route index element={<Navigate replace to="/gestao-carteira/dashboard" />} {...hidden} />
					<Route path="/gestao-carteira/dashboard" element={<DashboardContainer />} handle={createHandle({ displayName: 'Dashboard' })} />

					<Route path="/gestao-carteira/carteiras" errorElement={<ErrorElement fallback='carteira' />} handle={createHandle({ displayName: 'Carteiras' })}>
						<Route index element={<WalletsListContainer />} {...hidden} />
						<Route path="/gestao-carteira/carteiras/:id" element={<WalletFormContainer />} handle={createHandle({ displayName: 'Carteira', showNav: false })}>
							<Route index element={<InformationsWalletContainer />} {...hidden} />
							<Route path="/gestao-carteira/carteiras/:id/arquivos-remessa" element={<FileInstructionListContainer />} handle={createHandle({ displayName: 'Arquivos remessa', showNav: false })} />
							<Route path="/gestao-carteira/carteiras/:id/arquivos-retorno" element={<FileConfirmationListContainer />} handle={createHandle({ displayName: 'Arquivos retorno', showNav: false })} />
							<Route path="/gestao-carteira/carteiras/:id/tarifas-convenio" element={<CostsListContainer />} handle={createHandle({ displayName: 'Tarifas do convênio', showNav: false })} />
						</Route>
						<Route path="/gestao-carteira/carteiras/arquivos/:id/remessa" element={<FileLinesContainer recordType='FileInstruction' />} handle={createHandle({ displayName: 'Remessa', showNav: false })} />
						<Route path="/gestao-carteira/carteiras/arquivos/:id/retorno" element={<FileLinesContainer recordType='FileConfirmation' />} handle={createHandle({ displayName: 'Retorno', showNav: false })} />
					</Route>

					<Route path="/gestao-carteira/cobrancas" errorElement={<ErrorElement fallback='cobranças' />} handle={createHandle({ displayName: 'Cobranças' })}>
						<Route index element={<BillingListContainer />} {...hidden} />
						<Route path="/gestao-carteira/cobrancas/:billingId" element={<BillingAndBankSlipContainer />} handle={createHandle({ displayName: 'Cobrança', showNav: false })}>
							<Route index element={<BillingAndBankSlipContainer />} {...hidden} />
						</Route>
					</Route>


					<Route path="/gestao-carteira/parcelamentos" errorElement={<ErrorElement fallback='parcelamentos' />} handle={createHandle({ displayName: 'Parcelamentos' })}>
						<Route index element={<InstallmentListContainer />} {...hidden} />
						<Route path="/gestao-carteira/parcelamentos/:installmentId" handle={createHandle({ displayName: 'Parcelamentos', showNav: false })}>
							<Route index element={<InstallmentFormContainer />} {...hidden} />
						</Route>
					</Route>


					<Route path="/gestao-carteira/regua" element={<CommunicationSettingContainer />} handle={createHandle({ displayName: 'Régua de comunicação' })} />
					<Route path="/gestao-carteira/parametros" element={<BillingSettingListContainer />} handle={createHandle({ displayName: 'Régua de cobrança' })} />
				</Route>
				<Route path="/cadastro" errorElement={<ErrorElement fallback='menu de cadastro' />} handle={createHandle({ displayName: 'Cadastro', icon: <RegisterIcon htmlColor='#666666' />, permission: { resource: ["CreditProduct", "BankAccountProduct", "Fund"], type: 'Read' }, childrenCanBeHidden: true })}>
					{config.BANK_ACCOUNT_PRODUCT_MENU !== 0 && <Route index element={<Navigate replace to="/cadastro/produtos-conta" />} {...hidden} />}
					<Route path="/cadastro/produtos-conta" errorElement={<ErrorElement fallback='produtos de conta' />} handle={createHandle({ displayName: 'Produtos de Conta', permission: { resource: "BankAccountProduct", type: "Read", hidden: config.BANK_ACCOUNT_PRODUCT_MENU === 0 } })} >
						{config.BANK_ACCOUNT_PRODUCT_MENU !== 0 && <>
							<Route index element={<BankAccountProductListContainer />} {...hidden} />
							<Route path="/cadastro/produtos-conta/:productId" element={<BankAccountProductFormContainer />} handle={createHandle({ displayName: 'Atualizar produto', showNav: false })}>
								<Route index element={<InformationBankAccountProductTabContaienr />} {...hidden} />
								<Route path="/cadastro/produtos-conta/:productId/envolvidos" element={<InvolvedBankAccountProductContainer />} handle={createHandle({ displayName: 'Envolvidos', showNav: false })} />
								<Route path="/cadastro/produtos-conta/:productId/documentos" element={<RequiredDocumentsTabContainer />} handle={createHandle({ displayName: 'Documentos obrigatórios', showNav: false })} />
							</Route>
						</>}
					</Route>
					<Route path="/cadastro/produtos-credito" errorElement={<ErrorElement fallback='produtos de crédito' />} handle={createHandle({ displayName: 'Produtos de Crédito', permission: { resource: "CreditProduct", type: "Read", hidden: config.CREDIT_PRODUCT_MENU === 0 } })} >
						{config.CREDIT_PRODUCT_MENU !== 0 && <>
							<Route index element={<CreditProductListContainer />} {...hidden} />
							<Route path="/cadastro/produtos-credito/:productId" element={<CreditProductFormContainer />} handle={createHandle({ displayName: 'Atualizar produto', showNav: false })}>
								<Route index element={<InformationCreditProductContainer />} {...hidden} />
								<Route path="/cadastro/produtos-credito/:productId/envolvidos" element={<InvolvedTabContainer fieldName="relatedPerson" />} handle={createHandle({ displayName: 'Envolvidos', showNav: false })} />
								<Route path="/cadastro/produtos-credito/:productId/documentos" element={<RequiredDocumentsTabContainer />} handle={createHandle({ displayName: 'Documentos obrigatórios', showNav: false })} />
								<Route path="/cadastro/produtos-credito/:productId/custo-de-emissao" element={<CommissionRangeContainer />} handle={createHandle({ displayName: 'Custo de Emissão', showNav: false })} />
								<Route path="/cadastro/produtos-credito/:productId/orgao" element={<PayrollAgreementsListContainer />} handle={createHandle({ displayName: 'Lista de orgão', showNav: false })} />
							</Route>
						</>}
					</Route>
					<Route path="/cadastro/fundos" errorElement={<ErrorElement fallback='fundos' />} handle={createHandle({ displayName: 'Fundos', permission: { resource: "Fund", type: "Read", hidden: config.FUND_MENU === 0 } })} >
						{config.FUND_MENU !== 0 && <>
							<Route index element={<FundListContainer />} {...hidden} />
							<Route path="/cadastro/fundos/:fundId" element={<FundFormContainer />} handle={createHandle({ displayName: 'Atualizar fundo', showNav: false })}>
								<Route index element={<InformationFundTabContainer />} {...hidden} />
								<Route path="/cadastro/fundos/:fundId/envolvidos" element={<InvolvedFundTabContainer />} handle={createHandle({ displayName: 'Envolvidos', showNav: false })} />
								<Route path="/cadastro/fundos/:fundId/documentos" element={<UploadTabContainer name='relatedDocs' resource='Fund' />} handle={createHandle({ displayName: 'Documentos', showNav: false })} />
								<Route path="/cadastro/fundos/:fundId/cessoes" element={<BatchAssignmentListContainer recordType='fund' />} handle={createHandle({ displayName: 'Cessões', showNav: false })} />
							</Route>
						</>}
					</Route>
				</Route>
				<Route path="/seguranca" handle={createHandle({ displayName: 'Segurança', icon: <OperatorIcon htmlColor='#666666' />, permission: [{ resource: "User", type: "Read" }, { resource: "UserGroup", type: "Read" }] })}>
					<Route index element={<Navigate replace to="/seguranca/usuarios" />} {...hidden} />
					<Route path="/seguranca/usuarios" errorElement={<ErrorElement fallback='usuários' />} handle={createHandle({ displayName: 'Usuários', permission: { resource: "User", type: "Read" } })}>
						<Route index element={<UserListContainer />} {...hidden} />
						<Route path="/seguranca/usuarios/:userId" element={<UserFormContainer />} handle={createHandle({ displayName: 'Atualizar usuário', showNav: false })}>
							<Route index element={<InformationTab />} {...hidden} />
							<Route path="/seguranca/usuarios/:userId/grupos" element={<GroupTabContainer />} handle={createHandle({ displayName: 'Grupos', showNav: false })} />
							<Route path="/seguranca/usuarios/:userId/permissoes" element={<PermissionTabContainer />} handle={createHandle({ displayName: 'Permissões', showNav: false })} />
						</Route>
					</Route>
					<Route path="/seguranca/grupos" errorElement={<ErrorElement fallback='grupos' />} handle={createHandle({ displayName: 'Grupos', permission: { resource: "UserGroup", type: "Read" } })} >
						<Route index element={<GroupListContainer />} {...hidden} />
						<Route path="/seguranca/grupos/:groupId" element={<GroupFormContainer />} handle={createHandle({ displayName: 'Atualizar grupo', showNav: false })}>
							<Route index element={<InformationGroupTab />} {...hidden} />
							<Route path="/seguranca/grupos/:groupId/membros" element={<MemberTabContainer />} handle={createHandle({ displayName: 'Membros', showNav: false })} />
							<Route path="/seguranca/grupos/:groupId/permissoes" element={<PermissionTabContainer />} handle={createHandle({ displayName: 'Permissões', showNav: false })} />
						</Route>
					</Route>
				</Route>
				<Route path="/configuracoes" errorElement={<ErrorElement fallback='configurações' />} handle={createHandle({ displayName: 'Configurações', icon: <SettingsIcon htmlColor={theme.palette.grey[700]} /> })}>
					<Route path="/configuracoes/alterar-senha" element={<Navigate to='/login/alterar-senha' />} handle={createHandle({ displayName: 'Alterar senha' })} />
				</Route>
				<Route path="*" element={<NotFound />} handle={createHandle({ displayName: "Página não encontrada", showNav: false })} />
			</Route>
		</Route>)
);

export default appRouter;