import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { mapDescriptionWarranty, mapTitleWarranty } from 'helpers/formats/StringFormats';
import { localPaginateDataTable } from 'helpers/methods/GenericMethods';

type WarrantyTabProps = {
    name: string;
    rowsPerPage: number;
    deleteAsset: (index: number) => void;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    handleOnEdit: (rowIndex: number) => void;
};

export const WarrantyListTab = ({
    page,
    name,
    rowsPerPage,
    setPage,
    deleteAsset,
    setRowsPerPage,
    handleOnEdit,
}: WarrantyTabProps) => {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];
  
    const columns: GridColDef[] = [
        {
            field: 'warrantyType',
            headerName: 'Tipo de garantia',
            hideSortIcons: true,
            maxWidth: 200,
            flex: 1,
            editable: false,
            renderCell: (cellValue) => mapTitleWarranty(cellValue.value),
        },
        {
            field: 'description',
            headerName: 'Descrição',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: (cellValue) => {
                return mapDescriptionWarranty(cellValue.row);
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: ({ row }: any) => {
                const rowIndex = queryData.findIndex((obj: any) =>
                    Object.keys(row).every(key => obj[key] === row[key])
                );

                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        <IconButton onClick={() => handleOnEdit(rowIndex)} name="btn-edit">
                            <EditIcon sx={iconSx} />
                        </IconButton>
                        <IconButton onClick={() => deleteAsset(rowIndex)} name="btn-delete">
                            <DeleteIcon sx={iconSx} />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            rows={localPaginateDataTable(queryData, page, rowsPerPage)}
            page={page}
            rowCount={queryData?.length}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            getRowId={() => Math.random().toString()}
            enableJumpAction={false}
        />
    );
};
