import { Grid, Box, InputAdornment, IconButton } from '@mui/material';
import { HideIcon, ShowIcon, Switch, Typography } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields/TextFormField/TextFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useParams } from 'react-router-dom';
import { CheckboxFormField } from 'components/Forms/FormFields/CheckboxFormField/CheckboxFormField';
import { disableMFA } from 'services/user';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';
import { useState } from 'react';
import { ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { phoneNumberMask } from 'utils/constants';
import { cpfMask } from 'utils/constants';

export function InformationTab() {
    const { userId } = useParams();
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const [showPassword, setShowPassword] = useState(false);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { setSubmitError } = useApiRequest();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked)
            disableMFA(userId!, token!, currentTenantId!)
                .then(() => {
                    const title = 'Ótimo, MFA desabilitado com sucesso!';
                    const description = undefined;
                    showSuccessToast(title, description, setToast);
                })
                .catch((error) => {
                    const { errorMessage } = mapErrorResponse(error);
                    const title = 'Ops, ocorreu um erro!';
                    const description = errorMessage;
                    showErrorToast(title, description, setToast);
                    setSubmitError(error);
                });
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Typography variant="h5" color="common.black">
                Dados do Usuário
            </Typography>
            <Box py={3}>
                <Grid container spacing={5}>
                    <Grid item xs={4}>
                        <TextFormField
                            name="name"
                            label="Nome"
                            placeholder="Nome completo"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="registrationNumber"
                            label="CPF"
                            fullWidth
                            placeholder="000.000.000-00"
                            disabled={userId !== 'novo'}
                            variant="outlined"
                            required
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: cpfMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <TextFormField
                            name="email"
                            label="E-mail"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="phoneNumber"
                            variant="outlined"
                            fullWidth
                            label="Telefone"
                            placeholder="(00) 0000-0000"
                            required
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="address"
                            fullWidth
                            label="Endereço"
                            placeholder="Endereço"
                            variant="outlined"
                            required
                        />
                    </Grid>
                    {userId === 'novo' ? (
                        <>
                            <Grid item xs={4}>
                                <TextFormField
                                    name="password"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    label="Senha"
                                    placeholder="Senha"
                                    variant="outlined"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <ShowIcon /> : <HideIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFormField
                                    name="passwordConfirmation"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    label="Confirmar senha"
                                    placeholder="Senha"
                                    variant="outlined"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <ShowIcon /> : <HideIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                    <Grid container item>
                        <CheckboxFormField
                            color="primary"
                            label="Receber notificações"
                            name="sendNotifications"
                        />
                        <CheckboxFormField
                            label="E-mail verificado"
                            color="primary"
                            name="emailVerified"
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <Switch aria-label="disable-mfa" onChange={handleChange} />
                        <Typography
                            variant="sm"
                            color="common.black"
                            ml={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            Desabilitar MFA
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
