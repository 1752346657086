/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useFilterValues } from './filterValuesContext';
import moment from 'moment';

export type Filter = {
    name: string;
    label: string;
    value: string | Date;
    rangeValue: Date | undefined | string;
};

type FilterConfig = {
    label: string;
    type: 'text' | 'date' | 'select' | 'dateRange' | 'autocomplete';
    multiple?: boolean;
    options?: { label: string; value: string }[];
    dateRangeName?: string;
};

type FilterContextType = {
    filters: Filter[];
    setFilters: any;
    addFilter: (name: string, value: string, secondaryValue?: Date | undefined) => void;
    removeFilter: (name: string) => void;
    removeFilterByIndex: (index: number, duplicateRemove?: boolean) => void;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

type FilterConfigurationList = { [key: string]: FilterConfig };

interface FilterProviderProps {
    children: React.ReactNode;
    availableFilters: FilterConfigurationList;
}

const getFiltersFromUrl = (availableFilters: FilterConfigurationList): any => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const currentFilters: Filter[] = [];
    for (let entry of searchParams.entries()) {
        const [name, value] = entry;
        var filterCfg = availableFilters[name];
        if (filterCfg) {
            const filter = {
                name,
                value: getValueForFilter(filterCfg, value)!,
                label: filterCfg?.label,
                rangeValue: getValueForFilter(
                    filterCfg,
                    searchParams.get(filterCfg?.dateRangeName!)
                ) as Date | undefined,
            };
            currentFilters.push(filter);
        }
    }
    return currentFilters;
};

const toFiltersForUrl = (
    availableFilters: FilterConfigurationList,
    filters: Filter[]
): {
    [key: string]: string | string[];
} => {
    const filterParams: { [key: string]: string | string[] } = {};
    filters.forEach((filter) => {
        const cfg = availableFilters[filter.name];
        let currentFilterParam = filterParams[filter?.name];
        if (cfg?.dateRangeName && filter?.rangeValue) {
            if (filter?.rangeValue instanceof Date) {
                filterParams[cfg?.dateRangeName] = filter?.rangeValue.toISOString();
            } else {
                filterParams[cfg?.dateRangeName] = filter?.rangeValue;
            }
        }
        if (cfg?.multiple) {
            if (Array.isArray(currentFilterParam)) {
                currentFilterParam.push(getValueForUrl(cfg, filter));
            } else {
                filterParams[filter.name] = [getValueForUrl(cfg, filter)];
            }
        } else {
            filterParams[filter.name] = getValueForUrl(cfg, filter);
        }
    });
    return filterParams;
};

const getValueForUrl = (filterCfg: FilterConfig, filter: Filter): string => {
    if (filter?.value instanceof Date) {
        return filter?.value.toISOString();
    } else if (filterCfg?.type === 'select' || filterCfg?.type === 'autocomplete') {
        return filterCfg?.options?.find((f) => f?.label === filter?.value)?.value ?? '';
    } else {
        return filter?.value;
    }
};

const getValueForFilter = (filterCfg: FilterConfig, value: string | null) => {
    if (filterCfg?.type === 'date' || filterCfg?.type === 'dateRange') {
        return new Date(value!);
    } else if (filterCfg?.type === 'select' || filterCfg?.type === 'autocomplete') {
        return filterCfg?.options?.find((f) => f?.value === value)?.label;
    } else {
        return value;
    }
};

type AvailableFilters = {
    [key: string]: FilterConfig;
};

function transformKeysToLowerCase(obj: AvailableFilters): AvailableFilters {
    const newObj: AvailableFilters = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const lowerCaseKey = key.toLowerCase();
            newObj[lowerCaseKey] = obj[key];
        }
    }
    return newObj;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children, availableFilters }) => {
    const { setFilterValues } = useFilterValues();

    const [filters, setFilters] = useState<Filter[]>(getFiltersFromUrl(availableFilters));

    const addFilter = (name: string, value: string, secondaryValue?: Date | undefined) => {
        setFilters((filters) => {
            const newFilters = [...filters];
            const lowerCaseName = name.toLowerCase();
            const lowerCaseFilters = transformKeysToLowerCase(availableFilters);
            const filterConfig = lowerCaseFilters[lowerCaseName];

            const index = newFilters.findIndex((f) => f.name === name);
            if (index > -1 && !filterConfig?.multiple) {
                newFilters.splice(index, 1);
            }

            // Skip existing filter for the current value
            if (newFilters.some((f) => f.value === value && f.name === name)) {
                return newFilters;
            }

            let filterValue = getValueForFilter(filterConfig, value);
            if (!filterValue) {
                return newFilters;
            }

            // Format the main value if it's a date filter
            if (name === 'initialDate') {
                filterValue = formatInitialDate(new Date(filterValue));
            }

            // Format secondaryValue if it exists and if it's the finalDate
            let formattedSecondaryValue: string | undefined = undefined;
            if (secondaryValue) {
                formattedSecondaryValue = formatFinalDate(secondaryValue);
            }

            const newFilter = {
                name: name,
                value: filterValue,
                label: filterConfig?.label,
                rangeValue: formattedSecondaryValue,
            };

            newFilters.push(newFilter);
            return newFilters;
        });
    };

    useEffect(() => {
        setFilterValues(toFiltersForUrl(availableFilters, filters));
    }, []);

    const removeFilter = (name: string) => {
        setFilters((filters) => {
            let newFilters = [...filters];
            var index = filters.findIndex((f: Filter) => f.name === name);
            if (index > -1) {
                newFilters.splice(index, 1);
            }
            return newFilters;
        });
    };

    const removeFilterByIndex = (index: number, duplicateRemove?: boolean) => {
        setFilters((filters) => {
            let newFilters = [...filters];
            newFilters.splice(index, 1);
            if (!!duplicateRemove) {
                newFilters.splice(index, 1);
            }
            return newFilters;
        });
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const filterForUrl = toFiltersForUrl(availableFilters, filters);
        let urlfilters: [string, string][] = [];
        Object.keys(filterForUrl ?? {}).forEach((name) => {
            var currFilter = filterForUrl[name];
            if (Array.isArray(currFilter)) {
                currFilter.forEach((fil) => urlfilters.push([name, fil]));
            } else {
                urlfilters.push([name, currFilter]);
            }
        });
        const searchParams = new URLSearchParams(urlfilters);
        url.search = searchParams.toString();
        var newUrl = url.toString().replaceAll('%5B%5D', '');
        window.history.pushState(null, '', newUrl);
        setFilterValues(filterForUrl);
    }, [filters]);

    return (
        <FilterContext.Provider
            value={{
                filters,
                setFilters,
                addFilter,
                removeFilter,
                removeFilterByIndex,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export const useFilters = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('error: useFilters');
    }
    return context;
};

const formatInitialDate = (date: Date): string => {
    return moment(date)
        .utcOffset('-03:00')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('YYYY-MM-DDTHH:mm:ssZ');
};

const formatFinalDate = (date: Date): string => {
    return moment(date)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .format('YYYY-MM-DDTHH:mm:ss');
};
