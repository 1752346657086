export interface IBillingCrate {
  id: string
  walletCode: number
  documentNumber: string
  amount: number
  description: string
  discount: number
  dueDate: string
  billingSettingId: string
  tags: string[]
  creditTypeValue: string
  creditTypeDisplay: string
  payer: Payer
  guarantor: Guarantor
  bankSlipId: string
  registered: boolean
  errorMessage: string
  createdAt: string
}

export interface BillingFull {
  id: string
  walletCode: number
  documentNumber: string
  amount: number
  description: string
  discount: number
  dueDate: string
  billingSettingId: string
  billingSettingDisplay: string
  communicationSettingId: string
  communicationSettingDisplay: string
  tags: string[]
  creditTypeValue: string
  creditTypeDisplay: string
  payer: Payer
  guarantor: Guarantor
  registered: boolean
  errorMessage: string
  createdAt: string
  statusDisplay: string
  statusValue: string
  bankSlip: BankSlip
  settlementChannelValue: string; 
  settlementChannelValueDisplay: string; 
}

export interface BankSlip {
  id: string
  barCode: string
  statusRegisterValue: string
  statusRegisterValueDisplay: string
  statusSettlementValue: string
  statusSettlementValueDisplay: string
  statusDebtCollectionValue: string
  statusDebtCollectionValueDisplay: string
}

export interface Payer {
  name: string
  personTypeValue: string
  personTypeValueDisplay: string
  registrationNumber: number
  zipCode: string
  addressName: string
  email: string
}

export interface Guarantor {
  name: string
  registrationNumber: string
}

export interface IFiltersTags {
  walletCode: string; 
  searchString?: string; 
  size?: number
}

export type TagResult = {
  name: string; 
}[]

export interface BillingFiltersProps {
  page: number;
  size: number;
  tenant?: string;
  registered?: boolean
  searchString?: string
  payerRegistrationNumber?: string
  initialDueDate?: string
  finalDueDate?: string
  firstInstructionDebtCollection?: string
};

export interface IBillingUpdatePatch {
  documentNumber?: string
  amount?: number
  communicationSettingId?: string
  description?: string
  discount?: number
  dueDate?: string
  billingSettingId?: string
  secondInstructionDebtCollection?: number
  firstInstructionDebtCollection?: string
  tags?: string[]
  guarantor?: Guarantor
}

export const firstInstructionDebtCollectionOptions = [
  { label: "Não envia protesto", value: "00" },
  { label: "Protestar automático", value: "06" }
]
