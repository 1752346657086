import { useState } from 'react';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest
} from '../../apiRequestContext';
import { useIdentity } from '../../identityContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useTenant } from 'contexts/tenantContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { isEmpty } from 'lodash';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { responseBlobFile } from 'helpers/methods/ResponseBlobFile';
import { IBankSlipCosts, IIUpdateNotificationRecipient, IWalletUpdateModel, WalletFilterListProps, WalletsFull } from 'services/walletManagement/wallet/wallets.type';
import { deleteWalletById, getExportWalletPosition, getWalletById, getWalletsList, updateWalltByIdAsync, updateBankSlipCostAsync, updateNotificationRecipientAsync } from 'services/walletManagement/wallet/wallets';
import { useUserPermissionData } from 'contexts/userContext';

export function useWalletDataList(filters: WalletFilterListProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { token } = useIdentity();
    const { hasPermission } = useUserPermissionData();
    const { filterValues, setFilterValues } = useFilterValues();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [assignorList, setAssignorList] = useState<{ label: string; value: number }[]>([]);
    const recordType = isEmpty(filterValues.recordType) ? 'Wallet' : filterValues.recordType;
    const listDataFilters = recordType === 'Wallet' && { ...filterValues.filters };
    const filtersParams = { ...filters };
    const filtersComplete = { ...listDataFilters, ...filtersParams, tenant: currentTenantId };

    const queryContext = useQuery({
        enabled: !!token && hasPermission('BillingAccount', 'Read'),
        refetchIntervalInBackground: false,
        refetchOnMount,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-all-wallets', filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getWalletsList(filtersComplete, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as GetListApiResponseSuccess<WalletsFull>;
            const options = dataSuccess.data.map((item) => ({
                label: `(${item.walletCode}) - ${item.beneficiaryName}`,
                value: item.walletCode,
            }));
            setAssignorList(options);

            return dataSuccess;
        },
    });

    const walletCodeAutoCompleteProps = {
        listOptions: assignorList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (filter: string | undefined) => {
            let filterParams;
            if (/^\d+$/.test(filter!)) {
                filterParams = {
                    walletsCode: filter!
                };
            } else {
                filterParams = {
                    name: filter!
                };

            }
            filterParams && setFilterValues(filterParams, 'Wallet')
        },
    };

    return {
        ...queryContext,
        walletCodeAutoCompleteProps,
    };
};


export function useExportWalletPositionAsyncMutation(
    walletCode: number,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async () => {
            startRequest();
            return await getExportWalletPosition(walletCode, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess && onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}


export function useGetWalletListToBankSlipCosts(filters: WalletFilterListProps) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const filtersComplete = {
        ...filters,
        ...filterValues.filters,
        tenant: currentTenantId
    }

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-wallet-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<WalletsFull>> => {
            startRequest();
            const { data, status, statusText } = await getWalletsList(filtersComplete, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as GetListApiResponse<WalletsFull>;
        },
    });

    return queryContext;
}
export function useGetWalletByWalletCode(walletsCode: number) { 
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const filtersComplete = {
        tenant: currentTenantId, 
        walletsCode
    }

    const queryContext = useQuery({
        enabled: !!token && !!walletsCode,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-wallet-by-wallet-code', filtersComplete],
        queryFn: async (): Promise<WalletsFull> => {
            startRequest();
            const { data, status, statusText } = await getWalletsList({walletsCode}, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            const projectedData = data as any;
            return projectedData?.data[0] as any;
        },
    });

    return queryContext;
}

export function useWalletById(walletId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const queryClient = useQueryClient();

    const queryContext = useQuery({
        enabled: !!token && !isEmpty(walletId),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        onSuccess: (data) => {
            queryClient.setQueryData(['get-wallet-by-id', walletId], data);
        }, 
        refetchOnWindowFocus: false,
        queryKey: ['get-wallet-by-id', walletId],
        queryFn: async (): Promise<WalletsFull> => {
            startRequest();
            const { data, status, statusText } = await getWalletById(walletId, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            const dataMapped = data as WalletsFull;
            const enableAutoSubmitToDebtCollection = dataMapped?.enableAutoSubmitToDebtCollection;
            return {
                ...dataMapped, 
                enableAutoSubmitToDebtCollection: enableAutoSubmitToDebtCollection === null || !!enableAutoSubmitToDebtCollection ? true : false
            };
        },
    });

    return queryContext;
}
export function useDeleteWalletByIdAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (id: string) => {
            startRequest();

            const { data, status, statusText } = await deleteWalletById(id, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;

        },
        onSuccess(data: any, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useUpdateBankslipCostsMudataion(
    walletId: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: IBankSlipCosts) => {
            startRequest();
            const {data} = await updateBankSlipCostAsync(walletId, payload, token!);
            endRequest(true);
            return await data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useUpdateWalltByIdMutation(
    walletId: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (formValues: IWalletUpdateModel) => {
            startRequest();
            const payload: IWalletUpdateModel  = {
                billingTypeValue: formValues.billingTypeValue, 
                enableAutoSubmitToDebtCollection: formValues?.enableAutoSubmitToDebtCollection
            }; 
            
            const {data} = await updateWalltByIdAsync(walletId, payload, token!);
            endRequest(true);
            return await data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useUpdateNotificationRecipientMutation(
    walletId: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (formValues: IIUpdateNotificationRecipient) => {
            startRequest();
            const payload: IIUpdateNotificationRecipient  = {
                notificationRecepients: formValues.notificationRecepients
            }; 
            const {data} = await updateNotificationRecipientAsync(walletId, payload, token!);
            endRequest(true);
            return await data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

