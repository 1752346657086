import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

interface TransferHeaderListProps {
    isFetching: boolean;
    refetch: () => void;
    bankAccountId: string | undefined;
}

const theme = activeTheme();
const TransferHeader: React.FC<TransferHeaderListProps> = ({ isFetching, refetch, bankAccountId }) => {
    const queryClient = useQueryClient();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];

    async function transferRefetch() {
        refetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction={'row'} justifyContent="flex-end" spacing={2} mr={2} mb={3}>
            <Button
                sx={{ color }}
                variant="text"
                size="medium"
                onClick={transferRefetch}
                disabled={isFetching}
                startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                color="primary"
            >
                Atualizar
            </Button>
        </Stack>
    );
};
export default TransferHeader;
