import AddressForm from '../../../components/DrawerAddPerson/Address/Address';
import BankInformationForm from '../../../components/DrawerAddPerson/BankInformation/BankInformationForm';
import {
    bankDetailsListValidationSchema,
    defaultValuesBankDetails,
} from 'pages/BankAccount/BankDetailsTab/BankDetailsSchema';
import ButtonStep from 'components/DrawerAddPerson/ButtonSteps';
import React, { useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import {
    ApiResponseError,
    ToastType,
    invalidateAndRefetch,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider } from 'contexts/formContext';
import {
    LegalPersonFormSchema,
    defaultValuesLegalPersonForm,
} from '../LegalPersonForm/LegalPersonSchema';
import { useLegalPersonData, useLegalPersonMutation } from 'contexts/legalPersonContext';
import InformationLegalPersonContainer from './InformationLegalPerson/InformationLegalPersonContainer';
import FinancialInformation from './FinancialInformation/FinancialInformation';
import ContactInformation from './ContactInformation/ContactInformation';
import { validationSchemaDrawerForm } from './DrawerFormSchema';

interface DrawerFormProps {
    currentStep: number;
    setNextStep: (nextStep: boolean) => void;
    isAddBankAccount: boolean;
    handleClose: () => void;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    legalPersonId: string;
    displayName: string;
    setValue: UseFormSetValue<any>;
    onSuccessGeneric?: (values: FieldValues) => void;
}

const DrawerAddLegalPersonForm: React.FC<DrawerFormProps> = ({
    setNextStep,
    currentStep,
    isAddBankAccount,
    handleClose,
    handlePrevStep,
    handleNextStep,
    setToast,
    legalPersonId,
    displayName,
    setValue,
    onSuccessGeneric,
}) => {
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const { submitError, setSubmitError } = useApiRequest();
    const { legalPersonData } = useLegalPersonData(legalPersonId);
    const queryClient = useQueryClient();

    const formSteps: { [key: number]: JSX.Element } = {
        1: <InformationLegalPersonContainer />,
        2: <FinancialInformation />,
        3: <ContactInformation />,
        4: <AddressForm />,
        //5: <SendDocContainer />,
    };

    // validation schema and default values
    const legalPersonDefaultValues = defaultValuesLegalPersonForm;
    const legalPersonSchema = validationSchemaDrawerForm(currentStep);
    const bankDetailsDefaultValues = defaultValuesBankDetails;
    const bankDetailsSchema = bankDetailsListValidationSchema();
    // const documentSchema = validationSchemaDocForm();
    // const documentDefaultSchma = defaultValuesDocForm;

    let schema, defaultValues;
    if (isAddBankAccount) {
        schema = bankDetailsSchema;
        defaultValues = bankDetailsDefaultValues;
    } else {
        schema = legalPersonSchema;
        defaultValues = legalPersonDefaultValues;
    }

    const handleFormSave = (data: LegalPersonFormSchema) => {
        if (currentStep > 0 && currentStep < 4) {
            handleStepProgress(data);
        } else if (data?.operationTypeValue) {
            handleBankAccountSubmission(data);
        } else {
            //@ts-ignore
            const values: LegalPersonFormSchema = { ...formValues, ...data };
            onSubmit(values);
        }
    };

    const handleStepProgress = (data: LegalPersonFormSchema) => {
        setFormValues((prev) => ({ ...prev, ...data }));
        setNextStep(true);
        handleNextStep();
    };

    const handleBankAccountSubmission = (data: LegalPersonFormSchema) => {
        legalPersonData?.bankAccounts?.push(data);
        onSubmit(legalPersonData);
    };

    // const handleUploadSubmission = (data: LegalPersonFormSchema) => {
    //     const { id, fileType, fileName, displayName, tempGetUrl, fileTypeDisplay } = data;
    //     const uploads = [{ id, fileType, fileName, displayName, tempGetUrl, fileTypeDisplay }];
    //     const uploadsValues: any = { ...formValues, uploads };
    //     onSubmit(uploadsValues);
    // };

    const onSuccess = (id: string) => {
        if (!isAddBankAccount) {
            setValue('personId', id);
            setValue('personDisplay', formValues?.name);
            setValue('personDiscriminator', 'LegalPerson');
        }

        const title = 'Ação realizada com sucesso!';
        const description = 'Ótimo! Agora você pode seguir com o cadastro da operação.';
        showSuccessToast(title, description, setToast);
        onSuccessGeneric && onSuccessGeneric({ ...formValues!, id });
    };

    const onError = async (error: ApiResponseError) => {
        await queryClient.invalidateQueries(['legalPerson-data']);
        await queryClient.refetchQueries(['legalPerson-data']);
        const { errorMessage } = mapErrorResponse(error);
        setSubmitError(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate } = useLegalPersonMutation(legalPersonId, onSuccess, onError);

    const onSubmit = async (values: LegalPersonFormSchema) => {
        await mutate(values);
        await invalidateAndRefetch(queryClient, ['related-bank-account']);
        await invalidateAndRefetch(queryClient, ['persons-list']);
        handleClose();
    };

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={handleFormSave}
            onError={(value: any) => setSubmitError(value)}
        >
            <>
                {isAddBankAccount ? (
                    <BankInformationForm
                        onClose={handleClose}
                        personDisplay={displayName}
                        isLegalPerson
                    />
                ) : (
                    formSteps[currentStep]
                )}
                {!isAddBankAccount && (
                    <ButtonStep
                        currentStep={currentStep}
                        onNextStep={handleNextStep}
                        onPrevStep={handlePrevStep}
                        onClose={handleClose}
                        formError={submitError}
                    />
                )}
            </>
        </FormProvider>
    );
};

export default DrawerAddLegalPersonForm;
