import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, GetListApiResponse, useApiRequest } from "contexts/apiRequestContext";
import { useFilterValues } from "contexts/filterValuesContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { responseBlobFile } from "helpers/methods/ResponseBlobFile";
import { exportWalletsCosts, getAllCosts } from "services/walletManagement/costs/costs";
import { CostsParamsQuery, CostsType } from "services/walletManagement/costs/costs.type";

export function useCostsList(filters: CostsParamsQuery) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const filtersComplete = {
        ...filters,
        ...filterValues.filters,
        tenant: currentTenantId
    } as CostsParamsQuery

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-costs-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<CostsType>> => {
            startRequest();
            const { data, status, statusText } = await getAllCosts(filtersComplete, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as GetListApiResponse<CostsType>;
        },
    });

    return queryContext;
}

export function useExportWalletCostsMudataion(
    filters: object,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void,
) {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    
    const filtersComplete = {
        ...filterValues?.filters, 
        ...filters
    } as CostsParamsQuery;

    return useMutation({
        mutationFn: async () => {
            startRequest();
            const response = await exportWalletsCosts(filtersComplete, token!);
            endRequest(true);
            return await responseBlobFile(response);
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

