/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useRef, useState } from 'react';
import { NotificationsBox } from './Notifications.styled';
import { StepRenderNotificationsEnum } from 'services/notifications/enums/notificationsEnum';
import { ReadNotificationsContainer } from './ReadNotifications/ReadNotificationsContainer';
import { useShowNotification } from 'contexts/showNotificationsContext';
import { handleMouseDown } from 'helpers/methods/HandleMouseDown';
import { GetListApiElasticSearch } from 'contexts/apiRequestContext';
import { IMetadataNotifications, NotificationsReadModel } from 'services/notifications/types/notificationsReadModel';

type NotificationsContainerProps = {
    notificationsData: GetListApiElasticSearch<NotificationsReadModel, IMetadataNotifications> | undefined; 
    isLoading: boolean;
}

function NotificationsContainer ({ isLoading, notificationsData }: NotificationsContainerProps) {
    const { enableNotifications, setEnableNotifications } = useShowNotification();
    const [renderStep, setRenderStep] = useState<StepRenderNotificationsEnum | undefined>(StepRenderNotificationsEnum.Read);
    const componentRef = useRef(null);

    const onClose = () => setEnableNotifications(false);

    useEffect(() => {
        if (enableNotifications === false) {
            handleMouseDown(onClose, componentRef)
        }
    }, []);


    const renderOptions: { [type: string]: ReactNode } = {
        [StepRenderNotificationsEnum.Read]: (
            <ReadNotificationsContainer {...{
                onClose,
                setRenderStep, 
                notificationsData, 
                isLoading
            }} />
        )
    }

    // @ts-ignore
    const optionFromRender = renderOptions[renderStep];
    if (Boolean(enableNotifications) === false) return <></>;

    return (
        <NotificationsBox renderStep={renderStep} ref={componentRef}>
            {optionFromRender}
        </NotificationsBox>
    )
}

export default NotificationsContainer; 
