import { handleNumericNaN } from 'helpers/validations/formFieldValidator';
import moment from 'moment';
import { number, object, string, date } from 'yup';

export const validationDarfFormSchema = () => {
    return object().shape({
        name: string()
            .typeError('Nome Precisa ser preenchido.')
            .required('Nome: Precisa ser preenchido.'),
        trafficTicketValue: number()
            .typeError('Valor da multa de trânsito: Precisa ser preenchido.')
            .required('Valor da multa de trânsito: Precisa ser preenchido.'),
        interestValue: number()
            .typeError('Valor do juros Precisa ser preenchido.')
            .required('Valor do juros: Precisa ser preenchido.'),
        interestAndChargesValue: string()
            .typeError('Valor de Juros e Encargos Precisa ser preenchido.')
            .required('Valor de Juros e Encargos: Precisa ser preenchido.'),
        calculationPeriod: date()
            .typeError('Data de apuração inválida.')
            .required('Data de apuração: Precisa ser preenchido.'),
        paymentDate: string()
            .typeError('Data de apuração Precisa ser preenchido.')
            .required('Data de apuração: Precisa ser preenchido.'),
        paymentValue: number()
            .typeError('Valor do pagamento Precisa ser preenchido.')
            .required('Valor do pagamento: Precisa ser preenchido.')
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
        registrationNumber: string()
            .typeError('CPF/CNPJ precisa ser um número.')
            .required('CPF/CNPJ: Precisa ser preenchido.').cpfCnpjValidation("CPF ou CNPJ inválido."),
        percentual: number()
            .typeError('Percentual precisa ser um número.')
            .required('Percentual: Precisa ser preenchido.'),
        principalValue: number()
            .typeError('Valor principal precisa ser um número.')
            .required('Valor principal: Precisa ser preenchido.')
            .transform(handleNumericNaN)
            .min(0.01, 'Valor principal: Precisa ter um valor maior que 0.'),
        totalValue: number()
            .typeError('Valor total: Precisa ser preenchido.')
            .required("Valor total: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
        receitaFederalCodeId: string()
            .typeError('Código da receita federal inválido.')
            .required('Código da receita federal: Precisa ser preenchido.'),
    });
};

export const defaultValuesDarfForm = {
    paymentValue: 0,
    paymentDate: moment(new Date()).format('YYYY/MM/DD'),
    calculationPeriod: moment(new Date()).format('YYYY/MM/DD'),
    name: '',
    registrationNumber: '',
    percentual: 0,
    principalValue: 0,
    totalValue: 0,
    trafficTicketValue: 0,
    receitaFederalCodeId: null,
    interestValue: 0,
    interestAndChargesValue: 0,
};
