import { FunctionComponent } from 'react';
import { Box, Stack } from '@mui/material';
import { Button, Switch, Typography, Alert, RightIcon, CloseIcon } from '@uy3/web-components';
import {
    TextFormField as TextField,
    SelectFormField as SelectField,
} from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { ApiResponseError } from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useBankList } from 'contexts/bankList';
import { optionsTypeAccountComplete } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';

interface FavorecidoProps {
    submitError: ApiResponseError | undefined;
    onClose: () => void;
}

const theme = activeTheme();
export const BeneficiaryTransferForm: FunctionComponent<FavorecidoProps> = ({
    onClose,
    submitError,
}) => {
    const { bankListAutocomplete } = useBankList({ compe: true });
    const { watch, validationErrors, setValue } = useFormContext();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, '').length > 11;

    return (
        <Stack direction="column" spacing={4}>
            <TextField
                name="registrationNumber"
                label="CPF/CNPJ"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                }}
            />
            <TextField
                name="name"
                label="Nome do contato/Razão social"
                variant="outlined"
                fullWidth
                required
            />

            <AutocompleteField
                name="bank"
                label="Selecione um banco"
                displayName="bankDisplay"
                required
                {...bankListAutocomplete}
            />

            <Stack direction="row" spacing={3}>
                <TextField name="agency" label="Agência" variant="outlined" fullWidth required />
            </Stack>

            <Stack direction="row" spacing={3}>
                <TextField
                    name="accountNumber"
                    label="Conta"
                    variant="outlined"
                    fullWidth
                    required
                />
            </Stack>

            <SelectField
                name="accountType"
                label="Tipo de conta "
                options={optionsTypeAccountComplete}
                required
            />

            <Stack direction="row" alignItems="center">
                <Switch
                    id="simple-switch-label"
                    onChange={(event: any, checked: boolean) => {
                        setValue('saveBeneficiary', checked);
                    }}
                    checked={watch('saveBeneficiary')}
                    name="saveBeneficiary"
                />
                <Typography variant="sm" color="neutral.medium" lineHeight="19px" ml={2}>
                    Salvar novas informações do contato para transações futuras
                </Typography>
            </Stack>
            <ApiErrorAlert error={submitError} />
            {validationErrors && (
                <Box>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar: Corrija os erros do formulário e envie novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-create-favorite"
                    type="submit"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};
