import { object, string, array, boolean, InferType, number } from 'yup';
import { interestModel } from './AddInterestModel/AddInterestModelSchema';

export function validationSchemaBillingParamsForm() {
    return object().shape({
        billingSettingId: string()
            .required(`Selecione um régua de cobrança.`)
            .typeError('Selecione um régua de cobrança.'),
        interestModel: array().of(interestModel).typeError('Selecione um régua de cobrança.'),
        abatment: boolean(),
        discount: string().when("abatment", {
            is: true, 
            then: string().typeError("Disconto precisa ser preenchido").required("Disconto precisa ser preenchido"), 
            otherwise: string().nullable()
        }),
        discountLimitDate: string().when("abatment", {
            is: true, 
            then: string().typeError("Data limite disconto precisa ser preenchido").required("Data limite disconto precisa ser preenchido"), 
            otherwise: string().nullable()
        }),
        communicationSettingId: string().nullable().notRequired(),
        firstInstructionDebtCollection: string().nullable().notRequired(),
        secondInstructionDebtCollection: number().when("firstInstructionDebtCollection", {
            is: "06",
            then: number()
                .required('Nº de dias após vencimento para envio automático precisa ser preenchido.')
                .typeError('Nº de dias após vencimento para envio automático: precisa ser preenchido.')
                .min(3, 'Dias após vencimento: deve ser no mínimo 3 dias')
                .max(30, 'Dias após vencimento: não pode no máximo 30 dias'),
            otherwise: number().nullable()
        }),
    });
}
let inferedSchema = validationSchemaBillingParamsForm();
export type BillingParamsFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesBillingParamsForm = {
    interestModel: null,
    abatment: false,
    discountLimitDate: null, 
    firstInstructionDebtCollection: null, 
    secondInstructionDebtCollection: null, 
    communicationSettingId: null
};
