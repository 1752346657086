import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Button, HideIcon, ShowIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { useState } from 'react';

const ConfirmPasswordForm = () => {
    const { submitting } = useFormContext();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Stack direction="column" spacing={2} mb={2}>
            <TextFormField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <ShowIcon /> : <HideIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextFormField
                name="passwordConfirmation"
                type={showPassword ? 'text' : 'password'}
                label="Confirmar senha"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <ShowIcon /> : <HideIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type="submit"
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{ width: '100%' }}
            >
                Confirmar
            </Button>
        </Stack>
    );
};

export default ConfirmPasswordForm;
