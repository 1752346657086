/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import { useEnumContext } from "contexts/enumContext";
import InformationFundTab from './InformationTab';
import { useLegalPersonListData } from 'contexts/legalPersonContext';
import { useTenant } from 'contexts/tenantContext';
import { useEffect, useState } from 'react';
import { IUploadModel } from "contexts/apiRequestContext";
import Alert from '@mui/material/Alert';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { Drawer, Typography } from '@uy3/web-components';
import { UF } from 'services/zipCode/zipcode.types';
import { defaultValuesDocForm, validationSchemaDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { UploadFormWrapper } from 'components/Tabs/Uploads/UploadForm/UploadFormWrapper';
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { useBillingParametersList } from 'contexts/wallet/billingParameters/billingParametersContext';
import { useCommunicationSettingList } from 'contexts/wallet/communicationSetting/communicationSettingContext';
import { useUserPermissionData } from 'contexts/userContext';
import { isEmpty } from 'lodash';
import { getBillingSettingById } from 'services/walletManagement/billingSetting';
import { useIdentity } from 'contexts/identityContext';
import { getCommunicationSettingByIdAsync } from 'services/walletManagement/communicationSetting';
import { isValidString } from 'helpers/formats/StringFormats';

export const InformationFundTabContainer = () => {
    const { fundId } = useParams();
    const { token } = useIdentity();
    const { hasPermission } = useUserPermissionData();

    const { autoCompleteProps: typeOfDocument } = useEnumContext({ enumName: "FileType" });
    const { legalPersonAutoCompleteProps } = useLegalPersonListData({ page: 0, size: 15 }, 'always');
    const { tenantAutoCompleteProps } = useTenant();
    const [open, setOpen] = useState(false);
    const [isTemplateDoc, setIsTemplateDoc] = useState(false);
    const { watch, setValue } = useFormContext();

    const [walletCode, billingSettingId, communicationSettingId] = watch(['walletCode', 'billingSettingId', 'communicationSettingId']);

    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });
    const { billingParameterAutoCompleteProps } = useBillingParametersList({ page: 0, size: 10, walletsCode: walletCode }, false, walletCode);
    const { communicationSettingAutocomplete } = useCommunicationSettingList({ walletsCode: walletCode });

    useEffect(() => {
        async function getBillingAndCommunicationSetting() {
            if (!isEmpty(billingSettingId)) {
                const response = await getBillingSettingById(billingSettingId!, token!);
                const data = await response.data;
                setValue('billingSettingDisplay', `${data?.walletCode} - ${data?.name}`);
            };

            if (!isEmpty(communicationSettingId)) {
                const response = await getCommunicationSettingByIdAsync(communicationSettingId, token!);
                const data = await response.data;
                setValue('communicationSettingDisplay', `${data?.walletCode} - ${data?.name}`);
            };
        }
        getBillingAndCommunicationSetting();
    }, [billingSettingId, communicationSettingId]);


    useEffect(() => {
        if (isValidString(String(walletCode))) {
            setValue('walletCodeDisplay', walletCode);
        } else {
            setValue('billingSettingId', null);
            setValue('billingSettingDisplay', null);
            setValue('communicationSettingId', null);
            setValue('communicationSettingDisplay', null);
        }
    }, [walletCode])

    const handleClose = () => setOpen(false);

    const [templateBatchPath, templateDoc] = watch(["templateBatchPath", "templateDoc"]);
    let currentUpload = isTemplateDoc ? templateDoc : templateBatchPath;

    const setNewUpload = (values: IUploadModel) => {
        if (isTemplateDoc) {
            setValue("templateDoc", values);
        } else {
            setValue("templateBatchPath", values);
        };
        handleClose();
    };

    const defaultFormValue = isTemplateDoc ? templateDoc : templateBatchPath ? templateBatchPath : defaultValuesDocForm;

    return (
        <>
            <Drawer anchor='right' title="Arquivo de Cessão" open={open} onClose={handleClose}>
                <FormProvider
                    validationSchema={validationSchemaDocForm()}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewUpload}>
                    <UploadFormWrapper
                        options={typeOfDocument.listOptions}
                        accept='.docx'
                        onClose={handleClose}
                        currentValues={currentUpload}
                    />
                </FormProvider>
                <Alert
                    sx={{
                        mt: 4,
                        borderRadius: 2,
                        alignItems: 'center'
                    }}
                    severity="warning">
                    <Typography
                        variant="md"
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-all'
                        }}>
                        {currentUpload?.fileName ? <p>Atenção, o documento<strong>&nbsp;{currentUpload.fileName}&nbsp;</strong>
                            será atualizado!</p> : <>Carregando novo documento.</>}
                    </Typography>
                </Alert>
            </Drawer>
            <InformationFundTab
                fundId={fundId!}
                UFList={UF}
                legalPersonList={legalPersonAutoCompleteProps}
                tenantList={tenantAutoCompleteProps}
                setOpen={setOpen}
                setIsTemplateDoc={setIsTemplateDoc}
                billingSettingAutocomplete={billingParameterAutoCompleteProps}
                communicationSettingAutocomplete={communicationSettingAutocomplete}
                walletAutocomplete={walletCodeAutoCompleteProps}
                hasPermissionToCreateBillingAccount={hasPermission('BillingAccount', 'Create')}

            />
        </>
    );
}