import { BankAccountFull } from "contexts/apiRequestContext";
import { formatDocumentNumber, hideCredencesDocumentNumber } from "./DocumentNumber";
import { TransactionsLimitsType } from "services/approvals/approvals";
import { formatPhoneNumber, replacePhoneNumber } from "./PhoneNumber";
import { LimitsReadModel } from "services/accounts/bankAccount/bankAccount.types";
import { IBankAccountReadModel } from "utils/interface";

type TypeMapping = {
  [key: string]: string;
};

const invalidCode = (code: string): boolean => {
  if (code === '0' || parseInt(code) === 0 || code === undefined || code === null) return true;
  return false;
}

export const formatBankAccountForOption = (item: IBankAccountReadModel , optionalId?: string) => {
  const agencyDigit = item?.agencyDigit ? `- ${item.agencyDigit}` : "";
  const accountDigit = item?.accountDigit ? `- ${item.accountDigit}` : "";
  const optionLabel: { [operation: string]: string } = {
    "Pix": `Pix: ${formatPixKeyValue(item)}`,
    "Transfer": `Transferência: ${invalidCode(item?.bankCode) ? "" : `${item?.bankCode} - `} ${item?.bankCodeDisplay ?? 'N/D'} - Ag: ${item?.agency} ${agencyDigit} - Conta: ${item?.account ?? ''}${accountDigit}`
  }
  const option = {
    label: optionLabel[item?.operationTypeValue],
    value: optionalId ?? item?.id,
  };
  return option;
};
export const formatBankAccountForString = (item: any) => {
  let tedOrAgencyAndAccount = `${item.bankDisplay ?? "N/D"} - Ag: ${item.agency} Conta: ${item.accountNumber}`;
  return item?.operationTypeValue === 1 ?
    `Ted: ${tedOrAgencyAndAccount}`
    :
    `Pix: ${formatBankAccountBeneficiary(item)}`
};
export const formatBankAccountBeneficiary = (data: BankAccountFull) => {
  if (data.operationTypeValue === 1 || data.operationTypeValueDisplay === "PIX") {
    switch (data?.pixKeyTypeValueDisplay) {
      case 'Agência e Conta':
        return `Ag: ${data.agency} | Conta: ${data?.accountNumber}`;
      case 'Telefone':
        let phoneNumber = replacePhoneNumber(data.keyPix!);
        return `Telefone | ${formatPhoneNumber(phoneNumber)}`;
      case 'CPF':
        return `CPF | ${formatDocumentNumber(data?.keyPix!)}`;
      case 'CNPJ':
        return `CNPJ | ${formatDocumentNumber(data?.keyPix!)}`;
      case 'Automatica':
        return `Automática | ${data?.keyPix}`;
      case 'Email':
        return `Email | ${data?.keyPix}`;
    }
  }
  return `Ag:${data.agency} | Conta:${data?.accountNumber || data?.account}`;
};
export const formatBankAccountManagePixKey = (type: string, keyValue: string, hideString: boolean = false) => {
  const typeLowerCase = type?.toLowerCase();
  if (typeLowerCase === 'aleatória') type = 'automatica';
  switch (typeLowerCase) {
    case 'telefone':
      const phoneNumber = replacePhoneNumber(keyValue);
      return `Telefone | ${formatPhoneNumber(phoneNumber)}`;
    case 'cpf':
      return `CPF | ${hideString ? hideCredencesDocumentNumber(keyValue) : formatDocumentNumber(keyValue)}`;
    case 'cnpj':
      return `CNPJ | ${formatDocumentNumber(keyValue)}`;
    case 'automatica':
      return `Automática | ${keyValue}`;
    case 'email':
      return `Email | ${keyValue}`;
  }
};

export const formatBankAccountPixKeyOnyMask = (type: string, keyValue: string, hideString: boolean = false) => {
  const typeLowerCase = type?.toLowerCase();
  if (typeLowerCase === 'aleatória') type = 'automatica';
  switch (typeLowerCase) {
    case 'telefone':
      const phoneNumber = replacePhoneNumber(keyValue);
      return `${formatPhoneNumber(phoneNumber)}`;
    case 'cpf':
      return `${hideString ? hideCredencesDocumentNumber(keyValue) : formatDocumentNumber(keyValue)}`;
    case 'cnpj':
      return `${formatDocumentNumber(keyValue)}`;
    case 'automatica':
      return `${keyValue}`;
    case 'email':
      return `${keyValue}`;
  }
};

export const formatPixKeyValue = ({ keyPix, agency, accountNumber, account, pixKeyTypeValue, bankCodeDisplay, bankCode, ...rest }: IBankAccountReadModel): string => {
  const documentNumberFormated = formatDocumentNumber(keyPix!) ?? "N/D";
  const agencyDigit = rest?.agencyDigit ? `- ${rest.agencyDigit}` : "";
  const accountDigit = rest?.accountDigit ? `- ${rest.accountDigit}` : "";

  if (pixKeyTypeValue === 'Phone' && keyPix !== null) {
    keyPix = replacePhoneNumber(keyPix);
  }

  const optionToFormat: { [type: string]: string } = {
    "NaturalRegistrationNumber": `CPF - ${documentNumberFormated}`,
    "LegalRegistrationNumber": `CNPJ - ${documentNumberFormated}`,
    "Phone": `Telefone - ${formatPhoneNumber(keyPix!) ?? "N/D"}`,
    "Email": `Email - ${keyPix ?? "N/D"}`,
    "Automatic": `Automática - ${keyPix ?? "N/D"}`,
    "AgencyAndAccount": `Banco: ${invalidCode(bankCode) ? "" : `${bankCode} - `}  ${bankCodeDisplay ?? "N/D"} 
    | Ag: ${agency} ${agencyDigit} 
    | Conta: ${account ?? accountNumber} ${accountDigit}`
  };
  return optionToFormat[pixKeyTypeValue];
};

export function formatDataMyLimitsList(queryData: LimitsReadModel) {

  let onlyColumns: TransactionsLimitsType = {
    pixLimit: queryData?.pixLimit,
    tedLimit: queryData?.tedLimit,
    transferLimit: queryData?.transferLimit,
    bankSlipLimit: queryData?.bankSlipLimit
  };

  const typeMapping: TypeMapping = {
    bankSlipLimit: 'BOLETOS',
    transferLimit: 'TRANSFERÊNCIA INTERNA',
    pixLimit: 'Pix',
    tedLimit: "TED"
  };

  const limit = Object.entries(onlyColumns)?.map((item) => {
    const type: string = item[0];
    const values = item[1];
    return { type: typeMapping[type], values }
  });

  return limit;
}