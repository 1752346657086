import BankAccountList from './BankAccountRequestList';
import { Error } from 'components/Errors/Error';
import { Button, Modal, Popup } from '@uy3/web-components';
import { Stack } from '@mui/material';
import { useBankAccoutRequestList } from 'contexts/bankAccount/bankAccountRequest';
import { useState } from 'react';
import ApproveAndDisapproveFormContainer from './ApproveAndDisapproveForm/ApproveAndDisapproveFormContainer';
import { GridCallbackDetails, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { FilterProvider } from 'contexts/filterContext';
import { typeAccountOperator } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { optionsBankAccNewStatus } from 'contexts/bankAccount/bankAccountOptions';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { deleteBankAccountRequestById } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { RefreshProgress } from 'components/RefreshProgress';
import { useFilterValues } from 'contexts/filterValuesContext';
import BankAccountRequestHeader, { ActionsType } from './BankAccountRequestHeader';
import { ApproveInstrument } from '../BankAccountRequestForm/ButtonActions/ApprovalsInstrument/ApproveInstrument';
import { RejectInstrument } from '../BankAccountRequestForm/ButtonActions/ApprovalsInstrument/RejectInstrument';
import { PopupState } from 'contexts/creditNote/creditNoteActions.interface';
import { BankAccountRequestCreateOrUpdate } from 'services/accounts/bankAccountRequest/bankAccountRequest.types';

export function BankAccountRequestListContainer() {
    const { token } = useIdentity();
    const { filterValues } = useFilterValues();
    const [rowSelected, setRowSelected] = useState<string[] | GridRowId[]>();
    const { endRequest, startRequest, submitting } = useApiRequest();
    const [rowData, setRowData] = useState<any>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [approveOrDisapprove, setApproveOrDisapprove] = useState<ActionsType>(undefined);
    const [popupProps, setPopupProps] = useState<PopupState | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const [page, setPage] = useState(0);
    const {
        bankAccRequestData,
        bankAccRequestStatus,
        bankAccRequestErr,
        bankAccRequestIsFetch,
        refetch,
        isLoading,
    } = useBankAccoutRequestList({ page, size: rowsPerPage });

    const hasFilterValues = Object.values(filterValues.filters)?.length > 0;

    const handleSelectionModelChange = (
        selectionModel: GridSelectionModel,
        _: GridCallbackDetails<any>
    ) => {
        setRowSelected(selectionModel);
    };

    if (bankAccRequestStatus === 'error') return <Error error={bankAccRequestErr} />;

    const getSelectedRow: BankAccountRequestCreateOrUpdate[] = bankAccRequestData?.data?.filter((item: BankAccountRequestCreateOrUpdate) =>
        rowSelected!?.includes(item?.id)
    );

    const onClosePopup = () => setRowData(undefined);

    const handleErrorResponse = (error: ApiResponseError) => {
        endRequest(false);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const deleteBankAccRequestById = (bankAccountId: string) => {
        startRequest();
        deleteBankAccountRequestById(bankAccountId!, token!)
            .then(() => {
                endRequest(true);
                const title = 'Solicitação de conta excluída com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
                refetch();
                onClosePopup();
            })
            .catch(handleErrorResponse);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const openModal = approveOrDisapprove === 'approve' || approveOrDisapprove === 'disapprove';

    const titleAndDescriptionModal = (approveOrDisapprove: ActionsType) => {
        switch (approveOrDisapprove) {
            case 'approve':
                return {
                    title: 'Aprovar solicitação',
                    description: 'Tem certeza que deseja aprovar a solicitação?',
                };
            case 'disapprove':
                return {
                    title: 'Reprovar solicitação',
                    description: 'Tem certeza que deseja reprovar a solicitação?',
                };
            default:
                return { title: '' };
        }
    };

    const handleApproveInstrument = () => {
        getSelectedRow.map((item) => {
            return setPopupProps({
                title: 'Aprovar instrumento',
                message: 'Tem certeza que deseja aprovar esta solicitação?',
                children: (
                    <ApproveInstrument
                        onErrorCatch={handleErrorResponse}
                        setPopupProps={setPopupProps}
                        bankaccountId={item?.id}
                    />
                ),
            });
        });
    };

    const handleRejectInstrument = () => {
        getSelectedRow.map((item) => {
            return setPopupProps({
                title: 'Rejeitar instrumento',
                message: 'Tem certeza que deseja rejeitar esta solicitação?',
                children: (
                    <RejectInstrument
                        onErrorCatch={handleErrorResponse}
                        setPopupProps={setPopupProps}
                        bankaccountId={item?.id}
                    />
                ),
            });
        });
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <BankAccountRequestHeader
                setApproveOrDesapprove={setApproveOrDisapprove}
                refetch={refetch}
                rowsSelected={getSelectedRow}
                rejectInstrument={handleRejectInstrument}
                approveInstrument={handleApproveInstrument}
            />

            <Popup
                open={!!popupProps}
                title={popupProps?.title}
                text={popupProps?.message}
                onClose={() => setPopupProps(undefined)}
                children={popupProps?.children}
            />

            {approveOrDisapprove && (
                <Modal
                    open={openModal}
                    align="center"
                    title={titleAndDescriptionModal(approveOrDisapprove)?.title}
                    description={titleAndDescriptionModal(approveOrDisapprove)?.description}
                    onClose={() => setApproveOrDisapprove(undefined)}
                    children={
                        <ApproveAndDisapproveFormContainer
                            setToast={setToast}
                            clearSelectedRows={() => setRowSelected([])}
                            onClose={() => setApproveOrDisapprove(undefined)}
                            refetch={refetch}
                            bankAccountRowSelected={getSelectedRow?.filter(
                                (i: any) => i.status === 'Approval'
                            )}
                            approveOrDesapprove={approveOrDisapprove}
                            bankAccountRequestData={[]}
                        />
                    }
                />
            )}
            <RefreshProgress refreshing={bankAccRequestIsFetch && !isLoading} />
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    registrationNumber: { label: 'CPF/CNPJ', type: 'text' },
                    email: { label: 'Email', type: 'text' },
                    partnerName: { label: 'Nome do parceiro', type: 'text' },
                    ownerDisplay: { label: 'Nome do solicitante', type: 'text' },
                    accountNumber: { label: 'Número da conta', type: 'text' },
                    phone: { label: 'Número de telefone', type: 'text' },
                    status: {
                        label: 'Status',
                        type: 'select',
                        multiple: true,
                        options: optionsBankAccNewStatus,
                    },
                    typeAccount: {
                        label: 'Tipo de conta',
                        type: 'select',
                        multiple: false,
                        options: typeAccountOperator,
                    },
                    initialDate: {
                        label: 'Data de início',
                        type: 'date',
                        dateRangeName: 'finalDate',
                    },
                }}
            >
                <ListSkeleton isLoading={isLoading && !hasFilterValues}>
                    <BankAccountList
                        handleSelectionModelChange={handleSelectionModelChange}
                        rowsSelected={rowSelected}
                        setRowData={setRowData}
                        setRowsSelected={setRowSelected}
                        queryData={bankAccRequestData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                    />
                </ListSkeleton>
            </FilterProvider>

            <Popup
                open={!!rowData}
                onClose={() => setRowData(undefined)}
                title={`Excluir`}
                caption={`Tem certeza que deseja excluir ${rowData?.cardName ?? 'essa conta'}?`}
                children={
                    <Stack direction="row" spacing={2} mt={1} justifyContent="right">
                        <Button variant="outlined" onClick={onClosePopup}>
                            Não, cancelar
                        </Button>
                        <Button
                            disabled={submitting}
                            variant="contained"
                            onClick={() => deleteBankAccRequestById(rowData?.id)}
                        >
                            Sim, confirmar
                        </Button>
                    </Stack>
                }
            />
        </>
    );
}
