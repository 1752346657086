import React from 'react'
import { CheckboxWithTooltip } from '../CheckboxWithTooltip'

export const UserActions = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Habilitar usuário', name: 'actions.EnableUser' }}
                helpMessage="Permite habilitar um usuário desde que o mesmo esteja desabilitado."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Desabilitar usuário', name: 'actions.DisableUser' }}
                helpMessage="Permite desabilitar um usuário desde que o mesmo esteja habilitado."
            />
        </React.Fragment>
    )
}
