import PermissionTab from "./PermissionTab";
import { useState } from "react";
import { Drawer } from "@uy3/web-components";
import { FormProvider } from "contexts/formContext";
import { PermissionForm } from "./PermissionForm/PermissionForm";
import { validationPermissionFormSchema } from "./PermissionForm/PermissionFormSchema";
import { CreatePermissionProps, postPermissionList } from "services/permissions";
import { useIdentity } from "contexts/identityContext";
import { useLocation, useParams } from "react-router";
import { useUserPermissionData } from "contexts/userContext";
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from "contexts/apiRequestContext";
import { useTenant } from "contexts/tenantContext";
import Toast from "components/Toast/Toast";
import { mapErrorResponse } from "contexts/responseErrorContext";
import { RefreshProgress } from "components/RefreshProgress";
import PermissionHeader from "./PermissionHeader";

export const PermissionTabContainer = () => {
    const { pathname } = useLocation();
    const isPathGroup = pathname.includes('grupos');
    const { currentTenantId } = useTenant();
    const { endRequest, setSubmitError, startRequest, submitError, submitting } = useApiRequest();
    const { userId } = useParams();
    const { groupId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);

    const params: any = isPathGroup ? {
        principalId: groupId,
        principalType: "Group"
    } : {
        principalId: userId,
        principalType: "User"
    };

    const [formData, setFormData] = useState<any>(undefined);
    const { token } = useIdentity();

    const onClose = () => setFormData(undefined);

    const { data: permissionsData, refetch, isFetching } = useUserPermissionData(params!);

    const onAddedOrEditPermission = async (values: any) => {
        startRequest();
        let formValues = {
            createPermission: values?.createPermission,
            deletePermission: values?.deletePermission,
            readPermission: values?.readPermission,
            resource: values?.resource,
            resourceDisplay: values?.resourceDisplay,
            updatePermission: values?.updatePermission,
            actions: values?.actions
        };

        let index = permissionsData?.map((p) => p?.resource).indexOf(values?.resource);
        let updatedPermissions = [...permissionsData!]
        updatedPermissions[index!] = formValues;

        const body: CreatePermissionProps = {
            permissions: updatedPermissions,
            principalId: isPathGroup ? groupId : userId!,
            principalType: isPathGroup ? 'Group' : 'User',
            tenant: currentTenantId
        };

        await postPermissionList(body, token!).then(() => {
            endRequest(true)
            onClose();
            const title = 'Permissões atualizadas com sucesso!';
            const description = undefined;
            showSuccessToast(title, description, setToast);
            return refetch();
        }).catch((error: ApiResponseError) => {
            setSubmitError(error);
            const { errorMessage } = mapErrorResponse(error);
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        })
    };

    const schema = validationPermissionFormSchema();

    const isSubmitting = !submitError ? submitting : false;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <PermissionHeader refetch={refetch} />
            <RefreshProgress refreshing={isFetching} />
            <PermissionTab
                permissionData={permissionsData ?? []}
                setFormData={setFormData}
            />
            <Drawer
                anchor="right"
                title="Permissões do operador"
                description="Defina uma opção para cada tipo de permissão."
                open={formData !== undefined}
                onClose={onClose}
            >
                <>
                    <FormProvider
                        validationSchema={schema}
                        defaultValues={formData}
                        onSubmit={onAddedOrEditPermission}>
                        <PermissionForm
                            submitError={submitError}
                            submitting={isSubmitting}
                            onClose={onClose}
                        />
                    </FormProvider>
                </>
            </Drawer>
        </>
    );
}