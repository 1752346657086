/* eslint-disable no-useless-escape */
import { RelatedPerson } from 'services/creditProduct/types/creditProductReadModel';
import { formatCurrencyInCents, formatMultiply } from './Currency';
import { formatDocumentNumber } from './DocumentNumber';
import { isEmpty } from 'lodash';
import { isValidCNPJ, isValidCPF } from 'helpers/validations/formFieldValidator';

export const attributesTypeFormat = (option: string) => {
    const optionsAttributeType: { [type: string]: string } = {
        email: 'Email',
        zipcode: 'CEP',
        documentnumber: 'RG',
        phone: 'Telefone',
        ispb: 'ISPB',
    };
    return optionsAttributeType[option];
};
export function mapTitleWarranty(item: any) {
    switch (item) {
        case 'JudiciaryProcess':
            return `Processo Judicial`;
        case 'HomeEquity':
            return `Imóvel`;
        case 'Vehicle':
            return `Veículo`;
        case 'Factoring':
            return `Antecipação de Recebíveis`;
        case 'PublicPayroll':
            return `Consignado Público`;
        case 'InvoiceFactoring':
            return `Desconto`;
        case 'Consortium':
            return `Consórcio`;
        default:
            return 'Não identificado';
    }
}
export const hasMessageError = (errorMessage: string) => {
    if (
        errorMessage?.includes('OUTRO_DONO') ||
        errorMessage?.includes('MESMO_DONO_PSP') ||
        errorMessage?.includes('DONO_OUTRO_PSP') ||
        errorMessage?.includes('MESMO_PSP')
    ) {
        return true;
    }
    return false;
};

export const nameAndRegistrationNumber = (name: string, registrationNumber: string) => {
    if (isEmpty(name) && isEmpty(registrationNumber)) return "N/D"
    let registrationNumberFormated = registrationNumber;
    if (registrationNumber?.length < 11) {
        registrationNumberFormated = registrationNumber?.padStart(11, '0');
    }
    const documentNumber = formatDocumentNumber(registrationNumberFormated);

    return `${name ?? "N/D"} | ${documentNumber ?? "N/D"}`;
};

export const payloadActionBankAccountApprovals = (bankAccount: any) => {
    const object: { [type: string]: any } = {
        BankAccountTransfer: bankAccount?.bankAccountTransfer,
        BankAccountUser: bankAccount?.bankAccountUser,
        BankAccountBeneficiary: bankAccount?.bankAccountBeneficiary,
        BankAccountPayment: bankAccount?.bankAccountPayment,
        BankAccountTransactionLimits: bankAccount?.bankAccountTransactionLimits,
    };
    return object;
};

export const getRecordIdByResource = (resource: string) => {
    const options: { [type: string]: string } = {
        BankAccountTransfer: "transferId",
        BankAccountBeneficiary: "beneficiaryId",
        BankAccountPayment: "paymentId"
    };
    return options[resource!]
}

export const mapperOpitonsPayloadApprovals = (resource: string) => {
    const options: { [type: string]: string } = {
        BankAccountTransfer: "transferBankAccounts",
        BankAccountBeneficiary: "beneficiaryBankAccounts",
        BankAccountPayment: "payBankAccounts",
        BankAccountUser: 'operatorIds',
        BankAccountTransactionLimits: 'bankAccountTransactionLimitsIds'
    };
    return options[resource!]
}

export function objectToRedirectWithSearchParams(filters: any) {
    const parts = [];
    for (const chave in filters) {
        if (filters.hasOwnProperty(chave)) {
            const valor = filters[chave];
            if (Array.isArray(valor)) {
                valor.forEach((item) => {
                    parts.push(`${chave}=${encodeURIComponent(item)}`);
                });
            } else {
                parts.push(`${chave}=${encodeURIComponent(valor)}`);
            }
        }
    }
    return `?${parts.join('&')}`;
}
export function hideString(str: string) {
    return str
        .split('')
        .map((item) => item.replace(item, '*'))
        .join('');
}

export function mapDescriptionWarranty(item: any) {
    switch (item.warrantyType) {
        case 'JudiciaryProcess':
            return `${item.typeOfProcess} nº ${item.number} - ${item.court} - ${item.claimant} vs ${item.claimed
                } - Valor da causa: ${formatCurrencyInCents(item.valueOfCause || 0)}`;
        case 'HomeEquity':
            return `Rua ${item.addressName}, ${item.addressNumber} ${item.complementAddress} - ${item.district} - CEP ${item.zipCodeAddress} / ${item.uf}. Registrado em ${item.registryOffice} sob matrícula ${item.registrationNumber}. Dimensões: ${item.width}x${item.length} ${item.propertyFeatures}`;
        case 'Vehicle':
            return `Modelo ${item.brand} ${item.color}, ano ${item.yearOfManufacture} (modelo ${item.yearOfModel}), placa ${item.board} RENAVAM ${item.renavam}`;
        case 'Factoring':
            return `Registradora: ${item.register} | Tipo de Contrato: ${item.type === 'Collateral' ? 'Garantia' : 'Troca de Titularidade'
                } | Valor Total: ${item.splitMethodType === 'Percentage'
                    ? formatMultiply(item.totalValue) + '%'
                    : formatCurrencyInCents(item.totalValue || 0)
                }
                `;
        case 'PublicPayroll':
            return `Nome: ${item?.payrollAgreementName}, 
                            código da matrícula: ${item?.employeeCode}, 
                            código externo: ${item?.agreementCode ?? "N/D"}, 
                            dia do repasse: ${item?.paymentTransferDay ?? "N/D"}, 
                            valor da margem: ${formatCurrencyInCents(Number(item?.paymentAmountInCents ?? 0))}, 
                            código de averbação: ${item?.warrantyCode ?? "N/D"}, 
                            código do vínculo : ${item?.employmentStatusCode ?? "N/D"},
                            Descrição do vínculo: ${item?.employmentStatusDescription ?? "N/D"},`;
        case 'InvoiceFactoring':
            return `
                Pagador: ${item?.payerName}, 
                CPF/CNPJ: ${formatDocumentNumber(item?.payerRegistrationNumber)}, 
                valor presente: ${formatCurrencyInCents(Number(item?.presentValue))}, 
                valor nominal: ${formatCurrencyInCents(Number(item?.invoiceValue))}, 
                contrato: ${item?.contractNumber ?? "N/D"}`;
        case 'Consortium':
            return `
            CNPJ do Administrador: ${formatDocumentNumber(item?.administratorRegistrationNumber) ?? "N/D"},
            Nome do Administrador: ${item?.administratorName ?? "N/D"},               
            Grupo: ${item?.group ?? "N/D"},
            Cota: ${item?.quota ?? "N/D"}`;
        default:
            return 'Não identificado';
    }
}

// check if agency contains length < 4 characters
export const concatNumberAgency = (agency: string): string => {
    const concatList: string[] = [];
    let length = agency?.length;
    if (length < 4) {
        const compare = 4 - length;
        for (var i = 0; i < compare; i++) {
            concatList.push('0');
        }
        return concatList?.join('') + agency;
    }
    return agency;
};

export const assignToRelatedPersons = (relatedPerson: RelatedPerson[], productId: string) => {
    return relatedPerson.map((person) => {
        return { ...person, productId };
    });
};

export const formatWitTenant = (information: any, isRootTenancy: boolean) => {
    return isRootTenancy
        ? `${information?.userIdDisplay ?? information?.approvalTypeDisplay ?? 'N/D'} (${information?.tenantDisplay
        })`
        : information?.userIdDisplay;
};

export const formatAccountFormat = (accountString: string): string => {
    const options: { [value: string]: string } = {
        Phone: 'Telefone',
        Automatic: 'Chave aleatoria',
        Email: 'Email',
        NaturalRegistrationNumber: 'CPF',
        LegalRegistrationNumber: 'CNPJ',
        AgencyAndAccount: 'Agência e conta',
    };
    return options[accountString];
};

export const typeOfSendInString = (ruleSelected: {
    whatsapp: boolean;
    sms: boolean;
    email: boolean;
}): string[] => {
    if (!ruleSelected) return [];
    const values = {
        whatsapp: ruleSelected.whatsapp ? 'Whatsapp' : '',
        sms: ruleSelected.sms ? 'SMS' : '',
        email: ruleSelected.email ? 'E-mail' : '',
    };
    return Object.values(values)?.filter((i) => i.length);
};

export const yesOrNoToString = (value: boolean): string => (value ? 'Sim' : 'Não');

function isDateString(value: string): boolean {

    // Regex para formatos de data "YYYY-MM-DD" e "YYYY-MM-DDTHH:MM:SSZ"
    const dateRegex = /^\d{4}-\d{2}-\d{2}([Tt]\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:[Zz]|[+\-]\d{2}:\d{2})?)?$/;

    if (dateRegex.test(value)) {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
            return true;
        }
    }

    return false;
}

export const renderValue = (item: any) => {
    const value = item?.value;
    const label = item?.label;

    if (value instanceof Date || isDateString(value)) {
        const date = new Date(value);
        return date.toLocaleDateString('pt-BR');
    } else if (labelIncludesValor(label)) {
        return formatCurrencyInCents(parseInt(value));
    } else {
        return value ?? '';
    }
};

export const renderLabel = (label?: string): string => {
    return `${label}:` ?? '';
};

const labelIncludesValor = (label: string): boolean => {
    return label?.includes('Valor');
};

export const renderRangeValue = (rangeValue?: string): string => {
    if (rangeValue && isDateString(rangeValue)) {
        const date = new Date(rangeValue);
        return ` - ${date?.toLocaleDateString('pt-BR')}`;
    } else {
        return '';
    }
};

export const validationInformation = (value: string) => {
    if (isEmpty(value) || value === null || value === undefined || value === 'Invalid Date') {
        return 'N/D';
    }
    return value;
};

export const isValidString = (value: string): boolean => {
    if (isEmpty(value) || value === null || value === undefined || value === "null" || value === "undefined" || typeof value === 'number') {
        return false;
    }
    return true;
};

export const formatZipCodeWithSuffixAndPrefix = (type: "suffix" | "prefix", value: number | string) => {
    const zipCode = value.toString();
    if (type === 'prefix' && zipCode?.length < 5) {
        return zipCode.padStart(5, "0");
    } else if (type === 'suffix' && zipCode?.length < 3) {
        return zipCode.padStart(3, "0");
    } else {
        return zipCode;
    }
}

export const hasValidRegistrationNumber = (registrationNumber: string) => {
    if (registrationNumber.length === 14) {
        return isValidCNPJ(registrationNumber);
    }
    return isValidCPF(registrationNumber);
}

export const formatBooleanValueCommunicationSetting = (value: boolean) => {
    if (value === null || value === undefined) return "N/D"
    return value ? "Sim" : "Não"
}