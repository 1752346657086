import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material'
import { CheckboxFormField, DatePickerFormField, SelectFormField } from 'components/Forms/FormFields';
import { Button, Checkbox } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useFormContext } from 'contexts/formContext';

type ApproveRevisionFormFormProps = {
    onCloseModal: () => void;
    isLoadingRecalculate: boolean;
}

export const RecalculateForm = ({ onCloseModal,isLoadingRecalculate }: ApproveRevisionFormFormProps) => {
    const { watch, readOnly, setValue, getValues } = useFormContext();
    const [agioDesagio, setAgioDesagio] = useState<boolean>(
        getValues('assignmentCalculation') !== undefined
    );
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAgioDesagio(checked);
        if (checked) {
            setValue('assignmentCalculation.amount', 0);
        } else {
            setValue('assignmentCalculation', undefined);
        }
    };

    return (
        <Stack spacing={2} mt={3}>
            <Grid>
                <DatePickerFormField
                    label='Data da cessão'
                    name='assignmentDate'
                    required={true}
                    fullWidth
                />
            </Grid>
            <Grid mt={1}>
                <Checkbox
                    disabled={readOnly}
                    onChange={handleCheckboxChange}
                    checked={agioDesagio ? true : false}
                    label="Atualizar Ágio/Deságio das operações"
                    color="primary"
                />
            </Grid>
            {agioDesagio && (
                <>
                    <Grid item xs={6}>
                        <SelectFormField
                            name="assignmentCalculation.mode"
                            label="Modelo de cálculo"
                            fullWidth
                            required
                            options={[
                                { label: 'Padrão', value: 'Default' },
                                { label: 'Valor Inicial ', value: 'InitialValue' },
                                { label: 'Valor Presente', value: 'PresentValuePayment' },
                                { label: 'Valor Presente Parcela', value: 'PresentValueCreditNote' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField
                            name="assignmentCalculation.type"
                            label="Tipo de taxa"
                            fullWidth
                            options={[
                                { label: 'Valor absoluto', value: 'Absolute' },
                                { label: 'Percentual', value: 'Percentage' },
                            ]}
                        />
                    </Grid>
                    {watch('assignmentCalculation.type') === 'Absolute' ? (
                        <Grid item xs={6}>
                            <CurrencyFormField
                                label="Custo de cessão"
                                name="assignmentCalculation.amount"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <PercentageFormField
                                label="Custo de cessão (%)"
                                name="assignmentCalculation.amount"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    )}

                </>

            )}
            <Grid mt={1}>
                <CheckboxFormField
                    name={"updateDocuments"}
                    disabled={readOnly}
                    label="Salvar documentos"
                    color="primary"
                />
            </Grid>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCloseModal}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoadingRecalculate}
                    type="submit"
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    )
}
