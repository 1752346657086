import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { conciliacaoLiquidacaoCaixa } from "services/remessasVortx/remessasVortx";
import { IConciliacaoLiquidacaoCaixa } from "services/remessasVortx/remessasVortx.type";

export function useConciliacaoMutation(onSuccess?: (data: any) => void, onError?: (error: any) => void) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: IConciliacaoLiquidacaoCaixa) => {
            startRequest();
            const { data, status, statusText } = await conciliacaoLiquidacaoCaixa(payload,token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            return handleErrorUseQuery(error, setSubmitError, endRequest, onError!)
        }
    });
};