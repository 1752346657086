import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useFilterValues } from "contexts/filterValuesContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { responseBlobFile } from "helpers/methods/ResponseBlobFile";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { downloadFileConfirmationById, getAllFileConfirmation, getAllFileConfirmationById, getConfirmationLinesById } from "services/walletManagement/files/fileConfirmation/fileConfirmation";
import { FiltersFileConfirmation, FileConfirmationModel } from "services/walletManagement/files/fileConfirmation/fileConfirmation.type";


export function useGetAllFileConfirmation(params: FiltersFileConfirmation) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues } = useFilterValues();

    const filtersComplete = {
        ...filterValues.filters,
        ...params, 
        tenant: currentTenantId
    };

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-confirmation', filtersComplete],
        queryFn: async (): Promise<FileConfirmationModel[]> => {
            startRequest();

            const { data, status, statusText } = await getAllFileConfirmation(filtersComplete, token!);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as FileConfirmationModel[];
            return dataSuccess;
        },
    });

    return queryContext;
}


export function useGetByIdFileConfirmation(fileConfirmationId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();


    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-confirmation-by-id', fileConfirmationId],
        queryFn: async (): Promise<FileConfirmationModel> => {
            startRequest();

            const { data, status, statusText } = await getAllFileConfirmationById(fileConfirmationId, token!);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as FileConfirmationModel;
            return dataSuccess;
        },
    });

    return queryContext;
}

export function useGetByIdFileConfirmationLines(fileConfirmationId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-confirmation-lines-by-id', fileConfirmationId],
        queryFn: async () => {
            startRequest();

            const { data, status, statusText } = await getConfirmationLinesById(fileConfirmationId, token!);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return queryContext;
}


export function useDownloadFileConfirmationAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (fileConfirmationId: string) => {
            startRequest();
            return await downloadFileConfirmationById(fileConfirmationId, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess && onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}
