import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { categoryNameOptions, termStatusOptionsList } from 'services/creditProduct/types/creditProductReadModel';
import { sendSubmitEvent } from 'contexts/apiRequestContext';

type typeFilterProduct = "bankAccountProduct" | "creditProduct"

type ProdutFiltersProps = {
    currentValues: any,
    removeFilterSelected: (index: number) => void;
    typeFilter: typeFilterProduct;
}

function ProductFiltersForm({ typeFilter }: { typeFilter: typeFilterProduct }) {
    const { watch } = useFormContext();

    const optionsFilter = typeFilter === 'creditProduct' ? [
        { label: "Todos", value: 'searchString' },
        { label: "Nome simplificado", value: 'categoryName' },
        { label: "Tipo de amortização", value: 'amortizationType' },
        { label: "Nome do produto", value: 'name' },
        { label: "Data da ocorrência", value: 'initialDate' },
        { label: "Status de vigência", value: 'termStatus' },
    ] : [
        { label: "Todos", value: 'searchString' },
        { label: "Nome do produto", value: 'name' },
        { label: "Data da ocorrência", value: 'initialDate' }
    ]
    return <>
        <SelectFormField
            name='optionFilter'
            label='Filtrar por'
            variant='outlined'
            fullWidth
            options={optionsFilter}
        />
        {watch('optionFilter') === 'searchString' && (
            <TextFormField
                name='searchString'
                variant='outlined'
                label='Filtrar por todos'
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: -1 }}>
                            <IconButton type="submit">
                                <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )}
        {watch('optionFilter') === 'amortizationType' && (
            <TextFormField
                name='amortizationType'
                variant='outlined'
                label='Filtrar por tipo de amortização'
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: -1 }}>
                            <IconButton type="submit">
                                <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )}
        {watch('optionFilter') === 'categoryName' && (
            <SelectFormField
                name="categoryName"
                label="Nome simplificado"
                variant="outlined"
                fullWidth
                required
                options={categoryNameOptions}
                showEndAdornment
                onClick={sendSubmitEvent}
            />
        )}
        {watch('optionFilter') === 'termStatus' && (
            <SelectFormField
                name="termStatus"
                label="Status de vigência"
                variant="outlined"
                fullWidth
                required
                options={termStatusOptionsList}
                showEndAdornment
                onClick={sendSubmitEvent}
            />
        )}
        {watch('optionFilter') === 'name' && (
            <TextFormField
                name='name'
                variant='outlined'
                label='Filtrar por nome do produto'
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: -1 }}>
                            <IconButton type="submit">
                                <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )}
        {watch('optionFilter') === 'initialDate' && (
            <Grid
                sx={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: 'auto auto',
                    gridGap: 10,
                }}
            >
                <DatePickerFormField
                    label="Data de início da ocorrência"
                    name="initialDate"
                    fullWidth
                    required
                />
                <DatePickerFormField
                    label="Data final da ocorrência"
                    name="finalDate"
                    fullWidth
                    required
                    componentFilter={
                        <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                            <IconButton type="submit">
                                <SearchIcon
                                    sx={{ width: 25, height: 25 }}
                                    htmlColor="#666666"
                                />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
        )}
    </>
}

export const ProdutFilters = ({ currentValues, removeFilterSelected, typeFilter }: ProdutFiltersProps) => {
    return (
        <FilterStyleBase
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
            formFilters={<ProductFiltersForm typeFilter={typeFilter} />}
        />
    )
}
