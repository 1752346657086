import { QueryClient, QueryKey } from '@tanstack/react-query';
import { ToastProps } from '@uy3/web-components';
import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { mapErrorResponse } from './responseErrorContext';
import { GroupInfo, ICreatedBy } from 'utils/interface';

export type GetViewApiResponse<T> = GetViewApiResponseSuccess<T> | ApiResponseError;

export type GetViewApiResponseSuccess<T> = {
    type: 'success';
    data: T;
} & T;

export type PutRecordApiResponse = void | ApiResponseError;

export type PostRecordApiResponse = string | ApiResponseError;

export type GetListApiResponse<T> = GetListApiResponseSuccess<T> | ApiResponseError;

export type DomainError =
    "RESOURCE_ID_NOT_FOUND" |
    "USER_NOT_FOUND" |
    "MISSING_RESOURCE_PERMISSION" |
    "RECORD_NOT_EXISTS_OR_MISSING_PERMISSION" |
    "INVALID_ACTION_FOR_STATUS" |
    "INVALID_ACTION_FOR_RECORD" |
    "INVALID_BANK_CODE" |
    "FILE_NOT_CREATED" |
    "FILE_NOT_FOUND" |
    "FILE_ALREADY_EXISTS" |
    "DUPLICATED_PERMISSION_ENTRY" |
    "INVALID_FORM" |
    "INVALID_QUERY_PARAMS" |
    "RECORD_IS_DELETED" |
    "UNEXPECTED_EXCEPTION" |
    "INVALID_DOCUMENT_DELETE" |
    "FGTS_DATA_INTEGRATION_ERROR" |
    "BANKING_DATA_INTEGRATION_ERROR" |
    "BILLING_DATA_INTEGRATION_ERROR" |
    "CREDIT_CARD_DATA_INTEGRATION_ERROR" |
    "FGTS_UPDATE_DATASET_ERROR" |
    "WORKFLOW_ERROR" |
    "MISSING_REQUIRED_DOCUMENT" |
    "INVALID_RECORD_DATASET" |
    "DATASET_NOT_ENABLED" |
    "INVALID_AMORTIZATION_QUERY" |
    "INVALID_MFA";

export type ErrorDetails = {
    recordId: string;
    operatorId: string;
    message: string;
    currentStatus: string;
    exceptionMessage: string;
    invalidFields: string;
    errorMessages: string;
    errorMessage: string
    requiredDocuments: number[];
    formErrors: FormErrorsType[];
    allowedStatus: string[];
    permissionType: string;
    details: string;
    resource: string;
    reason: string;
}

export type FormErrorsType = {
    key: string;
    message: string;
}

export type ErrorResult = {
    type: 'error';
    code: DomainError;
    message: string;
    details: ErrorDetails;
    correlationId: string
};

export type ApiResponseError = {
    type: 'error';
    code: string;
    details?: ErrorDetails;
    errors?: ErrorResult[];
    message?: string | undefined;
    correlationId?: string;
    response?: {
        data: {
            status: string;
            errors: ErrorResult[];
            code: string;
            details: ErrorDetails;
            correlationId: string;
            message: string
        }
    }
}

export type GetListApiResponseSuccess<T> = {
    type: 'success';
    data: T[];
    page: number;
    paginationToken: string | undefined;
    totalItems: number;
    totalPages: number;
};

export type GetListApiElasticSearch<ListType, MetadataType> = {
    type: 'success';
    data: ListType[];
    page: number;
    totalItems: number;
    totalPage: number;
    metadata: MetadataType
};

type ApiRequestContextProps = {
    submitting: boolean;
    startRequest: () => void;
    endRequest: (success: boolean) => void;
    submitError: ApiResponseError | undefined;
    setSubmitError: (err: ApiResponseError | undefined) => void;
};

interface ApiRequestProviderProps {
    children: JSX.Element;
}

export type PaginatedRequest<T> = {
    page: number;
    totalPages: number;
    totalItems: number;
    paginationToken: string | null;
    data: T;
};

export type PaginationSelfType = {
    href: string;
    rel: string;
    method: string;
};

export type IRefetchOnMountModeGetQuery = "always" | boolean;

/**
 * @deprecated Este tipo foi depreciado. Favor utilizar o tipo `IUploadReadModel`.
 */
export type UploadGet = {
    id: string;
    createdAt: string;
    tenant: string;
    tenantDisplay: string;
    updatedAt: string;
    tempUrl: string;
    tempGetUrl: string;
    tempPutUrl: string;
} & DefaultRecordDetails &
    IUploadModel;

export type UploadNew = {
    type: 'success';
    filename: string;
    getUrl: string;
    putUrl: string;
    deteleURL: string;
};

export interface IUploadModel {
    id?: string;
    fileType: string;
    fileName: string;
    displayName: string;
}

/**
 * @deprecated Este tipo foi depreciado. Favor utilizar o tipo `IBankAccountReadModel`.
 */
export type BankAccountFull = {
    id: string;
    accountNumber: number;
    createdAt: string;
    tenant: string;
    tenantDisplay: string;
    updatedAt: string;
    bankCodeCompe: number;
    bankCodeDisplay: string;
    pixKeyTypeValueDisplay: string;
    operationTypeValueDisplay: string;
    bankDisplay: string;
    typeDisplay: string;
} & DefaultRecordDetails &
    BankAccountInfo;

export type BankAccountOperationType = 'Pix' | 'Transfer';

/**
 * @deprecated Este tipo foi depreciado. Favor utilizar o tipo `IBankAccountReadModel`.
 */
export interface BankAccountInfo {
    bankCode: string;
    bankIspb: number;
    account: string;
    accountDigit: string;
    agency: string;
    agencyDigit: string;
    type: string;
    jointAccount: boolean;
    operationTypeValue: BankAccountOperationType | any;
    pixKeyTypeValue: string;
    keyPix: string | null;
}

export interface DefaultRecordDetails {
    id: string;
    tenant: string;
    tenantDisplay: string;
    updatedAt: string | Date;
    createdAt: string;
    createdBy: ICreatedBy;
    ownerGroup: GroupInfo;
    ownerUser: ICreatedBy;
    updatedBy: ICreatedBy;
}

export interface IBase extends DefaultRecordDetails {
    name: string
}

export type TimelineType = {
    index: number;
    name: string;
    startedAt: string;
    finishedAt: string;
    description: string;
} & DefaultRecordDetails;

export type ToastType = {
    title: string;
    description?: string;
    severity: ToastProps['severity'];
    open: boolean;
};

export type MutateParamsWithRecordId <T> = {
    id?: string, 
    onSuccess: (data: T) => void,
    onError: (error: ApiResponseError) => void
}

export const toastState = {
    title: '',
    description: undefined,
    severity: undefined,
    open: false,
};

export const iconSx = { height: 19, width: 20 };

export const invalidateAndRefetch = async (queryClient: QueryClient, queryKey: QueryKey) => {
    await queryClient.invalidateQueries(queryKey);
    await queryClient.refetchQueries(queryKey);
};

const showToast = (title: string, description: string | undefined, severity: ToastProps['severity'], setToast: React.Dispatch<React.SetStateAction<ToastType>>) => {
    setToast({
        open: true,
        title,
        description,
        severity,
    });
};

export const showInfoToast = (title: string, description: string | undefined, setToast: React.Dispatch<React.SetStateAction<ToastType>>) => {
    showToast(title, description, 'info', setToast);
}

export const showWarningToast = (title: string, description: string, setToast: React.Dispatch<React.SetStateAction<ToastType>>) => {
    showToast(title, description, 'warning', setToast);
};

export const showSuccessToast = (title: string, description: string | undefined, setToast: React.Dispatch<React.SetStateAction<ToastType>>) => {
    showToast(title, description, 'success', setToast);
};

export const showErrorToast = (title: string, description: string, setToast: React.Dispatch<React.SetStateAction<ToastType>>) => {
    showToast(title, description, 'error', setToast);
};

export const handleOnError = (error: ApiResponseError, setToast: Dispatch<SetStateAction<ToastType>>) => {
    const { errorMessage } = mapErrorResponse(error);
    showErrorToast("Ops, ocorreu um erro", errorMessage, setToast);
}

export const sendSubmitEvent = (event: any) => {
    const submitEvent = new Event('submit', { bubbles: true });
    event.target.dispatchEvent(submitEvent);
};

const ApiRequestContext = createContext<ApiRequestContextProps>({
    submitting: false,
    startRequest: () => {
        throw Error('UserContext not initialized');
    },
    endRequest: () => {
        throw Error('UserContext not initialized');
    },
    submitError: undefined,
    setSubmitError: () => {
        throw Error('UserContext not initialized');
    },
});

export const ApiRequestProvider = ({ children }: ApiRequestProviderProps) => {
    const [submitting, setsubmitting] = useState(false);
    const [submitError, setSubmitError] = useState<ApiResponseError | undefined>(undefined);

    return (
        <ApiRequestContext.Provider
            value={{
                submitting,
                startRequest: () => setsubmitting(true),
                endRequest: (success: boolean) => {
                    setsubmitting(false);
                    if (success && submitError) {
                        setSubmitError(undefined);
                    }
                },
                submitError,
                setSubmitError,
            }}
        >
            {children}
        </ApiRequestContext.Provider>
    );
};

export function useApiRequest(): ApiRequestContextProps {
    const context = useContext(ApiRequestContext);
    const { submitting, startRequest, endRequest, submitError, setSubmitError } = context;

    return {
        submitting,
        startRequest,
        endRequest,
        submitError,
        setSubmitError,
    };
}
