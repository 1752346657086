import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, PersonIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

interface FavoredHeaderProps {
    isFetching: boolean;
    beneficiarieRefetch: () => void;
    setStepFavored: (step: string) => void;
}

const theme = activeTheme();
const FavoredHeader: React.FC<FavoredHeaderProps> = ({
    isFetching,
    beneficiarieRefetch,
    setStepFavored
}) => {
    const { bankAccountId } = useParams();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const queryClient = useQueryClient();

    async function favoredRefetch() {
        beneficiarieRefetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction="row" justifyContent="space-between" mb={3} alignItems="center">
            <Typography variant="xxl" color="neutral.dark" lineHeight="38.4px">
                Favorecidos
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color, fontSize: 18 }}
                    variant="text"
                    size="medium"
                    onClick={favoredRefetch}
                    disabled={isFetching}
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                    color="primary"
                >
                    Atualizar
                </Button>
                <Button
                    name="btn-add-favored"
                    sx={{ color, fontSize: 18 }}
                    startIcon={<PersonIcon htmlColor={color} sx={iconSx} />}
                    variant="text"
                    onClick={() => setStepFavored('bankAccountfavoredForm')}
                >
                    Adicionar favorecido
                </Button>
            </Grid>
        </Stack>
    );
};

export default FavoredHeader;
