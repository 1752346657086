import React, { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { Controller, useController } from 'react-hook-form';
import { ChangeEvent, KeyboardEvent } from 'react';
import { AddIcon, TextField, Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { SelectedOptionsChip } from 'pages/Dashboard/ProductTable/SelectProduct/SelectedValues/SelectedValues';

interface TagFieldProps {
    name: string;
    label: string;
    placeholder: string;
    required?: boolean;
}

const theme = activeTheme();

const TagField: React.FC<TagFieldProps> = ({ name, label, ...rest }) => {
    const { control, validationErrors, setValue, watch } = useFormContext();
    const { field } = useController({ name, control });
    const [tags, setTags] = useState<string[]>(field.value || []);
    const [inputValue, setInputValue] = useState<string>('');

    const watchValue = watch(name);
    console.log(watchValue);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            setValue(name, newTags);
            setInputValue('');
        }
    };

    const handleAddTag = () => {
        if (inputValue.trim() !== '') {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            const onlyNumbers = newTags.map((tag) => tag.replace(/\D/g, ''));
            setValue(name, onlyNumbers);
            setInputValue('');
        }
    };

    const handleRemoveTag = (tagToRemove: string) => {
        const newTags = tags.filter((tag) => tag !== tagToRemove);
        setTags(newTags);
        setValue(name, newTags);
    };

    const error = validationErrors && resolve(name, validationErrors)?.message;

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        {...rest}
                        label={label}
                        variant={'outlined'}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyPress}
                        value={inputValue}
                        fullWidth
                        margin="dense"
                        required={false}
                        multiline
                        rows={3}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleAddTag}>
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <Stack direction='row' alignItems='center' gap={1} flexWrap='wrap'>
                                    {tags.map((tag, index) => (
                                        <SelectedOptionsChip key={index} list={[tag]} removeFilterSelected={() => handleRemoveTag(tag)} />
                                    ))}
                                </Stack>
                            ),
                        }}
                    />
                )}
            />
            {error && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    {error}
                </Typography>
            )}
        </>
    );
};

export default TagField;
