import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import { Button, Popup } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { useCreditProdutList } from 'contexts/creditProductContext';
import CreditProductList from './CreditProductList';
import { deleteCreditProductById } from 'services/creditProduct';
import { useIdentity } from 'contexts/identityContext';
import { FilterProvider } from 'contexts/filterContext';
import CreditProductHeader from './CreditProductHeader';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useUserPermissionData } from 'contexts/userContext';
import { runSkeleton } from 'helpers/methods/GenericMethods';
import { useFilterValues } from 'contexts/filterValuesContext';
import { categoryNameOptions, termStatusOptionsList } from 'services/creditProduct/types/creditProductReadModel';

export function CreditProductListContainer() {
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('rowsPerPage')!) || 5
  );
  const [page, setPage] = useState(0);
  const { token } = useIdentity();
  const [rowData, setRowData] = useState<any | undefined>(undefined);
  const { hasPermission } = useUserPermissionData();
  const { filterValues } = useFilterValues();

  const { data, error, status, isFetching, refetch, isLoading } = useCreditProdutList({
    page,
    size: rowsPerPage,
  });

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onClosePopup = () => setRowData(undefined);
  const onDelete = async (productId: string) => {
    await deleteCreditProductById(productId, token!);
    onClosePopup();
    await refetch();
  };

  const isRunSkeleton = runSkeleton(isLoading, filterValues);

  return (
    <GenericErrorBoundary status={status} error={error} fallback='produtos de crédito'>
      <Stack mb={3}>
        <CreditProductHeader
          isFetching={isFetching}
          refetch={refetch}
          disableCreateProduct={!hasPermission('CreditProduct', 'Create')}
        />
      </Stack>
      {!isLoading && <RefreshProgress refreshing={isFetching} />}

      <FilterProvider
        availableFilters={{
          searchString: { label: "Todos", type: "text" },
          amortizationType: { label: "Tipo de amortização", type: "text" },
          name: { label: "Nome do produto", type: "text" },
          orderBy: { label: "Proprietário", type: "text" },
          initialDate: { label: "Data da ocorrência", type: "date" },
          categoryName: {
            label: "Nome simplificado",
            type: "select",
            multiple: false,
            options: categoryNameOptions
          }, 
          termStatus: {
            label: "Status de vigência",
            type: "select",
            multiple: false,
            options: termStatusOptionsList
          }, 
        }}>

        <ListSkeleton isLoading={isRunSkeleton}>
          <CreditProductList
            hasPermission={hasPermission}
            queryData={data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={onChangeRowsPerPage}
            setPage={onChangePage}
            page={page}
            setOpenPopup={setRowData}
          />
        </ListSkeleton>
      </FilterProvider>

      <Popup
        open={rowData !== undefined}
        title={rowData ? `Excluir #${rowData?.row?.name}?` : 'Excluir'}
        text={`Tem certeza que deseja excluir este produto de crédito?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDelete(rowData.id)}
              size="medium"
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </GenericErrorBoundary>
  );
}
