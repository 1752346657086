import { StepNewChargesButton } from '../../StepsButtons/StepNewChargesButton';
import { BillingSummary } from './BillingSummary';
import { useState } from 'react';
import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { GuarantorForm } from './GuarantorForm/GuarantorForm';
import { defaultValuesGuarantorForm, validationSchemaGuarantorFormSchema } from './GuarantorForm/GuarantorFormSchema';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { usePatchBillingAsyncMutation } from 'contexts/wallet/Billing/billingContext';
interface NewChargesStepProps {
    currentStep: number;
    onPrevStep: () => void;
    setValue: UseFormSetValue<any>
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const BillingSummaryStep: React.FC<NewChargesStepProps> = ({ currentStep, onPrevStep, setValue, setToast }) => {
    const [addGuarantor, setAddGuarantor] = useState(false);
    const { getValues } = useFormContext();

    const onClose = () => setAddGuarantor(false);

    const formValues = getValues();
    const chargesId = formValues?.id;

    const onSuccess = () => {
        showSuccessToast("Sacador avalista adicionado com sucesso", "", setToast);
        onClose();
    };

    const handleReponseError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        return showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
    };

    const { mutateAsync } = usePatchBillingAsyncMutation(chargesId, onSuccess, handleReponseError);

    async function createChargesAsync(values: FieldValues) {
        const onlyNumbers = /\D/g;
        const registrationNumber = String(values?.registrationNumber).replace(onlyNumbers, '');
        
        const guarantor = {
            name: values?.name, 
            registrationNumber
        }

        await mutateAsync({ guarantor });

        setValue('guarantor', values);
    };
    async function deleteGuarantorAsync() {
        const guarantor = {
            name: "", 
            registrationNumber: ""
        }
        await mutateAsync({ guarantor });
        setValue('guarantor', guarantor);
    };
    return (
        <>
            <Drawer
                anchor='right'
                open={addGuarantor}
                toggleDrawer
                title={`${formValues.guarantor !== null ? 'Editar' : 'Adicionar'} sacador avalista`}
                onClose={onClose}
            >
                <FormProvider
                    defaultValues={formValues?.guarantor ?? defaultValuesGuarantorForm}
                    validationSchema={validationSchemaGuarantorFormSchema()}
                    onSubmit={createChargesAsync}
                >
                    <GuarantorForm onClose={onClose} />
                </FormProvider>
            </Drawer>

            <BillingSummary setAddGuarantor={setAddGuarantor} deleteGuarantorAsync={deleteGuarantorAsync}/>
            <StepNewChargesButton {...{ currentStep, onPrevStep }} />
        </>
    );
};
