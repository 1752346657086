import { useState } from 'react';
import { Drawer } from '@uy3/web-components';
import { AxiosResponse } from 'axios';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import {
    useBankAccountBalance,
    useBankAccountOperatorList,
} from 'contexts/bankAccount/bankAccountContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { useParams } from 'react-router';
import {
    approveOrRejectBankAccountByResource,
    postBankAccountOperator,
} from 'services/accounts/bankAccount/bankAccount';
import { OperatorList } from './OperatorList';
import { BankAccountItemDetails } from 'pages/BankAccount/BankAccountActive/BankAccountDrawerItemsDetails/BankAccountItemDetails';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import { FilterProvider } from 'contexts/filterContext';
import { statusBankAccountOptions } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import {
    ActionApproveOrRejectType,
    postBankAccountRequestOperatorProps,
} from 'services/accounts/bankAccount/bankAccount.types';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { FieldValues } from 'react-hook-form';
import OperatorHeader from './OperatorHeader';
import AddOperator from './DrawerForm/AddOperator';
import DrawerMFA from '../DrawerMFA/DrawerMFA';
import EditOperatorDrawerForm from './DrawerForm/EditOperatorForm';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import PopupOperator from './PopupOperator/PopupOperator';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';

export const OperatorTabContainer = () => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [userData, setUserData] = useState<any>();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { setSubmitError, startRequest, endRequest, submitting } = useApiRequest();
    const [stepOperator, setStepOperator] = useState<string | undefined>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [operationInfo, setOperationInfo] = useState<any>(undefined);

    const onClose = () => {
        setStepOperator(undefined);
        setSubmitError(undefined);
    };

    const handleResponseError = (error: ApiResponseError) => {
        endRequest(false);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { data: bankAccountData } = useBankAccountBalance(bankAccountId!);
    const accountNumber = bankAccountData?.bankAccount?.account;

    const {
        operatorListData,
        operatorListError,
        operatorListStatus,
        operatorListRefetch,
        isFetching,
    } = useBankAccountOperatorList(bankAccountId!, {
        page,
        size: rowsPerPage,
    });

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

    const asyncGenerateSessionId = async (
        values: IMFAState,
        method: (response: AxiosResponse<string, any>) => void
    ) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: (response: AxiosResponse<string, any>) => method(response),
        });
    };

    const onApproveOrRejectOperator = async (values: IMFAState, action: 'Approve' | 'Reject') => {
        startRequest();
        asyncGenerateSessionId(values, (response) => {
            const data = {
                payload: {
                    action,
                    code: values.code, 
                    sessionId: response?.data, 
                    operatorIds: [userData]
                }, 
                resource: 'Operator'
            } as ActionApproveOrRejectType;
            approveOrRejectBankAccountByResource(bankAccountId!, userData!, data, token!)
                .then(() => {
                    operatorListRefetch();
                    endRequest(true);
                    onClose();

                    const approveOrReject = action === 'Approve' ? 'aprovado' : 'rejeitado';
                    const title = `Operador ${approveOrReject} com sucesso!`;
                    const description = undefined;
                    showSuccessToast(title, description, setToast);
                })
                .catch((response: ApiResponseError) => handleResponseError(response));
        });
    };

    const onEditOperator = (formValues: FieldValues) => {
        startRequest();
        const data = {
            level: formValues?.level,
            tenant: userData?.user?.tenant,
            userId: userData?.user?.userId,
        } as postBankAccountRequestOperatorProps;
        postBankAccountOperator(accountNumber!, data, token!)
            .then(() => {
                operatorListRefetch();
                endRequest(true);
                onClose();
                const title = 'Operador da conta editado com sucesso!.';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    if (operatorListStatus === 'error') <Error error={operatorListError} />;

    const drawerForm: { [key: string]: JSX.Element } = {
        bankAccountOperatorForm: <AddOperator {...{ onClose, operatorListRefetch, bankAccountId, handleResponseError, setToast }} />,
        confirmMfaApprove: <DrawerMFA onSubmit={(values) => onApproveOrRejectOperator(values, 'Approve')} onClose={onClose} />,
        confirmMfaReject: <DrawerMFA onSubmit={(values) => onApproveOrRejectOperator(values, 'Reject')} onClose={onClose} />,
        confirmMfaEditOperator: <DrawerMFA onSubmit={onEditOperator} onClose={onClose} />,
        editOperator: (
            <EditOperatorDrawerForm
                {...{
                    isFetching,
                    accountNumber,
                    handleResponseError,
                    operatorListRefetch,
                    setToast,
                    onClose,
                    userData
                }}
            />
        ),
    };
    const form = drawerForm[stepOperator!];

    const openDrawer: { [key: string]: boolean } = {
        bankAccountOperatorForm: true,
        confirmMfaApprove: true,
        confirmMfaReject: true,
        editOperator: true,
        confirmMfaEditOperator: true
    };

    const open = openDrawer[stepOperator!];

    const titleDrawer: { [key: string]: string } = {
        bankAccountOperatorForm: 'Adicionar operador',
        confirmMfaApprove: 'Aprovar operador',
        confirmMfaReject: 'Reprovar operador',
        editOperator: `Editar ${userData?.user?.userIdDisplay === null ? 'operador' : userData?.user?.userIdDisplay}`,
        confirmMfaEditOperator: 'Editar operador'

    };
    const title = titleDrawer[stepOperator!];

    return (
        <ErrorBoundary fallback="operadores da conta">
            <Toast toast={toast} setToast={setToast} />
            {operatorListStatus 
            !== 'loading' && <RefreshProgress refreshing={isFetching}/>}
            <OperatorHeader
                isFetching={isFetching || submitting}
                operatorListRefetch={operatorListRefetch}
                setStepOperator={setStepOperator}
            />
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    f_userUserId: { label: 'Nome', type: 'text' },
                    f_level: { label: 'Nível', type: 'text' },
                    f_status: {
                        label: 'Status',
                        type: 'select',
                        multiple: false,
                        options: statusBankAccountOptions,
                    },
                }}
            >
                <ListSkeleton isLoading={operatorListStatus === 'loading' }>
                    <OperatorList
                        setStepOperator={setStepOperator}
                        setOperationInfo={setOperationInfo}
                        setUserData={setUserData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        queryData={operatorListData}
                        page={page}
                        setPage={onChangePage}
                    />
                </ListSkeleton>
            </FilterProvider>

            <Drawer
                anchor="right"
                title="Detalhes da aprovação"
                open={operationInfo !== undefined}
                onClose={() => setOperationInfo(undefined)}
            >
                <BankAccountItemDetails
                    informationData={operationInfo}
                    setOpenDrawer={() => setOperationInfo(undefined)}
                    title="Informações da solicitação"
                />
            </Drawer>

            <Drawer anchor="right" title={title} open={open} onClose={onClose}>
                {form}
            </Drawer>

            <PopupOperator
                bankAccountId={bankAccountId}
                handleResponseError={handleResponseError}
                onClose={onClose}
                operatorListRefetch={operatorListRefetch}
                setToast={setToast}
                stepOperator={stepOperator!}
                userId={userData}
            />
        </ErrorBoundary>
    );
};
