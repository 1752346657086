import { handleNumericNaN } from 'helpers/validations/formFieldValidator';
import { string, object, lazy, number, InferType } from 'yup';

const genericRequired = 'Precisa ser preenchido.';

const isNullOrEmpty = (value: string | null) => {
    return value == null || value === '';
};

export function validationSchemaBankAccountRequestForm() {
    return object().shape({
        bankAccountProductId: string()
            .typeError('Selecione o produto.')
            .required('Selecione o produto.'),
        ownerId: string().typeError('Selecione o titular.').required('Selecione o titular.'),
        type: string().nullable(),
        partnerName: string()
            .typeError('Nome do parceiro inválido.')
            .required(`Nome do parceiro: ${genericRequired}.`),
        cardName: string().nullable(),
        quorumMinimumApproval: number().transform((value, _) => {
            return isNaN(value) ? 0 : value;
        }),
        cardReceiptAddress: lazy((value) => {
            var nothingIsFiled = isNullOrEmpty(value?.addressName) && isNullOrEmpty(value?.zipCode) && isNullOrEmpty(value?.city)
                && isNullOrEmpty(value?.uf) && isNullOrEmpty(value?.district) && isNullOrEmpty(value?.number);
            return nothingIsFiled ? object().nullable().transform((value, _) => {
                return Object.values(value ?? {}).every(x => x === null) ? null : value;
            }) : object().shape({
                addressName: string().nullable(),
                zipCode: string().nullable(),
                city: string().nullable(),
                uf: string().nullable(),
                district: string().nullable(),
                number: string().nullable(),
                complement: string().nullable()
            }).transform((value, _) => {
                return Object.values(value ?? {}).every(x => x === null) ? null : value;
            });
        }),
        reason: string()
            .typeError('Prezado(a) cliente, é necessário informar o motivo para abrir a conta.')
            .required(`Motivo: ${genericRequired}.`),
        otherReason: string().nullable(),
        owner: object().shape({
            address: lazy((value) => {
                var nothingIsFiled = isNullOrEmpty(value?.addressName) && isNullOrEmpty(value?.zipCode) && isNullOrEmpty(value?.city)
                    && isNullOrEmpty(value?.uf) && isNullOrEmpty(value?.district) && isNullOrEmpty(value?.number);
                return nothingIsFiled ? object().nullable().transform((value, _) => {
                    return Object.values(value ?? {}).every(x => x === null) ? null : value;
                }) : object().shape({
                    addressName: string().nullable(),
                    zipCode: string().nullable(),
                    city: string().nullable(),
                    uf: string().nullable(),
                    district: string().nullable(),
                    number: string().nullable(),
                    complement: string().nullable()
                }).transform((value, _) => {
                    return Object.values(value ?? {}).every(x => x === null) ? null : value;
                });
            }),
            averageMonthlyRevenue: number()
                .typeError('Valor precisa ser um número.')
                .required(`Faturamento médio mensal: ${genericRequired}.`)
                .transform(handleNumericNaN),
            numberOfEmployees: number()
                .typeError('Valor precisa ser um número.')
                .optional()
                .transform(handleNumericNaN),
            legalNature: string().nullable().optional(),
            economicActivityCodeId: string().nullable().optional(),
            phone: string()
                .typeError('Número de telefone inválido')
                .phoneNumberValidation('Número de telefone deve conter 11 Caracteres')
                .required(`Número de telefone: ${genericRequired}.`),
        }),
    });
}

let inferedSchema = validationSchemaBankAccountRequestForm();
export type BankAccountRequestFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesBankAccountRequestForm = {
    bankAccountProductId: '',
    ownerId: '',
    type: 'Payment',
    partnerName: '',
    cardName: null,
    cardReceiptAddress: null,
    reason: 'Credit',
    step: 6,
    quorumMinimumApproval: 2,
    owner: {},
};
