import { Grid, Stack } from "@mui/material";
import { Button, Typography, UpdateIcon } from "@uy3/web-components";
import { iconSx } from "contexts/apiRequestContext";
import { activeTheme } from "services/theme";

type PermissionHeaderProps = {
    refetch: () => void;
}

const theme = activeTheme();

export function PermissionHeader({ refetch }: PermissionHeaderProps) {

    return (
        <Stack direction="row" justifyContent="space-between" alignItems='center' mr={3} mt={3}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="25px"
                >
                    Permissões
                </Typography>
            </Grid>
            <Stack direction='row' alignItems='center' gap={1}>
                <Button
                    variant="text"
                    disabled={false}
                    onClick={() => refetch()}
                    size="medium"
                    sx={{ color: '#000' }}
                    startIcon={
                        <UpdateIcon
                            htmlColor={theme.palette.common.black}
                            sx={iconSx}
                        />
                    }
                >
                    Atualizar
                </Button>
            </Stack>
        </Stack>
    );
};

export default PermissionHeader;