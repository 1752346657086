import { useEffect, useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { useEnumContext } from 'contexts/enumContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import { useParams } from 'react-router';
import UploadList from 'components/Tabs/Uploads/UploadList/UploadList';
import { useNaturalPersonData } from 'contexts/naturalPersonContext';
import { useLegalPersonData } from 'contexts/legalPersonContext';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { uniqBy } from 'lodash';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { useUserPermissionData } from 'contexts/userContext';
import { GridValidRowModel } from '@mui/x-data-grid';
import { UploadHeader } from './DocumentsHeader';
import DrawerWrapper from 'components/Tabs/Uploads/DrawerWrapper/DrawerWrapper';
import { useBankAccountRequestData } from 'contexts/bankAccount/bankAccountRequest';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { Stack } from '@mui/material';

export const DocumentsContainer = () => {
    const {
        status,
        data: typeOfDocument,
        error,
    } = useEnumContext({ size: 100, enumName: 'FileType' });

    const { bankAccountId } = useParams();
    const { bankAccountRequestData } = useBankAccountRequestData(bankAccountId!);
    const { hasPermission } = useUserPermissionData();
    const [statusSignature, setStatusSignature] = useState('');
    const [involvedLegalPerson, setInvolvedLegalPerson] = useState<LegalPersonReadModel[]>([]);
    const [involvedNaturalPerson, setInvolvedNaturalPerson] = useState<NaturalPersonReadModel[]>([]);
    const [selectedUploadIndex, setSelectedUploadIndex] = useState<number | undefined>();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedAction, setSelectedAction] = useState<string | undefined>();
    const [rowData, setRowData] = useState<GridValidRowModel | undefined>();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch } = useFormContext();
    const [relatedDocs, uploads, ownerDiscriminator] = watch([
        'relatedDocs',
        'uploads',
        'ownerDiscriminator',
    ]);
    const watchUploads = uploads ?? relatedDocs ?? [];
    const typePermission = bankAccountId === 'nova' ? 'Create' : 'Update';
    const hasPermCreateOrEdit = hasPermission('BankAccountRequest', typePermission);
    const [toast, setToast] = useState<ToastType>(toastState);

    const taker = {
        ownerDiscriminator: ownerDiscriminator ?? '',
        ownerId: watch('ownerId') ?? '',
    };

    const naturalPersonDiscriminator = taker.ownerDiscriminator === 'NaturalPerson';
    const legalPersonDiscriminator = taker.ownerDiscriminator === 'LegalPerson';
    const ownerId = taker.ownerId;

    const { naturalPersonData, isFetching: naturalPersonFetch } = useNaturalPersonData(
        naturalPersonDiscriminator ? ownerId : ''
    );
    const { legalPersonData, isFetching: legalPersonFetch } = useLegalPersonData(
        legalPersonDiscriminator ? ownerId : ''
    );

    useEffect(() => {
        let uniqueInvolvedPersons = [];

        if (taker.ownerDiscriminator === 'LegalPerson' && legalPersonData) {
            const { id, companyName, uploads } = legalPersonData;
            uniqueInvolvedPersons.push({ id, name: companyName, uploads } as LegalPersonReadModel);
        } else if (naturalPersonData) {
            const { id, name, uploads } = naturalPersonData;
            uniqueInvolvedPersons.push({ id, name, uploads } as NaturalPersonReadModel);
        }

        uniqueInvolvedPersons = uniqBy(uniqueInvolvedPersons, 'id');

        if (uniqueInvolvedPersons.length > 0) {
            uniqueInvolvedPersons?.map((prev: any) => {
                if (taker.ownerDiscriminator === 'NaturalPerson') {
                    return setInvolvedNaturalPerson([prev]);
                } else if (taker.ownerDiscriminator === 'LegalPerson') {
                    return setInvolvedLegalPerson([prev]);
                }
                return null;
            });
        }
    }, [taker.ownerDiscriminator, legalPersonData, naturalPersonData]);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    function onDelete(index: number) {
        let newUploads = [...watchUploads];
        newUploads?.splice(index, 1);
        if (relatedDocs) setValue('relatedDocs', newUploads);
        else setValue('uploads', newUploads);
    }

    function onDeleteLegalOrNaturalPerson(index: number, type: 'legal' | 'natural') {
        const listUploads: { [type: string]: any } = {
            legal: involvedLegalPerson,
            natural: involvedNaturalPerson,
        };

        let newUploads = [...listUploads[type]];
        newUploads?.splice(index, 1);
        if (type === 'natural') setInvolvedNaturalPerson(newUploads);
        else return setInvolvedLegalPerson(newUploads);
    }

    const isRefresh = status === 'loading' || naturalPersonFetch || legalPersonFetch;
    if (isRefresh) return <RefreshProgress refreshing={true} />;
    if (status === 'error') return <Error error={error} />;

    const statusDisplay = bankAccountRequestData?.statusDisplay;
    const isDraft = statusDisplay === 'Rascunho' || bankAccountId === 'nova';

    return (
        <Stack spacing={2}>
            <DrawerWrapper
                {...{
                    data: bankAccountRequestData,
                    targetId: bankAccountId!,
                    openDrawer,
                    setOpenDrawer,
                    selectedAction,
                    selectedUploadIndex,
                    setSelectedUploadIndex,
                    typeOfDocument: typeOfDocument!,
                    rowData,
                    setRowData,
                    setSelectedAction,
                    setStatusSignature,
                    setToast,
                    targetType: 'BankAccountRequest',
                }}
            />
            <Toast toast={toast} setToast={setToast} />
            <UploadHeader
                addNewDocuemnt={() => setOpenDrawer(true)}
                disabled={!hasPermCreateOrEdit || !isDraft}
            />
            <Stack spacing={4}>
                <UploadList
                    titleHeader="Documentos da solicitação"
                    uploads={watchUploads}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    setPage={onChangePage}
                    setOpenDrawer={(type, row) => {
                        setSelectedAction(type);
                        setRowData(row!);
                    }}
                    page={page}
                    setSelectedUploadIndex={(rowNumber: number | undefined) => {
                        setSelectedUploadIndex(rowNumber ?? watchUploads?.length);
                        setRowData(undefined);
                    }}
                    onDelete={onDelete}
                    typeOfDocument={typeOfDocument!}
                    statusSignature={statusSignature}
                    enableAction={hasPermCreateOrEdit}
                />

                {involvedNaturalPerson
                    ?.filter((item) => item.id)
                    .map((person, index) => (
                        <UploadList
                            key={index}
                            uploads={person?.uploads}
                            titleHeader={`Documentos ${person?.name}`}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={onChangeRowsPerPage}
                            setPage={onChangePage}
                            page={page}
                            setSelectedUploadIndex={(rowNumber: number | undefined) =>
                                setSelectedUploadIndex(rowNumber ?? watchUploads?.length)
                            }
                            onDelete={(index) => onDeleteLegalOrNaturalPerson(index, 'natural')}
                            typeOfDocument={typeOfDocument!}
                        />
                    ))}

                {involvedLegalPerson
                    ?.filter((item) => item.id)
                    .map((person, index) => (
                        <UploadList
                            key={index}
                            uploads={person?.uploads}
                            rowsPerPage={rowsPerPage}
                            titleHeader={`Documentos ${person?.name ?? ''}`}
                            name={person?.name ?? ''}
                            setRowsPerPage={onChangeRowsPerPage}
                            setPage={onChangePage}
                            page={page}
                            setSelectedUploadIndex={(rowNumber: number | undefined) =>
                                setSelectedUploadIndex(rowNumber ?? watchUploads?.length)
                            }
                            onDelete={(index) => onDeleteLegalOrNaturalPerson(index, 'legal')}
                            typeOfDocument={typeOfDocument!}
                        />
                    ))}
            </Stack>
        </Stack>
    );
};
