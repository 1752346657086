/* eslint-disable react-hooks/exhaustive-deps */
import OperationsTab from '../CreditNoteForm/Tabs/Operation/OperationTab';
import AmortizationQuery from '../CreditNoteForm/Tabs/Operation/AmortizationQuery';
import OperationDetails from '../CreditNoteForm/Tabs/Operation/OperationDetails';
import { AmortizationReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { useMemo, useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showWarningToast,
    toastState,
} from 'contexts/apiRequestContext';
import { useCreateSimulation } from 'contexts/creditNote/creditContext';
import { defaultValuesSimulationForm, validationSchemaSimulationForm } from './SimulationSchema';
import { FieldValues, useForm } from 'react-hook-form';
import { useCreditProdutList, useCreditProductData } from 'contexts/creditProductContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { RefreshProgress } from 'components/RefreshProgress';
import { CreditNoteInformationFormContextProvider } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import SimulationForm from './SimulationForm';
import { usePersonsList } from 'contexts/personContext';
import { isEmpty } from 'lodash';
import { Drawer, Popup } from '@uy3/web-components';
import PrintConfiguration from './PrintConfiguration';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { SiapeQueryFormContainer } from 'components/GenericForms/SiapeQueryForm/SiapeQueryFormContainer';
import {
    calculateAndSetFirstPaymentDate,
    handlePaymentAndCutoffDays,
} from '../CreditNoteForm/Tabs/Operation/setFirstPaymentDate';
import { useAppConfig } from 'contexts/appConfig';
import { isFieldHidden } from 'helpers/methods/formFieldConfiguration';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';

const SimulationFormContainer = () => {
    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, 'always');
    const [action, setAction] = useState<string | undefined>(undefined);
    const [simulationResponse, setSimulationResponse] = useState<any | AmortizationReadModel>();
    const [error, setError] = useState<ApiResponseError | undefined>(undefined);
    const { submitError } = useFormContext();
    const [productId, setProductId] = useState<string | undefined>(undefined);
    const [simulationId, setSimulationId] = useState<string | undefined>(undefined);
    const [removedFromPrintList, setRemovedFromPrintList] = useState<string[]>([]);
    const { creditProductData, creditProductErr, creditProductStatus } = useCreditProductData(productId);
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const { setFormFieldsErrors } = useFormFieldsError();
    const schema = validationSchemaSimulationForm(creditProductData);
    const defaultValues = defaultValuesSimulationForm(creditProductData);
    const [toast, setToast] = useState<ToastType>(toastState);

    const onClose = () => setAction(undefined);

    const isAmortizationFGTS = creditProductData?.amortizationType === 'FGTS';

    useMemo(() => {
        if (productId === undefined) setFormFieldsErrors([]);
    }, [productId]);

    const onSuccess = (data: AmortizationReadModel) => {
        setSimulationId(data?.id!);
        setError(undefined);
        setSimulationResponse(data);
        setFormFieldsErrors([]);
    };

    const onError = (response: ApiResponseError) => {
        const { fieldErrors, warningToastError, errorMessage } = mapErrorResponse(response);
        setError(response);
        setFormFieldsErrors(fieldErrors ?? []);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const { mutate, isLoading } = useCreateSimulation(onSuccess, onError);

    const onSubmit = async (values: FieldValues) => {
        let requestedAmount = values?.amortization?.requestedAmount;
        setFormFieldsErrors([]);
        if (values?.personId !== undefined && requestedAmount === undefined) requestedAmount = 0;

        const payload = {
            ...values,
            amortization: {
                ...values.amortization,
                requestedAmount: requestedAmount,
            },
        };
        mutate(payload);
    };

    const onChangeCheckbox = (item: string) => {
        const currentItemIndex = removedFromPrintList.indexOf(item);

        if (currentItemIndex === -1) {
            setRemovedFromPrintList([...removedFromPrintList, item]);
        } else {
            var modifiedRemovedFromPrint = [...removedFromPrintList];
            modifiedRemovedFromPrint.splice(currentItemIndex, 1);
            setRemovedFromPrintList([...modifiedRemovedFromPrint]);
        }
    };

    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const isFirstPaymentAutoSet = appConfig.SET_FIRST_PAYMENT_ON_DAY;
    const isPaymentDay = appConfig.PAYMENTDAY_FIELD;
    const amortizationType = creditProductData?.amortizationType?.toLowerCase() ?? 'cleanprice';
    const executeAmortizationBasedOnType = ['price', 'sac', 'cleanprice'].includes(amortizationType);
    const instrumentType = creditProductData?.instrumentType.toLowerCase();
    const warrantyRegistrationOffice = creditProductData?.warrantyRegistrationOffice?.toLowerCase();
    const isPublicPayrollLoan = instrumentType === 'publicpayrollloan';
    const isSiapeOffice = warrantyRegistrationOffice === 'siape' && isPublicPayrollLoan;

    return (
        <GenericErrorBoundary
            status={creditProductStatus}
            error={creditProductErr}
            fallback="simulação"
        >
            <>
                <Toast toast={toast} setToast={setToast} />
                <ApiErrorAlert error={error ?? (submitError as ApiResponseError)} />
                <RefreshProgress refreshing={isLoading} />
                <CreditNoteInformationFormContextProvider product={creditProductData}>
                    <FormProvider
                        readOnly={false}
                        validationSchema={schema}
                        defaultValues={defaultValues}
                        onSubmit={onSubmit}
                        onChangeField={[
                            {
                                fieldName: 'productId',
                                delegate: (value, setValue, watch) => {
                                    setProductId(value);
                                    const startDateString = watch!('amortization.startDate');
                                    const isHidden = isFieldHidden(
                                        'amortization.startDate',
                                        formFieldsConfig
                                    );

                                    // #region Lógica para setar a data de pagamento - KORV
                                    if (isHidden) {
                                        calculateAndSetFirstPaymentDate({
                                            startDateString,
                                            executeAmortizationBasedOnType,
                                            setValue,
                                            watch: watch!,
                                            isFirstPaymentAutoSet,
                                        });
                                    } else {
                                        // #region Lógica para setar a data de pagamento - SIAPE
                                        handlePaymentAndCutoffDays(
                                            startDateString,
                                            isPaymentDay,
                                            isFirstPaymentAutoSet,
                                            setValue
                                        );
                                    }
                                },
                            },
                            {
                                fieldName: 'amortization.startDate',
                                delegate: (
                                    startDateString: string,
                                    setValue: ReturnType<typeof useForm>['setValue'],
                                    watch?: ReturnType<typeof useForm>['watch']
                                ) => {
                                    if (startDateString && !isSiapeOffice) {
                                        calculateAndSetFirstPaymentDate({
                                            startDateString,
                                            executeAmortizationBasedOnType,
                                            setValue,
                                            watch: watch!,
                                            isFirstPaymentAutoSet,
                                        });
                                    } else {
                                        handlePaymentAndCutoffDays(
                                            startDateString,
                                            isPaymentDay,
                                            isFirstPaymentAutoSet,
                                            setValue
                                        );
                                    }
                                },
                            },
                            {
                                fieldName: 'registrationNumber',
                                delegate: (value, setValue) => {
                                    if (isAmortizationFGTS) {
                                        if (!isEmpty(value)) {
                                            setValue('amortization.requestedAmount', 0);
                                            setValue('amortization.paymentMonth', null);
                                        }
                                        if (value?.length)
                                        setValue('registrationNumber', value?.replace(/\D/g, ''));
                                    }
                                },
                            },
                            {
                                fieldName: 'personId',
                                delegate: (value, setValue) => {
                                    if (isAmortizationFGTS) {
                                        if (!isEmpty(value)) {
                                            setValue('amortization.requestedAmount', 0);
                                            setValue('amortization.paymentMonth', null);
                                            setValue('registrationNumber', null);
                                        }
                                    }
                                },
                            },
                        ]}
                    >
                        <>
                            <SimulationForm simulationId={simulationId} handleAction={setAction} />
                            <Popup
                                title="Configurar impressão"
                                open={action === 'printSettting'}
                                onClose={onClose}
                                children={
                                    <PrintConfiguration
                                        handleOnChange={onChangeCheckbox}
                                        onCloseModal={onClose}
                                        removedFromPrintList={removedFromPrintList}
                                    />
                                }
                            />
                            <OperationsTab
                                productList={creditProductAutoCompleteProps}
                                tomadorList={personAutoCompleteProps}
                                isSimulation={true}
                                defaultValues={defaultValues}
                            />
                            <AmortizationQuery
                                removedFromPrintList={removedFromPrintList}
                                simulationResponse={simulationResponse}
                                simulationId={simulationId}
                            />
                            {simulationResponse && (
                                <OperationDetails
                                    removedFromPrintList={removedFromPrintList}
                                    currentPaymentScheduleItems={
                                        simulationResponse?.paymentScheduleItems
                                    }
                                />
                            )}

                            <Drawer
                                anchor="right"
                                open={action === 'siapeQueryMargin'}
                                title="Consultar margem"
                                onClose={onClose}
                            >
                                <SiapeQueryFormContainer onClose={onClose} setToast={setToast} />
                            </Drawer>
                        </>
                    </FormProvider>
                </CreditNoteInformationFormContextProvider>
            </>
        </GenericErrorBoundary>
    );
};

export default SimulationFormContainer;
