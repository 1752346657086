/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import { Button, CardData, CloseIcon, Drawer, Modal } from '@uy3/web-components';
import { useState } from 'react';
import CreditLimitList from './CreditLimitList';
import { useCreditStatusRequestList } from 'contexts/creditStatusRequest';
import { RefreshProgress } from 'components/RefreshProgress';
import { Grid, Stack } from '@mui/material';
import { Error } from 'components/Errors/Error';
import ApproveCreditRequestFormContainer from '../Modal/ApproveCreditRequestForm/ApproveCreditRequestFormContainer';
import CreditRequestFormContainer from '../Modal/CreditRequestForm/CreditRequestContainer';
import { activeTheme } from 'services/theme';
import InfoMessage from 'components/InfoMessage/InfoMessage';

type CreditLimitProps = {
    id?: string | undefined;
    disableRequestDataSet?: boolean;
};

const theme = activeTheme();
function CreditLimit({ id, disableRequestDataSet }: CreditLimitProps) {
    const [modal, setModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>(undefined);
    const { personId } = useParams();
    const dataId = id ?? personId;
    const { data, status, refetch, error, isFetching } = useCreditStatusRequestList({ page: 0, size: 15, personId: dataId! });
    if (status === "error") <Error error={error} />

    const closeModal = () => setModal(false);

    return (
        <>
            <RefreshProgress refreshing={isFetching} />
            <Modal
                title={!rowData ? "Solicitar crédito" : 'Operação'}
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={<>
                    {!rowData ? <CreditRequestFormContainer closeModal={closeModal} refetch={refetch} id={id} /> : <ApproveCreditRequestFormContainer rowData={rowData} closeModal={closeModal} refetch={refetch} />}
                </>}
            />

            <Drawer anchor='right' title='Detalhes' open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <>
                    <CardData
                        listItem={[
                            { id: "1", title: 'Criado por', value: `${rowData?.createdBy?.userIdDisplay}` },
                        ]}
                    />
                    <CardData listItem={[
                        { id: "2", title: 'Atualizado por', value: `${rowData?.updatedBy?.userIdDisplay}` }
                    ]}
                    />
                    <Stack direction='row' justifyContent="flex-end" spacing={3}>
                        <Button
                            startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                            variant="outlined"
                            size="medium"
                            onClick={() => setOpenDrawer(false)}
                        >
                            Fechar
                        </Button>
                    </Stack>
                </>
            </Drawer>

            <CreditLimitList
                result={data?.data}
                setRowData={setRowData}
                setModal={setModal}
                setOpenDrawer={() => setOpenDrawer(true)}
            />

            {disableRequestDataSet && <div style={{ marginTop: 15 }}>
                <InfoMessage message='Você não tem permissão para solicitar esse recurso' />
            </div>}

            <Grid container spacing={2} mt={2}>
                <Grid item xs={1.5}>
                    <Button
                        fullWidth
                        variant='contained'
                        disabled={disableRequestDataSet}
                        onClick={() => { setModal(true); setRowData(undefined); }} >
                        Solicitar
                    </Button>
                </Grid>

                <Grid item xs={1.5}>
                    <Button
                        fullWidth
                        variant='contained'
                        onClick={() => refetch()} >
                        Atualizar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default CreditLimit;