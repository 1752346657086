import { useState } from 'react';
import { useParams } from 'react-router';
import AccountStatementTabList from './AccountStatementTabList';
import { RefreshProgress } from 'components/RefreshProgress';
import moment from 'moment';
import { useBankAccountStatement } from 'contexts/bankAccount/bankAccountContext';
import { FilterProvider } from 'contexts/filterContext';
import {
    BalancesType,
    StatementQueryDataType,
} from 'services/accounts/bankAccount/bankAccount.types';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import ButtonExportAccountStatement from './ButtonExport';
import AccountStatementHeader from './AccountStatementHeader';

export type DateFilterType = {
    initialDate: string;
    finalDate: string;
};

export function AccountStatementTabContainer() {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { submitting } = useApiRequest();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [periodSelected, setPeriodSelected] = useState<string>('útimos 7 dias');
    const [dateFilter, setDateFilter] = useState<DateFilterType>({
        initialDate: moment().subtract(7, 'days').format(),
        finalDate: moment().format(),
    });

    let tableData: StatementQueryDataType[] = [];
    const {
        data: dataStatement,
        refetch,
        isFetching,
    } = useBankAccountStatement(bankAccountId!, dateFilter);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    if (dataStatement) {
        tableData = dataStatement?.balances
            .filter((row: BalancesType) => row?.transactions?.length > 0)
            .reduce((transactionList: StatementQueryDataType[], currentBalance: BalancesType) => {
                return [
                    ...transactionList,
                    {
                        id: 'Balance' + transactionList.length,
                        type: 'Balance',
                        availableValue: currentBalance.availableValue,
                        date: currentBalance.issueDate,
                    },
                    ...currentBalance.transactions.map((transaction: StatementQueryDataType) => {
                        return {
                            ...transaction,
                            id: Math.random().toString().replace('0.', ''),
                            type: 'Transaction',
                        };
                    }),
                ];
            }, [])
            .filter((result: StatementQueryDataType) => result.type !== 'Balance');
    }

    const isEmptyData = tableData?.length === 0;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <AccountStatementHeader
                isFetching={isFetching || submitting}
                refetch={refetch}
                periodSelected={periodSelected}
            />

            <RefreshProgress refreshing={submitting} />

            <FilterProvider
                availableFilters={{
                    initialDate: {
                        label: 'Data de início',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDate',
                    },
                }}
            >
                <AccountStatementTabList
                    setToast={setToast}
                    setPeriodSelected={setPeriodSelected}
                    setDateFilter={setDateFilter}
                    queryData={tableData}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    setPage={onChangePage}
                    page={page}
                />
            </FilterProvider>
            <ButtonExportAccountStatement
                dateFilter={dateFilter}
                isEmptyData={isEmptyData}
                isFetching={isFetching}
            />
        </>
    );
}
