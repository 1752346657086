import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { formatDataMyLimitsList } from 'helpers/formats/BankAccount';
import { LimitsReadModel, recordTypeOptions } from 'services/accounts/bankAccount/bankAccount.types';
interface MyLimitsListProps {
    recordType: number;
    queryData: LimitsReadModel;
    isLoading: boolean;
    accountType: string | undefined;
};

const MyLimitsList: React.FC<MyLimitsListProps> = ({
    queryData,
    recordType,
    isLoading,
    accountType
}) => {
    let rowData = formatDataMyLimitsList(queryData);
    if(accountType === 'Conta Escrow' && !!rowData?.length) {
        rowData = rowData.filter((row) => row?.type !== "BOLETOS");
    }

    const selectedRecordType = recordTypeOptions[recordType];

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: `Limites`,
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            renderCell: (cellValues) => cellValues?.row?.type
        },
        {
            field: 'values.businessHours',
            headerName: `Horário comercial (06h às 20h)`,
            hideSortIcons: true,
            minWidth: 250,
            editable: false,
            renderCell: ({ row }) => {
                const value = row?.values?.businessHours?.[selectedRecordType];
                return value === null ? "Padrão da instituição" : formatCurrencyInCents(value)
            }
        },
        {
            field: 'values.nonBusinessHours',
            headerName: `Fora do horário comercial (20h às 06h)`,
            hideSortIcons: true,
            minWidth: 300,
            editable: false,
            renderCell: ({ row }) => {
                const value = row?.values?.nonBusinessHours?.[selectedRecordType];
                return value === null ? "Padrão da instituição" : formatCurrencyInCents(value)
            }
        }
    ];

    return (
        <DataTable
            columns={columns}
            rows={rowData}
            loading={isLoading}
            getRowId={() => Math.random()}
            page={1}
            setPage={() => {}}
            rowsPerPage={5}
            setRowsPerPage={() => {}}
            rowCount={rowData?.length || 0}
            enableJumpAction={false}
            hideFooter
            sx={{
                '& .MuiDataGrid-cell:first-child': {
                    fontWeight: 800,
                },
            }}
        />
    );
};

export default MyLimitsList;
