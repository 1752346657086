import { IUploadModel } from 'contexts/apiRequestContext';
import { IAddressModel, IBankAccountCreateOrUpdateModel } from 'utils/interface';

export class LegalPersonBaseModel {
    companyName!: string;
    name!: string;
    typeOfActivity?: string;
    legalNature: string = '';
    municipalRegistration?: string;
    stateRegistration?: string;
    hasOpenCapitalRegistrationCVM?: boolean;
    openDate!: string;
    email!: string;
    site?: string;
    responsible?: string;
    phone!: string;
    phoneExtension?: string;
    phone2?: string;
    phone2Extension?: string;
    economicActivityCodeId!: string;
    sameBillingAddress?: boolean;
    b3Account?: string;
    averageMonthlyRevenue!: number;
    averageGrossProfitMargin?: number;
    initialShareCapital?: number;
    netWorth?: number;
    purchases?: number;
    amountRentedFinanced?: number;
    exportPercentage?: number;
    costWithSuppliers?: number;
    numberOfEmployees!: number;
    payrollValue?: number;
    spendingAmountWithTax?: number;
    typeOfResidence?: string;
    averageSalesPeriod?: number;
    fosterablePotential?: number;
    delayedSalePercentage?: number;
    allowAccessToSchedule?: boolean;
    allowAccessToReceivables?: boolean;
    allowPartnerApiSubscription?: boolean;
    description?: string;
    riskRating?: string;
    bankAccounts?: IBankAccountCreateOrUpdateModel[];
    address!: IAddressModel;
    uploads?: IUploadModel[];
    externalEmployerId?: number;

    constructor(init?: Partial<LegalPersonBaseModel>) {
        Object.assign(this, init);
    }
}
