import {
    useApiRequest,
    GetViewApiResponse,
    GetViewApiResponseSuccess,
    ApiResponseError,
    IRefetchOnMountModeGetQuery,
    UploadGet,
} from '../apiRequestContext';
import { useIdentity } from '../identityContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import {
    FGTSBatchAssignment,
    approveBatchAssignmentById,
    cancelBatchAssignmentById,
    cercPaymentLiquidation,
    cercRegisterBatchAssignment,
    deleteBatchAssignmentById,
    generateZipBatchAssignment,
    getBatchAssignment,
    getBatchAssignmentById,
    getCreditNotesBatchAssignment,
    getPaymentScheduleBatchAssignment,
    hemeraDepositBatchAssignment,
    hemeraGenerateFileBatchAssignment,
    hemeraSendFileBatchAssignment,
    installmentBatchAssignment,
    liquidationFileBatchAssignment,
    postBatchAssignmentById,
    postCalculateById,
    remittanceVortxBatchAssignment,
    saveDocumentBatchAssignment,
} from 'services/creditNote/BatchAssignment/BatchAssignment';
import {
    BatchAssignmentFilter,
    BathCreditNoteProps,
    FGTSBatchAssignmentType,
    PayloadBatchAssignmentApprovalProps,
    PayloadBatchAssignmentProps,
} from 'services/creditNote/BatchAssignment/BatchAssignment.types';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { useAppConfig } from 'contexts/appConfig';

export function useBatchAssignmentData(
    filters: BatchAssignmentFilter,
    refetchOnMount?: IRefetchOnMountModeGetQuery,
    enable?: boolean
) {
    const { token } = useIdentity();
    const { appConfig } = useAppConfig();
    const isRootTenancy = appConfig.TENANT_TYPE === 0;
    const { filterValues, setFilterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const recordType = isEmpty(filterValues.recordType)
        ? 'batchAssignment'
        : filterValues.recordType;
    let listDataFilters = recordType === 'batchAssignment' && { ...filterValues.filters };
    const [assignmentNumber, setAssignmentNumber] = useState<string | undefined>(undefined);
    if (refetchOnMount === 'always') {
        listDataFilters = { assignmentNumber: assignmentNumber! };
    }
    const [batchAssignmentList, setBatchAssignmentList] = useState<
        { label: string; value: string }[]
    >([]);
    const { currentTenantId } = useTenant();
    const filtersComplete = enable
        ? { ...filters, ...listDataFilters, tenant: currentTenantId }
        : { ...filters, tenant: currentTenantId };

    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BatchAssignment', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['batchAssignment-data', filtersComplete],
        queryFn: async (): Promise<GetViewApiResponse<CreditNoteReadModel[]>> => {
            startRequest();
            const { data, status, statusText } = await getBatchAssignment(filtersComplete, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            const dataSuccess = data as GetViewApiResponseSuccess<CreditNoteReadModel[]>;

            const options = dataSuccess.data.map((item) => {
                return {
                    label: isRootTenancy
                        ? `${item?.assignmentNumber}ª - ${
                              item?.fundIdDisplay || 'Não informado'
                          } (${item.tenantDisplay}))`
                        : `${item?.assignmentNumber}ª - ${item.fundIdDisplay}`,
                    value: item.id,
                };
            });
            setBatchAssignmentList(options);

            return dataSuccess;
        },
    });

    const batchAssignmentAutoComplete = {
        listOptions: batchAssignmentList,
        loading: !hasReadPermission ? false : isLoading || isFetching,
        onSearch: (assignmentNumber: string | undefined) => {
            if (refetchOnMount === 'always') return setAssignmentNumber(assignmentNumber);
            else
                return assignmentNumber && setFilterValues({ assignmentNumber }, 'batchAssignment');
        },
    };

    return {
        batchAssignmentStatus: status,
        batchAssignmentData: data,
        batchAssignmentError: error,
        refetch,
        isFetching,
        batchAssignmentAutoComplete,
        isLoading,
    };
}

export function useBatchAssignmentByIdData(id: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BatchAssignment', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['batch-query-simple-by-id', id],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getBatchAssignmentById(id!, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
    });

    return {
        batchAssignmentStatus: status,
        batchAssignmentData: data,
        batchAssignmenterror: error,
        refetch,
        isFetching,
        isLoading,
    };
}

export function useCreditNotesBatchAssignment(id: string, params: BathCreditNoteProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BatchAssignment', 'Read');
    const { filterValues } = useFilterValues();
    const listDataFilters = { ...filterValues.filters };
    const filtersComplete = { ...params, ...listDataFilters };
    
    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['batchAssignment-creditNotes', id, filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getCreditNotesBatchAssignment(
                id,
                filtersComplete,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
    });

    return {
        status,
        data,
        error,
        refetch,
        isFetching,
        isLoading: !Object.values(filterValues.filters ?? {}).length && isLoading,
    };
}

export function usePaymentScheduleBatchAssignment(
    id: string,
    params: { page: number; size: number }
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('BatchAssignment', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['BatchAssignment-payment-schedule', id, params],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getPaymentScheduleBatchAssignment(
                id,
                params,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetViewApiResponseSuccess<any>;
        },
    });

    return { status, data, error, refetch, isFetching, isLoading };
}

export function useBatchAssignmentMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (
            values: PayloadBatchAssignmentProps
        ): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await postBatchAssignmentById(values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<PayloadBatchAssignmentProps | any>;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}

export function useRecalculateBatchAssignamntMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: any): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await postCalculateById(id, token!, values);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<PayloadBatchAssignmentProps | any>;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { recalculateMutate: mutate, isLoadingRecalculate: isLoading };
}

export function useBatchAssignmenApprovalMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (
            values: PayloadBatchAssignmentApprovalProps
        ): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await approveBatchAssignmentById(id, values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<PayloadBatchAssignmentProps | any>;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}

export function useCancelBatchAssignmenMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (id: string): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await cancelBatchAssignmentById(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateCancel: mutate, isLoadingCancel: isLoading };
}

export function useDeleteBatchAssignmenMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (id: string): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await deleteBatchAssignmentById(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateDelete: mutate, isLoadingDelete: isLoading };
}

export function useFGTSBatchAssignmentMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: FGTSBatchAssignmentType): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await FGTSBatchAssignment(values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateFGTSManagement: mutate, isLoading };
}

export function useLiquidationFileBatchAssignemntMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (id: string): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await liquidationFileBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateLiquidationFile: mutate, isLoading };
}

export function useRemittenceVortxBatchAssignemntMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (id: string): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await remittanceVortxBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateRemittenceVortx: mutate, isLoading };
}

export function useGenareteZipMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (id: string): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await generateZipBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateGenerateZip: mutate, isLoading };
}

export function useSaveDocumentsMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (uploads: UploadGet[]): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await saveDocumentBatchAssignment(id, uploads, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateSaveDocuments: mutate, isLoading };
}

export function useInstallmentBatchAssigment(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (id: string) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await installmentBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { installmentBatchAssignmentMutate: mutateAsync, isLoading };
}

export function useHemeraDepositBatchAssignment(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await hemeraDepositBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateHemeraDeposit: mutate, isLoading };
}

export function useHemeraGenerateFileBatchAssignment(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await hemeraGenerateFileBatchAssignment(id, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateGenerateFile: mutate, isLoading };
}

export function useHemeraSendFileBatchAssignment(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (list: string[]): Promise<GetViewApiResponse<any>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await hemeraSendFileBatchAssignment(id, list, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
    return { mutateSendFile: mutate, isLoading };
}

export function useCercRegisterMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, status } = useMutation({
        mutationFn: async (batchId: string) => {
            startRequest();
            const { data, status, statusText } = await cercRegisterBatchAssignment(batchId, token);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error, status };
}

export function useCercPaymentLiquidationMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (paymentScheduleIds: string[]) => {
            startRequest();
            const { data, status, statusText } = await cercPaymentLiquidation(
                paymentScheduleIds,
                token
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
}
