import { Stack } from '@mui/material'
import CardsSelect from 'components/CardSelect/CardSelect';
import { DiscriminatorType } from '../../AddPayerFormContainer';
import { Alert, Button, CloseIcon } from '@uy3/web-components';
import { isEmpty } from 'lodash';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type DescriminatorFormProps = {
    onNextStep: (value: DiscriminatorType) => void;
    onClose: () => void;
    discriminator: DiscriminatorType
}

const theme = activeTheme();

export const DescriminatorForm = ({ onNextStep, discriminator, onClose }: DescriminatorFormProps) => {
    return (
        <Stack spacing={2}>
            <CardsSelect
                onChange={({ value }) => {
                    onNextStep(value as DiscriminatorType)
                }}
                options={[
                    { label: "Pessoa Física", value: "NaturalPerson" },
                    { label: "Pessoa Jurídica", value: "LegalPerson" }
                ]}
                value={discriminator!}
            />

            {!isEmpty(discriminator) ? <Alert
                severity='info'
                text={`Ótimo! Estamos direcionando você para o formulário de cadastro de ${discriminator === 'LegalPerson' ? 'pessoa jurídica' : 'pessoa física'}.`}
            /> :
                <Stack direction='row' alignItems='center' justifyContent='end'>
                    <Button onClick={onClose} variant='outlined' startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}>Fechar</Button>
                </Stack>}
        </Stack>
    )
}
