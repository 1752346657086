import { string, object, date, number, boolean } from 'yup';
import { isEmpty } from 'lodash';
import { DefaultSchemaAmmSAC, ValidationSchemaAmmSac } from '../schema/SAC';
import { DefaultSchemaAmmPrice } from '../schema/Price';
import { DefaultSchemaAmmCleanPrice, ValidationSchemaAmmCleanPrice } from '../schema/CleanPrice';
import { DefaultSchemaAmmPriceCoefficient, ValidationSchemaAmmPriceCoefficient } from '../schema/PriceCoefficient';
import {
    DefaultSchemaAmmReceivablesPrice,
    ValidationSchemaAmmReceivablesPrice,
} from '../schema/ReceivablesPrice';
import { DefaultSchemaAmmStudentLoan, ValidationSchemaAmmStudentLoan } from '../schema/StudentLoan';
import { DefaultSchemaAmmFGTS } from '../schema/FGTS';
import {
    DefaultSchemaAmmComercialPapper,
    ValidationSchemaAmmCommercialPapper,
} from '../schema/CommercialPapper';
import { DefaultSchemaBasicAmortization } from '../schema/BasicAmortization';
import { handleNumericNaN } from 'helpers/validations/formFieldValidator';
import { getNumberWithPrecision } from 'helpers/formats/Currency';
import { setDefaultValues } from 'helpers/methods/formFieldConfiguration';
import { CreditProductReadModel } from 'services/creditProduct/types/creditProductReadModel';

const genericRequired = 'Precisa ser preenchido.';

const isNullOrEmpty = (value: string | null) => {
    return value === null || value === '';
};

export const validationSchemaSimulationForm = (product?: CreditProductReadModel) => {
    var minimum = getNumberWithPrecision(product?.minimumInterestRate ?? 0);
    var maximum = getNumberWithPrecision(product?.maximumInterestRate ?? 100);

    const ammSacOrPriceSchema = new ValidationSchemaAmmSac();
    const ammCleanPriceSchema = new ValidationSchemaAmmCleanPrice();
    const ammPriceCoefficientSchema = new ValidationSchemaAmmPriceCoefficient();
    const ammReceivablesPrice = new ValidationSchemaAmmReceivablesPrice();
    const ammStudentLoan = new ValidationSchemaAmmStudentLoan();
    const ammComercialPapper = new ValidationSchemaAmmCommercialPapper();

    const schemaObject: { [key: string]: any } = {
        sac: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammSacOrPriceSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        price: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammSacOrPriceSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        cleanprice: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammCleanPriceSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        pricecoefficient: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammPriceCoefficientSchema,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        receivablesprice: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammReceivablesPrice,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        studentloan: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammStudentLoan,
                apr: number()
                    .typeError('Valor precisa ser um número.')
                    .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                    .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                    .required(`Taxa de juros: ${genericRequired}`),
            }),
        }),
        fgts: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            personId: string().nullable(),
            legalPerson: boolean(),
            registrationNumber: string().when(['personId', 'requestedAmount', 'amortization'], {
                is: (
                    personId: string | null,
                    requestedAmount: any,
                    amortization: { paymentMonth: any }
                ) => {
                    const monthAndAmountFilled =
                        (!requestedAmount && !amortization.paymentMonth) === true;
                    const shouldOnlyUseRegistrationNumber =
                        isNullOrEmpty(personId) && monthAndAmountFilled;
                    return shouldOnlyUseRegistrationNumber;
                },
                then: string()
                    .typeError('Valor precisa ser um número.')
                    .required(`CPF: ${genericRequired}`)
                    .cpfCnpjValidation('CPF inválido.'),
                otherwise: string().nullable(),
            }),
            amortization: object().when('personId', (personId) => {
                return object().shape({
                    requestedAmount: isEmpty(personId)
                        ? number()
                            .typeError('Valor precisa ser um número.')
                            .required(`Saldo FGTS: ${genericRequired}`)
                            .transform(handleNumericNaN)
                        : number().nullable().transform(handleNumericNaN),
                    startDate: date()
                        .typeError('Data inválida')
                        .required('Data de início é obrigatória.'),
                    termInMonths: number()
                        .typeError('Valor precisa ser um número.')
                        .required(`Prazo: ${genericRequired} `)
                        .min(1, 'Informe a quantidade de parcelas')
                        .max(10, 'Quantidade máxima de parcelas excedida (10 parcelas)'),
                    apr: number()
                        .typeError('Valor precisa ser um número.')
                        .min(minimum, `Valor mínimo: ${getNumberWithPrecision(minimum * 100)}%`)
                        .max(maximum, `Valor máximo: ${getNumberWithPrecision(maximum * 100)}%`)
                        .required(`Taxa de juros: ${genericRequired} `),
                    paymentMonth: string().when('personId', {
                        is: (personId: string | null) => isNullOrEmpty(personId),
                        then: string().typeError('Mês inválido.').required('Selecione um mês.'),
                        otherwise: string().nullable().typeError('Mês inválido.').notRequired(),
                    }),
                    agentCommission: object().shape({
                        amount: number().transform(handleNumericNaN),
                    }),
                    financeTaxExempted: boolean(),
                });
            }),
        }),
        commercialpapper: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammComercialPapper,
            }),
        }),

        discount: object().shape({
            amortization: object().shape({
                startDate: date()
                    .typeError('Data inválida')
                    .required('Data de início é obrigatória.'),
            })
        }),

        default: object().shape({
            productId: string().typeError('Selecione um produto').required('Selecione um produto'),
            legalPerson: boolean().required(`Tipo de Pessoa: ${genericRequired}`),
            amortization: object().shape({
                ...ammComercialPapper
            }),
        }),
    };

    const productType = (product?.amortizationType || 'default').toLowerCase();

    return schemaObject[productType];
};

export const defaultValuesSimulationForm = (product?: CreditProductReadModel) => {
    const ammSac = new DefaultSchemaAmmSAC();
    const ammPrice = new DefaultSchemaAmmPrice();
    const ammCleanPrice = new DefaultSchemaAmmCleanPrice();
    const ammPriceCoefficient = new DefaultSchemaAmmPriceCoefficient();
    const ammReceivablesPrice = new DefaultSchemaAmmReceivablesPrice();
    const ammStudentLoan = new DefaultSchemaAmmStudentLoan();
    const ammFGTS = new DefaultSchemaAmmFGTS();
    const ammComercialPapper = new DefaultSchemaAmmComercialPapper();
    const basicAmm = new DefaultSchemaBasicAmortization();

    const amortizationTypeObject: { [key: string]: any } = {
        sac: {
            legalPerson: false,
            amortization: { ...ammSac },
        },
        price: {
            legalPerson: false,
            amortization: { ...ammPrice },
        },
        cleanprice: {
            legalPerson: false,
            amortization: { ...ammCleanPrice },
        },
        pricecoefficient: {
            legalPerson: false,
            amortization: { ...ammPriceCoefficient },
        },
        receivablesprice: {
            legalPerson: false,
            amortization: { ...ammReceivablesPrice },
        },
        studentloan: {
            legalPerson: false,
            amortization: { ...ammStudentLoan },
        },
        fgts: {
            legalPerson: false,
            personId: null,
            amortization: { ...ammFGTS },
        },
        commercialpapper: {
            legalPerson: false,
            amortization: { ...ammComercialPapper },
        },
        default: {
            legalPerson: false,
            amortization: { ...basicAmm },
        },
    };

    const productType = (product?.amortizationType || 'default').toLowerCase();

    return setDefaultValues(amortizationTypeObject[productType]);
};
