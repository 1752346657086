import { Drawer } from "@uy3/web-components";
import { useState } from "react";
import { FormProvider, useFormContext } from "contexts/formContext";
import { ToastType, IUploadModel, toastState } from "contexts/apiRequestContext";
import { useEnumContext } from "contexts/enumContext";
import { RefreshProgress } from "components/RefreshProgress";
import { Error } from "components/Errors/Error";
import { defaultValuesInvolvedFundForm, validationSchemaInvolvedFundForm } from "./InvolvedFundSchema";
import { usePersonsList } from "contexts/personContext";
import InvolvedFundList from "./InvolvedFundList";
import { InvolvedFundForm } from "./InvolvedFundForm";
import Toast from "components/Toast/Toast";
import { useLocation } from "react-router";
import { useUserPermissionData } from "contexts/userContext";
import { GenericListHeader } from "components/GenericListHeader/GenericListHeader";
import { hasCustomPermission, isActionAllowed, isRootTenancy } from "helpers/methods/GenericMethods";
import { useAppConfig } from "contexts/appConfig";

export const InvolvedFundTabContainer = () => {
    const { status: signatureStts, data: signatureType, error: signatureErr } = useEnumContext({ enumName: "SignatureType" });
    const { status: SignatureValidationStts, data: signatureValidation, error: SignatureValidationErr } = useEnumContext({ enumName: "SignatureValidation", size: 50 });
    const { pathname } = useLocation();
    const typePermission = pathname.includes('novo') ? 'Create' : 'Update';
    const { hasPermission } = useUserPermissionData();

    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const [toast, setToast] = useState<ToastType>(toastState);
    const [selectedInvolvedFundIndex, setSelectedInvolvedFundIndex] = useState<number | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch, readOnly } = useFormContext();
    const { appConfig } = useAppConfig();

    const involveds = watch('relatedPersonFund') ?? []
    const involvedFundSchema = validationSchemaInvolvedFundForm();

    if ((SignatureValidationStts || signatureStts) === "loading") return <RefreshProgress refreshing={true} />;
    if ((signatureStts || SignatureValidationStts) === "error") return <Error error={signatureErr || SignatureValidationErr} />;

    const setNewRelatedPerson = (values: IUploadModel) => {
        let newRelatedPerson = [...involveds];
        newRelatedPerson[selectedInvolvedFundIndex!] = values;
        setValue("relatedPersonFund", newRelatedPerson);
        setToast({
            open: true,
            title: "Lista de envolvidos atualizada com sucesso!",
            severity: "success"
        });
        closeDrawer();
    };

    const closeDrawer = () => setSelectedInvolvedFundIndex(undefined);
    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    function onDelete(index: number) {
        let rowData = [...involveds];
        rowData?.splice(index, 1);
        setToast({
            open: true,
            title: "Sucesso ao excluir o envolvido",
            severity: "error"
        });
        setValue("relatedPersonFund", rowData);
    };

    const defaultFormValue = selectedInvolvedFundIndex !== undefined ? involveds[selectedInvolvedFundIndex!] : defaultValuesInvolvedFundForm;

    const resource = 'Fund';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, rootTenancy)

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <GenericListHeader
                title='Envolvidos'
                titleButton="Adicionar envolvido"
                enableAction={enableAction}
                onClick={() => setSelectedInvolvedFundIndex(involveds?.length)}
            />
            <InvolvedFundList
                name="relatedPersonFund"
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                openDrawer={(rowNumber: number | undefined) => setSelectedInvolvedFundIndex(rowNumber ?? involveds?.length)}
                onDelete={onDelete}
            />
            <Drawer anchor='right' title="Envolvido" description="Confira as informações antes de adicionar o envolvido" open={selectedInvolvedFundIndex !== undefined} onClose={closeDrawer}>
                <FormProvider
                    validationSchema={involvedFundSchema}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewRelatedPerson}>
                    <InvolvedFundForm
                        name="relatedPersonFund"
                        signatureType={signatureType ?? []}
                        signatureValidation={signatureValidation ?? []}
                        onClose={closeDrawer}
                        personList={personAutoCompleteProps}
                        isLoading={false}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};