import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { SearchIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import moment from 'moment';
import { FraudDataset } from 'services/fraudRecord/fraudRecord.interface';

interface IFraudColumns {
    handleOnClick: (value: FraudDataset) => void;
}

export const getFraudColumns = ({ handleOnClick }: IFraudColumns): GridColDef[] => [
    {
        field: 'requestType',
        headerName: 'Tipo de Registro',
        hideSortIcons: true,
        minWidth: 120,
        editable: false,
        renderCell: (cellValues) => (cellValues?.value === 'Register' ? 'Registro' : 'Consulta'),
    },
    {
        field: 'evidenceTypeDisplay',
        headerName: 'Evidência',
        hideSortIcons: true,
        minWidth: 120,
        flex: 2,
        editable: false,
    },
    {
        field: 'fraudModalityDisplay',
        headerName: 'Modalidade de Fraude',
        hideSortIcons: true,
        minWidth: 200,
        flex: 2,
        editable: false,
    },
    {
        field: 'relatedActivityDisplay',
        headerName: 'Atividade Relacionada',
        hideSortIcons: true,
        minWidth: 120,
        flex: 2,
        editable: false,
    },
    {
        field: 'eventDateTime',
        headerName: 'Data do Evento',
        hideSortIcons: true,
        minWidth: 120,
        flex: 2,
        editable: false,
        renderCell: (cellValues) =>
            cellValues?.value ? moment(cellValues?.value).format('DD/MM/YYYY') : 'N/D',
    },
    {
        field: 'createdAt',
        headerName: 'Data do Report',
        hideSortIcons: true,
        minWidth: 120,
        flex: 2,
        editable: false,
        renderCell: (cellValues) =>
            cellValues?.value ? moment(cellValues?.value).format('DD/MM/YYYY') : 'N/D',
    },
    {
        field: 'institution',
        headerName: 'Entidade Relatora',
        hideSortIcons: true,
        minWidth: 150,
        flex: 2,
        editable: false,
        renderCell: (cellValues) => cellValues?.value?.companyName ?? 'N/D',
    },
    {
        field: 'cnpj',
        headerName: 'CNPJ da Entidade Relatora',
        hideSortIcons: true,
        minWidth: 150,
        flex: 2,
        editable: false,
        renderCell: (cellValues) => {
            const cnpjStr = String(cellValues?.row?.institution?.cnpj);
            return formatDocumentNumber(cnpjStr) ?? 'N/D';
        },
    },
    {
        field: 'actions',
        headerName: 'Ações',
        hideSortIcons: true,
        minWidth: 150,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (cellValues) => {
            return (
                <Stack
                    component={Grid}
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        textAlign: 'left',
                        marginLeft: '-4px',
                    }}
                >
                    <IconButton
                        name="btn-view"
                        onClick={() => handleOnClick(cellValues.row as FraudDataset)}
                    >
                        <SearchIcon sx={iconSx} />
                    </IconButton>
                </Stack>
            );
        },
    },
];
