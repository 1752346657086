import { Grid } from "@mui/material";
import { Button } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { FormProvider } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { getRecoverSimulationCreditNote } from "services/creditNote";
import { defaultValuesRecoverSimulationForm, validationSchemaRecoverSimulationForm } from "./RecoverSimulationSchema";
import { FieldValues } from 'react-hook-form';
import { useProductId } from "contexts/productIdContext";
import { validationRequestedAmount } from "helpers/validations/ValiadationRequestedAmount";
import { WarrantyFullType } from "pages/CreditNote/WarrantyTab/WarrantyForm/WarrantyTypes";

type RecoverSimulationCreditNoteProps = {
    setPopupProps: (props: any) => void;
    setValue: (name: string, value: any) => void;
};

type RecoverSimulationProps = {
    simulationId: string;
    token?: string;
    setSubmitError: (err: ApiResponseError | undefined) => void;
    endRequest: (success: boolean) => void;
    setValue: (name: string, value: any) => void;
    setPopupProps: (props: any) => void;
    setProductId: (productId: string | undefined) => void
    warranty?: WarrantyFullType[]
}

export const getRecoverSimulation = ({
    endRequest,
    setPopupProps,
    setSubmitError,
    setValue,
    simulationId,
    token,
    setProductId, 
    warranty
}: RecoverSimulationProps) => {
    if (token !== undefined) {
        getRecoverSimulationCreditNote(simulationId, token!)
            .then(({ data }: { data: any }) => {
                const amortization = data?.amortization
                for (let [key, value] of Object.entries(amortization)) {
                    if (key === "requestedAmount") {
                        value = validationRequestedAmount({
                            currentValue: value as number,
                            initialValue: amortization?.initialValue
                        });
                    }
                    setValue(`amortization.${key}`, value);
                };

                const productId = data?.productId;
                const productIdDisplay = data?.productIdDisplay;
                setValue("productId", productId);
                setValue("productIdDisplay", productIdDisplay);
                
                const personId = data?.personId;
                const personDisplay = data?.personDisplay;
                const personDiscriminator = data?.personDiscriminator;
                setValue("personDiscriminator", personDiscriminator);
                setValue("personId", personId);
                setValue("personDisplay", personDisplay);
                setValue("warranty", warranty);

                setProductId(productId!);
    
                setValue("createSimulation", { 
                    ...data.amortization, 
                    paymentScheduleItems: data.paymentScheduleItems
                });
    
                setPopupProps(undefined);
            }).catch((error) => setSubmitError(error)).finally(() => endRequest(true));
        
    }
}

export const RecoverSimulationCreditNote = ({ setPopupProps, setValue }: RecoverSimulationCreditNoteProps) => {
    const { startRequest, setSubmitError, endRequest } = useApiRequest();
    const { setProductId } = useProductId();
    const { token } = useIdentity();

    const onSubmit = async (values: FieldValues) => {
        const { simulationId } = values;

        getRecoverSimulation({
            endRequest,
            setPopupProps,
            setSubmitError,
            setValue,
            simulationId,
            token, 
            setProductId
        });

        startRequest();
    };

    const schema = validationSchemaRecoverSimulationForm();
    const defaultValues = defaultValuesRecoverSimulationForm;

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}>
            <>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={12} mt={1}>
                        <TextFormField
                            name="simulationId"
                            label="ID"
                            variant='outlined'
                            fullWidth
                            disabled={false}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Recuperar Simulação
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );

};