import { WarrantyListTab } from './WarrantyTab';
import { useFormContext } from 'contexts/formContext';
import { useState } from 'react';
import WarrantyFormContainer from './WarrantyForm/WarrantFormContainer';
import { WarrantyHeaderTab } from './WarrantyHeaderTab';
import { Grid } from '@mui/material';

export function WarrantyTabContainer() {
    const { setValue, watch } = useFormContext();
    const [selectedWarranty, setSelectedWarranty] = useState<number | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(1);

    const warrantyData = watch('warranty') ?? [];

    const deleteAsset = function (index: number) {
        let rowData = [...warrantyData];
        rowData?.splice(index, 1);
        setValue('warranty', rowData);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const handleOnEdit = (rowIndex: number) => setSelectedWarranty(rowIndex);

    return (
        <>
            <Grid mt={3} mb={4}>
                <WarrantyHeaderTab handleAdd={() => setSelectedWarranty(warrantyData?.length)} />
            </Grid>
            <WarrantyListTab
                name="warranty"
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                deleteAsset={deleteAsset}
                handleOnEdit={handleOnEdit}
            />
            <WarrantyFormContainer
                {...{
                    selectedWarranty,
                    onCloseDrawer: () => setSelectedWarranty(undefined), 
                }}
            />
        </>
    );
}
