/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, PersonIcon, RulerIcon, Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { RefreshProgress } from 'components/RefreshProgress';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useGetNaturalOrLegalPerson } from 'contexts/personContext';
import { isValidString } from 'helpers/formats/StringFormats';
import { handlePersonLink } from 'pages/CreditNote/CreditNoteForm/Tabs/InformationTab';
import { FC, Fragment, useEffect } from 'react';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { activeTheme } from 'services/theme';

interface InstallmenCreateFormProps {
    billingSettingAutocomplete: AutocompleteOptionList;
    communicationSettingAutocomplete: AutocompleteOptionList;
    walletAutocomplete: AutocompleteOptionList;
    personAutocomplete: AutocompleteOptionList;
    setHandleAction: (step: string) => void
}

const theme = activeTheme();
const InstallmentCreateFormFields: FC<InstallmenCreateFormProps> = ({ walletAutocomplete, personAutocomplete, billingSettingAutocomplete, communicationSettingAutocomplete, setHandleAction }) => {
    const { watch, setValue } = useFormContext();

    const handleAddPayer = () => {
        setHandleAction('add-person');
        setValue('personId', null);
        setValue('personIdDisplay', null);
    };

    const personId = watch('personId');
    const discriminator = watch('personDiscriminator');

    const { data, isFetching } = useGetNaturalOrLegalPerson({ discriminator, personId });

    function handleSetFormValuesToPayer<T extends NaturalPersonReadModel | LegalPersonReadModel>(data: T, personTypeValue: string) {
        const address = data?.address;
        const fullZipCode = address?.zipCode;

        const registrationNumber = data?.registrationNumber ?? null;
        const addressName = address?.addressName
        const addressNumber = address?.number;
        const addressComplement = address?.complement;

        const email = data?.email ?? null;

        setValue('payer', {
            name: data?.name,
            personTypeValue,
            email,
            registrationNumber,
            zipCode: fullZipCode,
            addressName,
            addressNumber,
            addressComplement
        });
    };

    const payerLegalPerson = (data: LegalPersonReadModel) => {
        const cnpj = 'Legal';
        handleSetFormValuesToPayer<LegalPersonReadModel>(data, cnpj);
        setValue('payer', { ...watch('payer'), companyName: data?.companyName })
    };

    const payerNaturalPerson = (data: NaturalPersonReadModel) => {
        const cpf = 'Natural';
        handleSetFormValuesToPayer<NaturalPersonReadModel>(data, cpf);
    };

    useEffect(() => {
        if (discriminator === 'LegalPerson' && data) {
            payerLegalPerson(data as LegalPersonReadModel);
        }
        if (discriminator === 'NaturalPerson' && data) {
            payerNaturalPerson(data as NaturalPersonReadModel);
        }
    }, [data]);

    return (
        <Fragment>
            <RefreshProgress refreshing={isFetching} />

            <Stack spacing={3.5} direction="column">
                <Typography variant="lg" color="common.black">
                    Dados da carteira
                </Typography>

                <AutocompleteField
                    label="Selecione a carteira"
                    name="walletCode"
                    displayName="walletCodeDisplay"
                    required
                    {...walletAutocomplete!}
                />

                <Typography variant="lg" color="common.black">
                    Dados do pagador
                </Typography>
                <Grid>
                    <AutocompleteField
                        label="Selecione o pagador"
                        name="personId"
                        displayName="personIdDisplay"
                        required
                        endIconType="link"
                        link={handlePersonLink({
                            personId,
                            personDiscriminator: discriminator,
                        })}
                        showEndIcon={personId}
                        {...personAutocomplete!}
                    />
                    <Stack direction='row' justifyContent='end' alignItems='center'>
                        <Button
                            size="large"
                            variant="text"
                            color="primary"
                            onClick={handleAddPayer}
                            style={{ cursor: 'pointer' }}
                            startIcon={
                                <PersonIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                            }
                        >
                            Adicionar pagador
                        </Button>
                    </Stack>
                </Grid>

                {personId && (
                    <CardInfo
                        name={watch('payer')?.name ?? ""}
                        registrationNumber={watch('payer')?.registrationNumber ?? ""}
                        companyName={watch('payer')?.companyName ?? ""}
                    />
                )}

                {isValidString(String(watch('walletCode'))) && (
                    <Fragment>
                        <Typography variant="lg" color="common.black">
                            Régua de cobrança
                        </Typography>

                        <Grid>
                            <AutocompleteField
                                label="Régua de cobrança"
                                name="billingSettingId"
                                displayName="billingSettingDisplay"
                                required
                                {...billingSettingAutocomplete!}
                            />

                            <Stack direction='row' justifyContent='end' alignItems='center'>
                                <Button
                                    size="large"
                                    variant="text"
                                    color="primary"
                                    onClick={() => setHandleAction('add-billing-setting')}
                                    style={{ cursor: 'pointer' }}
                                    startIcon={
                                        <RulerIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                                    }
                                >
                                    Adicionar régua de cobrança
                                </Button>
                            </Stack>
                        </Grid>


                        <Typography variant="lg" color="common.black">
                            Régua de comunicação
                        </Typography>

                        <Grid>

                            <AutocompleteField
                                label="Régua de comunicação"
                                name="communicationSettingId"
                                displayName="communicationSettingDisplay"
                                required
                                {...communicationSettingAutocomplete!}
                            />

                            <Stack direction='row' justifyContent='end' alignItems='center'>
                                <Button
                                    size="large"
                                    variant="text"
                                    color="primary"
                                    onClick={() => setHandleAction('add-communication-setting')}
                                    style={{ cursor: 'pointer' }}
                                    startIcon={
                                        <AddIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                                    }
                                >
                                    Adicionar régua de comunicação
                                </Button>
                            </Stack>
                        </Grid>
                    </Fragment>
                )}
            </Stack>
        </Fragment>
    );
};

export default InstallmentCreateFormFields;
