import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Grid, SxProps, Theme, TypographyOwnProps } from '@mui/material';
import React from 'react';
import { CustomizedAccordion } from './CustomAccordion.styled';
import { Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import Tooltip from 'components/Tooltip';

interface IAccordion {
    description: string;
    descriptionVariant?: TypographyOwnProps["variant"]; 
    sxAccordion?: SxProps<Theme>
    children?: React.ReactNode;
    index: number;
    disabled?: boolean;
    expanded?: boolean;
    tooltipText?: string;
}

export const CommissionFormCostsAccordionMetadataKey = 'meta.currentAccordion';

const CustomAccordionContainer: React.FC<IAccordion> = ({
    description,
    children,
    index,
    disabled,
    tooltipText,
    descriptionVariant = "xs", 
    sxAccordion = {}, 
    expanded = undefined
}) => {
    const { watch, setValue } = useFormContext();
    var currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);

    const onClickExpand = () => {
        if (currentAccordion === index) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        } else {
            setValue(CommissionFormCostsAccordionMetadataKey, index);
        }
    };

    const isExpanded = expanded !== undefined ? expanded :  currentAccordion === index || (index === 0 && currentAccordion === undefined);
    return (
        <CustomizedAccordion sx={sxAccordion} elevation={0} expanded={isExpanded} disabled={disabled}>
            <AccordionSummary expandIcon={<ExpandMore sx={iconSx} />} onClick={onClickExpand}>
                {tooltipText && (
                    <Grid container  alignItems="center">
                        <Typography variant={descriptionVariant} color="common.black" fontWeight={700}>
                            {description}
                        </Typography>
                        <Tooltip description={tooltipText} title="" />
                    </Grid>
                )}
                {!tooltipText && (
                    <Typography variant={descriptionVariant} color="common.black" fontWeight={700}>
                        {description}
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </CustomizedAccordion>
    );
};

export default CustomAccordionContainer;
